import React from "react";
import cn from "classnames";

import "./CardContainer.scss";

export interface CardContainerProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {}

const CardContainer = ({ className, ...props }: CardContainerProps) => {
    return (
        <div className={cn("ds-card-container", className)} {...props}></div>
    );
};

export default CardContainer;
