import { defineMessages } from "react-intl";

export const authMessages = defineMessages({
    notAStudent: {
        id: "authentication-notAStudent",
        defaultMessage: "Not a student?",
    },
    loginAsTeacher: {
        id: "authentication-loginAsTeacher",
        defaultMessage: "Log in as teacher",
    },
    notATeacher: {
        id: "authentication-notATeacher",
        defaultMessage: "Not a teacher?",
    },
    loginAsStudent: {
        id: "authentication-loginAsStudent",
        defaultMessage: "Login as student",
    },
    emailRequired: {
        id: "authentication-emailRequired",
        defaultMessage: "Email is required",
    },
    passwordRequired: {
        id: "authentication-passwordRequired",
        defaultMessage: "Password is required",
    },
    login: {
        id: "authentication-login",
        defaultMessage: "Log in"
    },
    teacherAuthTitle: {
        id: "authentication-teacher-title",
        defaultMessage: "Teacher"
    },
});
