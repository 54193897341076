import React from "react";
import "./ModuleCard.scss"

export interface ModuleCardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement> {
    moduleTitle?: {
        as: "h2" | "h3" | "h4" | "h5"| "div";
        content: string;
    }
}

const ModuleCard = ({children, className, moduleTitle, ...props}: ModuleCardProps) => {
    const Title = moduleTitle?.as ?? "div";
    return (
        <article  {...props}
                  className={`ds-module-card ${className ?? ""}`}
        >
            {moduleTitle && <Title className="ds-module-title">{moduleTitle.content}</Title>}
            {children}
        </article>
    );
};

export default ModuleCard