import React, { useContext } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { ActivityShell } from "@evidenceb/gameplay-interfaces";
import { UserType } from "../../../../interfaces/User";
import { configStore } from "../../../../contexts/ConfigContext";
import { getLastResults } from "../../../../utils/exerciseUtils";
import { getUrl } from "../../../../utils/dataRetrieval";
import TeacherProgressBar from "./TeacherProgressBar/TeacherProgressBar";
import PlaylistNavigationBreadCrumbs from "./PlaylistNavigationBreadcrumbs/PlaylistNavigationBreacrumds";
import { PlayerHeaderProps } from "../../PlayerBuilder";
import { Playlist } from "../../../../interfaces/Player";
import { FormattedMessage } from "react-intl";

import "./TeacherHeader.scss";
import Icon from "../../../../design-system-components/Icon/Icon";

const TeacherHeader = ({ playlistManager, className }: PlayerHeaderProps) => {
    const { config } = useContext(configStore);

    return (
        <header className={cn("teacher-header", className)}>
            {!isPlaylistFinished(playlistManager.playlist) && (
                <PlaylistNavigationBreadCrumbs
                    goToExercise={playlistManager.goToExercise}
                    currentHierarchy={{
                        module: playlistManager.playlist.module,
                        objective: playlistManager.playlist.objective!,
                        activity: playlistManager.playlist.activity!,
                    }}
                    currentExerciseIndex={playlistManager.playlist.activity!.exerciseIds.findIndex(
                        (id) =>
                            id === playlistManager.playlist.currentExercise!.id
                    )}
                    // Do not add activity details tooltip when using the
                    // Chatbot shell because the info is already included
                    // in the left panel
                    showActivityInfo={
                        playlistManager.playlist.activity?.shell !==
                        ActivityShell.Chatbot
                    }
                />
            )}

            <TeacherProgressBar
                exerciseResults={getLastResults(
                    playlistManager.playlist.exerciseResults
                )}
                currentExercise={playlistManager.playlist.currentExercise}
                exercises={playlistManager.playlist.exercises}
            />

            <div className="header_close">
                <Link
                    to={`/${getUrl(
                        config.pages.find(
                            (page) => page.type === "MODULELIST"
                        )!,
                        UserType.Teacher
                    )}`}
                >
                    <Icon path="close" size="medium" />
                    <span><FormattedMessage id="exerciseShell-close" defaultMessage="Close" /></span>
                </Link>
            </div>
        </header>
    );
};

const isPlaylistFinished = (playlist: Playlist): boolean =>
    !playlist.currentExercise;

export default TeacherHeader;
