import { HtmlString } from '@evidenceb/gameplay-interfaces';
import React, { useRef } from 'react';
import Icon from '../Icon/Icon';
import { TooltipWrapper } from '../ToolTip/ToolTip';
import "./CustomProgressBar.scss"
// SCSS

// Interfaces
export interface CustomProgressBarProps extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
> {
    label: string,
    labelTooltip?: HtmlString
    progress: number,
    /**
     * Estimates express a percentage with a number from 0 to 100
     */
    estimate?: { min: number, max: number }
    defaultText?: string
    /**
     * @default above
     */
    estimationPosition?: "above" | "under"

}

/**
 *  This progress bar is only illustrating the estimates.
 *  Unlike a loading bar, it has no role specified.
 *  Accessibility requires `defaultText` to give plain information.
 */
const CustomProgressBar = ({
                               className,
                               label,
                               labelTooltip,
                               progress,
                               estimate,
                               defaultText,
                               estimationPosition = "above"
}: CustomProgressBarProps) => {
    const progressBar = useRef<HTMLDivElement | null>(null)

    return (
        <div ref={progressBar} className={`ds-progress-bar ${className ?? ""}`} >
            <div className="ds-progress-content">
                <div className='ds-bar-label'>
                    <span>{label}</span>
                    { labelTooltip &&
                        <TooltipWrapper
                            wrapper='button'
                            tooltip={labelTooltip.$html}
                        >
                            <Icon
                                    path="question-circle"
                                    color="var(--black)"
                                    size="small"
                                />
                        </TooltipWrapper>
                    }
                </div>
                {
                    estimationPosition === "above" &&
                    <span className="ds-progress">{defaultText}</span>
                }
                <div
                    className="ds-progress-bar__background"
                    aria-hidden="true"
                >
                </div>
                <div
                    className="ds-progress-bar__real"
                    aria-hidden="true"
                    style={{
                        width: progress + "%",
                    }}
                >
                </div>
                {estimate !== undefined && estimate.max > estimate.min &&
                    <div
                        aria-hidden="true"
                        className="ds-progress-bar__estimate"
                        style={{
                            width: estimate.max - estimate.min + "%",
                            left: estimate.min + "%"
                        }}
                    >
                    </div>
                }
                {
                    estimationPosition === "under" &&
                    <span className="ds-progress ds-progress__under">{defaultText}</span>
                }
            </div>
        </div>
    )
}
export default CustomProgressBar