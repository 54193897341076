import { Theme } from "../interfaces/Theme";

export function addContentPageCss(customCss: string) {
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(customCss));
    document.head.appendChild(style);
}

export function injectCss(css: string) {
    const style = document.createElement("style");
    // The following line is required for this to work on WebKit
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    style.sheet!.insertRule(css, 0);
}

export function applyTheme(theme: Theme, useCustomColors: boolean = false) {
    injectCss(`
        :root {
            --color-primary: ${theme.colorPrimary};
            --color-primary-light: ${theme.colorPrimaryLight};
            --color-secondary: ${theme.colorSecondary};
            --color-correct: ${theme.colorCorrect};
            --color-incorrect: ${theme.colorError};
            --color-alert: ${theme.colorAlert};

            /* Typographical chart for gameplays */
            --color-editor-one: #CA411D;
            --color-editor-two: #155598;

            ${
                theme.chatbot
                    ? `
                --chatbot-color-bot-bubble: ${theme.chatbot.botBubbleColor};
                --chatbot-color-bot-bubble-opacity-60: ${theme.chatbot.botBubbleColor}60;
                --chatbot-color-bot-bubble-correct: ${theme.chatbot.botBubbleColorCorrect};
                --chatbot-color-bot-bubble-incorrect: ${theme.chatbot.botBubbleColorIncorrect};
                --chatbot-color-bot-text-correct: ${theme.chatbot.textCorrect};
                --chatbot-color-bot-text-incorrect: ${theme.chatbot.textIncorrect};
                --chatbot-color-background-correct: ${theme.chatbot.backgroundColorCorrect};
                --chatbot-color-background-incorrect: ${theme.chatbot.backgroundColorIncorrect};
                --chatbot-color-bot-bubble-feedback: ${theme.chatbot.botBubbleColorFeedback};
                --chatbot-color-user-bubble-interactive: ${theme.chatbot.userBubbleColorInteractive};
                --chatbot-color-user-bubble-non-interactive: ${theme.chatbot.userBubbleColorNonInteractive};
                --chatbot-color-disabled-bg: ${theme.chatbot.disabledBg};
                --chatbot-color-disabled-txt: ${theme.chatbot.disabledText};
                --chatbot-color-validate-btn: ${theme.chatbot.validateBg};
            `
                    : ""
            }

                ${
                    useCustomColors && theme.customColors?.playerHeaderBg
                        ? `--custom-color-player-header-bg: ${theme.customColors.playerHeaderBg};`
                        : ""
                }
                ${
                    useCustomColors && theme.customColors?.playerHeaderTxt
                        ? `--custom-color-player-header-txt: ${theme.customColors.playerHeaderTxt};`
                        : ""
                }
                ${
                    useCustomColors &&
                    theme.customColors?.playerHeaderBottomBorder
                        ? `--custom-color-player-header-bottom-border: ${theme.customColors.playerHeaderBottomBorder};`
                        : ""
                }
                ${
                    useCustomColors && theme.customColors?.footerBg
                        ? `--custom-color-footer-bg: ${theme.customColors.footerBg};`
                        : ""
                }
                ${
                    useCustomColors && theme.customColors?.footerTxt
                        ? `--custom-color-footer-txt: ${theme.customColors.footerTxt};`
                        : ""
                }
                ${
                    useCustomColors && theme.customColors?.headerTxt
                        ? `--custom-color-header-txt: ${theme.customColors.headerTxt};`
                        : ""
                }
    `);
    addContentPageCss(theme.contentPagesStyle);
}