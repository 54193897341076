import React, { useContext, useEffect, useMemo } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { isArray } from "lodash";
import { dataStore } from "../../contexts/DataContext";
import { adaptiveTestsStore } from "../../contexts/AdaptiveTestsContext";
import { sessionStore } from "../../contexts/SessionContext";
import { configStore } from "../../contexts/ConfigContext";
import { errorStore } from "../../contexts/ErrorContext";
import useGetStatements from "../../hooks/useGetStatements";
import Loader from "../../components/Loader/Loader";
import {
    adaptiveTestDiagsStatementsFilter,
    adaptiveTestStatementsFilter,
    chronologicalStatementsSort,
} from "../../utils/statements";
import AdaptiveTestsResult from "./AdaptiveTestsResults/AdaptiveTestsResults";
import {
    Recommendations,
} from "../../interfaces/AdaptiveTests";
import {
    PerObjective,
} from "@evidenceb/gameplay-interfaces";
import AdaptiveTestsModuleList from "./AdaptiveTestModuleList/AdaptiveTestsModuleList";
import { useIntl } from "react-intl";
import { getRecommendations } from "./utils/recommendations";
import { getAllTestsResults, getMainTests } from "./utils/result";

const AdaptiveTestsDashboardRouter = () => {
    const { data } = useContext(dataStore);
    const {
        session: { statements },
    } = useContext(sessionStore);
    const { config } = useContext(configStore);
    const { setErrorInfo } = useContext(errorStore);
    const { adaptiveTests, setAdaptiveTests } = useContext(adaptiveTestsStore);
    const { status: statementsStatus } = useGetStatements();
    let { path } = useRouteMatch();
    const intl = useIntl();

    const rawRecommendations = useMemo(() => {
        if (
            isArray(
                config.recommendations[Object.keys(config.recommendations)[0]]
            )
        ) {
            let newReco: PerObjective<Recommendations> = {};
            let reco = config.recommendations as PerObjective<
                Recommendations[]
            >;
            Object.keys(reco).forEach((objective) => {
                newReco[objective] = reco[objective][0] as Recommendations;
            });
            return newReco;
        }
        return config.recommendations as PerObjective<Recommendations>;
    }, [config.recommendations]);

    // Handle status in error
    useEffect(() => {
        if (statementsStatus !== "error") return;
        setErrorInfo({
            displayModal: true,
            modal: {
                type: "POPUP",
                content: {
                    title: intl.formatMessage({
                        id: "errorGetAdaptiveTest",
                        defaultMessage:
                            "An error occured while trying to retrieve past tests. Try again later.",
                    }),
                },
            },
        });
    }, [statementsStatus, setErrorInfo, intl]);

    // Get statements from tests to gather tests per modules and select last test data
    useEffect(() => {
        if (statementsStatus !== "success" || !statements) return;
        (async () => {
            const adaptiveTestStatements = statements
                .filter(adaptiveTestStatementsFilter)
                .sort(chronologicalStatementsSort);
            const adaptiveTestDiagsStatements = statements.filter(
                adaptiveTestDiagsStatementsFilter
            );
            const testsResults = getAllTestsResults(
                data,
                adaptiveTestStatements,
                adaptiveTestDiagsStatements
            );
            const mainTest = getMainTests(testsResults);
            const recommendations = getRecommendations(
                data,
                testsResults,
                mainTest,
                rawRecommendations,
                config.features.recommendationsLimits
            );

            setAdaptiveTests((curr) => ({
                ...curr,
                testsResults,
                mainTest,
                recommendations,
            }));
        })();
    }, [
        statementsStatus,
        statements,
        data,
        config,
        rawRecommendations,
        setAdaptiveTests,
    ]);

    if (
        statementsStatus === "loading" ||
        adaptiveTests.testsResults === undefined
    )
        return <Loader />;

    return (
        <Switch>
            <Route exact path={`${path}/test-result/:moduleId`}>
                <AdaptiveTestsResult />
            </Route>

            <Route>
                <AdaptiveTestsModuleList />
            </Route>
        </Switch>
    );
};

export default AdaptiveTestsDashboardRouter;
