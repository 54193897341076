import React from "react";
import { useFeatureFlag } from "@evidenceb/athena-common";
import { useContext } from "react";
import { defineMessages, useIntl, IntlShape } from "react-intl";
import useOnlineStatus from "react-online-hook";
import {
    isModuleDownloading,
    isModulePrecached,
    isObjectiveDownloading,
    isObjectivePrecached,
} from "../../contexts/infrastructure/cacheUtils";
import { CacheAction } from "../../contexts/infrastructure/cacheAction";
import { cacheStore } from "../../contexts/CacheContext";

interface CacheButtonProps {
    cacheType: "module" | "objective";
    id: string;
    /** Human readable name of the download */
    name: string;
}

type PrecacheStatus =
    | "precached"
    | "offline"
    | "downloading"
    | "not_cached"
    | "unavailable";

const CacheButton = ({ id, cacheType, name }: CacheButtonProps) => {
    const { cacheState, dispatchCacheAction } = useContext(cacheStore);
    const { isOnline } = useOnlineStatus();
    const offlineFeatures = useFeatureFlag("offlineFeatures");
    const intl = useIntl();

    const precacheStatus: PrecacheStatus = (() => {
        if ("serviceWorker" in navigator && offlineFeatures) {
            if (isPrecached(cacheType)(cacheState, id)) {
                return "precached";
            } else if (!isOnline) {
                return "offline";
            } else if (isDownloading(cacheType)(cacheState, id)) {
                return "downloading";
            } else {
                return "not_cached";
            }
        } else {
            return "unavailable";
        }
    })();

    if (precacheStatus === "unavailable") return null;

    const { cacheAction, disabled, icon } = getBtnInfo(
        precacheStatus,
        cacheType,
        id,
        name,
        intl
    );

    return (
        <button
            onClick={
                cacheAction
                    ? (event) => {
                          event.stopPropagation();
                          dispatchCacheAction(cacheAction);
                      }
                    : undefined
            }
            disabled={disabled}
        >
            {/* eslint-disable-next-line jsx-a11y/alt-text */ /* (le alt existe bien dans icon) */}
            <img {...icon} />
        </button>
    );
};
export default CacheButton;

const getBtnInfo = (
    precacheStatus: Exclude<PrecacheStatus, "unavailable">,
    cacheType: "module" | "objective",
    id: string,
    downloadName: string,
    intl: IntlShape
): {
    cacheAction?: CacheAction;
    disabled?: boolean;
    icon: { src: string; alt: string };
} => {
    switch (precacheStatus) {
        case "precached":
            return {
                cacheAction: {
                    type: "START_UNCACHE",
                    payload: {
                        cacheType,
                        id,
                    },
                },
                icon: {
                    src: "icons/download_off.svg",
                    alt: intl.formatMessage(messages.removeDownload, {
                        name: downloadName,
                    }),
                },
            };
        case "downloading":
            return {
                cacheAction: {
                    type: "CANCEL_TASK",
                    payload: {
                        cacheType,
                        id,
                    },
                },
                icon: {
                    src: "icons/downloading.svg",
                    alt: intl.formatMessage(messages.cancelDownload, {
                        name: downloadName,
                    }),
                },
            };
        case "not_cached":
            return {
                cacheAction: {
                    type: "START_CACHE",
                    payload: {
                        cacheType,
                        id,
                    },
                },
                icon: {
                    src: "icons/download.svg",
                    alt: intl.formatMessage(messages.startDownload, {
                        name: downloadName,
                    }),
                },
            };
        case "offline":
            return {
                cacheAction: undefined,
                disabled: true,
                icon: {
                    src: "icons/wifi_off.svg",
                    alt: intl.formatMessage(messages.cannotDownload),
                },
            };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = precacheStatus;
            throw new Error(`Precache status ${precacheStatus} not configured`);
    }
};

const isPrecached = (cacheType: "module" | "objective") => {
    if (cacheType === "module") return isModulePrecached;
    return isObjectivePrecached;
};
const isDownloading = (cacheType: "module" | "objective") => {
    if (cacheType === "module") return isModuleDownloading;
    return isObjectiveDownloading;
};

const messages = defineMessages({
    cannotDownload: {
        id: "a11y-offline-cannotDownload",
        defaultMessage:
            "Cannot download. Connect to the internet to enable downloading.",
    },
    cancelDownload: {
        id: "a11y-offline-cancelDownload",
        defaultMessage: "Cancel {name} download",
    },
    startDownload: {
        id: "a11y-offline-startDownload",
        defaultMessage: "Start downloading {name}",
    },
    removeDownload: {
        id: "a11y-offline-removeDownload",
        defaultMessage: "Remove {name} from downloads",
    },
});
