import { IntlShape } from "react-intl";
import { ComingNext } from "../interfaces/Player";

export const getFeedbackButtonText = (
    comingNext: ComingNext,
    intl: IntlShape
): string => {
    switch (comingNext) {
        case "endOfPlaylist":
            return intl.formatMessage({id: "exerciseShell-feedback-endPlaylist", defaultMessage: "Finish"});
        case "nextExercise":
            return intl.formatMessage({id: "exerciseShell-feedback-nextExercise", defaultMessage: "Next exercise"});
        case "retry":
            return intl.formatMessage({id: "exerciseShell-feedback-retry", defaultMessage: "Try again"});
        case "nextActivity":
            return intl.formatMessage({id: "exerciseShell-feedback-nextActivity", defaultMessage: "Next activity"});
        default:
            return intl.formatMessage({id: "exerciseShell-close", defaultMessage: "Close"});
    }
};
