import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { configStore } from "../../../../contexts/ConfigContext";
import { commonMessages } from "../../../../utils/messages";
import Button from "../../../../design-system-components/Button/Button";
import Card from "../../../../design-system-components/Card/Card";
import Input from "../../../../design-system-components/Input/Input";
import PasswordCriteria from "./PasswordCriteria/PasswordCriteria";
import Error from "../../Error/Error";

import "./PasswordChangeForm.scss";

export const MIN_PASSWORD_LENGTH = 12;
const SYMBOL_REGEX = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

interface Props {
    onSubmit: (newPwd: string) => void;
    submitError?: "mismatch" | "same_password";
}
interface NewPwdProps {
    type: "new";
    email: string;
}
interface ChangePwdProps {
    type: "forgotten";
}

const PasswordChangeForm = ({
    onSubmit,
    submitError,
    ...props
}: Props & (NewPwdProps | ChangePwdProps)) => {
    const intl = useIntl();
    const { config } = useContext(configStore);
    const { register, handleSubmit, watch, formState } = useForm<{
        newPwd: string;
        newPwdConfirm: string;
    }>();

    // Show pwd criteria when input is focused or after the form is submitted
    const [showPwdCriteria, setShowPwdCriteria] = useState(false);

    // Update pwd criteria compliance when input changes
    const [pwdCriteriaCompliance, setPwdCriteriaCompliance] =
        useState<PwdCriteriaCompliance>({
            criteria: criteria.map((criterion) => ({
                name: criterion,
                compliant: false,
            })),
            level: "polite",
        });

    return (
        <Card
            cardTitle={{
                as: "h1",
                content: intl.formatMessage(
                    props.type === "new"
                        ? messages.createAccount
                        : messages.newPwd,
                    {
                        appName: config.client_name,
                    }
                ),
            }}
            className="password-change-form"
        >
            {submitError && <Error error={intl.formatMessage(messages[submitError])} />}

            <form
                onSubmit={handleSubmit((data) => {
                    onSubmit(data.newPwd);
                })}
            >
                {props.type === "new" && (
                    <>
                        <label
                            className="password-change-form__label password-change-form__label--inactive"
                            htmlFor="email"
                        >
                            {intl.formatMessage(commonMessages.email)}
                        </label>
                        <input
                            className="password-change-form__email"
                            type="text"
                            id="email"
                            disabled
                            value={props.email}
                        />
                    </>
                )}

                <label
                    className="password-change-form__label"
                    htmlFor="new-pwd"
                >
                    {intl.formatMessage(messages.newPwd)}
                </label>
                <Input
                    className="password-change-form__input"
                    id="new-pwd"
                    type="password"
                    allowReveal
                    onFocus={() => setShowPwdCriteria(true)}
                    {...register("newPwd", {
                        required: true,
                        validate: pwdValidators,
                        onChange: (e) => {
                            setPwdCriteriaCompliance((curr) => ({
                                ...curr,
                                criteria: criteria.map((criterion) => ({
                                    name: criterion,
                                    compliant: pwdValidators[criterion](
                                        e.target.value
                                    ),
                                })),
                            }));
                        },
                        onBlur: () => {
                            if (pwdCriteriaCompliance.level === "polite")
                                setShowPwdCriteria(false);
                        },
                    })}
                    error={
                        pwdCriteriaCompliance.level === "alert" &&
                        pwdCriteriaCompliance.criteria.some(
                            (criterion) => !criterion.compliant
                        )
                    }
                />
                <PasswordCriteria
                    pwdCriteriaCompliance={pwdCriteriaCompliance}
                    showPwdCriteria={showPwdCriteria}
                />

                <label
                    className="password-change-form__label"
                    htmlFor="pwd-confirmation"
                >
                    {intl.formatMessage(messages.pwdConfirmationLabel)}
                </label>
                <Input
                    id="pwd-confirmation"
                    className="password-change-form__input"
                    type="password"
                    allowReveal
                    {...register("newPwdConfirm", {
                        required: true,
                        validate: {
                            identical: (value) => value === watch("newPwd"),
                        },
                    })}
                    error={
                        formState.errors.newPwdConfirm &&
                        intl.formatMessage(messages.pwdMatch)
                    }
                />
                <Button
                    variant="primary"
                    disabled={!formState.dirtyFields.newPwdConfirm}
                    type="submit"
                    center
                    onClick={() => {
                        setPwdCriteriaCompliance((curr) => ({
                            ...curr,
                            level: "alert",
                        }));
                    }}
                    label={intl.formatMessage(
                        props.type === "new"
                            ? messages.newPwdValidate
                            : messages.changePwdValidate
                    )}
                />
            </form>
        </Card>
    );
};

export enum Criteria {
    minLength = "minLength",
    oneNumber = "oneNumber",
    oneSymbol = "oneSymbol",
    oneUppercase = "oneUppercase",
}

const criteria: Criteria[] = [
    Criteria.minLength,
    Criteria.oneNumber,
    Criteria.oneSymbol,
    Criteria.oneUppercase,
];

export interface PwdCriteriaCompliance {
    /**
     * This represents aria alert levels. It should be polite when the user has
     * not submitted the form yet, and become an alert when the form was
     * submitted
     */
    level: "polite" | "alert";
    criteria: { name: Criteria; compliant: boolean }[];
}

const pwdValidators: { [key in Criteria]: (value: string) => boolean } = {
    minLength: (value) => value.length >= MIN_PASSWORD_LENGTH,
    oneNumber: (value) => /\d/.test(value),
    oneSymbol: (value) =>
        SYMBOL_REGEX.test(value),
    oneUppercase: (value) => /[A-Z]/.test(value),
};

export const messages = defineMessages({
    newPwd: {
        id: "authentication-pwd-newPwd",
        defaultMessage: "New Password",
    },
    pwdConfirmationLabel: {
        id: "authentication-pwd-confirmNewPwd",
        defaultMessage: "Confirm new password",
    },
    pwdMatch: {
        id: "authentication-pwd-errorPwdMustMatch",
        defaultMessage: "Passwords must match",
    },
    createAccount: {
        id: "authentication-pwd-createAccountTitle",
        defaultMessage: "Create your {appName} account",
    },
    newPwdValidate: {
        id: "authentication-pwd-createAccountValidate",
        defaultMessage: "Create my password",
    },
    changePwdValidate: {
        id: "authentication-pwd-changePwdValidate",
        defaultMessage: "Confirm new password",
    },
    same_password: {
        id: "authentication-pwd-errorSamePassword",
        defaultMessage: "New password must be different from the old one",
    },
    mismatch: {
        id: "authentication-pwd-errorCriteria",
        defaultMessage: "The password doesn't meet the criteria",
    }
});

export default PasswordChangeForm;
