import React, { DetailedHTMLProps, VideoHTMLAttributes } from "react";
import useAssetsDetails from "../../hooks/useAssetsDetails";
import { getAssetUrl } from "@evidenceb/athena-common";

// TODO: Refactor with VideoResource (EVBPROD-2397)
const VideoAsset = ({
    src,
    poster,
    ...props
}: DetailedHTMLProps<
    VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
> & { src: string; poster: string }) => {
    const assetsDetails = useAssetsDetails();

    return (
        <video
            {...props}
            src={getAssetUrl(src, assetsDetails)}
            poster={getAssetUrl(poster, assetsDetails)}
            controls
        />
    );
};

export default VideoAsset;
