import React, { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import ControlledDialog from "../../../../design-system-components/ControlledDialog/ControlledDialog";
import MessageDismiss from "../../../../components/MessageDismiss/MessageDismiss";
import { dataStore } from "../../../../contexts/DataContext";
import { DismissableMessages } from "../../../../contexts/SessionContext";
import { PRLevel } from "../../../../interfaces/PedagogicalResourcesManagement";
import { getObjectiveById } from "../../../../utils/dataRetrieval";
import htmlHandler from "../../../../utils/html-handler";
import { ConfirmationModalProps } from "../usePRConfirmation";

const LockObjectiveAndDescendantObjectivesConfirmationModal = ({
    confirmation,
    confirm,
}: ConfirmationModalProps) => {
    const intl = useIntl();
    const { data } = useContext(dataStore);

    const objective = useMemo(
        () => getObjectiveById(confirmation.originId, data),
        [confirmation.originId, data]
    );

    return (
        <ControlledDialog
            title={intl.formatMessage(
                {
                    id: "prm-lockObjectiveAndDependencies",
                    defaultMessage:
                        "Lock objective {title} and its dependencies",
                },
                { title: objective.title.short?.toLowerCase() }
            )}
            description={{$html: intl.formatMessage(
                {
                    id: "html-prm-lockObjectiveAndDescendants-description",
                    defaultMessage:
                        "Objective {title} is a prerequisite for {numberOfDependencies} other objectives. To ensure consistency, <b>deactivating it will automatically deactivate elements that depend on it.</b>",
                },
                {
                    ...htmlHandler,
                    title: objective.title.short!.toLowerCase(),
                    numberOfDependencies:
                        "" +
                        (confirmation.itemsToToggle.filter(
                            (item) => item.level === PRLevel.Objectives
                        ).length -
                            1),
                }
            )}}
            primaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-listItem-lock",
                    defaultMessage: "Deactivate",
                }),
                fn: () => {
                    confirm(true);
                },
            }}
            secondaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-cancel",
                    defaultMessage: "Cancel",
                }),
                fn: () => {
                    confirm(false);
                },
            }}
        >
            <MessageDismiss
                sessionMessageKey={
                    DismissableMessages.prmObjectiveLockingConfirmationModal
                }
            />
        </ControlledDialog>
    );
};

export default LockObjectiveAndDescendantObjectivesConfirmationModal;
