import React, { useState } from "react";
import { useIntl } from "react-intl";
import Icon from "../../../../../design-system-components/Icon/Icon";
import "./Filter.scss";
export enum ObjectivesFilter {
    ALL = "all",
    DIFFICULTIES = "negative",
    FACILITIES = "positive",
    COMPLETED = "completed",
    IN_PROGRESS = "inProgress"
}

interface Props {
    progressFilter: ObjectivesFilter | undefined;
    setProgressFilter: React.Dispatch<React.SetStateAction<ObjectivesFilter | undefined>>;
}

const Filter = ({ progressFilter, setProgressFilter }: Props) => {
    const intl = useIntl();
    const [showFilters, setShowFilters] = useState<boolean>(false)
    const filters = {
        [ObjectivesFilter.ALL]: {
            label: intl.formatMessage({id:"dashboard-studentDetails-filterDefaultValue", defaultMessage:"Whole progression"}) ,
            icon: undefined,
        },
        [ObjectivesFilter.DIFFICULTIES]: {
            label: intl.formatMessage({id:"dashboard-studentDetails-fileters-difficulties", defaultMessage:"Difficulties"}) ,
            icon: <Icon path="alert_inf" size="medium" />,
        },
        [ObjectivesFilter.FACILITIES]: {
            label: intl.formatMessage({id:"dashboard-studentDetails-fileters-facilities", defaultMessage:"Successes"}) ,
            icon: <Icon path="alert_sup" size="medium" />,
        },
        [ObjectivesFilter.COMPLETED]: {
            label: intl.formatMessage({id:"dashboard-studentDetails-fileters-validatedObjectives", defaultMessage:"Completed objectives"}) ,
            icon: <span className="filter-icon filter-icon-completed"></span>,
        },
        [ObjectivesFilter.IN_PROGRESS]: {
            label: intl.formatMessage({id:"dashboard-studentDetails-fileters-currentObjectives", defaultMessage:"Current exercise"}) ,
            icon: <span className="filter-icon filter-icon-inProgress"></span>,
        },
    }
    const currentFilter = progressFilter ? filters[progressFilter] : filters[ObjectivesFilter.ALL]

    const toggleFilter = () => setShowFilters(!showFilters)

    const filterHandler = (newFilter: ObjectivesFilter) => {
        setProgressFilter(newFilter);
        setShowFilters(false)
    }

    const resetFilter = () => {
        setProgressFilter(undefined);
        setShowFilters(false);
    };
    return (
        <>
            {/* [TODO] This should be a <select> */}
            <div className="details-custom-select filters">
                {progressFilter && <button onClick={resetFilter}>X</button>}

                <div className="label" onClick={toggleFilter}>
                    {currentFilter.icon}
                    <span className="text">
                        {currentFilter.label}
                    </span>
                    <Icon path={showFilters ? "chevron_up" : "chevron_down"} size="medium" className="icon" />
                </div>
                {showFilters && (
                    <ul className="select">
                        {
                            Object.keys(filters).map((_filter, i) => {
                                let filter = _filter as ObjectivesFilter
                                if (filter !== ObjectivesFilter.ALL)
                                    return (
                                        <li 
                                            key={i} 
                                            onClick={() => filterHandler(filter)}
                                            className="option"
                                        >
                                            {filters[filter].icon}
                                            <span className="label">
                                                {filters[filter].label}
                                            </span>
                                        </li>
                                    )
                                return null;
                            })
                        }
                    </ul>
                )}
            </div>
        </>
    );
};

export default Filter;
