import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getFeedbackButtonText } from "../../../../../utils/feedbackUtils";
import ChatBubble from "../ChatBubble/ChatBubble";
import {
    ComingNext,
    ExerciseResult as IExerciseResult,
} from "../../../../../interfaces/Player";
import {
    ContentBlock,
    Exercise,
    GameplayMode,
    isHtmlStringInstruction,
    isLegacyFeedbackContent,
} from "@evidenceb/athena-common";

import "./ExerciseResult.scss";
import useAssetsDetails from "../../../../../hooks/useAssetsDetails";
import FeedbackExplicatif from "./FeedbackExplicatif";

export type ExerciseResultProps = {
    exerciseResult: IExerciseResult<any>;
    exercise: Exercise<any, any>;
    onGoToNextExercise?: () => void;
    comingNext?: ComingNext;
};

const ExerciseResult: React.FC<ExerciseResultProps> = ({
    exercise,
    exerciseResult,
    onGoToNextExercise,
    comingNext,
}) => {
    const [messageState, setMessageState] = useState("typing");
    useEffect(() => {
        setTimeout(() => setMessageState("display"), 1000);
    }, []);

    return (
        <>
            <Instruction exercise={exercise} />
            {messageState === "typing" ? (
                <EvaluatingResponse />
            ) : (
                <>
                    <Result
                        exercise={exercise}
                        exerciseResult={exerciseResult}
                    />
                    {exercise.executionOptions?.showFeedback ? (
                        <>
                            <Feedback
                                exerciseResult={exerciseResult}
                                exercise={exercise}
                            />
                            {exerciseResult.feedbackExplicatif && (
                                <FeedbackExplicatif
                                    exerciseResult={exerciseResult}
                                    exercise={exercise}
                                />
                            )}
                        </>
                    ) : null}
                    <GoToNext
                        comingNext={comingNext}
                        onGoToNextExercise={onGoToNextExercise}
                    />
                </>
            )}
        </>
    );
};
export default ExerciseResult;

function Instruction({ exercise }: Pick<ExerciseResultProps, "exercise">) {
    // TODO: check when instruction can be undefined
    return exercise.instruction ? (
        <ChatBubble
            className="ExerciseResultInstruction"
            direction="left"
            botAvatar="visible"
            color="var(--chatbot-color-bot-bubble)"
        >
            <p>
                {isHtmlStringInstruction(exercise.instruction) &&
                    parse(exercise.instruction.$html)}
                {/* TODO: HtmlString audio and Content[][] type instructions are not yet implemented for the Chatbot */}
            </p>
            {exercise.messages?.length ? (
                <div className="additional-instructions">
                    {exercise.messages.map((message) => (
                        <p key={message.$html}>{parse(message.$html)}</p>
                    ))}
                </div>
            ) : null}
        </ChatBubble>
    ) : null;
}

function Result({
    exercise,
    exerciseResult,
}: Pick<ExerciseResultProps, "exercise" | "exerciseResult">) {
    const intl = useIntl();
    const assetsDetails = useAssetsDetails({ forGameplay: true });
    const isLastTry =
        exerciseResult.try === exercise.executionOptions?.numberOfTries;

    return (
        <ChatBubble className="ExerciseResultResult" direction="right">
            <exercise.Gameplay
                mode={GameplayMode.NonInteractive}
                data={exercise.data}
                exerciseResult={exerciseResult}
                nonInteractiveOptions={{
                    showCorrectAnswer:
                        isLastTry &&
                        (exercise.executionOptions?.showCorrectAnswer ?? false),
                    // TODO: should we show the correction before the last try?
                    showCorrection:
                        isLastTry &&
                        (exercise.executionOptions?.showCorrection ?? false),
                    showCorrectness:
                        exercise.executionOptions?.showCorrectness ?? false,
                }}
                showCorrectAnswer={
                    isLastTry &&
                    (exercise.executionOptions?.showCorrectAnswer ?? false)
                }
                // TODO: make non-required
                onTrackingEvent={() => null}
                //Only to fix typescript error, it can probably be reworked
                onExerciseResult={() => null}
                assetsDetails={assetsDetails}
                intl={intl}
            />
        </ChatBubble>
    );
}

function EvaluatingResponse() {
    return (
        <ChatBubble
            className="EvaluatingResponse"
            direction="left"
            botAvatar="hidden"
            color="var(--chatbot-color-bot-bubble)"
        >
            <div className="loading" style={{ width: "30px" }} />
        </ChatBubble>
    );
}

function Feedback({
    exerciseResult,
    exercise,
}: Pick<ExerciseResultProps, "exercise" | "exerciseResult">) {
    const assetsDetails = useAssetsDetails();
    const intl = useIntl();

    if (
        exerciseResult.feedback &&
        isLegacyFeedbackContent(exerciseResult.feedback)
    )
        return (
            <>
                {Array.isArray(exerciseResult.feedback) ? (
                    exerciseResult.feedback.map((fb) => (
                        <ChatBubble
                            className="ExerciseResultFeedback"
                            direction="left"
                            botAvatar="hidden"
                            color={
                                exerciseResult.correct
                                    ? "var(--chatbot-color-bot-bubble-correct)"
                                    : "var(--chatbot-color-bot-bubble-incorrect)"
                            }
                            textColor={
                                exerciseResult.correct ? "white" : undefined
                            }
                        >
                            {fb.title}
                        </ChatBubble>
                    ))
                ) : (
                    <ChatBubble
                        className="ExerciseResultFeedback"
                        direction="left"
                        botAvatar="hidden"
                        color={
                            exerciseResult.correct
                                ? "var(--chatbot-color-bot-bubble-correct)"
                                : "var(--chatbot-color-bot-bubble-incorrect)"
                        }
                        textColor={exerciseResult.correct ? "white" : undefined}
                    >
                        {exerciseResult.feedback.title}
                    </ChatBubble>
                )}
            </>
        );
    else if (
        exerciseResult.feedback &&
        !isLegacyFeedbackContent(exerciseResult.feedback)
    ) {
        return (
            <ContentBlock
                data={exerciseResult.feedback}
                assetsDetails={assetsDetails}
                intl={intl}
            />
        );
    }
    return <></>;
}

export function GoToNext({
    onGoToNextExercise,
    comingNext,
}: Pick<ExerciseResultProps, "onGoToNextExercise" | "comingNext">) {
    const intl = useIntl();

    return onGoToNextExercise ? (
        <div className="ExerciseResultGoToNext">
            <button onClick={() => onGoToNextExercise()}>
                {getFeedbackButtonText(comingNext!, intl)}
            </button>
        </div>
    ) : null;
}
