import React, { useRef, useContext, useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import cn from "classnames";
import { Page, Pagetype } from "../../interfaces/Config";
import { configStore } from "../../contexts/ConfigContext";
import { sessionStore } from "../../contexts/SessionContext";
import { getLabel, getUrl } from "../../utils/dataRetrieval";
import { UserType } from "../../interfaces/User";
import useAthenaAPIClient from "../../hooks/useAthenaAPIClient";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import Button from "../../design-system-components/Button/Button";
import Icon from "../../design-system-components/Icon/Icon";
import SpecimenBanner from "../Specimen/SpecimenBanner/SpecimenBanner";
import SwitchUserModal from '../Specimen/Modal/Modal'
import { Image } from "@evidenceb/athena-common";
import useAssetsDetails from "../../hooks/useAssetsDetails";
import { commonMessages } from "../../utils/messages";

import "./Header.scss";
import { useOverlayTriggerState } from "react-stately";

const NAVLINK_CLASS = "header__nav-link"

// Reference for the hamberger menu's accessibility : https://www.accede-web.com/en/guidelines/rich-interface-components/hamburger-menu/

interface HeaderProps {
    logo: string;
    pages: Page[];
}

function Header({ logo, pages }: HeaderProps) {
    const { config } = useContext(configStore);
    const { session, setSession } = useContext(sessionStore);
    const athenaAPIClient = useAthenaAPIClient();
    const intl = useIntl();
    const history = useHistory()
    const switchUserModal = useOverlayTriggerState({})

    const assetsDetails = useAssetsDetails();

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const toggleMobileMenuHandler = () => {
        setIsMobileMenuOpen((curr) => !curr);
    };

    const logoutHandler = async () => {
        let isLoggedOut = await athenaAPIClient.logout<string>();
        if (!isLoggedOut) return;

        localStorage.clear();

        const provider = config.logoutUrl.find(
            (item) => item.provider === session.appProvider
        );
        const defaultProvider = config.logoutUrl.find(
            (item) => item.provider === "default"
        );
        let url: string;
        if (provider) url = getRedirectionUrl(provider.url);
        else if (defaultProvider) url = getRedirectionUrl(defaultProvider.url);
        else url = window.location.origin;
        window.location.assign(url);
    };

    const hamburgerBtnRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        const escHandler = (e: KeyboardEvent) => {
            if (e.key !== "Escape") return;
            if (!document.activeElement?.classList.contains(NAVLINK_CLASS)) return;
            hamburgerBtnRef.current?.focus();
            setIsMobileMenuOpen(false);
        }
        document.addEventListener("keydown", escHandler);
        return () => {
            document.removeEventListener("keydown", escHandler);
        }
    }, [])

    const switchUserHandler = () => {
        setSession(session => ({
            ...session,
            userType: session.userType === UserType.Teacher ? UserType.Student : UserType.Teacher
        }))
        history.push('/')
    }
    return (
        <>  
        <header className="header-top">
            <div className="container">
                <div className="logo">
                    {session.userType === UserType.Teacher ? (
                        <Link to={`/`}>
                            <Image
                                src={logo}
                                assetsDetails={assetsDetails}
                                alt={intl.formatMessage(commonMessages.goHome)}
                            />
                        </Link>
                    ) : (
                        <Image
                            src={logo}
                            assetsDetails={assetsDetails}
                            alt={config.client_name}
                        />
                    )}
                </div>

                {config.features.backlinkURL && (
                    <Button
                        className="header__backlink"
                        asLink={{
                            href: config.features.backlinkURL,
                        }}
                        label={intl.formatMessage(
                            {
                                id: "backlink-label",
                                defaultMessage: "Go back to {appName}",
                            },
                            { appName: config.client_name }
                        )}
                        color="secondary"
                        variant="secondary"
                        icon={{
                            path: "arrow_right",
                            position: "left",
                        }}
                    />
                )}

                <div
                    className={cn("menu-container", {
                        "menu-container--show": isMobileMenuOpen,
                    })}
                >
                    <nav
                        role="navigation"
                        aria-label={intl.formatMessage(messages.mainMenu)}
                    >
                        <button
                            ref={hamburgerBtnRef}
                            aria-expanded={isMobileMenuOpen}
                            onClick={toggleMobileMenuHandler}
                            className={cn("header__mobile-menu-btn", {
                                "header__mobile-menu-btn--open":
                                    !isMobileMenuOpen,
                                "header__mobile-menu-btn--close":
                                    isMobileMenuOpen,
                            })}
                            title={intl.formatMessage(
                                isMobileMenuOpen
                                    ? messages.closeNavigationMenu
                                    : messages.openNavigationMenu
                            )}
                        >
                            <Icon
                                path={
                                    isMobileMenuOpen ? "close" : "menu_mobile"
                                }
                                size="clickable"
                            />
                        </button>

                        <ul>
                            {pages
                                .filter(
                                    (page) =>
                                        page.inTopNav &&
                                        page.authorized?.find(
                                            (user) => user === session.userType
                                        ) &&
                                        page.type !== Pagetype.LOGOUT &&
                                        page.type !== Pagetype.SWITCH_USER_TYPE                      )
                                .map((page, i) => (
                                    <li
                                        key={`navItem-${page.type}-${i}`}
                                        onClick={toggleMobileMenuHandler}
                                    >
                                        <NavLink
                                            className={NAVLINK_CLASS}
                                            exact
                                            to={`/${getUrl(
                                                page,
                                                session.userType
                                            )}`}
                                            title={getLabel(
                                                page,
                                                session.userType
                                            )}
                                        >
                                            {getLabel(page, session.userType)}
                                        </NavLink>
                                    </li>
                                ))}
                        </ul>

                        {pages.some(
                            (page) =>
                                page.inTopNav &&
                                page.authorized?.find(
                                    (userType) => userType === session.userType
                                ) &&
                                page.type === "LOGOUT"
                        ) && (
                            <button
                                className="header__logout-btn"
                                onClick={logoutHandler}
                            >
                                <span className="label">
                                    <Icon
                                        path="logout"
                                        size="medium"
                                        className="label_icon"
                                    />
                                    <FormattedMessage
                                        id="misc-logout"
                                        defaultMessage="Log out"
                                    />
                                </span>
                            </button>
                        )}

                        {pages
                            .filter(
                                (page) =>
                                    page.inTopNav &&
                                    page.authorized?.find(
                                        (user) => user === session.userType
                                    ) &&
                                    page.type === Pagetype.SWITCH_USER_TYPE
                            )
                            .map((page, i) => (
                                <Button
                                    key={`navItem-${page.type}-${i}`}
                                    className="header__button--action-button"
                                    icon={{ path: "switch-user", size: "large" }}
                                    label={getLabel(page, session.userType)}
                                    size="medium"
                                    color="primary"
                                    onClick={() => {
                                        if (session.dismissMessages.specimenSwitchUserModal)
                                            switchUserHandler()
                                        else
                                            switchUserModal.open()
                                    }}
                                />
                            ))
                        }
                    </nav>
                </div>
            </div>
        </header>
        {session.specimen && (
            <>
                <SpecimenBanner />
                { switchUserModal.isOpen && !session.dismissMessages.specimenSwitchUserModal && (
                    <SwitchUserModal
                        state={switchUserModal}
                        onModalDismiss={switchUserModal.close}
                        onClick={() => {
                            switchUserHandler()
                            switchUserModal.close()
                            setSession(session => ({...session, dismissMessages: {...session.dismissMessages, specimenSwitchUserModal: true}}))
                        }}
                        title={intl.formatMessage({ id: "header-specimen-switchToStudent-modalTitle", defaultMessage: "Access the student version" })}
                        content={intl.formatMessage({ id: "header-specimen-switchToStudent-modalDescription", defaultMessage: "On the student interface, you can start a personalized course. The algorithm selects, in a zone of the activity bank that evolves as you go along, the most useful exercises to help the student progress. Each student benefits from a different exercise path." })}
                        buttonLabel={intl.formatMessage({ id: "header-specimen-switchToStudent-modalButton", defaultMessage: "Access the student version" })}
                    />
                )}
            </>
        )}
        </>
    );
}

const getRedirectionUrl = (partialUrl: string): string => {
    if (partialUrl.startsWith("/"))
        return window.location.origin.replace(/\/$/, "") + partialUrl;
    return partialUrl;
};

export default Header;

const messages = defineMessages({
    openNavigationMenu: {
        id: "a11y-openNavigationMenu",
        defaultMessage: "Open navigation menu",
    },
    closeNavigationMenu: {
        id: "a11y-closeNavigationMenu",
        defaultMessage: "Close navigation menu",
    },
    mainMenu: {
        id: "a11y-mainMenu",
        defaultMessage: "Main menu",
    },
});
