import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import cn from 'classnames'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'

import "./RemovableBanner.scss"
import { DismissableMessages, sessionStore } from '../../contexts/SessionContext'

interface RemovableBannerProps {
    /**
     * @default "informational"
     */
    variant?: "informational",
    onClose?: () => void,
    messageKey?: DismissableMessages,
    children: React.ReactNode,
}

const RemovableBanner = ({
    variant = "informational",
    onClose,
    messageKey,
    children,
}: RemovableBannerProps) => {
    const intl = useIntl()
    const { setSession } = useContext(sessionStore)

    const getIcon = () => {
        return 'question-circle'
    }

    return (
        <div className={cn('ds-removable-banner', `ds-removable-banner--${variant}`)}>
            <Icon path={getIcon()} />
            <p className='ds-removable-banner__content'>
                {children}
            </p>
            {(messageKey || onClose) &&
                <Button
                    variant='invisible'
                    icon={{ path: "close" }}
                    label={intl.formatMessage({ id: "close", defaultMessage: "Close" })}
                    hideLabel
                    onPress={() => {
                        if (messageKey)
                            setSession(session => ({
                                ...session,
                                dismissMessages: {
                                    ...session.dismissMessages,
                                    [messageKey]: true
                                }
                            }))
                        if (onClose)
                            onClose()
                    }}
                />
            }
        </div>
    )
}

export default RemovableBanner