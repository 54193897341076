import React from 'react';
// External modules
import ReactCodeInput, { ReactCodeInputProps } from "react-code-input";
// SCSS
import "./CodeInput.scss";
// Interfaces
interface CodeInputProps {
    name: string;
    type: ReactCodeInputProps["type"];
    onValueChange: (value: string) => void;
    isValid?: ReactCodeInputProps["isValid"];
    info?: string;
    fields?: ReactCodeInputProps["fields"];
}
const CodeInput = ({ name, type, onValueChange, isValid, info, fields, className, ...props }: CodeInputProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {

    return (
        <div {...props} className={`code-form ${className ?? ""}`}>
            <div className="code-form__form">
                <ReactCodeInput
                    type={type}
                    fields={fields}
                    inputMode={"verbatim"}
                    name={name}
                    onChange={onValueChange}
                    inputStyle={{ ...defaultStyle, ...idleStyle }}
                    inputStyleInvalid={{ ...defaultStyle, ...errorStyle }}
                />
                {
                    info && <span>{info}</span>
                }
            </div>
        </div>
    )
}

export default CodeInput

const defaultStyle = {
    width: "100%",
    height: (80 / 16) + "rem",
    fontSize: (64 / 16) + "rem",
    borderRadius: "4px",
    backgroundColor: "var(--white)",
    color: "inherit",
    border: "2px solid var(--dark-primary-blue)",
}
const idleStyle = {
    backgroundColor: "var(--white)",
}
const errorStyle = {
    backgroundColor: "rgb(185, 74, 72);",
}