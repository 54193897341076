import React, { useMemo, useRef } from "react";
import { AriaDialogProps, useDialog } from "@react-aria/dialog";
import cn from "classnames";
import { AriaButtonProps, useButton } from "@react-aria/button";
import VisuallyHidden from "../../components/VisuallyHidden/VisuallyHidden";
import Icon from "../Icon/Icon";
import { commonMessages } from "../../utils/messages";
import { useIntl } from "react-intl";

import "./Dialog.scss";

export type DialogProps = {
    children: React.ReactNode;
    title?: {
        as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div" | "span";
        content: string | React.ReactNode;
        className?: string;
    };
    onClose?: () => void;
    className?: string;
    /**
     * Places the close btn absolutely in the corner rather than inline
     * with the title
     * @default false
     */
    absoluteCloseBtn?: boolean;
} & AriaDialogProps;

const Dialog = ({
    children,
    title,
    onClose,
    className,
    absoluteCloseBtn,
    ..._props
}: DialogProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const props = useMemo(
        () => ({
            ..._props,
            "aria-label":
                _props["aria-label"] ??
                (typeof title === "string" ? title : undefined),
        }),
        [_props, title]
    );
    const { dialogProps, titleProps } = useDialog(props, ref);

    return (
        <div {...dialogProps} ref={ref} className={cn("ds-dialog", className)}>
            {(title || onClose) && (
                <header className={cn("ds-dialog__header", title?.className)}>
                    {title && (
                        <title.as
                            {...titleProps}
                            className={cn(
                                "ds-dialog__title roboto",
                                titleProps.className
                            )}
                        >
                            {title.content}
                        </title.as>
                    )}
                    {onClose && !absoluteCloseBtn && (
                        <CloseDialog onPress={onClose} />
                    )}
                </header>
            )}

            {onClose && absoluteCloseBtn && (
                <CloseDialog
                    onPress={onClose}
                    absoluteCloseBtn={absoluteCloseBtn}
                />
            )}

            {children && <div className="ds-dialog__content">{children}</div>}
        </div>
    );
};

const CloseDialog = ({
    absoluteCloseBtn,
    ...props
}: Pick<DialogProps, "absoluteCloseBtn"> & AriaButtonProps) => {
    const intl = useIntl();
    const ref = useRef<HTMLButtonElement>(null);
    const { buttonProps } = useButton(props, ref);

    return (
        <button
            {...buttonProps}
            ref={ref}
            className={cn("close-dialog__button", {
                "close-dialog__button--absolute": absoluteCloseBtn,
            })}
        >
            <VisuallyHidden>
                {intl.formatMessage(commonMessages.close)}
            </VisuallyHidden>
            <Icon path="close" size={absoluteCloseBtn ? "medium" : "large"} />
        </button>
    );
};

export default Dialog;
