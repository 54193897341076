import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { Module } from "@evidenceb/gameplay-interfaces";
import Modal from "../../design-system-components/Modal/Modal";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";

import "./ModuleMap.scss";
import mapIcon from "../../assets/images/Map_icon.svg";
import { useOverlayTriggerState } from "@react-stately/overlays";
import Dialog from "../../design-system-components/Dialog/Dialog";
import { Image } from "@evidenceb/athena-common";
import useAssetsDetails from "../../hooks/useAssetsDetails";

interface Props {
    module: Module;
    btnLabel: ReactElement;
    modalTitle: string;
}

const ModuleMap = ({ module, btnLabel, modalTitle }: Props) => {
    const intl = useIntl();
    const modal = useOverlayTriggerState({});
    const assetsDetails = useAssetsDetails();

    if (!module.studentPath) return null;

    return (
        <>
            <button
                className="module-map"
                onClick={() => {
                    modal.open();
                }}
            >
                <img src={mapIcon} alt="" />
                {btnLabel}
            </button>

            {modal.isOpen && (
                <Modal
                    className="module-map__modal"
                    aria-labelledby="module-map-title"
                    state={modal}
                    isDismissable={true}
                >
                    <Dialog
                        onClose={modal.close}
                        title={{
                            as: "div",
                            content: (
                                <>
                                    <img src={mapIcon} alt="" />
                                    <h2 id="module-map-title">{modalTitle}</h2>
                                </>
                            ),
                            className: "module-map__modal-title",
                        }}
                        className="module-map__dialog"
                        absoluteCloseBtn
                    >
                        <Image
                            src={module.studentPath.url}
                            alt={intl.formatMessage(
                                {
                                    id: "modulemap-title",
                                    defaultMessage:
                                        'Map of the "{title}" module',
                                },
                                { title: module.title.short! }
                            )}
                            aria-describedby={`modulemap-${module.id}`}
                            assetsDetails={assetsDetails}
                        />
                        <VisuallyHidden>
                            <p id={`modulemap-${module.id}`}>
                                {module.studentPath.alt}
                            </p>
                        </VisuallyHidden>
                    </Dialog>
                </Modal>
            )}
        </>
    );
};

export default ModuleMap;
export { default as moduleMapMessages } from "./messages";
