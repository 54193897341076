import React, {
    ButtonHTMLAttributes,
    DetailedHTMLProps,
    PropsWithChildren,
} from "react";
import cn from "classnames";

interface Props {
    status: "none" | "inProgress" | "completed";
    type?: "default" | "dashed";
}

const ProgressionButton = ({
    status,
    children,
    type = "default",
    ...props
}: PropsWithChildren<Props> &
    Omit<
        DetailedHTMLProps<
            ButtonHTMLAttributes<HTMLButtonElement>,
            HTMLButtonElement
        >,
        "type"
    >) => {
    return (
        <>
            {status === "completed" || status === "inProgress" ? (
                <button
                    {...props}
                    className={cn(
                        "objective-progression__button",
                        `--${type}`,
                        props.className
                    )}
                >
                    {children}
                </button>
            ) : (
                <div className="objective-progression__button">
                    <span></span>
                </div>
            )}
        </>
    );
};

export default ProgressionButton;
