import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";

import "./ResizeBar.scss";

interface Props {
    onResize: (width: number) => void;
    className?: string;
}

const ResizeBar = ({ onResize, className }: Props) => {
    const [isResizing, setIsResizing] = useState(false);

    const onMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsResizing(true);
    };

    const disableResize = useCallback(() => {
        if (isResizing) setIsResizing(false);
    }, [isResizing]);

    useEffect(() => {
        const onMouseMove = (e: MouseEvent) => {
            e.preventDefault();
            if (isResizing) onResize(window.innerWidth - e.pageX);
        };

        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", disableResize);

        return () => {
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", disableResize);
        };
    }, [isResizing, disableResize, onResize]);

    return (
        <div
            className={cn(
                "resize-bar",
                {
                    "--hover": isResizing,
                },
                className
            )}
            onMouseDown={onMouseDown}
            onMouseUp={disableResize}
        ></div>
    );
};
export default ResizeBar;
