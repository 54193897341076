import { useContext, useState } from "react";
import {
    LevelData,
    PRLevel,
    PRItem,
    PRLockStatus,
} from "../../../interfaces/PedagogicalResourcesManagement";
import { Data } from "../../../interfaces/Data";
import { configStore } from "../../../contexts/ConfigContext";
import { dataStore } from "../../../contexts/DataContext";
import { Session, sessionStore } from "../../../contexts/SessionContext";
import { getItemsToToggle, prLevelsToLevelIds } from "../../../utils/prm";
import { getGraph } from "../../../utils/ai";
import {
    areActivitiesInSameObjective,
    getObjectiveById,
} from "../../../utils/dataRetrieval";
import { UsePRManagerReturn } from "./usePRManager";
import UnlockObjectiveAndAncestorObjectivesConfirmationModal from "./ConfirmationModal/UnlockObjectiveAndAncestorObjectivesConfirmationModal";
import LockObjectiveAndDescendantObjectivesConfirmationModal from "./ConfirmationModal/LockObjectiveAndDescendantObjectivesConfirmationModal";
import UnlockObjectiveAndAncestorActivitiesConfirmationModal from "./ConfirmationModal/UnlockObjectiveAndAncestorActivitiesConfirmationModal";
import LockActivityAndDescendantObjectivesConfirmationModal from "./ConfirmationModal/LockActivityAndDescendantObjectivesConfirmationModal";
import UnlockActivityAndAncestorObjectivesConfirmationModal from "./ConfirmationModal/UnlockActivityAndAncestorObjectivesConfirmationModal";
import { AIType } from "@evidenceb/ai-handler";

export interface UsePRConfirmationReturn {
    attemptToggle: (
        id: string,
        prLockStatus: PRLockStatus,
        level: LevelData
    ) => void;
    confirmation?: Confirmation;
    confirm?: (confirm: boolean) => void;
}

interface BaseConfirmation {
    originId: string;
    type: "unlock" | "lock";
    dependencyIds: string[];
    itemsToToggle: PRItem[];
    level: PRLevel;
    secondConfirmation?: Confirmation;
}
interface InlineConfirmation extends BaseConfirmation {
    displayType: "inline";
}
export interface ModalConfirmation extends BaseConfirmation {
    dependenciesList?: string[];
    displayType: "modal";
    Modal: (props: ConfirmationModalProps) => JSX.Element;
}
export type Confirmation = InlineConfirmation | ModalConfirmation;
export interface ConfirmationModalProps {
    confirmation: ModalConfirmation;
    confirm: Required<UsePRConfirmationReturn>["confirm"];
    levelData: LevelData;
}

const usePRConfirmation = (
    toggleItems: UsePRManagerReturn["toggleItems"]
): UsePRConfirmationReturn => {
    const {
        config: { ai },
    } = useContext(configStore);
    const { data } = useContext(dataStore);
    const {
        session: { dismissMessages },
    } = useContext(sessionStore);

    const [confirmation, setConfirmation] = useState<Confirmation>();

    return {
        confirmation,

        attemptToggle: (id, prLockStatus, levelData) => {
            const itemsToToggle = getItemsToToggle(
                id,
                levelData,
                prLockStatus,
                getGraph(ai!, data, levelData.parentLevel?.moduleId ?? id),
                data,
                ai[AIType.BanditManchot]
            );
            const confirmationType = prLockStatus[
                prLevelsToLevelIds(levelData.level)
            ].includes(id)
                ? "unlock"
                : "lock";

            let confirmation: Confirmation | undefined;
            if (levelData.level === PRLevel.Objectives)
                confirmation = getObjectiveConfirmation(
                    id,
                    confirmationType,
                    itemsToToggle,
                    dismissMessages,
                    data
                );
            else if (levelData.level === PRLevel.Activities)
                confirmation = getActivityConfirmation(
                    id,
                    confirmationType,
                    itemsToToggle,
                    data,
                    levelData.prPool
                );
            else confirmation = undefined;

            if (confirmation) setConfirmation(confirmation);
            else
                toggleItems([...itemsToToggle, { id, level: levelData.level }]);
        },

        confirm: confirmation
            ? (confirm) => {
                  if (!confirm) {
                      setConfirmation(undefined);
                  } else if (confirmation.secondConfirmation) {
                      setConfirmation(confirmation.secondConfirmation);
                  } else {
                      toggleItems(confirmation!.itemsToToggle);
                      setConfirmation(undefined);
                  }
              }
            : undefined,
    };
};

const getObjectiveConfirmation = (
    originId: string,
    confirmationType: "unlock" | "lock",
    /** Items other than the origin to toggle */
    itemsToToggle: PRItem[],
    dismissMessages: Session["dismissMessages"],
    data: Data
): Confirmation | undefined => {
    const objective = getObjectiveById(originId, data);

    const hasIntraModuleDependencies =
        itemsToToggle.filter((item) => item.level === PRLevel.Objectives)
            .length > 0;
    const hasAncestorActivities =
        confirmationType === "unlock" &&
        itemsToToggle
            .filter((item) => item.level === PRLevel.Activities)
            // Ignore objective's own activities
            .filter((item) => !objective.activityIds.includes(item.id)).length >
            0;

    if (hasIntraModuleDependencies) {
        const firstConfirmation: InlineConfirmation = {
            originId,
            level: PRLevel.Objectives,
            displayType: "inline",
            dependencyIds: itemsToToggle.map((item) => item.id),
            type: confirmationType,
            itemsToToggle: [
                ...itemsToToggle,
                { id: originId, level: PRLevel.Objectives },
            ],
        };

        if (
            confirmationType === "unlock" &&
            !dismissMessages.prmObjectiveUnlockingConfirmationModal
        )
            return {
                ...firstConfirmation,
                secondConfirmation: {
                    ...firstConfirmation,
                    displayType: "modal",
                    Modal: UnlockObjectiveAndAncestorObjectivesConfirmationModal,
                },
            };
        if (
            confirmationType === "lock" &&
            !dismissMessages.prmObjectiveLockingConfirmationModal
        )
            return {
                ...firstConfirmation,
                secondConfirmation: {
                    ...firstConfirmation,
                    displayType: "modal",
                    Modal: LockObjectiveAndDescendantObjectivesConfirmationModal,
                },
            };
        return firstConfirmation;
    }
    if (hasAncestorActivities)
        return {
            originId,
            level: PRLevel.Objectives,
            displayType: "modal",
            Modal: UnlockObjectiveAndAncestorActivitiesConfirmationModal,
            dependencyIds: itemsToToggle.map((item) => item.id),
            type: "unlock",
            itemsToToggle: [
                ...itemsToToggle,
                { id: originId, level: PRLevel.Objectives },
            ],
        };
    return undefined;
};

const getActivityConfirmation = (
    originId: string,
    confirmationType: "unlock" | "lock",
    itemsToToggle: PRItem[],
    data: Data,
    pool: LevelData["prPool"]
): Confirmation | undefined => {
    const hasIntraObjectiveDependencies =
        itemsToToggle.filter(
            (item) =>
                item.level === PRLevel.Activities &&
                areActivitiesInSameObjective(originId, item.id, data)
        ).length > 0;
    const hasInterObjectiveDependencies =
        itemsToToggle.filter((item) =>
            pool.some(
                (poolItem) =>
                    poolItem.id === item.id &&
                    poolItem.isPrerequisiteOf?.some((objId) =>
                        itemsToToggle.some((item) => item.id === objId)
                    )
            )
        ).length > 0 ||
        pool.some(
            (item) =>
                item.id === originId &&
                item.isPrerequisiteOf?.some((objId) =>
                    itemsToToggle.some((item) => item.id === objId)
                )
        );

    if (hasIntraObjectiveDependencies) {
        const firstConfirmation: InlineConfirmation = {
            originId,
            level: PRLevel.Activities,
            displayType: "inline",
            dependencyIds: itemsToToggle.map((item) => item.id),
            type: confirmationType,
            itemsToToggle: [
                ...itemsToToggle,
                { id: originId, level: PRLevel.Activities },
            ],
        };
        if (!hasInterObjectiveDependencies) return firstConfirmation;
        return {
            ...firstConfirmation,
            secondConfirmation: {
                ...firstConfirmation,
                displayType: "modal",
                Modal:
                    confirmationType === "lock"
                        ? LockActivityAndDescendantObjectivesConfirmationModal
                        : UnlockActivityAndAncestorObjectivesConfirmationModal,
                dependenciesList: itemsToToggle
                    .filter((item) => item.level === PRLevel.Objectives)
                    .map((item) => item.id),
            },
        };
    }
    if (hasInterObjectiveDependencies)
        return {
            originId,
            level: PRLevel.Activities,
            dependenciesList: itemsToToggle
                .filter((item) => item.level === PRLevel.Objectives)
                .map((item) => item.id),
            displayType: "modal",
            Modal: LockActivityAndDescendantObjectivesConfirmationModal,
            dependencyIds: itemsToToggle.map((item) => item.id),
            type: confirmationType,
            itemsToToggle: [
                ...itemsToToggle,
                { id: originId, level: PRLevel.Activities },
            ],
        };
    return undefined;
};

export default usePRConfirmation;
