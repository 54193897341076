import { Theme } from "../interfaces/Theme"

const chatbotTheme: Theme["chatbot"] = {
    backgroundColorCorrect: "#cbf9d2",
    backgroundColorIncorrect: "#ffdede",
    botBubbleColor: "#ACE4E6",
    botBubbleColorCorrect: "#408D59",
    botBubbleColorFeedback: "#FFB03B",
    botBubbleColorIncorrect: "#FF613880",
    disabledBg: "#ECF0F1",
    disabledText: "#929C9E",
    textCorrect: "#376F40",
    textIncorrect: "#760015",
    userBubbleColorInteractive: "#FFFFFF",
    userBubbleColorNonInteractive: "#ECF0F1",
    validateBg: "#2C3E50",
};

export default chatbotTheme;
