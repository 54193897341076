import React from "react";

import "./BorderlessCard.scss";

export type BorderlessCardProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

const BorderlessCard = ({
    className,
    children,
    ...props
}: BorderlessCardProps) => {
    return (
        <div {...props} className={`ds-borderless-card ${className ?? ""}`}>
            {children}
        </div>
    );
};

export default BorderlessCard;
