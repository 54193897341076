import React from 'react';
import {AriaProgressBarProps, useProgressBar} from '@react-aria/progress';
// SCSS
import './ProgressBar.scss';
import cn from "classnames";
// Interfaces
interface ProgressBarProps {
    height: number;
    colors?: {
        foreground: string;
        background: string;
    }
    disabled?: boolean;
    className?: string;
}

const ProgressBar = (
    {
        label, 
        showValueLabel = !!label, 
        value,
        minValue = 0,
        maxValue = 100,
        height,
        colors,
        disabled,
        className,
        ...props
    }: AriaProgressBarProps & ProgressBarProps
) => {
    let {
      progressBarProps,
      labelProps
    } = useProgressBar({...props, label, showValueLabel: !!label, value, minValue, maxValue});
    
    const disabledDarkGrey = getComputedStyle(document.body).getPropertyValue('--grey-dark');
    const disabledMediumGrey = getComputedStyle(document.body).getPropertyValue('--medium-grey');
    
    // Calculate the width of the progress bar as a percentage
    let percentage = value ? (value - minValue) / (maxValue - minValue) : 0;
    let barWidth = `${Math.round(percentage * 100)}%`;
  
    const classnames = cn([
        "ds-progress-bar-container",
        className,
        {"--disabled": disabled}
    ])

    return (
      <div className={classnames} {...progressBarProps}>
        <div className="ds-progress-bar-container__infos">
          {label &&
            (
              <span {...labelProps}>
                {label}
              </span>
            )}
          {showValueLabel &&
            (
              <span>
                {progressBarProps['aria-valuetext']}
              </span>
            )}
        </div>
        <div 
            className="ds-progress-bar-container__bar" 
            style={{ 
                height: height, 
                background: disabled ? disabledMediumGrey : colors && colors.background 
            }}>
          <div 
            style={{ 
                width: barWidth, 
                height: height, 
                backgroundColor: disabled ? disabledDarkGrey : colors && colors.foreground 
            }} />
        </div>
      </div>
    );
  }

export default ProgressBar