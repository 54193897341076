import React, { useContext, useState } from "react";
// External modules
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { useOverlayTriggerState } from "@react-stately/overlays";

//Contexts
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
//Hook
import { useAthenaTokenlessAPIClient } from '../../../hooks/useAthenaAPIClient';
// Components
import Button from "../../../design-system-components/Button/Button";
import CodeInput from "./CodeInput/CodeInput";
import Card from "../../../design-system-components/Card/Card";
import Modal from "../../../design-system-components/Modal/Modal";
import StudentList from "./StudentsList/StudentList";
import Error from "../Error/Error";
//utilz
import * as localStorage from "../../../utils/localStorage";
// SCSS
import './StudentAuth.scss';
// Interfaces
import { Student } from "../../../interfaces/Authentication";
import { commonMessages } from "../../../utils/messages";
import { authMessages } from "../auth-messages";
import Dialog from "../../../design-system-components/Dialog/Dialog";
import { Image } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../hooks/useAssetsDetails";
enum StudentAuthSteps {
    CLASSROOM_CODE = "classroomCode",
    STUDENT_SELECTION = "studentSelection",
}
interface Classroom {
    id: string;
    code: string;
    name: string;
}
const StudentAuth = () => {
    const intl = useIntl();
    const athenaAPIClient = useAthenaTokenlessAPIClient();
    const { config: { auth } } = useContext(configStore);
    const { session: { app} } = useContext(sessionStore);
    const assetsDetails = useAssetsDetails();

    const [authStep, setAuthStep] = useState<StudentAuthSteps>(
        StudentAuthSteps.CLASSROOM_CODE
    );
    const [classroom, setClassroom] = useState<Classroom>();
    const [students, setStudents] = useState<Student[]>([]);
    const [selectedStudent, setSelectedStudent] = useState<Student>();
    const selectStudentModal = useOverlayTriggerState({ defaultOpen: false })
    const [error, setError] = useState<string>();

    const classroomCodeValidation = async (code: string) => {
        if (code.length === 4) {
            let body = {
                classroom_code: code.toUpperCase(),
                app: app!,
                role: "student"
            };
            try {
                let res = await athenaAPIClient.classroomCodeValidation(body);
                setStudents(res.students);
                setClassroom({ id: res.id, code: code, name: res.name });
                setAuthStep(StudentAuthSteps.STUDENT_SELECTION);
            } catch (err) {
                if ((err as AxiosError).response?.status === 404)
                    setError(intl.formatMessage(messages.classroomErrorNotExist));
                else {
                    setError(intl.formatMessage(commonMessages.genericErrorMessage));
                    Sentry.captureException(err)
                }
            }
        } else {
            //reset error to hide it when user is changing their code
            if (error) setError(undefined);
        }
    };
    const studentCodeValidation = async (code: string) => {
        if (code.length === 4) {
            let body = {
                classroom_code: classroom!.code.toUpperCase(),
                student_code: code.toUpperCase(),
                student_id: selectedStudent!.id,
                app: app!,
                role: "student"
            };
            try {
                let { token } = await athenaAPIClient.directAuthStudent(body);
                setSelectedStudent(undefined);
                selectStudentModal.close()
                localStorage.setItem(localStorage.Key.TOKEN, token);
                //When the token is set, reload the page to re-init the app and go through the default process
                window.location.replace("/");
            } catch (err) {
                if ((err as AxiosError).response?.status === 403)
                    setError(intl.formatMessage(messages.studentErrorNotExist));
                else {
                    setError(intl.formatMessage(commonMessages.genericErrorMessage));
                    Sentry.captureException(err)
                }
            }
        } else {
            //reset error to hide it when user is changing their code
            if (error) setError(undefined);
        }
    };

    const reset = () => {
        setAuthStep(StudentAuthSteps.CLASSROOM_CODE);
        setClassroom(undefined);
        setStudents([]);
        setSelectedStudent(undefined);
        selectStudentModal.close()
        setError(undefined);
    };

    return (
        <div className="student-auth">
            <Button
                variant="primary"
                label={
                    authStep === StudentAuthSteps.CLASSROOM_CODE
                        ? intl.formatMessage(commonMessages.back)
                        : intl.formatMessage(messages.changeClassroom)
                }
                icon={{
                    path: "arrow_left",
                    position: "left",
                    size: "medium",
                }}
                asRouterLink={
                    authStep === StudentAuthSteps.CLASSROOM_CODE
                        ? { to: "/auth/" }
                        : undefined
                }
                onClick={
                    authStep === StudentAuthSteps.STUDENT_SELECTION
                        ? () => reset()
                        : undefined
                }
            />
            {authStep === StudentAuthSteps.CLASSROOM_CODE && (
                <Card
                    className="code-form-card__classroom-code"
                    cardTitle={{
                        as: "h2",
                        content: intl.formatMessage(messages.classroomCode),
                    }}
                >
                    {auth.mascotUrl && (
                        <div className="auth___mascot" aria-hidden={true}>
                            <Image src={auth.mascotUrl} alt="" assetsDetails={assetsDetails} />
                        </div>
                    )}
                    <CodeInput
                        className="code-form-card__form"
                        type="text"
                        name="classroom-code"
                        onValueChange={classroomCodeValidation}
                        info={intl.formatMessage(messages.whereToFindClassroomCode)}
                    />
                    {error && <Error error={error} />}
                </Card>
            )}
            {authStep === StudentAuthSteps.STUDENT_SELECTION &&
                students.length && (
                    <div className="student-auth__student-selection">
                        <h1>
                            {intl.formatMessage(messages.welcome, {
                                classroom: classroom?.name,
                            })}
                        </h1>
                        <p>{intl.formatMessage(messages.whoAreYou)}</p>
                        <StudentList
                            students={students}
                            onStudentClick={(student) => {
                                setSelectedStudent(student)
                                selectStudentModal.open()
                            }
                            }
                        />
                    </div>
                )}
            {selectedStudent && selectStudentModal.isOpen && (
                <Modal
                    state={selectStudentModal}
                    onClose={() => {
                        setSelectedStudent(undefined)
                        selectStudentModal.close()
                        setError(undefined)
                    }}
                    isDismissable={true}
                >
                    <Dialog
                        onClose={() => {
                            setSelectedStudent(undefined)
                            selectStudentModal.close()
                            setError(undefined)
                        }}
                    >
                        <Card
                            className="code-form-card__student-code"
                            cardTitle={{
                                as: "h2",
                                content: selectedStudent.displayName,
                            }}
                        >
                            <h3>{intl.formatMessage(messages.studentCode)}</h3>
                            <CodeInput
                                type="text"
                                name="student-code"
                                onValueChange={studentCodeValidation}
                                info={intl.formatMessage(messages.whereToFindClassroomCode)}
                            />
                            {error && <Error error={error} />}
                        </Card>
                    </Dialog>
                </Modal>
            )}
            <div className="change-login-method">
                {intl.formatMessage(authMessages.notAStudent)}{" "}
                <Link className="change-login-method" to="/auth/teacher">
                    {intl.formatMessage(authMessages.loginAsTeacher)}
                </Link>
            </div>
        </div>
    );
};

export default StudentAuth;

const messages = defineMessages({
    welcome: {
        id: "authentication-student-welcome",
        defaultMessage: "Welcome in the {classroom} classroom",
    },
    whoAreYou: {
        id: "authentication-student-whoAreYou",
        defaultMessage: "Who are you?",
    },
    changeClassroom: {
        id: "authentication-student-changeClassroom",
        defaultMessage: "Change classroom",
    },
    classroomCode: {
        id: "authentication-student-classroomCode",
        defaultMessage: "Classroom code",
    },
    studentCode: {
        id: "authentication-student-studentCode",
        defaultMessage: "Student code",
    },
    whereToFindClassroomCode: {
        id: "authentication-student-whereToFindClassroomCode",
        defaultMessage: "Ask this code to your teacher!",
    },
    whereToFindStudentCode: {
        id: "authentication-student-whereToFindStudentCode",
        defaultMessage: "Ask this code to your teacher!",
    },
    classroomErrorNotExist: {
        id: "authentication-student-classroomErrorNotExist",
        defaultMessage: "This classroom code doesn't exist. Please try again.",
    },
    studentErrorNotExist: {
        id: "authentication-student-studentErrorNotExist",
        defaultMessage: "This student code doesn't exist. Please try again.",
    }
});
