import React, { useRef } from "react";
import cn from "classnames";
import { useToggleState, ToggleProps as StatelyToggleProps } from "@react-stately/toggle";
import { useToggleButton, AriaToggleButtonProps } from "@react-aria/button";
import Icon from "../../design-system-components/Icon/Icon";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";

// Reference for building an accessible toggle component with react-aria:
// https://react-spectrum.adobe.com/react-aria/useToggleButton.html

import "./Toggle.scss";

type Props = DefaultToggleProps & CustomProps;

type DefaultToggleProps = AriaToggleButtonProps<'button'> & StatelyToggleProps & TogglePropsOverrides;
// Force controlled usage by making properties required
type TogglePropsOverrides = {
    children?: never;
    isSelected: boolean;
    onChange: (isSelected: boolean) => void;
}

interface CustomProps {
    /**
     * An invisible label used by screenreader user to determine the nature of
     * the action
     */
    accessibleLabel: JSX.Element | JSX.Element[] | string;
    /**
     * Name of the material icon to include in the pin
     */
    pinIcon?: string;
    className?: string;
}

/**
 * Accessible toggle component
 */
const Toggle = ({
    accessibleLabel,
    pinIcon,
    className,
    ...props
}: Props) => {
    const ref = useRef<HTMLButtonElement | null>(null);
    const state = useToggleState(props);
    const { buttonProps } = useToggleButton(props, state, ref as any);

    return (
        <button
            {...buttonProps}
            ref={ref}
            className={cn(
                "toggle",
                {
                    "--selected": props.isSelected,
                    "--unselected": !props.isSelected,
                },
                className
            )}
        >
            <div className="toggle__pin" aria-hidden="true">
                {pinIcon && (
                    <Icon
                        className="toggle__pin-icon"
                        path={pinIcon}
                        size={10}
                    />
                )}
            </div>
            <VisuallyHidden>{accessibleLabel}</VisuallyHidden>
        </button>
    );
};

export default Toggle;
