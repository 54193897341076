import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";
import { AdaptiveTests } from "../interfaces/AdaptiveTests";

// Context Typing
interface AdaptiveTestsContext {
    adaptiveTests: AdaptiveTests;
    setAdaptiveTests: Dispatch<SetStateAction<AdaptiveTests>>;
}

// Initial State
const initialState: AdaptiveTests = {
    testsResults: undefined,
    objectiveDiagnosis: {},
    mainTest: undefined,
    recommendations: {},
};

// Context init
const adaptiveTestsStore = createContext<AdaptiveTestsContext>({
    adaptiveTests: initialState,
    setAdaptiveTests: () => null,
});

// Provider init
const { Provider } = adaptiveTestsStore;

// Final setup
const AdaptiveTestsProvider = ({ children }: { children: JSX.Element }) => {
    const [adaptiveTests, setAdaptiveTests] = useState(initialState);
    return (
        <Provider value={{ adaptiveTests, setAdaptiveTests }}>
            {children}
        </Provider>
    );
};

export { adaptiveTestsStore, AdaptiveTestsProvider };
