import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { useIntl } from "react-intl";
import { ModuleDashboard, ParamTypes } from "../../../../interfaces/Dashboard";
import {
    PRLevelIds,
    PRLockStatus,
} from "../../../../interfaces/PedagogicalResourcesManagement";
import { dataStore } from "../../../../contexts/DataContext";
import {
    getModuleById,
    getResourceIndex,
} from "../../../../utils/dataRetrieval";
import { commonMessages } from "../../../../utils/messages";
import ObjectiveProgression from "../../../../components/ProgressionTableCell/ObjectiveProgression";
import TestProgression from "../../../../components/ProgressionTableCell/TestProgression";
import dashboardMessages from "../../dashboardMessages";
import TableLegend from "./TableLegend/TableLegend";
import StudentNamesColumn from "./StudentNamesColumn/StudentNamesColumn";
import ObjectiveListLine from "./ObjectiveListLine/ObjectiveListLine";

import "./ProgressionTable.scss";
import COLOR_SCHEME from "./COLOR_SCHEME";

interface TableProps {
    students: ModuleDashboard["students"];
    objectives: string[];
    prLockStatus?: PRLockStatus;
    moduleId: string;
}

const ProgressionTable = ({
    students,
    objectives,
    prLockStatus,
    moduleId,
}: TableProps) => {
    let { classroomId } = useParams<ParamTypes>();
    const { data } = useContext(dataStore);
    const intl = useIntl();
    
    const sortByName = (a: string, b: string) => {
        return students[a].lastname.localeCompare(students[b].lastname);
    };

    const hasTestResultColumn = useMemo(
        () => Object.values(students).some((student) => student.testsResults),
        [students]
    );

    return (
        <div
            className="progression-table"
            /* @ts-ignore TS doesn't allow to set variables but we can */
            style={
                COLOR_SCHEME[
                    prLockStatus &&
                    prLockStatus[PRLevelIds.ModuleIds].includes(moduleId)
                        ? "locked"
                        : "unlocked"
                ]
            }
        >
            <TableLegend />

            <div className="table-content">
                <div className="column left">
                    <div className="heading">
                        <span>
                            {intl.formatMessage(dashboardMessages.students)}
                        </span>
                        <span>
                            {intl.formatMessage(commonMessages.objectives)}
                        </span>
                        <span className="split"></span>
                    </div>

                    <StudentNamesColumn
                        classroomId={classroomId}
                        students={students}
                    />
                </div>

                <div className="column right">
                    <SimpleBar style={{ width: "100%" }} autoHide={false}>
                        <div className="table-objectives">
                            <ObjectiveListLine
                                objectives={objectives}
                                prLockStatus={prLockStatus}
                                moduleId={moduleId}
                                testResultColumn={hasTestResultColumn}
                            />

                            {Object.keys(students)
                                .sort(sortByName)
                                .map((studentId, i) => (
                                    <div className="tr" key={"tr" + i}>
                                        {hasTestResultColumn && (
                                            <TestProgression
                                                student={students[studentId]}
                                                module={getModuleById(
                                                    moduleId,
                                                    data
                                                )}
                                            />
                                        )}

                                        {students[studentId].objectives.map(
                                            (objective, j) => (
                                                <ObjectiveProgression
                                                    key={"td" + j}
                                                    prLockStatus={prLockStatus}
                                                    objective={objective}
                                                    classroomId={classroomId}
                                                    student={
                                                        students[studentId]
                                                    }
                                                    objectiveIndex={
                                                        getResourceIndex(
                                                            objective.id,
                                                            objectives
                                                        ) + 1
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                ))}
                        </div>
                    </SimpleBar>
                </div>
            </div>
        </div>
    );
};

export default ProgressionTable;
