import { Pagetype, WorkModeType } from "../../interfaces/Config";
import { UserType } from "../../interfaces/User";

export interface modeStaticConfig {
    type: WorkModeType;
    nameKey: string;
    logoPath: string;
    linkedPageType?: Pagetype;
    userType?: UserType[];
    primaryColor: string;
}

export const modeStaticConfig = [
    {
        type: WorkModeType.Duo,
        nameKey: "mode-duo",
        logoPath: "icon_mode_duo",
        linkedPageType: undefined,
        primaryColor: "--mode-duo-primary",
    },
    {
        type: WorkModeType.SoloAI,
        nameKey: "mode-solo-ai",
        logoPath: "icon_mode_solo_ai",
        linkedPageType: Pagetype.MODULELIST,
        userType: [UserType.Student],
        primaryColor: "--mode-solo-ai-primary",
    },
    {
        type: WorkModeType.SoloTeacher,
        nameKey: "mode-solo-teacher",
        logoPath: "icon_mode_solo-teacher",
        linkedPageType: undefined,
        userType: [UserType.Teacher],
        primaryColor: "--mode-teacher-primary",
    },
    {
        type: WorkModeType.Test,
        nameKey: "mode-test",
        logoPath: "icon_mode_test",
        linkedPageType: undefined,
        primaryColor: "--mode-test-primary",
    },
    {
        type: WorkModeType.Tuto,
        nameKey: "mode-tuto",
        logoPath: "icon_mode_tuto",
        linkedPageType: undefined,
        primaryColor: "--mode-tuto-primary",
    },
    {
        type: WorkModeType.Workshop,
        nameKey: "mode-workshop",
        logoPath: "icon_mode_workshop",
        linkedPageType: undefined,
        primaryColor: "--mode-workshop-primary",
    },
] as modeStaticConfig[];
