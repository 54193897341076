import React from 'react';
// External modules
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
// Contexts
// Components
import Loader from '../../components/Loader/Loader';
import ResourceCard from '../../design-system-components/ResourceCard/ResourceCard';
import ControlledDialog from '../../design-system-components/ControlledDialog/ControlledDialog';
// Utils
//Hooks
import useBanditManchot from '../../hooks/useBanditManchot';
import useLockedModules from '../../hooks/useLockedModules';
import useSoloModuleList from '../../hooks/useSoloModuleList';
// SCSS
import './SoloModuleList.scss';
// Interfaces
import { LoaderStatus } from '../../interfaces/Status';

const SoloModuleList = () => {

    const intl = useIntl();
    const history = useHistory();
    const bmInfo = useBanditManchot();
    const lockedModules = useLockedModules();

    const soloModules = useSoloModuleList();
    const workmodesSolo = getComputedStyle(document.body).getPropertyValue('--workmodes-solo');
    const workmodesSolo20 = getComputedStyle(document.body).getPropertyValue('--workmodes-solo-20');

    return (
        <>
            {
                (bmInfo.status === "error" || lockedModules.status === LoaderStatus.Error) && (
                    <ControlledDialog
                        title={intl.formatMessage(messages.errorTitle)}
                        description={
                            { $html: intl.formatMessage(messages.errorDescription) }
                        }
                        primaryButton={{
                            label: intl.formatMessage(messages.errorBtn),
                            fn: () => {
                                history.push("/");
                            },
                        }}
                    />
                )
            }
             {
                (bmInfo.status === "loading" || lockedModules.status === LoaderStatus.Loading) && <Loader />
            }
            {
                bmInfo.status === "success" && lockedModules.status === LoaderStatus.Success &&
                <div className='solo-module-list'>
                    <h1>{intl.formatMessage(messages.soloModuleListTitle)}</h1>
                    <h2>{intl.formatMessage(messages.soloModuleListMessage)}</h2>
                    <div className="solo-module-list__modules">
                        {
                            soloModules.map((soloModule) => {
                                const disabled = lockedModules.payload.includes(soloModule.id)
                                return <ResourceCard
                                            key={soloModule.id}
                                            name={{as:"h3", content: soloModule.name}}
                                            iconPath={disabled ? "resource-solo-ai_disabled" : "resource-solo-ai"}
                                            objectivesNb={soloModule.objectivesNb}
                                            progression={soloModule.progression}
                                            progressBarColors={{
                                                foreground: workmodesSolo,
                                                background: workmodesSolo20
                                            }}
                                            disabled={disabled}
                                            onClick={() => {
                                                history.push("/player/" + soloModule.id);
                                            }}
                                        />
                                }
                            )
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default SoloModuleList

const messages = defineMessages({
    soloModuleListTitle: {
        id: "soloModuleList-title",
        defaultMessage: "Solo AI",
    },
    soloModuleListMessage: {
        id: "soloModuleList-message",
        defaultMessage: "Select a module to work on",
    },
    errorTitle: {
        id: "errors-statements-cannotRetrieveHistory-title",
        defaultMessage: "Impossible to retrieve history"
    },
    errorDescription: {
        id: "errors-statements-cannotRetrieveHistory-description",
        defaultMessage: "Your progress data is not available at this time. Please try again later."
    },
    errorBtn: {
        id: "errors-statements-cannotRetrieveHistory-goToHomepageButton",
        defaultMessage: "Go to homepage"
    }
});