import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from '../../design-system-components/Icon/Icon';
// External modules
// Components
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
// Utils
// SCSS
import './GoToTopButton.scss';
// Interfaces

const GoToTopButton = () => {
    //Refs
    const buttonContainer = useRef<HTMLDivElement>(null)
    // States
    // Functions
    const scrollHandler = () => {
        if (window.pageYOffset > window.innerHeight) {
            buttonContainer.current?.classList.add('show')
        } else {
            buttonContainer.current?.classList.remove('show')
        }
    }
    const goToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    // UseEffects
    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [])
    return (
        <div ref={buttonContainer} className='GoToTopButton'>
            <button onClick={goToTop}>
                <Icon path="chevron_up" size="medium" />
                <VisuallyHidden><FormattedMessage id="misc-goToTopButtonLabel" defaultMessage="Go to the top of the page" /></VisuallyHidden>
            </button>
        </div>
    )
}

export default GoToTopButton