import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { useIntl } from "react-intl";
import { Playlist } from "../../../../../interfaces/Player";
import { getFeedbackButtonText } from "../../../../../utils/feedbackUtils";
import { isExerciseSolved } from "../WizardShell";
import ResizeBar from "./ResizeBar/ResizeBar";
import WizardFeedbackContent from "./WizardFeedbackContent/WizardFeedbackContent";
import Button from "../../../../../design-system-components/Button/Button";

import "./WizardFeedback.scss";

interface Props {
    playlist: Playlist;
    onNextExercise: () => void;
    getMaxToggleHeight: () => number | undefined;
}

const WizardFeedback = ({ playlist, onNextExercise, getMaxToggleHeight }: Props) => {
    const intl = useIntl();

    // Animate the fedback panel
    const [animateFeedback, setAnimateFeedback] = useState<boolean>(false);
    useEffect(() => {
        if (!isExerciseSolved(playlist?.currentExerciseResult)) return;

        setTimeout(() => {
            setAnimateFeedback(true);
        });
    }, [playlist?.currentExerciseResult]);

    const [feedbackWidth, setFeedbackWidth] = useState<number | undefined>(
        undefined
    );
    const onFeedbackResize = (newWidth: number) => {
        if (newWidth < MIN_FB_WIDTH) newWidth = MIN_FB_WIDTH;
        else if (newWidth > MAX_FB_WIDTH) newWidth = MAX_FB_WIDTH;
        setFeedbackWidth(newWidth);
    };

    const feedback = useMemo(
        () => ({
            content: playlist.currentExerciseResult!.feedback!,
            type: playlist.currentExerciseResult!.feedbackType,
        }),
        [playlist.currentExerciseResult]
    );

    return (
        <div
            className={cn("wizard-feedback-container", {
                "--animate-feedback": animateFeedback,
            })}
            style={{
                /* @ts-ignore */
                "--fb-container-width": feedbackWidth ? `${feedbackWidth}px` : undefined,
            }}
        >
            {playlist.currentExercise &&
                playlist.currentExercise.executionOptions?.showFeedback && (
                    <WizardFeedbackContent
                        className="wizard-feedback__content"
                        feedback={feedback}
                        getMaxToggleHeight={getMaxToggleHeight}
                    />
                )}

            <div className="wizard-feedback__navigation-button">
                <Button
                    variant="primary"
                    label={getFeedbackButtonText(playlist.comingNext!, intl)}
                    icon={{
                        path:
                            playlist.comingNext === "endOfPlaylist"
                                ? ""
                                : "arrow_forward",
                        position: "right",
                    }}
                    center={true}
                    onClick={() => {
                        onNextExercise();
                        setAnimateFeedback(false);
                    }}
                />
            </div>

            <ResizeBar
                className="wizard-feedback__resize-bar"
                onResize={onFeedbackResize}
            />
        </div>
    );
};

const MIN_FB_WIDTH = 320;
const MAX_FB_WIDTH = 500;

export default WizardFeedback;
