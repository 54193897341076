import React, { useContext } from "react";
import { useIntl } from "react-intl";
import cn from "classnames";
import { configStore } from "../../../contexts/ConfigContext";
import { dataStore } from "../../../contexts/DataContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { getItemDescription, getModuleById, getResourceIndex } from "../../../utils/dataRetrieval";
import {
    bmStatementsFilter,
    byModuleStatementsFilter,
} from "../../../utils/statements";
import ProgressBarWithLabel from "../../../components/ProgressBarWithLabel/ProgressBarWithLabel";
import ModuleMap, {
    moduleMapMessages,
} from "../../../components/ModuleMap/ModuleMap";
import VisuallyHidden from "../../../components/VisuallyHidden/VisuallyHidden";
import InactiveModuleCard from "./InactiveModuleCard/InactiveModuleCard";

import "./NewModuleCard.scss";
import completedMedal from "./completed-medal.svg";
import { parse } from "@evidenceb/athena-common";
import { getModuleState, messages, ModuleState } from "./utils";
import CardAction from "./CardAction/CardAction";
import CacheButton from "../../../components/CacheButton/CacheButton";

interface Props {
    moduleId: string;
    diagnosis?: {
        progression: number;
        averageScore: number;
        isModuleFinished: boolean;
    };
    error?: boolean;
    locked?: boolean;
    specimenLocked?: boolean;
}

const NewModuleCard = ({ moduleId, diagnosis, error, locked, specimenLocked }: Props) => {
    const { data } = useContext(dataStore);
    const {
        config: { features },
    } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const intl = useIntl();

    const module = getModuleById(moduleId, data);
    if (error || locked || specimenLocked || typeof locked === "undefined" || !diagnosis)
        return (
            <InactiveModuleCard
                module={module}
                type={specimenLocked ? "specimen_locked" : error ? "error" : "locked"}
                diagnosis={diagnosis}
            />
        );

    const moduleState = getModuleState(
        diagnosis.isModuleFinished,
        (session.statements
            ?.filter(bmStatementsFilter)
            .filter(byModuleStatementsFilter(moduleId)).length ?? 0) > 0
    );

    return (
        <article
            className={cn("new-student-module-card", {
                "--module-completed": moduleState === ModuleState.Completed,
            })}
        >
            {moduleState === ModuleState.Completed && (
                <img
                    className="card__completed-medal"
                    src={completedMedal}
                    alt=""
                />
            )}
        
            <div className="card__head">
                {features.showModuleMapInStudentModuleList &&
                    module.studentPath && (
                        <ModuleMap
                            module={module}
                            btnLabel={
                                <VisuallyHidden>
                                    {intl.formatMessage(
                                        moduleMapMessages.studentBtnLabel
                                    )}
                                </VisuallyHidden>
                            }
                            modalTitle={intl.formatMessage(
                                moduleMapMessages.studentModalTitle
                            )}
                        />
                    )}
                <span className="card__title">
                    {features.teacherModuleListShowFullTitle
                        ? parse(getItemDescription(module, "student"))
                        : parse(
                            getResourceIndex(module.id, data.modules) +
                            1 +
                            ". " +
                            getItemDescription(module, "student")
                        )}
                </span>
                {
                    // Placeholder to maintain the title in the center of the
                    // flex div when there is a StudentPath icon
                    module.studentPath && <div></div>
                }
                <CacheButton cacheType="module" id={module.id} name={module.title.short ?? module.title.long} />
            </div>

            <ProgressBarWithLabel
                progress={diagnosis.progression}
                label={intl.formatMessage(messages.progression)}
                color="#BAE7E9"
            />
            <ProgressBarWithLabel
                progress={diagnosis.averageScore}
                label={intl.formatMessage(messages.successRate)}
                color="rgba(255, 184, 0, 0.15)"
            />
            <CardAction
                module={{
                    id: moduleId,
                    state: moduleState,
                }}
            />
        </article>
    );
};



export default NewModuleCard;
