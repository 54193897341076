import { Icon } from "@evidenceb/athena-common";
import classNames from "classnames";
import React from "react";
import { WorkMode, WorkModeStatus } from "../../interfaces/Config";
import Card from "../Card/Card";
import "./WorkModeCard.scss";

export interface WorkModeCardProps
    extends Omit<
            React.HTMLProps<Omit<HTMLDivElement, "name" | "type">>,
            "name" | "type"
        >,
        WorkMode {
    name: string;
    description?: string;
    logoPath: string;
    iconColor: string;
}

export const WorkModeCard = ({
    name,
    description,
    logoPath,
    type,
    iconColor,
    status = WorkModeStatus.Active,
}: WorkModeCardProps) => {
    return (
        <Card
            className={classNames("work-mode-card", {
                "--disabled": status === WorkModeStatus.Disabled,
                "--not-clickable": status === WorkModeStatus.NotClickable,
            })}
        >
            <div className="work-mode-card__inner-div">
                <div className="work-mode-card__text">
                    <div className="work-mode-card__title">{name}</div>
                    {description && (
                        <p className="work-mode-card__description">
                            {description}
                        </p>
                    )}
                </div>
                <div
                    className={`work-mode-card__illustration  ${type
                        ?.toLowerCase()
                        .replace("_", "-")}`}
                >
                    {
                        <Icon
                            className="work-mode-card__icon"
                            path={logoPath}
                            size={50}
                            color={
                                status === WorkModeStatus.Disabled
                                    ? "var(--disabled-primary)"
                                    : `var(${iconColor})`
                            }
                        />
                    }
                </div>
            </div>
        </Card>
    );
};
