import React, { useContext } from "react";
import parse from "html-react-parser";
import { FormattedMessage, useIntl } from "react-intl";
import { MinimalDataItem } from "@evidenceb/gameplay-interfaces";
import {
    LevelData,
    PRLevel,
    PRLevelIds,
} from "../../../interfaces/PedagogicalResourcesManagement";
import { dataStore } from "../../../contexts/DataContext";
import { configStore } from "../../../contexts/ConfigContext";
import { UsePRManagerReturn } from "./usePRManager";
import usePRConfirmation, { Confirmation } from "./usePRConfirmation";
import { getChildLevel, prLevelsToLevelIds } from "../../../utils/prm";
import Icon from "../../../design-system-components/Icon/Icon";
import ManagerListItem, {
    Props as ManagerListItemProps,
} from "./ManagerListItem/ManagerListItem";
import Loader from "../../../components/Loader/Loader";
import BatchControls from "./BatchControls/BatchControls";
import ConfirmationItemControls from "./ManagerListItem/ConfirmationItemControls/ConfirmationItemControls";
import ToggleItemControls, {
    getToggleText,
} from "./ManagerListItem/ToggleItemControls/ToggleItemControls";

import "./Manager.scss";

interface Props {
    prLockStatus: UsePRManagerReturn["currentPRLockStatus"];
    toggleItems: UsePRManagerReturn["toggleItems"];
    levelData: LevelData;
    setLevelData: React.Dispatch<React.SetStateAction<LevelData>>;
}

const Manager = ({
    prLockStatus,
    toggleItems,
    levelData,
    setLevelData,
}: Props) => {
    const { data } = useContext(dataStore);
    const {
        config: { ai },
    } = useContext(configStore);
    const { confirmation, attemptToggle, confirm } =
        usePRConfirmation(toggleItems);
    const intl = useIntl();

    if (!prLockStatus) return <Loader />;

    return (
        <>
            {confirmation && confirmation.displayType === "modal" && (
                <confirmation.Modal
                    confirmation={confirmation}
                    confirm={confirm!}
                    levelData={levelData}
                />
            )}

            <div className="pr-manager">
                <BatchControls
                    levelData={levelData}
                    setLevelData={setLevelData}
                    prLockStatus={prLockStatus}
                    toggleItems={toggleItems}
                    confirmToggleAttempt={confirm}
                />

                <ol className="manager__item-list">
                    {/* Container for initial test */}
                    {levelData.level === PRLevel.Objectives && (
                        <ManagerListItem
                            status={
                                levelData.prPool.every((item) =>
                                    prLockStatus[
                                        PRLevelIds.ObjectiveIds
                                    ].includes(item.id)
                                )
                                    ? "locked"
                                    : "unlocked"
                            }
                            className="item-list__initial-test"
                        >
                            <div className="initial-test__title">
                                <FormattedMessage
                                    id="prm-manager-listItem-initialTest"
                                    defaultMessage="Initial test"
                                />
                            </div>
                            <p className="initial-test__description">
                                {levelData.prPool.some(
                                    (item) =>
                                        !prLockStatus[
                                            PRLevelIds.ObjectiveIds
                                        ].includes(item.id)
                                ) ? (
                                    <FormattedMessage
                                        id="prm-manager-listItem-initialTestLockDescription"
                                        defaultMessage="The initial test is deactivated only if the entire module is deactivated (all objectives). "
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="prm-manager-listItem-initialTestUnlockDescription"
                                        defaultMessage="The initial test is activated as soon as at least one of the objectives of the module is activated. "
                                    />
                                )}
                            </p>
                            <div className="list-item__toggle-container">
                                <span className="toggle__state">
                                    {levelData.prPool.some(
                                        (item) =>
                                            !prLockStatus[
                                                PRLevelIds.ObjectiveIds
                                            ].includes(item.id)
                                    )
                                        ? getToggleText(false, intl)
                                        : getToggleText(true, intl)}
                                </span>
                            </div>
                        </ManagerListItem>
                    )}

                    {levelData.prPool.map((item, index) => {
                        const isItemLocked = prLockStatus[
                            prLevelsToLevelIds(levelData.level)
                        ].includes(item.id);
                        const isClickable =
                            levelData.level !== PRLevel.Activities &&
                            !isItemLocked &&
                            !confirmation;
                        const ItemDescription = isClickable ? "button" : "div";

                        return (
                            <ManagerListItem
                                key={"list-item-" + item.id}
                                isClickable={isClickable}
                                isModule={levelData.level === PRLevel.Modules}
                                status={getItemStatus(
                                    confirmation,
                                    item.id,
                                    isItemLocked
                                )}
                                displayAncestorIcon={
                                    (item.isPrerequisiteOf?.length ?? 0) > 0
                                }
                            >
                                <ItemDescription
                                    className="list-item__description"
                                    onClick={
                                        isClickable
                                            ? () => {
                                                  setLevelData((curr) =>
                                                      getChildLevel(
                                                          item.id,
                                                          data,
                                                          curr,
                                                          intl,
                                                          ai
                                                      )
                                                  );
                                              }
                                            : undefined
                                    }
                                >
                                    <p>
                                        {getItemDescription(
                                            item,
                                            levelData.level,
                                            index
                                        )}
                                    </p>

                                    {isClickable && (
                                        <div className="list-item__chevron">
                                            <Icon
                                                path="chevron_right"
                                                className="list-item__chevron-icon"
                                                size={24}
                                            />
                                        </div>
                                    )}
                                </ItemDescription>

                                {confirmation?.originId === item.id &&
                                confirmation.displayType === "inline" ? (
                                    <ConfirmationItemControls
                                        confirmation={confirmation}
                                        confirm={confirm!}
                                    />
                                ) : confirmation ? (
                                    <></>
                                ) : (
                                    <ToggleItemControls
                                        item={item}
                                        prLockStatus={prLockStatus}
                                        levelData={levelData}
                                        attemptToggle={attemptToggle}
                                        isItemLocked={isItemLocked}
                                    />
                                )}
                            </ManagerListItem>
                        );
                    })}
                </ol>
            </div>
        </>
    );
};

const getItemStatus = (
    confirmation: Confirmation | undefined,
    id: string,
    isItemLocked: boolean
): ManagerListItemProps["status"] => {
    if (
        confirmation &&
        (confirmation.originId === id ||
            confirmation.dependencyIds.includes(id))
    )
        return `${confirmation.type}Dependency` as const;
    if (isItemLocked) return "locked";
    return "unlocked";
};

const getItemDescription = (
    item: MinimalDataItem,
    hierarchy: PRLevel,
    itemIndex: number
) => {
    if (hierarchy === PRLevel.Modules) return item.title.short;
    if (hierarchy === PRLevel.Activities)
        return `${itemIndex + 1}. ${item.title.short}`;
    return `${itemIndex + 1}. ${parse(item.description.$html)}`;
};

export default Manager;
