import React from 'react';
// External modules
import { defineMessages, useIntl } from 'react-intl';
// Contexts
// Components
// Utils
// SCSS
import './SearchStudent.scss';
// Interfaces
import Icon from '../../../../../../design-system-components/Icon/Icon';
interface SearchStudentProps {
    filter: string;
    onUpdateFilter: (filter: string) => void;
    className?: string;
}
const SearchStudent = ({filter, onUpdateFilter, className}: SearchStudentProps) => {
    const intl = useIntl()

    return (  
        <div className={`search-student ${className ?? ""}`}>
            <Icon path="search" size="medium" />
            <input 
                className="search-student__input"
                placeholder={intl.formatMessage(messages.search)} 
                type="search" 
                value={filter} 
                onChange={(e) => onUpdateFilter(e.target.value)} 
            />
        </div>
    )
}

export default SearchStudent

const messages = defineMessages({
    search:{
        id:"searchStudent-placeholder", defaultMessage:"Search a student in the list..."
    }
})