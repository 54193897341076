import React from "react";
import cn from "classnames";
import "./LayerModal.scss";
import { AriaOverlayProps } from "@react-aria/overlays";
import { AriaDialogProps } from "@react-aria/dialog";
import { OverlayTriggerState } from "@react-stately/overlays";
import Modal from "../Modal/Modal";

interface LayerModalAdditionalProps {
    /**
     * The two children should be a LayerModalBackdrop and a LayerModalMain
     */
    children: [React.ReactNode, React.ReactNode];
    className?: string;
    state: OverlayTriggerState;
    /** @default false */
    preventScroll?: boolean;
}

export type LayerModalProps = LayerModalAdditionalProps &
    ({ "aria-labelledby": string } | { "aria-label": string });

export const LayerModal = ({
    children,
    className,
    state,
    preventScroll = false,
    ...props
}: AriaOverlayProps & AriaDialogProps & LayerModalProps) => {
    return (
        <Modal className="layer-modal__container" state={state} isDismissable={true} onClose={props.onClose}>
            <div
                className={cn(
                    "layer-modal",
                    {
                        "layer-modal--prevent-scroll": preventScroll,
                    },
                    className
                )}
            >
                <div className="layer-modal__inner">{children}</div>
            </div>
        </Modal>
    );
};

interface LayerModalBackdropProps {
    children: React.ReactNode;
}

export const LayerModalBackdrop = ({ children }: LayerModalBackdropProps) => {
    return <div className="layer-modal__backdrop">{children}</div>;
};

interface LayerModalMainProps {
    children: React.ReactNode;
    className?: string;
    title?: string;
}

export const LayerModalMain = ({
    children,
    className,
    title,
}: LayerModalMainProps) => {
    return (
        <div className={cn("layer-modal__main", className)}>
            {title && <h3 className="layer-modal-main__title">{title}</h3>}
            {children}
        </div>
    );
};
