import React, { MouseEvent, useRef } from 'react';
// External modules
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
// Contexts
// Components
import Card, { CardProps } from '../Card/Card';
import { Icon } from '@evidenceb/athena-common';
import { Image } from "@evidenceb/athena-common";
// Utils
// Hooks
import useAssetsDetails from '../../hooks/useAssetsDetails';
// SCSS
import './ResourceCard.scss';
import ProgressBar from '../ProgressBar/ProgressBar';
import cn from 'classnames';
// Interfaces

interface ResourceCardProps {
    name: {
        as: "h2" | "h3" | "h4" | "h5" | "h6" | "div";
        content: string | JSX.Element;
    };
    iconPath: string;
    objectivesNb: number;
    progression: number;
    progressBarColors?: {
        foreground: string;
        background: string;
    };
    img?:{
        src: string;
        alt?:string;
    }
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
}

const ResourceCard = ({ name, iconPath, objectivesNb, progression, progressBarColors, img, disabled, className, onClick }: ResourceCardProps) => {
    const intl = useIntl();
    const assetsDetails = useAssetsDetails();
    const classnames = cn([
        "ds-resource-card",
        className,
        {
            "--disabled": disabled,
            "--clickable": !disabled && !!onClick
        },
    ])
    const primaryBlue = getComputedStyle(document.body).getPropertyValue('--primary-blue');
    const hoverEffect = (e:MouseEvent<HTMLDivElement>, add: boolean) => {
        let el = e.currentTarget
        if(add && progressBarColors)
            el.style.borderColor = progressBarColors.foreground
        if(add && !progressBarColors)
            el.style.borderColor = primaryBlue
        if(!add)
            el.style.borderColor = "transparent"
    }

    return (
        <Card 
            className={classnames} 
            onClick={!disabled ? onClick : undefined}
            onMouseEnter={(e) => !disabled && hoverEffect(e, true)}
            onMouseLeave={(e) => !disabled && hoverEffect(e, false)}
        >
            <header>
                <Icon className="icon" path={iconPath} size="clickable" />
                <div className="infos">
                    <name.as>{name.content}</name.as>
                    <span>{
                        disabled ?
                            intl.formatMessage(messages.unavailable) :
                            intl.formatMessage(messages.objectives, { numObjective: objectivesNb })
                    }
                    </span>
                </div>
            </header>
            {
                img && 
                    <Image
                        className="ds-resource-card__image"
                        assetsDetails={assetsDetails}
                        src={img.src}
                        alt={img.src ?? ""}
                    />
            }
            <ProgressBar
                className="progress"
                height={8}
                colors={progressBarColors}
                disabled={disabled}
                label={intl.formatMessage(messages.progression)}
                value={progression}
            />
        </Card>
    )
}

export default ResourceCard

const messages = defineMessages({
    objectives: {
        id: "hierarchy-objective-plural",
        defaultMessage: `{numObjective, plural,
                            =0 {0 objective}
                            =1 {1 objective}
                            other {# objectives}
                        }`
    },
    unavailable: {
        id: "resource-unavailable",
        defaultMessage: "Unavailable"
    },
    progression: {
        id: "dashboard-common-progression",
        defaultMessage: "Progression",
    },
})

