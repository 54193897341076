import React, { useRef } from "react";
import cn from "classnames";
import {
    AriaOverlayProps,
    Overlay,
    useModalOverlay,
    useOverlayTrigger,
} from "@react-aria/overlays";
import { AriaDialogProps } from "@react-aria/dialog";
import {
    OverlayTriggerState,
    useOverlayTriggerState,
    OverlayTriggerProps,
} from "@react-stately/overlays";
import Button, { ButtonProps } from "../Button/Button";

import "./Modal.scss";

export interface ModalProps {
    className?: string;
    children?: React.ReactNode;
    /**
     * Object usually created with useOverlayTriggerState
     */
    state: OverlayTriggerState;
}

/**
 * The Modal components allows to add chilren to the top layer. It doesn't
 * include a Dialog whose responsibility it is to style the content that you
 * want to add in the top layer created by the Modal
 */
const Modal = ({
    className,
    state,
    children,
    ...props
}: AriaOverlayProps & AriaDialogProps & ModalProps) => {
    const ref = useRef(null);
    const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

    return (
        <Overlay>
            <div className="ds-modal__background" {...underlayProps}>
                <div
                    {...modalProps}
                    ref={ref}
                    className={cn("ds-modal", className)}
                >
                    {children}
                </div>
            </div>
        </Overlay>
    );
};

export type ModalTriggerProps = {
    children: (close: () => void) => JSX.Element;
    buttonProps: ButtonProps;
    modalClassname?: string;
} & OverlayTriggerProps &
    AriaOverlayProps;

/**
 * Button that controls the state of a Modal. The child should be a function
 * with a close function argument which can be used to control the state of the
 * modal. The content of the modal must have a single non Fragment Wrapper that
 * can accept DOMAttributes props so that the overlayProps can be passed down to
 * the component.
 * Good:
 * <ModalTrigger>
 *     {(close) => <div>
 *         <h1></h1>
 *         <button onClick={close}>Close</button>
 *     </div>}
 * </ModalTrigger>
 * Not good:
 * <ModalTrigger>
 *     {(close) => <>
 *         <h1></h1>
 *         <button onClick={close}>Close</button>
 *     </>}
 * </ModalTrigger>
 * Also not good:
 * <ModalTrigger>
 *     {(close) => <Container>
 *         <h1></h1>
 *         <button onClick={close}>Close</button>
 *     </Container>}
 * </ModalTrigger>
 * if Container doesn't have a non fragment wrapper or doesn't allow to pass
 * DOMAttributes to said container
 */
const ModalTrigger = ({
    children,
    buttonProps,
    modalClassname,
    ...props
}: ModalTriggerProps) => {
    let state = useOverlayTriggerState(props);
    let ref = useRef(null);
    let { triggerProps, overlayProps } = useOverlayTrigger(
        { type: "dialog" },
        state,
        ref
    );

    return (
        <>
            <Button {...buttonProps} {...triggerProps} ref={ref} />
            {state.isOpen && (
                <Modal {...props} state={state} className={modalClassname}>
                    {React.cloneElement(children(state.close), overlayProps)}
                </Modal>
            )}
        </>
    );
};

export { ModalTrigger };

export default Modal;
