import { IntlShape } from "react-intl";
import { cloneDeep } from "lodash";
import {
    getAncestors,
    getDescendants,
    preprocessData,
} from "@evidenceb/parametric-graph";
import { ModuleNotFoundError } from "../errors";
import { Config } from "../interfaces/Config";
import {
    PRLevel,
    PRLevelIds,
    PRItem,
    PRLockStatus,
    LevelData,
} from "../interfaces/PedagogicalResourcesManagement";
import { Data, MinimalData } from "../interfaces/Data";
import {
    getActivityById,
    getModuleById,
    getObjectiveById,
    initialTestFilter,
} from "./dataRetrieval";
import { getGraph, getParams } from "./ai";
import { completeLockStatus } from "./pedagogical-ressources";
import { AIType } from "@evidenceb/ai-handler";

export const getPRLevel = (
    id: string,
    data: Omit<MinimalData, "exercises">
): PRLevel => {
    if (data.modules.some((mod) => mod.id === id)) return PRLevel.Modules;
    if (data.objectives.some((obj) => obj.id === id)) return PRLevel.Objectives;
    if (data.activities.some((act) => act.id === id)) return PRLevel.Activities;
    throw new Error(`Level not found for resource with id ${id}`);
};

export const getChildLevel = (
    clickedItemId: string,
    data: Data,
    currentLevelData: LevelData,
    intl: IntlShape,
    aiConfig: Config["ai"]
): LevelData => {
    if (currentLevelData.level === PRLevel.Modules) {
        const mod = getModuleById(clickedItemId, data);
        return {
            level: PRLevel.Objectives,
            prPool: mod.objectiveIds
                .filter(
                    initialTestFilter(
                        aiConfig[AIType.BanditManchot].initialTest,
                        "notInitialTest"
                    )
                )
                .map((objId) => getObjectiveById(objId, data)),
            parentLevel: {
                ...currentLevelData,
                id: clickedItemId,
                moduleId: mod.id,
                listTitle: intl.formatMessage({
                    id: "prm-listOfObjectives",
                    defaultMessage: "List of objectives",
                }),
            },
        };
    } else {
        const obj = getObjectiveById(clickedItemId, data);
        return {
            level: PRLevel.Activities,
            prPool: obj.activityIds.map((actId) => {
                const activity = getActivityById(actId, data);
                return {
                    ...activity,
                    isPrerequisiteOf: getDirectDescendantObjectives(
                        actId,
                        data.objectives.map((obj) => obj.id),
                        aiConfig,
                        data,
                        currentLevelData.parentLevel!.moduleId
                    ),
                };
            }),
            parentLevel: {
                ...currentLevelData,
                id: clickedItemId,
                moduleId: currentLevelData.parentLevel!.moduleId,
                listTitle: intl.formatMessage({
                    id: "prm-listOfActivities",
                    defaultMessage: "List of activities",
                }),
            },
        };
    }
};

export const updatePRLockStatus = (
    originalPRLockStatus: PRLockStatus,
    itemsToToggle: PRItem[]
): PRLockStatus => {
    const newPRLockStatus: PRLockStatus = { ...originalPRLockStatus };
    itemsToToggle.forEach(({ id, level }) => {
        const levelIds = prLevelsToLevelIds(level);
        if (newPRLockStatus[levelIds].includes(id))
            newPRLockStatus[levelIds] = newPRLockStatus[levelIds].filter(
                (lockedId) => lockedId !== id
            );
        else newPRLockStatus[levelIds] = [...newPRLockStatus[levelIds], id];
    });
    return newPRLockStatus;
};

export const getPRModuleId = (
    id: string,
    data: Omit<MinimalData, "exercises">,
    aiConfig: Config["ai"][AIType.BanditManchot]
): string => {
    const mod = data.modules.find((mod) => {
        if (mod.objectiveIds.includes(id)) return true;
        const objectives = mod.objectiveIds
            .filter(initialTestFilter(aiConfig.initialTest, "notInitialTest"))
            .map((objId) => getObjectiveById(objId, data));
        return objectives.some((obj) => obj.activityIds.includes(id));
    });
    if (!mod) throw new ModuleNotFoundError(`No module contains resource with id ${id}`);
    return mod.id;
};

/**
 * Returns true if the pr Lock statuses are similar
 */
export const comparePRLockStatus = (
    prLockStatus1: PRLockStatus,
    prLockStatus2: PRLockStatus
): boolean => {
    const keys1 = Object.keys(prLockStatus1).sort();
    const keys2 = Object.keys(prLockStatus2).sort();
    if (
        keys1.length !== keys2.length ||
        keys1.some((key1, index) => keys2[index] !== key1)
    )
        return false;

    for (let key1 of keys1) {
        if (
            prLockStatus1[key1 as PRLevelIds].some(
                (value) => !prLockStatus2[key1 as PRLevelIds].includes(value)
            ) ||
            prLockStatus2[key1 as PRLevelIds].some(
                (value) => !prLockStatus1[key1 as PRLevelIds].includes(value)
            )
        )
            return false;
    }

    return true;
};

export const getDirectDescendantObjectives = (
    activityId: string,
    objectiveIds: string[],
    aiConfig: Config["ai"],
    data: Data,
    moduleId: string
): string[] => {
    const params = getParams(aiConfig, data, moduleId);
    const preprocessedData = preprocessData(params, moduleId);
    return objectiveIds.filter((objId) =>
        preprocessedData[objId]?.requirements.some(
            ([requirementId]) => requirementId === activityId
        )
    );
};

/**
 * Returns the list of items other than the origin that should be toggled
 * because they are dependencies
 */
export const getItemsToToggle = (
    itemId: string,
    levelData: LevelData,
    prLockStatus: PRLockStatus,
    graph: any,
    data: Omit<MinimalData, "exercises">,
    aiConfig: Config["ai"][AIType.BanditManchot],
    otherToBeToggledItems: PRItem[] = [],
): PRItem[] => {
    if (levelData.level === PRLevel.Modules)
        return getModuleItemsToToggle(itemId, prLockStatus, data, aiConfig);

    const isCurrentItemLocked =
        prLockStatus[prLevelsToLevelIds(levelData.level)].includes(itemId);
    if (isCurrentItemLocked)
        return getItemsToUnlock(
            itemId,
            graph,
            data,
            prLockStatus,
            levelData,
            otherToBeToggledItems
        );
    else
        return getItemsToLock(
            itemId,
            graph,
            data,
            prLockStatus,
            levelData,
            aiConfig,
            otherToBeToggledItems
        );
};

export const getItemsToUnlock = (
    originId: string,
    graph: any,
    data: Omit<MinimalData, "exercises">,
    prLockStatus: PRLockStatus,
    levelData: { level: LevelData["level"] } & {
        parentLevel?: Pick<
            Required<LevelData>["parentLevel"],
            "id" | "moduleId"
        >;
    },
    otherToBeToggledItems: PRItem[] = []
): PRItem[] => {
    const ancestors = getAncestors(graph, originId);

    let subLevelItems: string[] = [];
    if (levelData.level === PRLevel.Objectives) {
        const objective = getObjectiveById(originId, data);
        if (
            objective.activityIds.every((activityId) =>
                prLockStatus[PRLevelIds.ActivityIds].includes(activityId)
            )
        )
            subLevelItems = objective.activityIds.filter(
                (actId) => !ancestors.includes(actId)
            );
    }

    return [
        ...ancestors
            .map((id) => ({ id, level: getPRLevel(id, data) }))
            .filter(
                ({ id, level }) =>
                    id !== originId &&
                    prLockStatus[prLevelsToLevelIds(level)].includes(id) &&
                    otherToBeToggledItems.every(
                        (otherToBeToggledItem) => otherToBeToggledItem.id !== id
                    )
            ),
        ...subLevelItems
            .map((id) => ({ id, level: PRLevel.Activities }))
            .filter(({ id }) =>
                otherToBeToggledItems.every(
                    (otherToBeToggledItems) => otherToBeToggledItems.id !== id
                )
            ),
    ];
};

export const getItemsToLock = (
    originId: string,
    graph: any,
    data: Omit<MinimalData, "exercises">,
    prLockStatus: PRLockStatus,
    levelData: { level: LevelData["level"] } & {
        parentLevel?: Pick<
            Required<LevelData>["parentLevel"],
            "id" | "moduleId"
        >;
    },
    aiConfig: Config["ai"][AIType.BanditManchot],
    otherToBeToggledItems: PRItem[] = []
): PRItem[] => {
    const descendants = getDescendants(graph, originId);
    const itemsToLock: PRItem[] = descendants
        .map((id) => ({ id, level: getPRLevel(id, data) }))
        .filter(
            ({ id, level }) =>
                id !== originId &&
                !prLockStatus[prLevelsToLevelIds(level)].includes(id) &&
                otherToBeToggledItems.every(
                    (otherToBeToggledItem) => otherToBeToggledItem.id !== id
                )
        );

    if (levelData.level === PRLevel.Activities) {
        const objective = getObjectiveById(levelData.parentLevel!.id, data);
        if (
            shouldLockResourceBecauseAllChildrenAreLocked(
                prLockStatus,
                [
                    ...itemsToLock,
                    ...otherToBeToggledItems,
                    { id: originId, level: levelData.level },
                ],
                PRLevel.Objectives,
                objective.id,
                objective.activityIds,
                PRLevel.Activities
            )
        )
            itemsToLock.push({
                id: objective.id,
                level: PRLevel.Objectives,
            });
    }
    const module = getModuleById(levelData.parentLevel!.moduleId, data);
    if (
        shouldLockResourceBecauseAllChildrenAreLocked(
            prLockStatus,
            [
                ...itemsToLock,
                ...otherToBeToggledItems,
                { id: originId, level: levelData.level },
            ],
            PRLevel.Modules,
            module.id,
            module.objectiveIds.filter(
                initialTestFilter(aiConfig.initialTest, "notInitialTest")
            ),
            PRLevel.Objectives
        )
    )
        itemsToLock.push({
            id: module.id,
            level: PRLevel.Modules,
        });

    return itemsToLock;
};

const shouldLockResourceBecauseAllChildrenAreLocked = (
    prLockStatus: PRLockStatus,
    itemsToLock: PRItem[],
    resourceLevel: PRLevel,
    resourceId: string,
    children: string[],
    childLevel: PRLevel
): boolean => {
    return (
        !prLockStatus[prLevelsToLevelIds(resourceLevel)].includes(resourceId) &&
        itemsToLock.every((item) => item.id !== resourceId) &&
        children.every(
            (childId) =>
                prLockStatus[prLevelsToLevelIds(childLevel)].includes(
                    childId
                ) || itemsToLock.some((item) => item.id === childId)
        )
    );
};

/**
 * Get items to toggle for module level
 */
export const getModuleItemsToToggle = (
    moduleToToggle: string,
    prLockStatus: PRLockStatus,
    data: Omit<MinimalData, "exercises">,
    aiConfig: Config["ai"][AIType.BanditManchot],
    otherToBeToggledItems: PRItem[] = []
): PRItem[] => {
    const isModuleLocked =
        prLockStatus[PRLevelIds.ModuleIds].includes(moduleToToggle);
    if (!isModuleLocked)
        // Only lock module itself
        return [];

    // Unlock module and all sub resources that are locked
    const module = getModuleById(moduleToToggle, data);
    const itemsToToggle: PRItem[] = [];
    module.objectiveIds
        .filter(initialTestFilter(aiConfig.initialTest, "notInitialTest"))
        .forEach((objId) => {
            if (prLockStatus[PRLevelIds.ObjectiveIds].includes(objId))
                itemsToToggle.push({
                    id: objId,
                    level: PRLevel.Objectives,
                });
            const objective = getObjectiveById(objId, data);
            objective.activityIds.forEach((actId) => {
                if (prLockStatus[PRLevelIds.ActivityIds].includes(actId))
                    itemsToToggle.push({
                        id: actId,
                        level: PRLevel.Activities,
                    });
            });
        });
    return itemsToToggle.filter((item) =>
        otherToBeToggledItems.every(
            (otherToBeToggledItem) => otherToBeToggledItem.id !== item.id
        )
    );
};

export const areAllLevelItemsUnlocked = (
    levelData: LevelData,
    currentPRLockStatus: PRLockStatus
): boolean => {
    return levelData.prPool.every(
        (item) =>
            !currentPRLockStatus[prLevelsToLevelIds(levelData.level)].includes(
                item.id
            )
    );
};

export const getAllLevelItemsToToggle = (
    levelData: LevelData,
    graph: any,
    data: Omit<MinimalData, "exercises">,
    prLockStatus: PRLockStatus,
    aiConfig: Config["ai"][AIType.BanditManchot]
): PRItem[] => {
    return levelData.prPool
        .filter((item) => {
            if (areAllLevelItemsUnlocked(levelData, prLockStatus))
                // Lock all
                return true;

            // Unlock locked items
            return prLockStatus[prLevelsToLevelIds(levelData.level)].includes(
                item.id
            );
        })
        .reduce((itemsToToggle, item) => {
            const currentItemsToToggle = getItemsToToggle(
                item.id,
                levelData,
                prLockStatus,
                graph,
                data,
                aiConfig,
                itemsToToggle
            );
            const newItemsToToggle = [
                ...itemsToToggle,
                ...currentItemsToToggle,
            ];
            if (
                newItemsToToggle.every(
                    (existingItem) => existingItem.id !== item.id
                )
            )
                newItemsToToggle.push({
                    id: item.id,
                    level: levelData.level,
                });
            return newItemsToToggle;
        }, [] as PRItem[]);
};

/**
 * [TEMP] while we handle legacy lock status that didn't necessarily have all
 * dependencies locked (due to activities not being unlocked or the previous
 * design where only intentionaly locked resources where stored)
 *
 * Returns a lock status for which we are sure all the dependencies are locked
 */
export const checkPRLockStatusIntegrity = (
    originalLockStatus: PRLockStatus,
    data: MinimalData,
    config: Config["ai"]
): PRLockStatus => {
    const modulesCheckedLockStatus = lockResourcesOfLockedModules(
        completeLockStatus(originalLockStatus),
        data,
        config[AIType.BanditManchot]
    );
    const descendantsCheckedLockStatus = lockDescendantsOfLockedResources(
        modulesCheckedLockStatus,
        data,
        config
    );
    return descendantsCheckedLockStatus;
};

const lockResourcesOfLockedModules = (
    originalLockStatus: PRLockStatus,
    data: MinimalData,
    aiConfig: Config["ai"][AIType.BanditManchot]
) => {
    const updatedLockStatus = cloneDeep(originalLockStatus);
    const initTestFilter = initialTestFilter(aiConfig.initialTest, "notInitialTest");

    updatedLockStatus[PRLevelIds.ModuleIds].forEach((modId) => {
        const module = getModuleById(modId, data);
        module.objectiveIds
            .filter(initTestFilter)
            .forEach((objId) => {
                if (!updatedLockStatus[PRLevelIds.ObjectiveIds].includes(objId))
                    updatedLockStatus[PRLevelIds.ObjectiveIds].push(objId);

                const objective = getObjectiveById(objId, data);
                objective.activityIds.forEach((actId) => {
                    if (
                        !updatedLockStatus[PRLevelIds.ActivityIds].includes(
                            actId
                        )
                    )
                        updatedLockStatus[PRLevelIds.ActivityIds].push(actId);
                });
            });
    });

    return updatedLockStatus;
};

const lockDescendantsOfLockedResources = (
    originalLockStatus: PRLockStatus,
    data: MinimalData,
    config: Config["ai"]
) => {
    const updatedLockStatus = cloneDeep(originalLockStatus);

    ([PRLevelIds.ObjectiveIds, PRLevelIds.ActivityIds] as const).forEach(
        (Level) => {
            updatedLockStatus[Level].forEach((id) => {
                const moduleId = getPRModuleId(
                    id,
                    data,
                    config[AIType.BanditManchot]
                );
                const graph = getGraph(config, data, moduleId);
                const itemsToLock = getItemsToLock(
                    id,
                    graph,
                    data,
                    updatedLockStatus,
                    {
                        level: prLevelIdsToLevel(Level),
                        parentLevel: {
                            moduleId,
                            id: getPRParentId(
                                id,
                                data,
                                config[AIType.BanditManchot]
                            ),
                        },
                    },
                    config[AIType.BanditManchot]
                );
                itemsToLock.forEach((item) => {
                    updatedLockStatus[prLevelsToLevelIds(item.level)].push(
                        item.id
                    );
                });
            });
        }
    );

    return updatedLockStatus;
};

export const getPRParentId = (
    id: string,
    data: Omit<MinimalData, "exercises">,
    aiConfig: Config["ai"][AIType.BanditManchot]
): string => {
    for (let module of data.modules) {
        if (module.id === id) throw new Error("Module cannot have a parent");
        if (module.objectiveIds.includes(id)) return module.id;
    }
    for (let objective of data.objectives.filter(
        initialTestFilter(aiConfig.initialTest, "notInitialTest")
    )) {
        if (objective.activityIds.includes(id)) return objective.id;
    }
    throw new Error("Cannot find parent id");
};

export const prLevelsToLevelIds = (Levels: PRLevel): PRLevelIds => {
    switch (Levels) {
        case PRLevel.Modules:
            return PRLevelIds.ModuleIds;
        case PRLevel.Objectives:
            return PRLevelIds.ObjectiveIds;
        case PRLevel.Activities:
            return PRLevelIds.ActivityIds;
    }
};

export const prLevelsToLevel = (
    Levels: PRLevel
): "module" | "objective" | "activity" => {
    switch (Levels) {
        case PRLevel.Modules:
            return "module";
        case PRLevel.Objectives:
            return "objective";
        case PRLevel.Activities:
            return "activity";
    }
};

export const prLevelIdsToLevel = (LevelIds: PRLevelIds): PRLevel => {
    switch (LevelIds) {
        case PRLevelIds.ActivityIds:
            return PRLevel.Activities;
        case PRLevelIds.ObjectiveIds:
            return PRLevel.Objectives;
        case PRLevelIds.ModuleIds:
            return PRLevel.Modules;
    }
};
