import React, { useEffect } from "react";
import { FeatureFlagsProvider, MathJaxProvider } from "@evidenceb/athena-common"
import { ConfigProvider } from "./contexts/ConfigContext";
import { HomeProvider } from "./contexts/HomeContext";
import { ContentPagesProvider } from "./contexts/ContentPagesContext";
import { DataProvider } from "./contexts/DataContext";
import Root from "./pages/Root/Root";
import "./scss/App.scss";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { DashboardProvider } from "./contexts/DashboardContext";
import { SessionProvider } from "./contexts/SessionContext";
import { ErrorProvider } from "./contexts/ErrorContext";
import { HelmetProvider } from "react-helmet-async";
import { SyncProvider } from "./contexts/Sync";
import { CacheProvider } from "./contexts/CacheContext";

const App = () => {
    const { enableLinkTracking, trackPageView } = useMatomo();

    enableLinkTracking();
    useEffect(() => {
        trackPageView({});
    }, [trackPageView]);

    return (
        <ErrorProvider>
            <DataProvider>
                <ConfigProvider>
                    <FeatureFlagsProvider>
                        <DashboardProvider>
                            <HomeProvider>
                                <ContentPagesProvider>
                                    <SessionProvider>
                                        <MathJaxProvider>
                                            <HelmetProvider>
                                                <SyncProvider>
                                                    <CacheProvider>
                                                        <Root />
                                                    </CacheProvider>
                                                </SyncProvider>
                                            </HelmetProvider>
                                        </MathJaxProvider>
                                    </SessionProvider>
                                </ContentPagesProvider>
                            </HomeProvider>
                        </DashboardProvider>
                    </FeatureFlagsProvider>
                </ConfigProvider>
            </DataProvider>
        </ErrorProvider>
    );
};

export default App;
