import React from "react";
import { FormattedMessage } from "react-intl";
import { ICON_COLORS } from "../../scss/IconColors";
import Icon, { IconProps } from "../Icon/Icon";

import "./Chip.scss";

export interface ChipProps {
    /** @default light */
    colorMode?: "dark" | "light";
    /** @default "neutral" */
    type?: "sup" | "inf" | "conf" | "correct" | "incorrect" | "neutral";
    icon?: Partial<IconProps> & { path: IconProps["path"] };
    label?: string;
    input: string;
    /** @default medium */
    size?: "medium" | "small" | "big";
}

const Chip = ({
    icon,
    label,
    input,
    className,
    colorMode = "light",
    type = "neutral",
    size = "medium",
    ...props
}: Omit<
    React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >,
    "children"
> &
    ChipProps) => {
    return (
        <div
            {...props}
            className={`ds-chip ds-chip--${type} ds-chip--${colorMode}-bg ds-chip--${size} ${
                className ?? ""
            }`}
        >
            {!icon && hasIcon(type) && (
                <Icon
                    path={getIconPath(type, size)}
                    size={24}
                    className="ds-chip__set-type-icon"
                    color={getIconColor(type, size)}
                />
            )}
            {icon && <Icon {...icon} size={icon.size ?? "small"} />}

            <p>
                {label && (
                    <span className="ds-chip__label">
                        {label}
                        <FormattedMessage id="misc-colon" defaultMessage=":" />
                    </span>
                )}
                <span className="ds-chip__input">{input}</span>
            </p>
        </div>
    );
};

const hasIcon = (type: ChipProps["type"]): boolean => {
    switch (type) {
        case "neutral":
        case "correct":
        case "incorrect":
            return false;
        default:
            return true;
    }
};

const getIconPath = (
    type: ChipProps["type"],
    size: ChipProps["size"]
): string => {
    switch (type) {
        case "conf":
            return "conf_3d";
        case "sup":
            return size === "small" ? "arrow_drop_up" : "sup_3d";
        case "inf":
            return size === "small" ? "arrow_drop_down" : "inf_3d";
        default:
            throw new Error(`Icon path not configure for type ${type} in Chip`);
    }
};

const getIconColor = (
    type: ChipProps["type"],
    size: ChipProps["size"]
): string | undefined => {
    switch (type) {
        case "sup":
            return size === "small" ? ICON_COLORS.alertSupDefault : undefined;
        case "inf":
            return size === "small" ? ICON_COLORS.alertInfDefault : undefined;
        default:
            return undefined;
    }
};

export default Chip;
