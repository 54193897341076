import { AIType } from "@evidenceb/ai-handler";
import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";
import { ApplicationsConfig, AuthMode, Config } from "../interfaces/Config";

// Context Typing
interface ConfigContext {
    config: Config & Partial<ApplicationsConfig>;
    setConfig: Dispatch<SetStateAction<Config>>;
}

// Initial State
const initialState: Config = {
    versioning: 0,
    declinaison: "",
    client_name: "",
    apiUrls: {
        match: "",
        endpoints: {
            content: "",
            assetsProxy: "",
        },
    },
    activate_contentPage: false,
    features: {
        classCode: true,
        displayDevelopedBy: true,
        footerContactBtn: true,
    },
    pages: [],
    auth: {
        mode: AuthMode.DirectAccess,
        rightImage: "",
        registerURL: "",
        createAccountUrl: "",
        teacherAuthButton: "",
        studentAuthButton: "",
    },
    recommendations: {},
    logos: {
        avatar: "",
        header_client: "",
        endPlaylistIcon: "",
        footer_client: "",
        footer_evidenceb: "",
    },
    contact: "",
    developedBy: "",
    logoutUrl: [],
    ai: {
        [AIType.AdaptiveTest]: {
            id: "",
            baseConfig: {},
            moduleConfig: {},
        },
        [AIType.BanditManchot]: {
            id: "",
            baseConfig: {},
            moduleConfig: {},
            initialTest: {},
        },
    },
    displayedDateFormat: "D/MM/YY",
};

// Context init
const configStore = createContext<ConfigContext>({
    config: initialState,
    setConfig: () => null,
});

// Provider init
const { Provider } = configStore;

// Final setup
const ConfigProvider = ({ children }: { children: JSX.Element }) => {
    const [config, setConfig] = useState(initialState);
    useEffect(() => {
        console.log("CONTEXT CONFIG", config);
    }, [config]);
    return <Provider value={{ config, setConfig }}>{children}</Provider>;
};

export { configStore, ConfigProvider };
