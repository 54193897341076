import React, { useContext, useState } from "react";
import parse from "html-react-parser";
import { FormattedMessage, useIntl } from "react-intl";
import Collapse, { Panel } from "rc-collapse";
import Drawer from "rc-drawer";
import SimpleBar from "simplebar-react";
import {
    getItemDescription,
    getResourceIndex,
    initialTestFilter,
} from "../../../../utils/dataRetrieval";
import { InfoPanelProps } from "../../PlayerBuilder";
import "rc-drawer/assets/index.css";
import "rc-collapse/assets/index.css";
import "./TeacherPlayerInfoPanel.scss";
import arrow from "./arrow.svg";
import { AIType } from "@evidenceb/ai-handler";
import { configStore } from "../../../../contexts/ConfigContext";
import { MOBILE_BREAKPOINT } from "../../../../const/layout";

const TeacherPlayerInfoPanel = ({
    playlist: { module, activity, objective },
    getContainer,
}: InfoPanelProps) => {
    const {
        config: { ai },
    } = useContext(configStore);
    const [open, setOpen] = useState<boolean>(
        window.innerWidth > MOBILE_BREAKPOINT
    );
    const intl = useIntl();
    if (!activity || !objective) return null;

    const generateObjectiveTitle = (objectiveId: string) => {
        let initialTestId = module.objectiveIds.find(
            initialTestFilter(
                ai[AIType.BanditManchot].initialTest,
                "isInitialTest"
            )
        );
        let objectiveIntl = intl.formatMessage({
            id: "hierarchy-objective-full",
            defaultMessage: "objective",
        });
        if (initialTestId) {
            if (initialTestId === objectiveId) return `${objective.title.long}`;
            return `${objectiveIntl} ${getResourceIndex(
                objective.id,
                module.objectiveIds
            )}`;
        }
        return `${objectiveIntl} ${
            getResourceIndex(objective.id, module.objectiveIds) + 1
        }`;
    };

    const generateActivityTitle = () => {
        return `${intl.formatMessage({
            id: "hierarchy-activity-full",
            defaultMessage: "activity",
        })} ${getResourceIndex(activity.id, objective.activityIds) + 1}`;
    };

    const objectiveDescription = getItemDescription(objective, "teacher");
    const activityDescription = getItemDescription(activity, "teacher");

    if (
        !objectiveDescription &&
        !objective.targetedDifficulties?.$html &&
        !activityDescription
    )
        return null;

    return (
        <Drawer
            className="teacher-player-info-panel"
            rootClassName="teacher-info__drawer__container"
            open={open}
            placement="left"
            width={380}
            getContainer={getContainer}
            mask={false}
            motion={{
                motionAppear: true,
                motionName: 'teacher-info__drawer--motion'
            }}
        >
            <div
                className="drawer-handle"
                onClick={() => setOpen(!open)}
            >
                <DrawerExpandIcon open={open} />
            </div>
                <SimpleBar className="teacher-info__drawer__content">
                    <Collapse
                        expandIcon={CollapseExpandIcon}
                        accordion={false}
                        defaultActiveKey={["objective", "activity"]}
                    >
                        {(objectiveDescription !== "" ||
                            objective.targetedDifficulties?.$html) && (
                            <Panel
                                header={generateObjectiveTitle(objective.id)}
                                className="title"
                                key={"objective"}
                            >
                                <div className="panel-section">
                                    <p className="description">
                                        {parse(objectiveDescription)}
                                    </p>
                                </div>
                                {objective.targetedDifficulties?.$html ? (
                                    <div className="panel-section">
                                        <p className="sub-title">
                                            <FormattedMessage
                                                id="drawer-targetedDifficulties"
                                                defaultMessage="Targeted difficulties"
                                            />
                                        </p>
                                        <p className="description">
                                            {parse(
                                                objective.targetedDifficulties
                                                    ?.$html
                                            )}
                                        </p>{" "}
                                    </div>
                                ) : null}
                            </Panel>
                        )}
                        {activityDescription !== "" && (
                            <Panel
                                header={generateActivityTitle()}
                                className="title"
                                key={"activity"}
                            >
                                <div className="panel-section">
                                    <p className="description">
                                        {parse(activityDescription)}
                                    </p>
                                </div>
                            </Panel>
                        )}
                    </Collapse>
                </SimpleBar>
                <div
                    aria-hidden="true"
                    className="teacher-info-drawer__hidden-handler"
                    onClick={() => setOpen(!open)}
                />
        </Drawer>
    );
};

const DrawerExpandIcon = (props: any) => {
    return (
        <img
            className={"custom-drawer-handle-icon"}
            style={{
                transition: "transform .2s",
                transform: `rotate(${props.open ? 90 : -90}deg)`,
            }}
            src={arrow}
            alt="collapse"
        />
    );
};

const CollapseExpandIcon = (props: any) => {
    return (
        <img
            style={{
                verticalAlign: "-.125em",
                transition: "transform .2s",
                transform: `rotate(${props.isActive ? 180 : 0}deg)`,
            }}
            className={"custom-arrow"}
            src={arrow}
            alt="collapse"
        />
    );
};

export default TeacherPlayerInfoPanel;
