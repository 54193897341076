import React, { ReactNode, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import DemoBanner from "../../../../components/DemoBanner/DemoBanner";
import VisuallyHidden from "../../../../components/VisuallyHidden/VisuallyHidden";
import { configStore } from "../../../../contexts/ConfigContext";
import Icon from "../../../../design-system-components/Icon/Icon";
import { LayerModalBackdrop } from "../../../../design-system-components/LayerModal/LayerModal";
import { Student } from "../../../../interfaces/Dashboard";
import Details from "../Details/Details";

import "./ModalHeader.scss";

interface Props {
    student: Pick<Student, "firstname" | "lastname">;
    chips?: ReactNode;
    description?: ReactNode;
    onDismiss: () => void;
}

const ModalHeader = ({ student, onDismiss, chips, description }: Props) => {
    const {
        config: { features },
    } = useContext(configStore);
    const intl = useIntl();

    return (
        <LayerModalBackdrop>
            {features.demoMode?.displayIn.studentProgression && (
                <DemoBanner
                    position={"top"}
                    message={features.demoMode?.message.studentProgression}
                />
            )}

            <div className="student-progression__header-container">
                <div className="header__main-info">
                    <div className="header__student-identity">
                        <Icon
                            className="student-identity__icon"
                            color={"#fff"}
                            path={"person"}
                        />
                        <h1>
                            {student.firstname} {student.lastname}
                        </h1>

                        {description && (
                            <>
                                <span
                                    aria-hidden="true"
                                    className="header__separator"
                                >
                                    -
                                </span>
                                <p>
                                    {description}
                                </p>
                            </>
                        )}
                    </div>

                    <button
                        className="header__close-button"
                        onClick={onDismiss}
                    >
                        <Icon size="medium" path="close" color="var(--white)" />
                        <VisuallyHidden>
                            <FormattedMessage
                                id="misc-modalClose"
                                defaultMessage="Close"
                            />
                        </VisuallyHidden>
                    </button>
                </div>

                {chips && (
                    <>
                        <div className="header__info-labels">{chips}</div>
                        <Details
                            className="header__details"
                            variant="secondary"
                            summary={{
                                open: intl.formatMessage({
                                    id: "dashboard-studentProgression-hideDetails",
                                    defaultMessage: "Hide details",
                                }),
                                closed: intl.formatMessage({
                                    id: "dashboard-studentProgression-showDetails",
                                    defaultMessage: "Show details",
                                }),
                            }}
                        >
                            {chips}
                        </Details>
                    </>
                )}
            </div>
        </LayerModalBackdrop>
    );
};

export default ModalHeader;
