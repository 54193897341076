import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import {
    DismissableMessages,
    sessionStore,
} from "../../contexts/SessionContext";

import "./MessageDismiss.scss";

interface Props {
    sessionMessageKey: DismissableMessages;
}

/**
 * This component displays a checkbox that allows the user to say they don't
 * want to see the message again. It updates the session accordingly
 */
const MessageDismiss = ({ sessionMessageKey }: Props) => {
    const {
        session: { dismissMessages },
        setSession,
    } = useContext(sessionStore);

    return (
        <>
            <input
                type="checkbox"
                checked={dismissMessages[sessionMessageKey]}
                onChange={() => {
                    setSession((curr) => ({
                        ...curr,
                        dismissMessages: {
                            ...curr.dismissMessages,
                            [sessionMessageKey]:
                                !curr.dismissMessages[sessionMessageKey],
                        },
                    }));
                }}
                id="dismiss-message-checkbox"
            />
            <label
                className="message-dismiss__label"
                htmlFor="dismiss-message-checkbox"
            >
                <FormattedMessage
                    id="dontShowMessageAgain"
                    defaultMessage="Do not show this message again"
                />
            </label>
        </>
    );
};

export default MessageDismiss;
