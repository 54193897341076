import React, { useMemo } from "react";
import { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { configStore } from "../../contexts/ConfigContext";
import { sessionStore } from "../../contexts/SessionContext";
import CustomSelect from "../../design-system-components/CustomSelect/CustomSelect";
import useSelectApp from "../../hooks/useSelectApp";

type Props = {
    className?: string;
};

const AppSelect = ({ className }: Props) => {
    const intl = useIntl();
    const {
        config: { choiceType, hideChoiceLabel, availableApps },
    } = useContext(configStore);
    const {
        session: { app },
    } = useContext(sessionStore);

    const selectApp = useSelectApp();
    const handleSelectOption = (appName: string) => {
        const app = availableApps?.find(
            (availableApp) => availableApp.app === appName
        );
        if (!app) return;
        selectApp(app);
    };

    const currentApp = useMemo(
        () => availableApps?.find((availableApp) => availableApp.app === app),
        [app, availableApps]
    );

    if (!currentApp || !availableApps || availableApps.length < 2) return null;

    return (
        <CustomSelect
            className={className}
            selectedOption={currentApp.selectorLabel}
            onSelectOption={handleSelectOption}
            label={intl.formatMessage(
                choiceType === "LANGUAGE"
                    ? messages.changeLanguage
                    : messages.changeApp
            )}
            hideLabel={hideChoiceLabel}
            variant="inline"
        >
            {availableApps.map((app) => (
                <option key={app.app} value={app.app}>
                    {app.selectorLabel}
                </option>
            ))}
        </CustomSelect>
    );
};

const messages = defineMessages({
    changeApp: {
        id: "authentication-changeApp",
        defaultMessage: "Use another app:",
    },
    changeLanguage: {
        id: "authentication-changeLanguage",
        defaultMessage: "Use the app with a different langauge:",
    },
});

export default AppSelect;
