import { useContext, useEffect, useState } from "react";
import { AILoadingInfo, AIType } from "@evidenceb/ai-handler";
import { configStore } from "../contexts/ConfigContext";
import { dataStore } from "../contexts/DataContext";
import useBanditManchot from "./useBanditManchot";
import { PerModule } from "@evidenceb/gameplay-interfaces";
import useAdaptiveTest from "./useAdaptiveTest";

interface UseAIError {
    status: "error";
}

interface UseAILoading {
    status: "loading";
    forceLoad?: () => void;
}

export interface UseAISuccess<T = PerModule<AILoadingInfo> | AILoadingInfo> {
    status: "success";
    aiLoadingInfo: T;
}

export type UseAIStatus<T = PerModule<AILoadingInfo> | AILoadingInfo> =
    | UseAIError
    | UseAILoading
    | UseAISuccess<T>;

const useAI = (
    aiType: AIType,
    moduleId: string
): UseAIStatus<AILoadingInfo> => {
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);

    const bmInfo = useBanditManchot({ holdLoad: true });
    const adaptiveTestInfo = useAdaptiveTest(moduleId, { holdLoad: true });

    const [ai, setAI] = useState<UseAIStatus<AILoadingInfo>>({
        status: "loading",
    });

    useEffect(() => {
        if (ai.status !== "loading") return;

        if (aiType === AIType.BanditManchot) {
            if (bmInfo.status === "loading" && bmInfo.forceLoad)
                bmInfo.forceLoad();
            if (bmInfo.status === "error") setAI(bmInfo);
            if (bmInfo.status === "success")
                setAI({
                    status: "success",
                    aiLoadingInfo: bmInfo.aiLoadingInfo[moduleId],
                });
        } else if (aiType === AIType.AdaptiveTest) {
            if (
                adaptiveTestInfo.status === "loading" &&
                adaptiveTestInfo.forceLoad
            )
                adaptiveTestInfo.forceLoad();
            if (adaptiveTestInfo.status === "error") setAI(adaptiveTestInfo);
            if (adaptiveTestInfo.status === "success")
                setAI({
                    status: "success",
                    aiLoadingInfo: adaptiveTestInfo.aiLoadingInfo,
                });
        } else {
            throw new Error(`Initialization not configured for ${aiType} AI`);
        }
    }, [bmInfo, ai, aiType, config.ai, data, moduleId, adaptiveTestInfo]);

    return ai;
};

export default useAI;
