import React, {
    useEffect,
    FormEvent,
    useState,
    useContext,
    useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { dashboardStore } from "../../../contexts/DashboardContext";
import SimpleBar from "simplebar-react";
import "./Search.scss";
import { useIntl } from "react-intl";
import { UserType } from "../../../interfaces/User";
import { getUrl } from "../../../utils/dataRetrieval";
import { configStore } from "../../../contexts/ConfigContext";
import Icon from "../../../design-system-components/Icon/Icon";

interface IUser {
    firstname: string;
    lastname: string;
    id: string;
    classroom: {
        id: string;
        name: string;
    };
}

const Search: React.FC = () => {
    const history = useHistory();
    const { dashboard } = useContext(dashboardStore);
    const { config } = useContext(configStore);
    const [value, setValue] = useState<string>("");
    const [students, setStudents] = useState<IUser[]>([]);
    const [results, setResults] = useState<IUser[]>([]);
    const ref = useRef<HTMLDivElement>(null);
    const intl = useIntl();

    const onChange = (e: FormEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
        if (e.currentTarget.value.trim() !== "") {
            let filterdResults: IUser[] = students.filter((student) => {
                let completeName = `${student.firstname
                    .toLowerCase()
                    .trim()} ${student.lastname.toLowerCase().trim()}`;
                if (
                    completeName.includes(
                        e.currentTarget.value.toLowerCase().trim()
                    )
                ) {
                    return true;
                } else {
                    return false;
                }
            });
            setResults(filterdResults);
        } else {
            setResults([]);
        }
    };

    const reset = () => {
        setResults([]);
        setValue("");
    };

    const goToStudentDetails = (student: IUser) => {
        history.push(
            `/${
                getUrl(
                    config.pages.find((page) => page.type === "DASHBOARD")!,
                    UserType.Teacher
                ) as string
            }/${intl.formatMessage({
                id: "dashboard-paths-student",
                defaultMessage: "student",
            })}/${student.classroom.id}/${student.id}`
        );
        reset();
    };

    // Get all students
    useEffect(() => {
        let allStudents: IUser[] = [];
        dashboard?.classrooms.forEach((classroom) => {
            classroom.modulesList.forEach((ml) => {
                for (const id in ml.students) {
                    let duplicate = allStudents.find((e) => {
                        return e.id === id && e.classroom.id === classroom.id;
                    });
                    if (!duplicate) {
                        allStudents.push({
                            firstname: ml.students[id].firstname,
                            lastname: ml.students[id].lastname,
                            id: ml.students[id].id,
                            classroom: {
                                id: classroom.id,
                                name: classroom.name,
                            },
                        });
                    }
                }
            });
        });
        setStudents(allStudents);
    }, [dashboard]);

    useEffect(() => {
        const handleClickOutside = (e: globalThis.MouseEvent): void => {
            if (
                ref &&
                ref.current &&
                !(ref.current! as any).contains(e.target)
            ) {
                reset();
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [ref]);

    return (
        <div className="search">
            <div className="bar">
                <Icon path="search" size="medium" />
                <input
                    aria-label={intl.formatMessage({
                        id: "dashboard-common-searchStudent",
                        defaultMessage: "Search students",
                    })}
                    placeholder={intl.formatMessage({
                        id: "dashboard-common-searchStudent",
                        defaultMessage: "Search students",
                    })}
                    type="search"
                    value={value}
                    onChange={onChange}
                />
            </div>
            <div ref={ref} className={`list ${results.length ? "show" : ""}`}>
                <SimpleBar autoHide={false} forceVisible={true}>
                    <ul>
                        {results.map((student, i) => (
                            <li
                                key={"result" + i}
                                onClick={() => goToStudentDetails(student)}
                            >
                                <Icon className="icon" path="person" />
                                <span className="name">
                                    {student.firstname} {student.lastname} (
                                    {student.classroom.name})
                                </span>
                            </li>
                        ))}
                    </ul>
                </SimpleBar>
            </div>
        </div>
    );
};

export default Search;
