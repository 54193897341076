import React from "react";
import Icon from "../../design-system-components/Icon/Icon";

import "./CustomSelect.scss";

interface CustomSelectProps {
    onSelectOption: (value: string) => void;
    selectedOption: string | JSX.Element | (string | JSX.Element)[];
    label?: string;
    children: React.ReactNode
}

/**
 * Select component using native select functionalities but adding custom
 * styles.
 * Children should be the various options.
 */
const CustomSelect = ({
    selectedOption,
    onSelectOption,
    label,
    children
}: CustomSelectProps) => {
    const labelId = `select-label-${label?.replaceAll(" ", "")}`;

    return (
        <div className="custom-select">
            <select
                aria-labelledby={labelId}
                className="custom-select__native-select"
                onChange={(e) => {
                    onSelectOption(e.target.value);
                }}
            >
                {children}
            </select>
            
            <div className="custom-select__visual-select">
                {label && <label id={labelId}>{label}</label>}
                <div className="custom-select__visual-select__wrapper">
                    <div className="option">{selectedOption}</div>
                    <div className="visual-select__drop-icon">
                        <Icon path="arrow_drop_down" size="medium" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomSelect;
