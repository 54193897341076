import React, { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Activity, Statement } from "@xapi/xapi";
import {
    NextActivityPlayerEvent,
    PlayerEventEmitter,
} from "@evidenceb/gameplay-interfaces";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import useGetStatements from "../../../hooks/useGetStatements";
import { makeActivityVideoTutorialWatchedStatement } from "../../../utils/statement-builder";
import WizardInterruptingVideo from "../Shell/WizardShell/WizardInterruptingVideo/WizardInterruptingVideo";
import { activityVideoTutorialWatchedStatementsFilter } from "../../../utils/statements";
import { ActivityVideoTutorialResource } from "../../../interfaces/Resources";
import { UserType } from "../../../interfaces/User";
import useSyncStatements from "../../../hooks/useSyncStatements";
import { getAssetUrl } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../hooks/useAssetsDetails";

export interface UseMinitutorReturn {
    /**
     * Whether Minitutor wants to display something
     */
    display?: {
        /**
         * Describe how the thing should be displayed
         * - "replace" means the node should replace the shell completely
         */
        type: "replace";
        content: React.ReactNode;
    };
}

/**
 *      ,     ,
 *     (\____/)
 *      (_oo_)         Hello, my name is Minitutor. I watch over the student's
 *        (O)          progression in the playlist, and perform certain tasks
 *      __||__    \)   in reaction to events in their progress.
 *   []/______\[] /
 *   / \______/ \/
 *  /    /__\
 * (\   /____\
 */
const useMinitutor = (
    playerEventEmitter: PlayerEventEmitter
): UseMinitutorReturn => {
    const intl = useIntl();
    const { status: statementsStatus } = useGetStatements();
    const { sendStatement } = useSyncStatements();
    const {
        session: {
            statements,
            statementsFetched,
            userType,
            evidencebId,
            sessionId,
        },
    } = useContext(sessionStore);
    const { config } = useContext(configStore);
    const assetsDetails = useAssetsDetails();
    const [display, setDisplay] =
        useState<undefined | Required<UseMinitutorReturn>["display"]>();

    // Handle activity tutorial video
    const watchedVideosStatements = useMemo<undefined | Statement[]>(() => {
        if (!statementsFetched || !statements) return undefined;
        return statements.filter(activityVideoTutorialWatchedStatementsFilter);
    }, [statements, statementsFetched]);
    useEffect(() => {
        if (
            !config.features.minitutor?.activityTutorialVideo ||
            statementsStatus !== "success" ||
            userType !== UserType.Student
        )
            return;

        const nextExerciseHandler = (
            nextActivity: NextActivityPlayerEvent["payload"]
        ) => {
            if (!nextActivity) return;

            const resource = config.resources?.find(
                (resource) =>
                    resource.type === "activity-video-tutorial" &&
                    (resource.activityId === nextActivity.id ||
                        resource.activityIds?.includes(nextActivity.id))
            ) as undefined | ActivityVideoTutorialResource;
            if (!resource) {
                // There is no video for that activity
                return;
            }

            if (
                watchedVideosStatements?.some(
                    (statement) =>
                        (statement.object as Activity).id ===
                        getAssetUrl(
                            resource.video.sources[0].src,
                            assetsDetails
                        )
                )
            ) {
                // The video has already been shown
                return;
            }

            setDisplay({
                type: "replace",
                content: (
                    <WizardInterruptingVideo
                        video={resource.video}
                        instruction={resource.instruction}
                        dismissBtn={{
                            label: intl.formatMessage({
                                id: "goToExercise",
                                defaultMessage: "Go back to the exercise",
                            }),
                            fn: () => {
                                sendStatement(
                                    makeActivityVideoTutorialWatchedStatement(
                                        config.declinaison,
                                        evidencebId,
                                        sessionId,
                                        getAssetUrl(
                                            resource.video.sources[0].src,
                                            assetsDetails
                                        )!,
                                        nextActivity.id,
                                        "compulsory"
                                    )
                                );
                                setDisplay(undefined);
                            },
                            opts: {
                                disabledUntilVideoEnd: true,
                            },
                        }}
                    />
                ),
            });
        };

        playerEventEmitter.subscribe("NEXT_ACTIVITY", nextExerciseHandler);
        return () => {
            playerEventEmitter.unsubscribe(nextExerciseHandler);
        };
    }, [
        playerEventEmitter,
        statementsStatus,
        watchedVideosStatements,
        config.features.minitutor,
        config.resources,
        intl,
        userType,
        config.declinaison,
        evidencebId,
        sendStatement,
        sessionId,
        assetsDetails
    ]);

    return {
        display: display,
    };
};

export default useMinitutor;
