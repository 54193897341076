import { PerObjective } from "@evidenceb/gameplay-interfaces";
import React from "react";
import { useIntl } from "react-intl";
import CustomProgressBar from "../../../../design-system-components/CustomProgressBar/CustomProgressBar";
import { ComputedDiagnosis } from "../../../../interfaces/AdaptiveTests";
import { Data } from "../../../../interfaces/Data";

interface ToggleObjectivesProps {
    data: Data;
    displayedObjectives: PerObjective<ComputedDiagnosis>;
    displayEstimateInterval: boolean;
}

const ToggleObjectives = ({
    data,
    displayedObjectives,
    displayEstimateInterval,
}: ToggleObjectivesProps) => {
    const intl = useIntl();

    return (
        <>
            {displayedObjectives &&
                Object.entries(displayedObjectives).map(
                    ([objectiveId, objectiveDiagnosis]) => (
                        <CustomProgressBar
                            key={`progress-${objectiveId}`}
                            progress={objectiveDiagnosis.meanEstimate}
                            label={
                                data.objectives.find(
                                    (objective) => objective.id === objectiveId
                                )?.title.short ?? ""
                            }
                            estimate={
                                displayEstimateInterval
                                    ? {
                                          min: objectiveDiagnosis.lowEstimate,
                                          max: objectiveDiagnosis.highEstimate,
                                      }
                                    : undefined
                            }
                            defaultText={intl.formatMessage(
                                {
                                    id: "estimated-around",
                                    defaultMessage:
                                        "estimated at about {estimation}",
                                },
                                {
                                    estimation:
                                        objectiveDiagnosis.meanEstimate.toString(),
                                }
                            )}
                            estimationPosition={"under"}
                        />
                    )
                )}
        </>
    );
};

export default ToggleObjectives;
