import React from "react";
import { FormattedMessage } from "react-intl";
import parse from "html-react-parser";
import Collapse, { Panel } from "rc-collapse";
import { Activity,  Objective } from "@evidenceb/gameplay-interfaces";
import { Status } from "@evidenceb/bandit-manchot";
import ActivityLabel from "./ActivityLabel/ActivityLabel";

import tear from "../tear.svg";
import { getItemDescription } from "../../../../../utils/dataRetrieval";
import Icon from "../../../../../design-system-components/Icon/Icon";

type CurrentObjectiveProps = {
    objective: Objective;
    activities: (Activity & { status: Status })[];
    currentActivityId: string;
};

const CurrentObjective: React.FC<CurrentObjectiveProps> = ({
    objective,
    activities,
}) => {
    const description = getItemDescription(objective, "student");
    return (
        <>
            <div className="objective current-objective">
                <img className="current-objective-tear" src={tear} alt="" />
                <div className="objective-description">
                    { parse(description ) }
                    <Collapse
                        expandIcon={(props) =>
                            ExpanIconClojure(
                                (props as any)["isActive"] as boolean
                            )
                        }
                        accordion={false}
                    >
                        <Panel>
                            <div className="journey-activities">
                                {activities.map((activity, index) => (
                                    <ActivityLabel
                                        status={activity.status}
                                        key={`activity-${index}`}
                                    >
                                        <>
                                            {parse(getItemDescription(activity, "student"))}
                                        </>
                                    </ActivityLabel>
                                ))}
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </>
    );
};

const ExpanIconClojure = (isActive: boolean) => {
    return (
        <div className="expand-icon-current-objective">
            <Icon path={isActive ? "remove_circle" : "add_circle"} size="small" color="white" className="expand-icon-current-objective__icon"/>
            <span className="details-text">
                {isActive
                    ? <FormattedMessage id="features-studentChatbotPlayerInfoPanel-reduceDetails" defaultMessage="Reduce details" />
                    : <FormattedMessage id="features-studentChatbotPlayerInfoPanel-details" defaultMessage="Details" />}
            </span>
        </div>
    );
};

export default CurrentObjective;
