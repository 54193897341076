import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Page } from "../../interfaces/Config";
import "./Footer.scss";
import { getLabel, getUrl } from "../../utils/dataRetrieval";
import { sessionStore } from "../../contexts/SessionContext";
import UserDataDownloadButton from "./UserDataDownloadButton/UserDataDownloadButtons";
import { configStore } from "../../contexts/ConfigContext";
import Button from "../../design-system-components/Button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import { Image } from "@evidenceb/athena-common";
import useAssetsDetails from "../../hooks/useAssetsDetails";

interface FooterProps {
    logos: {
        footer_client: string;
        footer_evidenceb: string;
    };
    pages: Page[];
    contact: string;
}

/**
 * Main footer of the Athena app
 *
 * Available features:
 * - user data download button (with the userDataDownload flag)
 */
function Footer({ logos, pages, contact }: FooterProps) {
    /*     const goToContact = () => {
        window.open(contact,'_blank')
    } */

    const {
        session: { userType },
    } = useContext(sessionStore);
    const { config } = useContext(configStore);
    const currentYear = new Date().getFullYear();
    const intl = useIntl();
    const assetsDetails = useAssetsDetails();

    return (
        <div className="footer">
            <div className="container">
                {logos.footer_client && (
                    <Image
                        assetsDetails={assetsDetails}
                        src={logos.footer_client}
                        className="logo left"
                        alt={config.client_name}
                    />
                )}
                <div className="links">
                    <nav>
                        {pages.map((page, i) =>
                            page.inFooter ? (
                                <li key={`footer-link-${i}`}>
                                    <Link to={`/${getUrl(page, userType)}`}>
                                        {getLabel(page, userType)}
                                    </Link>
                                </li>
                            ) : null
                        )}
                    </nav>

                    <div className="footer__buttons-container">
                        {config.features.userDataDownload && (
                            <UserDataDownloadButton />
                        )}

                        {config.features.footerContactBtn && <Button
                            color={
                                config.features.footerBtnVariant?.color ??
                                "secondary"
                            }
                            variant={
                                config.features.footerBtnVariant?.variant ??
                                "primary"
                            }
                            asLink={{
                                href: contact,
                                target: "_blank",
                                rel: "noreferrer",
                            }}
                            icon={{
                                path: "mail",
                                position: "left",
                            }}
                            label={intl.formatMessage({
                                id: "misc-contact",
                                defaultMessage: "Contact",
                            })}
                            center={true}
                        />}
                    </div>
                </div>
                {config.features.displayDevelopedBy !== false && (
                    <div className="logo right">
                        <span>
                            <FormattedMessage
                                id="misc-developedBy"
                                defaultMessage="Developed by"
                            />
                        </span>
                        <Image assetsDetails={assetsDetails} src={logos.footer_evidenceb} className="" alt="EvidenceB" />
                    </div>
                )}
            </div>
            {config.features.displayCopyright && (
                <div className="copyright">
                    © Copyright {currentYear} EvidenceB
                </div>
            )}
        </div>
    );
}

export default Footer;
