import { defineMessages, IntlShape } from "react-intl";

export enum ModuleState {
    New = "NEW",
    InProgress = "IN_PROGRESS",
    Completed = "COMPLETED",
}

export const getModuleState = (
    isModuleFinished: boolean,
    hasStatements: boolean
): ModuleState => {
    if (isModuleFinished) return ModuleState.Completed;
    if (!hasStatements) return ModuleState.New;
    return ModuleState.InProgress;
};

export const getModuleActionText = (
    moduleState: ModuleState,
    intl: IntlShape
): string => {
    if (moduleState === ModuleState.New)
        return intl.formatMessage(messages.start);
    if (moduleState === ModuleState.Completed)
        return intl.formatMessage(messages.moduleCompleted);
    return intl.formatMessage(messages.continue);
};

export const messages = defineMessages({
    progression: {
        id: "moduleList-student-progression",
        defaultMessage: "Progression",
    },
    successRate: {
        id: "moduleList-student-successRate",
        defaultMessage: "Success rate",
    },
    start: {
        id: "moduleList-student-start",
        defaultMessage: "Start",
    },
    moduleCompleted: {
        id: "moduleList-student-completedModule",
        defaultMessage: "Module completed",
    },
    continue: {
        id: "moduleList-student-continue",
        defaultMessage: "Continue",
    },
});