import React, { useContext } from "react";
// External modules
import { useHistory } from "react-router-dom";
//Hooks
import { defineMessages, useIntl } from "react-intl";
// Contexts
import { configStore } from "../../../contexts/ConfigContext";
// Components
import Icon from "../../../design-system-components/Icon/Icon";
// Utils
import { authMessages } from "../auth-messages";
// SCSS
import "./SelectUserType.scss";
import { Image } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../hooks/useAssetsDetails";

// Interfaces
const SelectUserType = () => {
    const intl = useIntl();
    const history = useHistory();
    const {
        config: { client_name, auth, contact },
    } = useContext(configStore);
    const assetsDetails = useAssetsDetails();

    return (
        <div className="select-user-type-container">
            <div className="select-user-type--header">
                <h1>
                    {intl.formatMessage(messages.welcomeTitle, {
                        clientName: client_name,
                    })}
                </h1>
            </div>
            <div className="select-user-type">
                <button onClick={() => history.push("/auth/student")}>
                    {auth.studentAuthButton && (
                        <Image src={auth.studentAuthButton} assetsDetails={assetsDetails} />
                    )}
                    {!auth.studentAuthButton && (
                        <Icon
                            path="illustrations/select-user-type-student"
                            size={{ width: 218, height: 200 }}
                        />
                    )}
                    <div>{intl.formatMessage(authMessages.loginAsStudent)}</div>
                </button>
                <button onClick={() => history.push("/auth/teacher")}>
                    {auth.teacherAuthButton && (
                        <Image src={auth.teacherAuthButton} assetsDetails={assetsDetails} />
                    )}
                    {!auth.teacherAuthButton && (
                        <Icon
                            path="illustrations/select-user-type-teacher"
                            size={{ width: 218, height: 200 }}
                        />
                    )}
                    <div>
                        {intl.formatMessage(authMessages.teacherAuthTitle)}
                    </div>
                </button>
            </div>
            <div className="select-user-type__help">
                {intl.formatMessage(messages.welcomeMessage).trim() && (
                    <div className="select-user-type__helpInformation">
                        <p>
                            {intl.formatMessage(messages.welcomeMessage, {
                                clientName: client_name,
                            })}
                        </p>
                    </div>
                )}
                <div className="select-user-type__contact">
                    <span>
                        {intl.formatMessage(messages.helpContact, {
                            clientName: client_name,
                        })}
                    </span>{" "}
                    <span className="contact-link">
                        <a href={contact}>
                            {intl.formatMessage(messages.helpContactLink)}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SelectUserType;

const messages = defineMessages({
    welcomeTitle: {
        id: "authentication-welcomeTitle",
        defaultMessage: "Log in to {clientName}",
    },
    welcomeMessage: {
        id: "authentication-welcomeMessage",
        defaultMessage:
            "If your {clientName} account is available on your provider, please connect through your school authentification process.",
    },
    helpContact: {
        id: "authentication-helpContact",
        defaultMessage:
            "If you need help to sign in to {clientName}, contact us ",
    },
    helpContactLink: {
        id: "authentication-helpContact-link",
        defaultMessage: "here",
    },
});
