import React from "react";
import {
    ClusterInfosClustering,
    InfosEleves,
    InfosVariables,
} from "../../../interfaces/Dashboard";
import { FormattedMessage, useIntl } from "react-intl";

import "./GroupCharacteristics.scss";
import Icon from "../../../design-system-components/Icon/Icon";

interface GroupCharacteristicsProps {
    groupIndex: number; // TEMP for color
    selected: boolean;
    onSelectGroup: () => void;
    selectedStudentIndex?: number;
    cluster: ClusterInfosClustering;
    variables: (InfosVariables & { name: string })[];
    selectedStudent?: InfosEleves;
}

const GroupCharacteristics: React.FC<GroupCharacteristicsProps> = ({
    cluster,
    variables,
    groupIndex,
    selected,
    onSelectGroup,
    selectedStudent,
}) => {
    const intl = useIntl();

    return (
        <div className="group-characteristics" onClick={onSelectGroup}>
            <div className="header">
                <div className={`circle --group-${groupIndex + 1}`}>
                    <Icon path="chevron_down" size="medium" className="--group__icon"/>
                </div>
                <div className="title">{`${intl.formatMessage({id: "dashboard-common-group", defaultMessage: "Group"})} ${cluster.name}`}</div>
            </div>
            <div
                className={`characteristic characteristic-border-${
                    groupIndex + 1
                } ${selected ? "selected" : ""} ${typeof selectedStudent !== "undefined" ? "selected-student" : ""}`}
            >
                {!selected ? (
                    <div style={{ marginLeft: "10px" }}>
                        {`${getNumberOfVariables(cluster, variables)} ${
                            getNumberOfVariables(cluster, variables) > 1
                                ? intl.formatMessage({id: "dashboard-clustering-characteristics", defaultMessage: "Characteristics"})
                                : intl.formatMessage({id: "dashboard-clustering-characteristic", defaultMessage: "Characteristic"})
                        }`}
                    </div>
                ) : (
                    variables.map(
                        (variable, key) =>
                            /* @ts-ignore: Checking for key existence should be fine*/
                            typeof cluster[variable.name] !== "undefined" && (
                                <CharacteristicDetails
                                    key={key}
                                    groupIndex={groupIndex}
                                    variable={variable}
                                    /* @ts-ignore variable.name should be one of var1 var2 or var3 and there should be a value provided for the cluster */
                                    clusterValue={cluster[variable.name]}
                                    selectedStudentValue={
                                        selectedStudent
                                            ? /* @ts-ignore variable.name should be one of var1 var2 or var3 and there should be a value provided for the student */
                                              selectedStudent[variable.name]
                                            : undefined
                                    }
                                />
                            )
                    )
                )}
            </div>
        </div>
    );
};

const CharacteristicDetails: React.FC<{
    groupIndex: number;
    variable: InfosVariables;
    clusterValue: string;
    selectedStudentValue?: string;
}> = ({ groupIndex, variable, selectedStudentValue, clusterValue }) => {
    return (<>
            <div
                className={`detailed-characteristic-title --group-${
                    groupIndex + 1
                }`}
            >
                {variable.description.type === "MEAN_SCORE" && <FormattedMessage id="dashboard-clustering-variables-MEAN_SCORE" defaultMessage="Mean score of all completed exercises /1" />}
                {variable.description.type === "MAX_DIFF" && <FormattedMessage id="dashboard-clustering-variables-MAX_DIFF" defaultMessage="Maximum difficulty level" />}
                {variable.description.type === "MEAN_DUR" && <FormattedMessage id="dashboard-clustering-variables-MEAN_DUR" defaultMessage="Mean time spent on each exercise" />}
                {variable.description.type === "EX_DONE" && <FormattedMessage id="dashboard-clustering-variables-EX_DONE" defaultMessage="Number of completed exercises" />}
                {variable.description.type === "NB_OBJ" && <FormattedMessage id="dashboard-clustering-variables-NB_OBJ" defaultMessage="Number of opened objectives" />}
                {variable.description.type === "EX_SUCC" && <FormattedMessage id="dashboard-clustering-variables-EX_SUCC" defaultMessage="Number of exercises completed correctly" />}
            </div>

            <div
                className={`duration --group-${groupIndex + 1}`}
            >{`${clusterValue} ${variable.unit} ${variable.description.type === "MEAN_SCORE" ? "/1" : ""}`}</div>

            {selectedStudentValue && (
                <div
                    className="characteristic-details__student-value"
                >{`${selectedStudentValue} ${variable.unit} ${variable.description.type === "MEAN_SCORE" ? "/1" : ""}`}</div>
            )}
    </>);
};

const getNumberOfVariables = (
    cluster: ClusterInfosClustering,
    variables: (InfosVariables & { name: string })[]
): number => {
    return variables.filter(
        /* @ts-ignore: Checking for key existence should be fine*/
        (variable) => typeof cluster[variable.name] !== "undefined"
    ).length;
};

export default GroupCharacteristics;
