import React, { useContext, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import Case from "case";
import { Objective, Student } from "../../interfaces/Dashboard";
import {
    PRLevelIds,
    PRLockStatus,
} from "../../interfaces/PedagogicalResourcesManagement";
import { dataStore } from "../../contexts/DataContext";
import { getObjectiveById } from "../../utils/dataRetrieval";
import { commonMessages } from "../../utils/messages";
import Icon from "../../design-system-components/Icon/Icon";
import StudentProgression, {
    ProgressionData,
} from "../../pages/DashBoard/StudentProgression/StudentProgression";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";
import ProgressionButton from "./ProgressionButton/ProgressionButton";
import ProgressionContainer from "./ProgressionContainer/ProgressionContainer";

import "./ProgressionTableCell.scss";
import { TooltipWrapper } from "../../design-system-components/ToolTip/ToolTip";

interface Props {
    prLockStatus?: PRLockStatus;
    objective: Objective;
    classroomId: string;
    student: Student;
    type?: "default" | "dashed";
    /** @default false */
    showObjectiveTitle?: boolean;
    /**
     * Required when showing the title
     * @default 1
     */
    objectiveIndex?: number;
}

const ObjectiveProgression = ({
    prLockStatus,
    objective,
    student,
    type,
    showObjectiveTitle = false,
    objectiveIndex = 1,
}: Props) => {
    const { data } = useContext(dataStore);
    const intl = useIntl();
    const [progression, setProgression] = useState<ProgressionData>();

    return (
        <>
            {progression && (
                <StudentProgression
                    {...progression}
                    onDismiss={() => {
                        setProgression(undefined);
                    }}
                />
            )}

            <ProgressionContainer
                status={objective.status}
                prLock={
                    prLockStatus
                        ? {
                            status: prLockStatus,
                            level: PRLevelIds.ObjectiveIds,
                            id: objective.id,
                        }
                        : undefined
                }
                title={
                    showObjectiveTitle
                        ? intl.formatMessage(commonMessages.objectiveShort) +
                        " " +
                        objectiveIndex
                        : undefined
                }
            >

                <TooltipWrapper
                    wrapper="div"
                    tooltip={`${Case.pascal(
                        intl
                            .formatMessage(commonMessages.objectiveShort)
                            .charAt(0)
                    )}${objectiveIndex}. ${getObjectiveById(objective.id, data).title.short
                        }`}
                    tooltipClassname="objective-progression__tooltip"
                >

                    <ProgressionButton
                        status={objective.status}
                        type={type}
                        onClick={() =>
                            setProgression({
                                objective,
                                student,
                            })
                        }
                    >
                        {objective.alert !== "none" && (
                            <span aria-hidden="true" className="alert">
                                <Icon
                                    path={
                                        objective.alert === "positive"
                                            ? "alert_sup"
                                            : "alert_inf"
                                    }
                                    size="medium"
                                />
                            </span>
                        )}
                        <Icon
                            path="open_in_full"
                            size="medium"
                            className="icon"
                        />
                        <VisuallyHidden>
                            {intl.formatMessage(
                                messages.a11ySeeObjectiveProgression
                            )}
                        </VisuallyHidden>
                    </ProgressionButton>
                </TooltipWrapper>
            </ProgressionContainer>
        </>
    );
};

const messages = defineMessages({
    a11ySeeObjectiveProgression: {
        id: "dashboard-a11y-seeObjectiveProgression",
        defaultMessage: "Open the objective's progression",
    },
});

export default ObjectiveProgression;
