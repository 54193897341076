import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { Pagetype } from "../../../../interfaces/Config";
import { configStore } from "../../../../contexts/ConfigContext";
import { getLastResults } from "../../../../utils/exerciseUtils";
import Button from "../../../../components/Button/Button";
import { getUrl } from "../../../../utils/dataRetrieval";
import { sessionStore } from "../../../../contexts/SessionContext";
import { UserType } from "../../../../interfaces/User";
import { FormattedMessage, useIntl } from "react-intl";
import { ExerciseResult, PlayerOpts } from "../../../../interfaces/Player";

import "./DefaultPlaylistSummary.scss";
import useAssetsDetails from "../../../../hooks/useAssetsDetails";
import { Image } from "@evidenceb/athena-common";

export interface PlaylistSummaryProps {
    exerciseResults: ExerciseResult<any>[];
    opts?: Partial<PlayerOpts>;
    className?: string;
}

const DefaultPlaylistSummary = ({
    exerciseResults,
    className,
}: PlaylistSummaryProps) => {
    const { config } = useContext(configStore);
    const {
        session: { userType },
    } = useContext(sessionStore);
    const history = useHistory();
    const intl = useIntl();
    const assetsDetails = useAssetsDetails();

    const moduleList = () => {
        config.pages.forEach((page) => {
            if (page.type === Pagetype.MODULELIST) {
                history.push(`/${getUrl(page, userType)}`);
            }
        });
    };

    const dashboard = () => {
        config.pages.forEach((page) => {
            if (page.type === Pagetype.DASHBOARD) {
                history.push(`/${getUrl(page, userType)}`);
            }
        });
    };

    return (
        <div className={cn("default-playlist-summary-container", className)}>
            <div className="content">
                <div className="icon">
                    <Image
                        src={config.logos.endPlaylistIcon}
                        assetsDetails={assetsDetails}
                    />
                </div>

                <h1>
                    <span><FormattedMessage id="endPlaylist-congratulations" defaultMessage="Congratulations" /></span>
                    <span>
                        <FormattedMessage id="endPlaylist-completed" defaultMessage="You have completed the module!" />
                    </span>
                </h1>

                <p><FormattedMessage id="endPlaylist-result" defaultMessage="Results:" /></p>
                <ul>
                    {getLastResults(exerciseResults).map(
                        (exerciseResult, index) => (
                            <li key={exerciseResult.exerciseId}>
                                {index + 1}:
                                {exerciseResult.correct ? (
                                    <span className="--correct">
                                        <FormattedMessage id="endPlaylist-exerciseCorrect" defaultMessage="Correct answer" />
                                    </span>
                                ) : (
                                    <span className="--incorrect">
                                        <FormattedMessage id="endPlaylist-exerciseIncorrect" defaultMessage="Incorrect answer" />
                                    </span>
                                )}
                            </li>
                        )
                    )}
                </ul>

                <nav>
                    <Button
                        onClick={moduleList}
                        type={"primary"}
                        label={intl.formatMessage({id: "endPlaylist-moduleList", defaultMessage: "Modules list"})}
                        icons={[ { placement: "right", code:"arrow_forward" } ]}
                    />

                    {userType === UserType.Teacher &&
                        config.features.teacherDashboard && (
                            <Button
                                onClick={dashboard}
                                type={"primary"}
                                label={intl.formatMessage({id: "endPlaylist-dashboard", defaultMessage: "Dashboard"})}
                                icons={[ { placement: "right", code:"arrow_forward" } ]}
                            />
                        )}
                </nav>
            </div>
        </div>
    );
};

export default DefaultPlaylistSummary;
