import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { AIType } from "@evidenceb/ai-handler";
import { Module } from "@evidenceb/gameplay-interfaces";
import { Student, TestResult } from "../../interfaces/Dashboard";
import {
    PRLevelIds,
    PRLockStatus,
} from "../../interfaces/PedagogicalResourcesManagement";
import Icon from "../../design-system-components/Icon/Icon";
import dashboardMessages from "../../pages/DashBoard/dashboardMessages";
import StudentTestProgression from "../../pages/DashBoard/StudentProgression/StudentTestProgression";
import VisuallyHidden from "../VisuallyHidden/VisuallyHidden";
import ProgressionButton from "./ProgressionButton/ProgressionButton";
import ProgressionContainer from "./ProgressionContainer/ProgressionContainer";

import "./ProgressionTableCell.scss";
import { useOverlayTriggerState } from "react-stately";

interface Props {
    student: Student;
    module: Module;
    /** @default false */
    showTitle?: boolean;
    prLockStatus?: PRLockStatus;
}

const TestProgression = ({
    student,
    module,
    showTitle,
    prLockStatus,
}: Props) => {
    const intl = useIntl();
    const testResult = useMemo<undefined | TestResult>(
        () =>
            student.testsResults?.find(
                (test) => test.type === AIType.BanditManchot
            ),
        [student]
    );
    const modalState = useOverlayTriggerState({})

    return (
        <>
            <ProgressionContainer
                status={testResult?.status ?? "none"}
                prLock={
                    prLockStatus
                        ? {
                              status: prLockStatus,
                              level: PRLevelIds.ModuleIds,
                              id: module.id,
                          }
                        : undefined
                }
                title={
                    showTitle
                        ? intl.formatMessage(dashboardMessages.diagTest)
                        : undefined
                }
            >
                <ProgressionButton
                    status={testResult?.status ?? "none"}
                    onClick={modalState.open}
                >
                    <Icon path="open_in_full" size="medium" className="icon" />
                    <VisuallyHidden>
                        {intl.formatMessage(messages.openTestResult)}
                    </VisuallyHidden>
                </ProgressionButton>
            </ProgressionContainer>

            {modalState.isOpen && (
                <StudentTestProgression
                    student={student}
                    state={modalState}
                    onDismiss={modalState.close}
                    moduleTitle={module.title.short!}
                />
            )}
        </>
    );
};

const messages = defineMessages({
    openTestResult: {
        id: "dashboard-openTestResultDetail",
        defaultMessage: "Open the test's detailed results",
    },
});

export default TestProgression;
