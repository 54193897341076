import {
  Module,
  Objective,
} from "@evidenceb/gameplay-interfaces";
import { ExerciseDefinition } from '@evidenceb/athena-common';
import { getObjectiveById } from '../../utils/dataRetrieval';
import { buildModuleStatus, buildObjectiveStatus, buildExerciceStatus } from "./cacheCommon";
import {
  CacheState,
  ModuleStatus,
  ObjectiveStatus,
  ExerciceStatus,
  AssetStatus,
} from './cacheState';
import { InitCachePayload } from './cacheAction';

export function initCacheState(payload: InitCachePayload): CacheState {
  const moduleStatuses = new Array<ModuleStatus>();
  payload.data.modules.forEach(module => {
    const moduleStatus = initModuleStatus(module, payload);
    moduleStatuses.push(moduleStatus);
  });

  return {
    modules: moduleStatuses,
    assetsUrl: payload.assetsUrl,
    version: payload.version,
    currentTask: undefined,
  };
}

function initModuleStatus(module: Module, payload: InitCachePayload): ModuleStatus {
  const objectiveStatuses = new Array<ObjectiveStatus>();
  module.objectiveIds
    .map(objectiveId => getObjectiveById(objectiveId, payload.data, false))
    .forEach(objective => {
      const objectiveStatus = initObjectiveStatus(objective, payload);
      objectiveStatuses.push(objectiveStatus);
    });

  return buildModuleStatus(module.id, objectiveStatuses);
}

function initObjectiveStatus(objective: Objective, payload: InitCachePayload): ObjectiveStatus {
  const exerciceStatuses = new Array<ExerciceStatus>();
  payload.data.activities
    .filter(activity => (objective.activityIds.some(activityId => activityId === activity.id)))
    .flatMap(activity => activity.exerciseIds)
    .map(exerciceId => payload.data.exercises.find(exercice => exercice.id === exerciceId))
    .filter(exercice => exercice !== undefined)
    .forEach(exercice => {
      const exerciceStatus = initExerciceStatus(exercice!, payload);
      exerciceStatuses.push(exerciceStatus);
    });

  return buildObjectiveStatus(objective.id, exerciceStatuses);
}

function initExerciceStatus(exercice: ExerciseDefinition<any>, payload: InitCachePayload): ExerciceStatus {
  const assetStatuses = new Array<AssetStatus>();
  const exerciceAssets = filterAssets(getAllJsonStringValues(exercice));

  for (let index = 0; index < exerciceAssets.length; index++) {
    const asset = exerciceAssets[index];
    const assetUrl = payload.assetsUrl + asset + "?version=" + payload.version;

    let assetStatus: AssetStatus;
    if (payload.cacheKeys.includes(encodeURI(assetUrl))) {
      assetStatus = {
        url: assetUrl,
        status: "downloaded",
      };
    } else {
      assetStatus = {
        url: assetUrl,
        status: "not_downloaded",
      };
    }
    assetStatuses.push(assetStatus);
  }

  return buildExerciceStatus(exercice.id, assetStatuses);
}

function filterAssets(values: Set<string>): string[] {
  const assetList: string[] = [];
  values.forEach(stringValue => {
    if (stringValue.endsWith(".png") || stringValue.endsWith(".svg")) {
      assetList.push("content" + stringValue);
    }
    if (stringValue.endsWith(".mp3") || stringValue.endsWith(".wav")) {
      assetList.push("tts/" + stringValue);
    }
  });
  return assetList;
}

function getAllJsonStringValues(object: any, resultSet: Set<string> = new Set()): Set<string> {
  if (object === null || object === undefined) {
    return resultSet;
  }
  else if (typeof (object) === 'string') {
    resultSet.add(object);
    return resultSet;
  } else if (Array.isArray(object)) {
    object
      .map(jsonValue => getAllJsonStringValues(jsonValue))
      .forEach(resultSetTmp => resultSetTmp.forEach(stringValue => resultSet.add(stringValue)));
    return resultSet;
  } else if (typeof (object) === 'object') {
    return getAllJsonStringValues(Object.values(object));
  } else {
    return resultSet;
  }
}
