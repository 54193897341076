import React from "react";
import { FormattedMessage } from "react-intl";
import cn from "classnames";
import Icon from "../../../../design-system-components/Icon/Icon";
import { PlayerHeaderProps } from "../../PlayerBuilder";
import SafeguardedLink from "../Components/SafeguardedLink/SafeguardedLink";

import "./AdaptiveTestHeader.scss";

const AdaptiveTestHeader = ({
    opts,
    playlistManager,
    className,
    aiType,
}: PlayerHeaderProps) => {
    return (
        <header className={cn(className, "adaptive-test-header")}>
            <SafeguardedLink
                to="/"
                opts={opts}
                className="adaptive-test-header__button"
                moduleId={playlistManager.playlist.module.id}
                aiType={aiType}
            >
                <Icon
                    path="arrow_right"
                    className="adaptive-test-header__leave-icon"
                />
                <FormattedMessage id="leave" defaultMessage="Leave" />
            </SafeguardedLink>

            <h1>{playlistManager.playlist.module.title.short}</h1>

            <div aria-hidden="true">{/* placeholder */}</div>
        </header>
    );
};

export default AdaptiveTestHeader;
