import { PerModule } from "@evidenceb/gameplay-interfaces";
import { Statement } from "@xapi/xapi";
import moment, { Moment } from "moment";
import { ADAPTIVE_TEST_LIFESPAN } from "../../../hooks/useAdaptiveTest";
import {
    ComputedDiagnosis,
    TestMetadata,
} from "../../../interfaces/AdaptiveTests";
import { Data } from "../../../interfaces/Data";
import { XAPI_REGISTRY } from "../../../utils/statement-builder";
import {
    byAdaptiveTestNumberStatementsFilter,
    byAdaptiveTestNumberStatementsFind,
    byModuleStatementsFilter,
    getLastAdaptiveTestNumber,
} from "../../../utils/statements";
import { getModuleDiagnosis } from "./diagnosis";

export const getAllTestsResults = (
    data: Data,
    adaptiveTestStatements: Statement[],
    diagnosisStatements: Statement[]
): PerModule<TestMetadata[]> => {
    const testsResults: PerModule<TestMetadata[]> = {};
    data.modules.forEach((module) => {
        const moduleStatements = adaptiveTestStatements.filter(
            byModuleStatementsFilter(module.id)
        );
        if (moduleStatements.length) {
            testsResults[module!.id] = getModuleTestResults(
                moduleStatements,
                diagnosisStatements.filter(byModuleStatementsFilter(module.id))
            );
            testsResults[module.id].reverse();
        } else testsResults[module.id] = [];
    });
    return testsResults;
};

/**
*    mainTest is either
*   - the ongoing test: already started, not finished, still valid (expiration date not past)
*   - the latest finished test
*   - undefined
*/
export const getMainTests = (
    testsResults: PerModule<TestMetadata[]>
): PerModule<TestMetadata | undefined> => {
    const mainTests: PerModule<TestMetadata | undefined> = {};
    Object.keys(testsResults).forEach((moduleId) => {
        if (!testsResults[moduleId].length) mainTests[moduleId] = undefined;
        else if (
            testsResults[moduleId][0].endDate ||
            testsResults[moduleId][0].expirationDate
        )
            mainTests[moduleId] = testsResults[moduleId][0];
        else {
            mainTests[moduleId] = getLatestCompletedTest(
                testsResults[moduleId]
            );
        }
    });
    return mainTests;
};

const getModuleTestResults = (
    moduleStatements: Statement[],
    diagStatements: Statement[]
): TestMetadata[] => {
    let resultsCopy: TestMetadata[] = [];
    for (
        let testNumber = 1;
        testNumber <= getLastAdaptiveTestNumber(moduleStatements)!;
        testNumber++
    ) {
        const testStatements = moduleStatements.filter(
            byAdaptiveTestNumberStatementsFilter(testNumber)
        );
        if (testStatements.length === 0) continue;
        else {
            const diagStatement = diagStatements.find(
                byAdaptiveTestNumberStatementsFind(testNumber)
            );
            if (typeof diagStatement === "undefined") {
                resultsCopy.push(
                    setValidResult(testStatements, testNumber, undefined)
                );
            } else {
                const diag = getModuleDiagnosis(
                    diagStatement.result!.extensions![
                        `${XAPI_REGISTRY}/extensions/adaptive-test-diagnosis`
                    ].probabilities
                );
                resultsCopy.push(
                    setValidResult(testStatements, testNumber, diag)
                );
            }
        }
    }
    return resultsCopy;
};

export const getLatestTest = (
    mainTest: TestMetadata | undefined,
    tests: TestMetadata[]
) => {
    return mainTest?.endDate ? mainTest : getLatestCompletedTest(tests);
};

const getLatestCompletedTest = (tests: TestMetadata[]) =>
    tests.find((test) => test.endDate);

function setValidResult(
    testStatements: Statement[],
    testNumber: number,
    diagnosis: ComputedDiagnosis | undefined
) {
    const deadline = willExpireOn(testStatements[0].timestamp!);
    /* If test has expired it is invalid and expirationDate is not set */
    const expirationDate = deadline > moment() ? deadline.format() : undefined;
    return {
        startDate: testStatements[0].timestamp!,
        endDate: diagnosis
            ? testStatements[testStatements.length - 1].timestamp!
            : undefined,
        expirationDate: diagnosis ? undefined : expirationDate,
        diagnosis: diagnosis,
        adaptiveTestNumber: testNumber,
        error: false,
    };
}

const willExpireOn = (startTimestamp: string): Moment => {
    return moment(startTimestamp).add(ADAPTIVE_TEST_LIFESPAN);
};
