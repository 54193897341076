import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import cn from "classnames";
import { configStore } from "../../../../contexts/ConfigContext";
import { UserType } from "../../../../interfaces/User";
import { getUrl } from "../../../../utils/dataRetrieval";
import { PlayerHeaderProps } from "../../PlayerBuilder";
import ProgressBar from "./ProgressBar/ProgressBar";
import SafeguardedLink from "../Components/SafeguardedLink/SafeguardedLink";

import "./StudentHeader.scss";
import Icon from "../../../../design-system-components/Icon/Icon";

const StudentHeader = ({
    playlistManager: { playlist },
    progression,
    opts,
    className,
    aiType,
}: PlayerHeaderProps) => {
    const { config } = useContext(configStore);

    return (
        <header className={cn("student-header", className)}>
            <SafeguardedLink
                /*TEMP find a more elegant way to get the url*/
                to={`/${getUrl(
                    config.pages.find((page) => page.type === "MODULELIST")!,
                    UserType.Student
                )}`}
                moduleId={playlist.module.id}
                className="student-header__exit"
                opts={opts}
                aiType={aiType}
            >
                <Icon path="arrow_left" size="large" className="exit__icon" />
                <div>
                    <FormattedMessage
                        id="exerciseShell-close"
                        defaultMessage="Close"
                    />
                </div>
            </SafeguardedLink>

            <div className="student-header__progression">
                <h2>{playlist.module.title.short}</h2>
                {!playlist.isInitialTest && (
                    <ProgressBar completion={progression!} />
                )}
            </div>

            <div>{/* For layout purposes */}</div>
        </header>
    );
};

export default StudentHeader;
