import React from "react";
//Components
import StudentAuth from "./StudentAuth/StudentAuth";
import TeacherAuth from "./TeacherAuth/TeacherAuth";
import SelectUserType from "./SelectUserType/SelectUserType";
// SCSS
import "./Authentication.scss";
import { Route, Switch } from "react-router-dom";
import PasswordChange from "./PasswordChange/PasswordChange";
import ForgottenPassword from "./ForgottenPassword/FogottenPassword";
import LogoutConfirmation from "./LogoutConfirmation/LogoutConfirmation";
import AppSelect from "../../components/AppSelect/AppSelect";

const Authentication = () => {
    return (
        <div className="user-authentication-container">
            <div className="user-auth__content">
                <AppSelect className="user-auth__app-select" />

                <Switch>
                    <Route path="/auth/student" children={<StudentAuth />} />

                    <Route
                        path="/auth/teacher/pwd/:type/:actionToken"
                        children={<PasswordChange />}
                    />
                    <Route
                        path="/auth/teacher/pwd"
                        children={<ForgottenPassword />}
                    />
                    <Route path="/auth/teacher" children={<TeacherAuth />} />

                    <Route
                        path="/auth/logout-confirmation"
                        children={<LogoutConfirmation />}
                    />

                    <Route>
                        <SelectUserType />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};
export default Authentication;
