import React, { useContext, useEffect, useState } from "react";
import {
    ActivityShell, VisibilityStatus,
} from "@evidenceb/gameplay-interfaces/";
import { dataStore } from "../../contexts/DataContext";
import {
    ExerciseNotFoundError,
    PlayerProgressionError,
} from "../../errors";
import {
    getExercisesInActivity,
    getHierarchy,
    getNextHierarchyLevel,
} from "../../utils/dataRetrieval";
import { getResultsForActivity } from "../../utils/exerciseUtils";
import { Data } from "../../interfaces/Data";
import { useParams } from "react-router";
import { PlaylistPlayerProps } from "./PlaylistPlayer/PlaylistPlayer";
import { ComingNext, ExerciseResult, InitializedPlaylistManager, PlaylistExecutionStage } from "../../interfaces/Player";
import { getFeedback } from "./useAIPlaylistManager";
import useAssetsDetails from "../../hooks/useAssetsDetails";
import { Hierarchy } from "../../interfaces/Hierarchy";
import { Exercise, useFeatureFlag } from "@evidenceb/athena-common";
import { cacheStore } from "../../contexts/CacheContext";
import { isObjectivePrecached } from "../../contexts/infrastructure/cacheUtils";
import { getObjectiveById } from "../../utils/dataRetrieval";

/**
 * This hook is meant to handle the progression through a playlist by a
 * teacher. A teacher:
 * - should be able to review all exercises in an objective
 * - should be able to jump from one exercise to the other freely.
 * @param moduleId
 * @param objectiveId
 */
const useTeacherPlaylistManager = (
    moduleId?: string,
    objectiveId?: string
): InitializedPlaylistManager => {
    const { data } = useContext(dataStore);
    const assetsDetails = useAssetsDetails();

    /** Current context for the exercise list */
    const [currentHierarchy, setCurrentHierarchy] = useState<
        Omit<Hierarchy, "exercise" | "isInitialTest">
    >(getHierarchy(data, moduleId, objectiveId));
    /** The list of exercises within the current playing activity. */
    const [availableExercises, setAvailableExercises] = useState<
        Exercise[]
    >(
        getExercisesInActivity(
            currentHierarchy.activity,
            data,
        )
    );

    const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number>(0);
    const [currentTry, setCurrentTry] = useState<number>(1);
    const [currentExerciseResult, setCurrentExerciseResult] = useState<
        ExerciseResult<any> | undefined
    >(undefined);
    const [currentExecutionStage, setCurrentExecutionStage] = useState(
        PlaylistExecutionStage.PlayingCurrentExercise
    );
    const [comingNext, setComingNext] = useState<ComingNext | undefined>(
        undefined
    );

    const [exerciseResults, setExercisesResult] = useState<
        ExerciseResult<any>[]
    >([]);

    const { cacheState, dispatchCacheAction }  = useContext(cacheStore);
    const offlineFeatures = useFeatureFlag("offlineFeatures")

    // Reset on new try or exercise
    useEffect(() => {
        setCurrentExerciseResult(undefined);
        setComingNext(undefined);
    }, [currentExerciseIndex, currentTry]);

    // Go to next hierarchy level when going past the last exercise of the playing
    // activity
    useEffect(() => {
        if (availableExercises[currentExerciseIndex]) return;

        const newHierarchy = getNextHierarchyLevel(
            data,
            currentHierarchy,
            false
        );
        if (!newHierarchy) return;
        setCurrentHierarchy(newHierarchy);
        setAvailableExercises(
            getExercisesInActivity(
                newHierarchy.activity,
                data,
            )
        );
        setCurrentExerciseIndex(0);
    }, [
        availableExercises,
        currentExerciseIndex,
        currentHierarchy,
        data,
        assetsDetails
    ]);

    // Update available exercises when hierarchy changes
    useEffect(() => {
        setAvailableExercises(
            getExercisesInActivity(
                currentHierarchy.activity,
                data,
            )
        );
    }, [currentHierarchy, data, assetsDetails]);

    // Preload next objective
    useEffect(() => {
        function isLastActivityInObjective(): boolean {
            const lastActivityOfObjective = currentHierarchy
                .objective
                .activityIds[currentHierarchy.objective.activityIds.length - 1];
            return currentHierarchy.activity.id === lastActivityOfObjective;
        }

        if (offlineFeatures && navigator.onLine && isLastActivityInObjective()) {
            const visibleObjectivesIds = currentHierarchy.module.objectiveIds.filter(objectiveId =>
                getObjectiveById(objectiveId, data, false).visibilityStatus === VisibilityStatus.Visible
            );

            const currentObjectiveIndex = visibleObjectivesIds.indexOf(currentHierarchy.objective.id);
            if (currentObjectiveIndex >= 0 && currentObjectiveIndex < visibleObjectivesIds.length - 1) {
                const nextObjectiveId = visibleObjectivesIds[currentObjectiveIndex + 1];

                if (!isObjectivePrecached(cacheState, nextObjectiveId)) {
                    dispatchCacheAction({
                        type: "START_CACHE",
                        payload: {
                            cacheType: "objective",
                            id: nextObjectiveId,
                        }
                    });
                }
            }
        }
    }, [currentHierarchy, data, offlineFeatures, cacheState, dispatchCacheAction]);

    const teacherPlaylistManger: InitializedPlaylistManager = {
        initialized: true,
        playlist: {
            ...currentHierarchy,
            exercises: availableExercises,
            currentExecutionStage: currentExecutionStage,
            currentExercise: availableExercises[currentExerciseIndex],
            currentTry,
            currentExerciseResult,
            comingNext: comingNext,
            exerciseResults: availableExercises
                ? getResultsForActivity(
                      currentHierarchy.activity,
                      exerciseResults
                  )
                : exerciseResults,
            initiallyFresh: true
        },

        goToExercise: ({ moduleId, objectiveId, activityId, exerciseId }) => {
            if (!availableExercises)
                throw new PlayerProgressionError(
                    "goToExercise called after playlist end"
                );

            const newHierarchy = getHierarchy(
                data,
                moduleId,
                objectiveId,
                activityId
            );
            const exerciseIndex = exerciseId
                ? newHierarchy.activity.exerciseIds.findIndex(
                      (availableExerciseId) =>
                          availableExerciseId === exerciseId
                  )
                : 0;
            if (exerciseIndex === -1) throw new ExerciseNotFoundError(exerciseId);

            setCurrentExerciseResult(undefined);
            setCurrentHierarchy(newHierarchy);
            setCurrentExerciseIndex(exerciseIndex);
            setCurrentTry(1);
            setCurrentExecutionStage(
                PlaylistExecutionStage.PlayingCurrentExercise
            );
        },

        goToNextExercise: () => {
            if (currentHierarchy.activity.shell !== ActivityShell.Chatbot) {
                if (!availableExercises[currentExerciseIndex])
                    throw new PlayerProgressionError(
                        "goToNextExercise called after playlist end"
                    );

                if (!currentExerciseResult)
                    throw new PlayerProgressionError(
                        "Go to next exercise was called before getting an exercise result"
                    );
            }

            if (comingNext === "retry") {
                setCurrentTry((curr) => curr + 1);
            } else {
                setCurrentTry(1);
                setCurrentExerciseIndex((curr) => curr + 1);
            }
            setCurrentExecutionStage(
                PlaylistExecutionStage.PlayingCurrentExercise
            );
        },

        recordCurrentExerciseResult: (partialExerciseResult) => {
            if (!availableExercises[currentExerciseIndex])
                throw new PlayerProgressionError(
                    "recordExerciseResult called after the playlist end"
                );

            const feedback =  getFeedback(availableExercises[currentExerciseIndex].feedback[
                currentTry - 1], partialExerciseResult)

            const exerciseResult: ExerciseResult<any> = {
                ...partialExerciseResult,
                activityId: currentHierarchy.activity.id,
                exerciseId: availableExercises[currentExerciseIndex].id,
                try: currentTry,
                feedback: feedback.content,
                feedbackExplicatif: feedback.explicatifContent,
                feedbackType: feedback.type
            };
            setCurrentExerciseResult(exerciseResult);
            setExercisesResult((curr) => [...curr, exerciseResult]);
            setCurrentExecutionStage(
                PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
            );

            const comingNext = getWhatsComingNext(
                data,
                currentHierarchy,
                availableExercises,
                currentExerciseIndex,
                currentTry,
                exerciseResult
            );
            setComingNext(comingNext);
        },
    };
    return teacherPlaylistManger;
};

const getWhatsComingNext = (
    data: Data,
    hierarchy: Omit<Hierarchy, "exercise" | "isInitialTest">,
    availableExericses: Exercise[],
    currentExerciseIndex: number,
    currentTry: number,
    currentResult?: ExerciseResult<any>
): InitializedPlaylistManager["playlist"]["comingNext"] => {
    if (!availableExericses[currentExerciseIndex] || !currentResult)
        return undefined;
    if (
        !currentResult.correct &&
        currentTry <
            availableExericses[currentExerciseIndex]?.executionOptions!
                .numberOfTries!
    )
        return "retry";
    if (currentExerciseIndex === availableExericses.length - 1) {
        const newHierarchy = getNextHierarchyLevel(data, hierarchy, false);
        if (!newHierarchy) return "endOfPlaylist";
        return "nextActivity";
    }
    return "nextExercise";
};

export default useTeacherPlaylistManager;

/**
 * HOC that inject the teacher playlist manager in the passed component
 */
export const withTeacherPlaylistManager: (
    WrappedComponent: (props: PlaylistPlayerProps) => JSX.Element
) => ({
    InfoPanel,
}: Omit<PlaylistPlayerProps, "playlistManager">) => JSX.Element = (
    WrappedComponent
) => {
    return (props) => {
        const url = useParams<{ moduleId: string; objectiveId: string }>();
        const playlistManager = useTeacherPlaylistManager(
            url.moduleId,
            url.objectiveId
        );

        return (
            <WrappedComponent
                playlistManager={playlistManager}
                {...props}
            />
        );
    };
};
