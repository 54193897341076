import React from 'react';
import { Student } from '../../../../interfaces/Authentication';
// SCSS
import './StudentList.scss';
// Interfaces
interface StudentListProps {
    students: Student[],
    onStudentClick: (student: Student) => void;
}
const StudentList = ({students, onStudentClick}: StudentListProps) => {
    return (
        <div className="student-list">
            {
                students.map((student, i) => (
                    <button key={student.id} className="student-list__student" onClick={() => onStudentClick(student)}>
                        <span style={{color: generateRandomColor()}}>{student.displayName.charAt(0)}</span>
                        <span>
                            {student.displayName}
                        </span>
                    </button>   
                ))
            }
        </div>
    )
}

export default StudentList

const getRandomInt = (max: number)=>  {
    return Math.floor(Math.random() * max);
  }
  
const generateRandomColor = () => {
    const colors = [ "#073B88", "#880707", "#6200C6", "#6D4C41", "#3278D4", "#BD0068", "#A46100", "#00796B" ];
    return colors[getRandomInt(8)]
}