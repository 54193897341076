import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router";
import Icon from "../../../design-system-components/Icon/Icon";
import ControlledDialog from "../../../design-system-components/ControlledDialog/ControlledDialog";

interface Props {
    url: string;
}

const SuccessModal = ({ url }: Props): JSX.Element => {
    const history = useHistory();
    const intl = useIntl();

    return (
        <ControlledDialog
            title={
                {
                    as: "h2",
                    className: "prm__success-title",
                    content: (
                        <>
                            <Icon
                                path="negative_checkmark"
                                size={35}
                                className="prm__success-icon"
                            />
                            <span>
                                <FormattedMessage
                                    id="prm-manager-successModal-title"
                                    defaultMessage="Changes saved"
                                />
                            </span>
                        </>
                    )
                }
            }
            aria-label={intl.formatMessage({
                id: "prm-manager-successModal-title",
                defaultMessage: "Changes saved",
            })}
            description={{
                $html: intl.formatMessage({
                    id: "prm-manager-successModal-description",
                    defaultMessage:
                        "The content available to your students has been updated.",
                }),
            }}
            primaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-successModal-primaryButton",
                    defaultMessage: "Go back to the dashboard",
                }),
                fn: () => {
                    history.push(url);
                },
            }}
        />
    );
};

export default SuccessModal;
