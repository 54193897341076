import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { adaptiveTestsStore } from "../../../contexts/AdaptiveTestsContext";
import CardContainer from "../../../design-system-components/CardContainer/CardContainer";
import ModuleCard from "../../../design-system-components/ModuleCard/ModuleCard";
import Icon from "../../../design-system-components/Icon/Icon";
import ToggleRecommendationsDiv from "./Components/ToggleRecommendationsDiv";
import ToggleObjectives from "./Components/ToggleObjectives";
import {
    firstTestButton,
    newTestButton,
    resumeTestButtonLong,
} from "../CONSTANTS";
import Button from "../../../design-system-components/Button/Button";
import CustomProgressBar from "../../../design-system-components/CustomProgressBar/CustomProgressBar";
import CustomSelect from "../../../design-system-components/CustomSelect/CustomSelect";
import { ToggleDiv } from "../../../design-system-components/ToggleDiv/ToggleDiv";
import { Link, Recommendations } from "../../../interfaces/AdaptiveTests";
import {
    ComputedDiagnosis,
    TestMetadata,
} from "../../../interfaces/AdaptiveTests";
import { configStore } from "../../../contexts/ConfigContext";
import { dataStore } from "../../../contexts/DataContext";
import { PerObjective } from "@evidenceb/gameplay-interfaces";
import { errorStore } from "../../../contexts/ErrorContext";

import "./AdaptiveTestsResults.scss";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import htmlHandler from "../../../utils/html-handler";
import { getLatestTest } from "../utils/result";
import { computeObjectiveEstimations } from "../utils/diagnosis";

const AdaptiveTestsResult = () => {
    const { moduleId }: { moduleId: string } = useParams();
    const { data } = useContext(dataStore);
    const { adaptiveTests, setAdaptiveTests } = useContext(adaptiveTestsStore);
    const module = data.modules.find((module) => module.id === moduleId);
    const intl = useIntl();
    const { setErrorInfo } = useContext(errorStore);
    if (!module)
        setErrorInfo({
            displayModal: true,
            modal: {
                type: "POPUP",
                content: {
                    title: intl.formatMessage({
                        id: "errorGetAdaptiveTest",
                        defaultMessage:
                            "An error occured while trying to retrieve past tests. Try again later.",
                    }),
                },
            },
        });
    const mainTest = adaptiveTests.mainTest![module!.id];
    const { config } = useContext(configStore);
    const recommendationsLinks = Object.keys(
        adaptiveTests.recommendations
    ).includes(module!.id)
        ? getRecommendationsLinks(
              adaptiveTests.recommendations[module!.id],
              config.features.recommendationRegex
          )
        : [];

    const [historyTests, setHistorytests] =
        useState<TestMetadata[] | undefined>();
    const history = useHistory();
    let latesTest = undefined;
    if (
        adaptiveTests.testsResults &&
        Object.keys(adaptiveTests.testsResults).includes(moduleId)
    )
        latesTest = getLatestTest(
            mainTest,
            adaptiveTests!.testsResults![module!.id]
        );

    const [selectedTest, setSelectedTest] = useState(
        mainTest?.endDate ? mainTest : latesTest
    );
    const buttonContent = mainTest?.endDate
        ? newTestButton
        : mainTest
        ? resumeTestButtonLong
        : firstTestButton;
    const [objectivesNumber, setObjectivesNumber] = useState<number>(0);
    const [displayedObjectives, setDisplayedObjectives] = useState<
        PerObjective<ComputedDiagnosis>
    >({});
    let { path } = useRouteMatch();

    /*
    /* Get history options
     */
    useEffect(() => {
        setHistorytests(
            adaptiveTests?.testsResults?.[module!.id]?.filter(completedTests)
        );
    }, [adaptiveTests.testsResults, module]);

    /*
    /* Compute objective scores
     */
    useEffect(() => {
        const moduleDiagnosis = adaptiveTests.testsResults?.[module!.id].find(
            (test: TestMetadata) => {
                return (
                    test.adaptiveTestNumber === selectedTest?.adaptiveTestNumber
                );
            }
        )?.diagnosis?.allEstimates;

        if (!moduleDiagnosis) return;

        const testNumberStr = selectedTest!.adaptiveTestNumber.toString();

        if (
            !(
                Object.keys(adaptiveTests.objectiveDiagnosis).includes(
                    module!.id
                ) &&
                Object.keys(
                    adaptiveTests.objectiveDiagnosis![module!.id]
                ).includes(testNumberStr)
            )
        ) {
            const newDiagnosis = computeObjectiveEstimations(
                data,
                moduleDiagnosis,
                moduleId
            );
            setAdaptiveTests((cur) => ({
                ...cur,
                objectiveDiagnosis: {
                    ...adaptiveTests.objectiveDiagnosis,
                    [module!.id]: {
                        ...adaptiveTests.objectiveDiagnosis[module!.id],
                        [testNumberStr]: newDiagnosis,
                    },
                },
            }));
        }

        if (
            Object.keys(adaptiveTests.objectiveDiagnosis).includes(
                module!.id
            ) &&
            Object.keys(adaptiveTests.objectiveDiagnosis[module!.id]).includes(
                testNumberStr
            )
        ) {
            setObjectivesNumber(
                Object.keys(
                    adaptiveTests.objectiveDiagnosis[module!.id][testNumberStr]
                ).length
            );
            setDisplayedObjectives(
                adaptiveTests.objectiveDiagnosis[module!.id][testNumberStr]
            );
        }
    }, [
        data,
        selectedTest,
        adaptiveTests.testsResults,
        adaptiveTests.objectiveDiagnosis,
        module,
        setAdaptiveTests,
        moduleId,
    ]);

    const handleTestSelection = (selection: string) => {
        const selectedTest = historyTests?.find(
            (test) => test.adaptiveTestNumber.toString() === selection
        );
        if (selectedTest) setSelectedTest(selectedTest!);
    };

    return (
        <div className="adaptive-tests-results">
            <div className="title_card desktop">
                <h3 className="title">
                    <FormattedMessage
                        id="adaptive-test-results-title"
                        defaultMessage="Evaluation - my results"
                    />
                </h3>
                <h4 className="subtitle">
                    <Icon
                        className="go-back-arrow"
                        path="arrow_right"
                        size="small"
                        color="black"
                        style={{ transform: "scaleX(-1)", marginRight: "20px" }}
                        onClick={() => history.push(`/${path.split("/")[1]}`)}
                    />
                    <span>{module!.title.short}</span>
                </h4>
            </div>
            <CardContainer>
                <div className="result-boxes">
                    <ModuleCard
                        className={`main-test-card ${
                            mainTest && mainTest.expirationDate
                                ? "main-test-card__tall"
                                : ""
                        }`}
                    >
                        {mainTest && mainTest!.expirationDate !== undefined && (
                            <div className="main-test-card__banner">
                                <Icon
                                    className="warning-icon"
                                    path="warning"
                                    size={20}
                                    color="#E66342"
                                />
                                <span>
                                    <FormattedMessage
                                        id="adaptive-test-results-deadline"
                                        defaultMessage="You have until {date} to comple this test. After that, your progress will be lost"
                                        values={{
                                            date: moment(
                                                mainTest!.expirationDate
                                            ).format(
                                                config.displayedDateFormat
                                            ),
                                        }}
                                    />
                                </span>
                            </div>
                        )}
                        <div className="main-test-card__main">
                            <div className="main-test-card__title">
                                <span>
                                    <div className="main-test-card__title_fixed">
                                        <FormattedMessage
                                            id="previous-test"
                                            defaultMessage="Previous test"
                                        />
                                    </div>
                                    <span>
                                        <FormattedMessage
                                            id="test-identifier"
                                            defaultMessage="Test #{testNumber}"
                                            values={{
                                                testNumber:
                                                    mainTest?.adaptiveTestNumber ??
                                                    "-",
                                            }}
                                        />
                                        {" - "}
                                        {mainTest && mainTest.endDate ? (
                                            moment(mainTest.endDate).format(
                                                config.displayedDateFormat
                                            )
                                        ) : mainTest ? (
                                            <FormattedMessage
                                                id="ongoing"
                                                defaultMessage="ongoing"
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </span>
                            </div>
                            {((mainTest && !mainTest?.error) ||
                                mainTest === undefined) && (
                                <CustomProgressBar
                                    className={`desktop ${
                                        mainTest!.expirationDate
                                            ? "ongoing"
                                            : ""
                                    }`}
                                    progress={
                                        mainTest!.diagnosis?.meanEstimate ?? 0
                                    }
                                    label={intl.formatMessage({
                                        id: "estimated-level",
                                        defaultMessage: "estimated level",
                                    })}
                                    labelTooltip={{
                                        $html: intl.formatMessage(
                                            {
                                                id: "html-adaptive-test-acquisition-explained",
                                                defaultMessage:
                                                    "Estimated from the test answers, with a margin of error represented by the blue bubble",
                                            },
                                            htmlHandler
                                        ),
                                    }}
                                    estimate={
                                        config.features.dislayEstimateInterval
                                            ? {
                                                  min:
                                                      mainTest!.diagnosis
                                                          ?.lowEstimate ?? 0,
                                                  max:
                                                      mainTest!.diagnosis
                                                          ?.highEstimate ?? 0,
                                              }
                                            : undefined
                                    }
                                    defaultText={
                                        mainTest
                                            ? getProgressBarText(
                                                  mainTest!,
                                                  intl
                                              )
                                            : ""
                                    }
                                />
                            )}
                            {((mainTest && !mainTest?.error) ||
                                mainTest === undefined) && (
                                <CustomProgressBar
                                    className="mobile"
                                    progress={
                                        mainTest!.diagnosis?.meanEstimate ?? 0
                                    }
                                    label={
                                        mainTest?.endDate
                                            ? intl.formatMessage({
                                                  id: "estimated-level",
                                                  defaultMessage:
                                                      "estimated level",
                                              })
                                            : intl.formatMessage({
                                                  id: "finish-your-test",
                                                  defaultMessage:
                                                      "Finish your test to get your estimated level",
                                              })
                                    }
                                    labelTooltip={{
                                        $html: intl.formatMessage(
                                            {
                                                id: "html-adaptive-test-acquisition-explained",
                                                defaultMessage:
                                                    "Estimated from the test answers, with a margin of error represented by the blue bubble",
                                            },
                                            htmlHandler
                                        ),
                                    }}
                                    estimate={
                                        config.features.dislayEstimateInterval
                                            ? {
                                                  min:
                                                      mainTest!.diagnosis
                                                          ?.lowEstimate ?? 0,
                                                  max:
                                                      mainTest!.diagnosis
                                                          ?.highEstimate ?? 0,
                                              }
                                            : undefined
                                    }
                                    defaultText={
                                        mainTest!.endDate
                                            ? getProgressBarText(
                                                  mainTest!,
                                                  intl
                                              )
                                            : ""
                                    }
                                />
                            )}
                            <Button
                                className="module-btn"
                                variant="primary"
                                size="full"
                                center={true}
                                label={buttonContent(intl).label}
                                icon={buttonContent(intl).icon}
                                disabled={mainTest?.error}
                                onClick={() => {
                                    history.push(
                                        "/adaptive-test/" + module!.id
                                    );
                                }}
                            />
                        </div>
                    </ModuleCard>
                    <CardContainer className="secondary-card-container">
                        {recommendationsLinks.length ? (
                            <ModuleCard className="mobile recommendations">
                                <ToggleRecommendationsDiv
                                    recommendations={recommendationsLinks}
                                    defaultState={"open"}
                                    moduleId={moduleId}
                                />
                            </ModuleCard>
                        ) : (
                            ""
                        )}

                        <div className="secondary-card-container">
                            <ModuleCard className="extensible_card history">
                                <div>
                                    <FormattedMessage
                                        id="test-history"
                                        defaultMessage="Tests history"
                                    />
                                </div>
                                <div className="history__select">
                                    <span className="toggle-card">
                                        <div>
                                            <FormattedMessage
                                                id="test"
                                                defaultMessage="test"
                                            />
                                        </div>{" "}
                                    </span>
                                    <span className="toggle-card">
                                        <CustomSelect
                                            className="history__list"
                                            selectedOption={
                                                selectedTest
                                                    ? getFormattedHistory(
                                                          selectedTest,
                                                          config.displayedDateFormat,
                                                          intl
                                                      )
                                                    : ""
                                            }
                                            onSelectOption={(selection) =>
                                                handleTestSelection(selection)
                                            }
                                        >
                                            {historyTests?.map((pastTest) => {
                                                return (
                                                    <option
                                                        key={`result-${pastTest.adaptiveTestNumber}`}
                                                        className="toggle-card"
                                                        value={
                                                            pastTest.adaptiveTestNumber
                                                        }
                                                    >
                                                        {getFormattedHistory(
                                                            pastTest,
                                                            config.displayedDateFormat,
                                                            intl
                                                        )}
                                                    </option>
                                                );
                                            })}
                                        </CustomSelect>
                                    </span>
                                </div>
                                <CustomProgressBar
                                    progress={
                                        selectedTest?.diagnosis?.meanEstimate ??
                                        0
                                    }
                                    label={intl.formatMessage({
                                        id: "estimated-level",
                                        defaultMessage: "estimated level",
                                    })}
                                    labelTooltip={{
                                        $html: intl.formatMessage(
                                            {
                                                id: "html-adaptive-test-acquisition-explained",
                                                defaultMessage:
                                                    "Estimated from the test answers, with a margin of error represented by the blue bubble",
                                            },
                                            htmlHandler
                                        ),
                                    }}
                                    estimate={
                                        config.features.dislayEstimateInterval
                                            ? {
                                                  min:
                                                      selectedTest?.diagnosis
                                                          ?.lowEstimate ?? 0,
                                                  max:
                                                      selectedTest?.diagnosis
                                                          ?.highEstimate ?? 0,
                                              }
                                            : undefined
                                    }
                                    defaultText={intl.formatMessage(
                                        {
                                            id: "estimated-around",
                                            defaultMessage:
                                                "estimated at about {estimation}",
                                        },
                                        {
                                            estimation: (
                                                selectedTest?.diagnosis
                                                    ?.meanEstimate ?? 0
                                            ).toString(),
                                        }
                                    )}
                                />
                                <div className="separator"></div>
                                <ToggleDiv
                                    className="toggle-card results-toggle history__details"
                                    label={intl.formatMessage(
                                        {
                                            id: "result-details",
                                            defaultMessage:
                                                "Result details for the {skillsCount} evaluated skills",
                                        },
                                        {
                                            skillsCount:
                                                objectivesNumber.toString(),
                                        }
                                    )}
                                >
                                    {selectedTest && (
                                        <ToggleObjectives
                                            data={data}
                                            displayedObjectives={
                                                displayedObjectives
                                            }
                                            displayEstimateInterval={
                                                config.features
                                                    .dislayEstimateInterval!
                                            }
                                        />
                                    )}
                                </ToggleDiv>
                            </ModuleCard>

                            <ModuleCard className="desktop recommendations extensible_card">
                                <ToggleRecommendationsDiv
                                    recommendations={recommendationsLinks}
                                    defaultState={"close"}
                                    moduleId={moduleId}
                                />
                            </ModuleCard>
                        </div>
                    </CardContainer>
                </div>
            </CardContainer>
            <Button
                className="module-btn floating-btn"
                variant={"primary"}
                label={buttonContent(intl).label}
                icon={buttonContent(intl).icon}
                disabled={mainTest?.error}
                onClick={() => {
                    history.push("/adaptive-test/" + module!.id);
                }}
            />
        </div>
    );
};

export default AdaptiveTestsResult;

const completedTests = (testsMetaData: TestMetadata) => {
    return testsMetaData.endDate !== undefined;
};

const getFormattedHistory = (
    test: TestMetadata,
    displayedDateFormat: string,
    intl: IntlShape
) => {
    return intl.formatMessage(
        {
            id: "test-history-details",
            defaultMessage: "#{testNumber} - on {date}",
        },
        {
            testNumber: test.adaptiveTestNumber.toString(),
            date: moment(test.endDate).format(displayedDateFormat).toString(),
        }
    );
};

const getProgressBarText = (test: TestMetadata, intl: IntlShape) => {
    if (!test.diagnosis)
        return intl.formatMessage({
            id: "finish-your-test",
            defaultMessage: "Finish your test to get your estimated level",
        });
    return [
        intl.formatMessage({
            id: "last estimated level",
            defaultMessage: "your last level is",
        }),
        intl.formatMessage(
            {
                id: "estimated-around",
                defaultMessage: "estimated at about {estimation}",
            },
            { estimation: test.diagnosis.meanEstimate.toString() }
        ),
    ].join(" ");
};

const getRecommendationsLinks = (
    recommendations: PerObjective<Recommendations>,
    recommendationRegex: string = ""
) => {
    const regex = new RegExp(`${recommendationRegex} (\\d+)`);
    return Object.keys(recommendations)
        .reduce((previous, current) => {
            let newLinks: Link[] = [];
            recommendations[current].links.forEach((link) => {
                if (
                    !previous.find(
                        (previousLink) =>
                            previousLink.link === link.link &&
                            previousLink.description === link.description
                    )
                )
                    newLinks.push(link);
            });
            return previous.concat(newLinks);
        }, [] as Link[])
        .sort((a, b) => {
            if (
                Number(regex.exec(a.description)?.[1]) <=
                Number(regex.exec(b.description)?.[1])
            )
                return -1;
            return 1;
        });
};
