import React, { useContext } from "react";
import cn from "classnames";
import { ActivityVideoTutorialResource } from "../../../../../interfaces/Resources";
import { configStore } from "../../../../../contexts/ConfigContext";
import WizardVideoTutorialBtn from "../WizardVideoTutorialBtn/WizardVideoTutorialBtn";

import "./WizardMascotZone.scss";
import { Image } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../../../hooks/useAssetsDetails";

export type VideoTutorial = Omit<
    ActivityVideoTutorialResource,
    "activityIds"
> & {
    activityId: string;
};

interface Props {
    videoTutorial?: VideoTutorial;
    className?: string;
}

const WizardMascotZone = ({ videoTutorial, className }: Props) => {
    const {
        config: { logos },
    } = useContext(configStore);
    const assetsDetails = useAssetsDetails();

    return (
        <div className={cn("wizard-mascot-zone", className)}>
            {videoTutorial && <WizardVideoTutorialBtn {...videoTutorial} />}

            {logos.mascotIcon && (
                <Image
                    className="mascot-icon"
                    src={logos.mascotIcon}
                    alt=""
                    assetsDetails={assetsDetails}
                />
            )}
        </div>
    );
};

export default WizardMascotZone;
