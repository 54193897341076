import React from "react";
import { useIntl } from "react-intl";
import { ConfirmationModalProps } from "../usePRConfirmation";
import htmlHandler from "../../../../utils/html-handler";
import ControlledDialog from "../../../../design-system-components/ControlledDialog/ControlledDialog";

import "./ConfirmationModal.scss";

const UnlockObjectiveAndAncestorActivitiesConfirmationModal = ({
    confirm,
}: ConfirmationModalProps) => {
    const intl = useIntl();

    return (
        <ControlledDialog
            title={intl.formatMessage({
                id: "prm-objectiveRequiresLockedActivity",
                defaultMessage: "This objective requires a locked activity",
            })}
            description={{$html: intl.formatMessage({
                    id: "html-prm-objectiveRequiresLockedActivity-descr",
                    defaultMessage:
                        "Activating this objective requires that some activities are unlocked as well. To ensure consistency, <b>activating it will also activate those activities</b>",
                }, htmlHandler)
            }}
            primaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-listItem-unlock",
                    defaultMessage: "Activate",
                }),
                fn: () => {
                    confirm(true);
                },
            }}
            secondaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-cancel",
                    defaultMessage: "Cancel",
                }),
                fn: () => {
                    confirm(false);
                },
            }}
        ></ControlledDialog>
    );
};

export default UnlockObjectiveAndAncestorActivitiesConfirmationModal;
