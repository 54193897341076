import React from "react";
import Icon from "../../../../design-system-components/Icon/Icon";

import "./Details.scss";

interface Props
    extends React.DetailedHTMLProps<
        React.DetailsHTMLAttributes<HTMLElement>,
        HTMLDetailsElement
    > {
    summary: {
        open: string;
        closed: string;
    };
    /** @default primary */
    variant?: "primary" | "secondary";
}

const Details = ({
    summary,
    variant = "primary",
    children,
    className,
    ...props
}: Props) => {
    return (
        <details
            className={`details details--${variant} ${className ?? ""}`}
            {...props}
        >
            <summary>
                <div className="summary--hide-on-open">
                    <Icon path="more" size="small" />
                    <span className="summary__title">{summary.closed}</span>
                </div>
                <div className="summary--hide-on-closed">
                    <Icon path="less" size="small" />
                    <span className="summary__title">{summary.open}</span>
                </div>
            </summary>
            {children}
        </details>
    );
};

export default Details;
