import React from 'react';
import { FormattedMessage } from 'react-intl';

//Contexts
import Icon from '../../../../../../design-system-components/Icon/Icon';

//SCSS
import "./ModuleInfo.scss";

//Interfaces
interface ModuleInfoProps{
        progression: number
        total:number;
        inProgress: number;
        inDifficulty: number;
}

function ModuleInfo({progression, total, inProgress, inDifficulty}: ModuleInfoProps) {
 
    return (
        <div className={`registered-progression ${progression === 0 ? "center": ""}`}>
            <div className="container">
                <div className="students">
                    <span className="label">
                        {total} {
                            total <= 1 ? <FormattedMessage id="singleClass-savedProgressionSingular" defaultMessage="Saved progression" /> :
                            <FormattedMessage id="singleClass-savedProgressionPlural" defaultMessage="Saved progressions" />
                        }
                    </span>
                </div>
            </div>
            {
                progression !== 0 &&
                    <div className="container">
                        <div className="info in-difficulty">
                            <Icon path="alert_inf" size="medium" />
                            <span className="label">
                                <span>{inDifficulty}</span> {
                                    inDifficulty <= 1 ? <FormattedMessage id="singleClass-progressionRegressionSingular" defaultMessage="Student with learning difficulties" /> :
                                    <FormattedMessage id="singleClass-progressionRegressionPlural" defaultMessage="Students with learning difficulties" />
                                }
                            </span>
                        </div>
                        <div className="info in-progress">
                            <Icon path="alert_sup" size="medium" />
                            <span className="label">
                                <span>{inProgress}</span> {
                                    inProgress <= 1 ? <FormattedMessage id="singleClass-progressionAccelerationSingular" defaultMessage="Student who has overcome a difficulty" /> :
                                    <FormattedMessage id="singleClass-progressionAccelerationPlural" defaultMessage="Students who have overcome a difficulty" />

                                }
                            </span>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ModuleInfo
