import React, { useContext, useMemo, useState } from "react";
// External modules
import { defineMessages, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
// Contexts
import { errorStore } from "../../../../../contexts/ErrorContext";
import { configStore } from "../../../../../contexts/ConfigContext";
//Hooks
import useAthenaAPIClient from "../../../../../hooks/useAthenaAPIClient";
// Components
import Button from "../../../../../design-system-components/Button/Button";
import { TooltipWrapper } from "../../../../../design-system-components/ToolTip/ToolTip";
import Input from "../../../../../design-system-components/Input/Input";
import SearchStudent from "./SearchStudent/SearchStudent";
import Loader from "../../../../../components/Loader/Loader";
import Settings from "../Components/Settings/Settings";
// Utils
import { getUrl } from "../../../../../utils/dataRetrieval";
// SCSS
import "./StudentManager.scss";
// Interfaces
import { Classroom, Student, UserType } from "../../../../../interfaces/User";
interface StudentManagerProps {
    classroom?: Classroom;
    students: Student[];
    onAddUserClick: () => void;
    onRenameStudent: (student: Student) => void;
}
interface IFormValues {
    firstname: string;
    lastname: string;
}
const StudentManager = ({
    classroom,
    students,
    onAddUserClick,
    onRenameStudent,
}: StudentManagerProps) => {
    const intl = useIntl();
    const {
        config: { pages },
    } = useContext(configStore);
    const { setErrorInfo } = useContext(errorStore);
    const athenaAPIClient = useAthenaAPIClient();

    const { register, handleSubmit, reset } = useForm<IFormValues>();

    // Deletion comes later this year...
    //const deleteStudent = (studentId: string) => {}

    const [studentToEdit, setStudentToEdit] = useState<string | undefined>(
        undefined
    );
    const submitStudentEdit = async (data: IFormValues, student: Student) => {
        if (data.firstname || data.lastname) {
            const user = { ...student };
            user.first_name = data.firstname ? data.firstname : user.first_name;
            user.last_name = data.lastname ? data.lastname : user.last_name;
            const payload = {
                first_name: user.first_name,
                last_name: user.last_name,
            };
            try {
                await athenaAPIClient.updateUser("student", user.id, payload);
                onRenameStudent(user);
            } catch (err) {
                setErrorInfo((errorInfo) => {
                    return {
                        ...errorInfo,
                        displayModal: true,
                        modal: {
                            type: "NOTIFICATION",
                            content: {
                                title: intl.formatMessage(messages.editStudentError),
                            },
                        },
                    };
                });
            }
        }
        resetEditStudent();
    };

    const editStudent = (studentId: string) => {
        reset();
        setStudentToEdit(studentId);
    };

    const resetEditStudent = () => {
        setStudentToEdit(undefined);
        reset();
    };

    const getStudentDetailsUrl = (studentId: string) => {
        return `/${getUrl(
            pages.find((page) => page.type === "DASHBOARD")!,
            UserType.Teacher
        ) as string
            }/${intl.formatMessage({
                id: "dashboard-paths-student",
                defaultMessage: "student",
            })}/${classroom?.id}/${studentId}`;
    };

    const [filter, setFilter] = useState("");
    const filteredStudents = useMemo(() => {
        if (!filter) return students;
        return students.filter((student) => {
            const completeName = `${student.first_name
                .toLowerCase()
                .trim()} ${student.last_name.toLowerCase().trim()}`;
            if (completeName.includes(filter.toLowerCase().trim())) return true;
            return false;
        });
    }, [filter, students]);


    if (!classroom) return <Loader />;

    return (
        <div className="student-manager">
            {classroom && filteredStudents.length > 0 &&
                <>
                    <div className="student-manager__actions">
                        <SearchStudent
                            className="student-manager__actions__search"
                            filter={filter}
                            onUpdateFilter={(filter) => setFilter(filter)}
                        />
                        <Button
                            className="student-manager__actions__add-students"
                            type="button"
                            variant="secondary"
                            label={intl.formatMessage(messages.add)}
                            onClick={onAddUserClick}
                            icon={{
                                path: "user-add-outlined",
                                size: "medium",
                            }}
                        />
                    </div>
                    <table
                        role="grid"
                        aria-labelledby="student-manager"
                        className="student-manager__table"
                    >
                        <tbody>
                            <tr className="student-manager__header">
                                <th>
                                    <div
                                        className="student-manager__table__placeholder"
                                        aria-hidden={true}
                                    ></div>
                                </th>
                                <th>
                                    {intl.formatMessage(messages.name)}
                                </th>
                                <th className="student-manager__table__center">
                                    {intl.formatMessage(messages.studentCode)}
                                </th>
                                <th className="student-manager__table__classroom-code student-manager__table__center">
                                    {intl.formatMessage(messages.classroomCode)}
                                </th>
                                <th></th>
                            </tr>
                            {filteredStudents.map((student) => (
                                <tr key={student.id}>
                                    <td>
                                        {/* TODO: for downloading invites pdf feature <input type="checkbox" /> */}
                                    </td>
                                    <td>
                                        {studentToEdit !== student.id && (
                                            <TooltipWrapper
                                                wrapper="div"
                                                wrapperClassname="student-manager__edit-name-idle"
                                                tooltip={intl.formatMessage(messages.seeStudentDetails)}
                                            >
                                                <Link
                                                    className="student-manager__student-details--btn"
                                                    to={getStudentDetailsUrl(student.id)}
                                                >
                                                    {student.first_name}{" "}
                                                    {student.last_name}
                                                </Link>
                                            </TooltipWrapper>
                                        )}
                                        {studentToEdit === student.id && (
                                            <div className="student-manager__edit-name-edit">
                                                <form
                                                    onSubmit={handleSubmit(
                                                        (data) =>
                                                            submitStudentEdit(data, student)
                                                    )}
                                                >
                                                    <Input
                                                        placeholder={student.first_name}
                                                        {...register("firstname")}
                                                    />
                                                    <Input
                                                        placeholder={student.last_name}
                                                        {...register("lastname")}
                                                    />
                                                    <button
                                                        type="submit"
                                                        className="student-manager__edit-name-edit__validate"
                                                    >
                                                        {intl.formatMessage(messages.validate)}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="student-manager__edit-name-edit__cancel"
                                                        onClick={resetEditStudent}
                                                    >
                                                        {intl.formatMessage(messages.cancel)}
                                                    </button>
                                                </form>
                                            </div>
                                        )}
                                    </td>
                                    <td className="student-manager__table__center">
                                        {student.code}
                                    </td>
                                    <td className="student-manager__table__center student-manager__table__classroom-code">
                                        {classroom.code}
                                    </td>
                                    <td className="student-manager__options">
                                    <Settings 
                                        className="student-manager__options__settings"
                                        label={intl.formatMessage({id: "dashboard-settings", defaultMessage: "Settings"})} 
                                        hideLabel={true}
                                        icon={{path:'more_vert', size:"large"}}
                                        settings={[
                                            {
                                                key: "edit-student",
                                                label: intl.formatMessage(messages.renameStudent),
                                                action: () => editStudent(student.id)
                                            }
                                        ]} 
                                    />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            }
            {
                classroom && filteredStudents.length === 0 &&
                <div className="student-manager__no-students">
                    <div>
                        <div>{intl.formatMessage(messages.noStudents)}</div>
                        <Button
                            className="student-manager__actions__add-students"
                            type="button"
                            variant="secondary"
                            label={intl.formatMessage(messages.add)}
                            onClick={onAddUserClick}
                            icon={{
                                path: "user-add-outlined",
                                size: "medium",
                            }}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

export default StudentManager;

const messages = defineMessages({
    add: {
        id: "student-manager-addStudent",
        defaultMessage: "Add a student",
    },
    noStudents:{
        id:"student-manager-noStudents",
        defaultMessage:"There are no students"
    },
    studentCode: {
        id: "student-manager-studentCode",
        defaultMessage: "Student code",
    },
    name: {
        id: "student-manager-name",
        defaultMessage: "First name/last name",
    },
    classroomCode: {
        id: "student-manager-classroomCode",
        defaultMessage: "Classroom code",
    },
    renameStudent: {
        id: "student-manager-renameStudent",
        defaultMessage: "Rename",
    },
    deleteStudent: {
        id: "student-manager-deleteStudent",
        defaultMessage: "Delete",
    },
    validate: {
        id: "student-manager-validate",
        defaultMessage: "Validate",
    },
    cancel: {
        id: "student-manager-cancel",
        defaultMessage: "Cancel",
    },
    editStudentError: {
        id: "student-manager-editStudentError",
        defaultMessage: "An error has occured while editing the student",
    },
    seeStudentDetails: {
        id: "student-manager-seeStudentDetails",
        defaultMessage: "See student details",
    },
});
