import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import Icon from "../../../../../../design-system-components/Icon/Icon";
import SimpleBar from "simplebar-react";
import { defineMessages, useIntl } from "react-intl";
import LegacyFeedbackContent from "./LegacyFeedbackContent/LegacyFeedbackContent";
import {
    ContentBlock,
    ContentBlockFeatures,
    isLegacyFeedbackContent,
    UntypedFeedback,
} from "@evidenceb/athena-common";

import "./WizardFeedbackContent.scss";
import useAssetsDetails from "../../../../../../hooks/useAssetsDetails";

interface Props {
    feedback?: {
        content: UntypedFeedback;
        type: "correct" | "incorrect" | "moderate" | undefined;
    };
    className?: string;
    getMaxToggleHeight: () => number | undefined;
}

const WizardFeedbackContent = ({
    feedback,
    className,
    getMaxToggleHeight,
}: Props) => {
    const intl = useIntl();
    const assetsDetails = useAssetsDetails();

    const [toggleFeedback, setToggleFeedback] = useState<boolean>(false);
    useEffect(() => {
        setToggleFeedback(false);
    }, [feedback]);

    // Handle touch events for swipe
    const container = useRef<HTMLDivElement>(null);
    const [touches, setTouches] = useState({ y: 0 });
    useEffect(() => {
        if (!container.current) return;

        const handleStart = (e: TouchEvent) => {
            const touch = e.changedTouches[0];
            setTouches({ y: touch.screenY });
        };

        const handleEnd = (e: TouchEvent) => {
            const touch = e.changedTouches[0];
            const deltaY = touch.screenY - touches.y;

            if (deltaY < 0) setToggleFeedback(true);
            else setToggleFeedback(false);
        };

        const el = container.current;
        el.addEventListener("touchstart", handleStart);
        el.addEventListener("touchend", handleEnd);
        return () => {
            el.removeEventListener("touchstart", handleStart);
            el.removeEventListener("touchend", handleEnd);
        };
    }, [touches]);

    const headerRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className={cn(
                "wizard-feedback-content",
                {
                    "--open": toggleFeedback,
                    "--close": toggleFeedback === false,
                },
                className
            )}
            ref={container}
        >
            <div className="content-wrapper">
                {feedback && feedback.type !== undefined && (
                    <div
                        className={`wizard-feedback__header --${feedback.type}`}
                        onClick={() => {
                            if (toggleFeedback === undefined)
                                setToggleFeedback(true);
                            else setToggleFeedback(!toggleFeedback);
                        }}
                        ref={headerRef}
                    >
                        {feedback.type !== "moderate" && (
                            <Icon
                                className="feedback__correctness-icon"
                                path={
                                    feedback.type === "correct"
                                        ? "player_external_fb_correct.png"
                                        : "player_external_fb_incorrect.png"
                                }
                                size={{ width: 35, height: 22 }}
                            />
                        )}
                        <label className="feedback__label">
                            {intl.formatMessage(
                                messages[
                                    feedback.type === "correct" ||
                                    feedback.type === "moderate"
                                        ? "fbCorrect"
                                        : "fbIncorrect"
                                ]
                            )}
                        </label>
                        <Icon
                            className="feedback__toggle-button--mobile"
                            path="right_chevron"
                            size="medium"
                        />
                    </div>
                )}

                {feedback && (
                    <div
                        className="feedback__explanation"
                        style={{
                            /* @ts-ignore */
                            "--max-toggle-height": `${getMaxToggleHeight()}px`,
                        }}
                    >
                        <SimpleBar
                            className="feedback__explanation__simplebar"
                            autoHide={false}
                        >
                            <div className="feedback__explanation-wrapper">
                                {isLegacyFeedbackContent(feedback.content) &&
                                    (Array.isArray(feedback.content) ? (
                                        feedback.content.map((fb, i) => (
                                            //TEMP FIX FOR P4: alignment added to be used only on "indication"-like feedbacks
                                            <LegacyFeedbackContent
                                                key={"fb-" + i}
                                                feedback={fb}
                                                alignment
                                                className="feedback-content--legacy"
                                            />
                                        ))
                                    ) : (
                                        <LegacyFeedbackContent
                                            feedback={feedback.content}
                                            className="feedback-content--legacy"
                                        />
                                    ))}

                                {!isLegacyFeedbackContent(feedback.content) && (
                                    <ContentBlock
                                        data={feedback.content}
                                        className="feedback-content__content-block"
                                        assetsDetails={assetsDetails}
                                        intl={intl}
                                        features={[
                                            ContentBlockFeatures.NFTTSAsBlock,
                                            ContentBlockFeatures.NoCentering
                                        ]}
                                    />
                                )}
                            </div>
                        </SimpleBar>
                    </div>
                )}
            </div>

            <div
                className="content-placeholder"
                style={{ height: headerRef.current?.clientHeight }}
            >
                {/* placeholder when mobile feedback is toggled, so that the
                feedback doesn't change height when other elements are placed
                absolutely */}
            </div>
        </div>
    );
};

export default WizardFeedbackContent;

const messages = defineMessages({
    fbCorrect: {
        id: "fbExerciseCorrect",
        defaultMessage: "Correct answer",
    },
    fbIncorrect: {
        id: "fbExerciseIncorrect",
        defaultMessage: "Incorrect answer",
    },
});
