import { Activity } from "@evidenceb/gameplay-interfaces";
import { ExerciseResult } from "../interfaces/Player";

export const isCorrect = (
    exerciseId: string,
    exerciseResults: ExerciseResult<any>[]
): boolean => {
    return exerciseResults.find(
        (exerciseResult) => exerciseResult.exerciseId === exerciseId
    )!.correct;
};

export const hasResult = (
    exerciseId: string,
    exerciseResults: ExerciseResult<any>[]
): boolean => {
    return !!exerciseResults.find(
        (exerciseResult) => exerciseResult.exerciseId === exerciseId
    );
};

export const getExerciseLastResult = (
    exerciseResults: ExerciseResult<any>[],
    exerciseId: string,
    activityId?: string
): ExerciseResult<any> | undefined => {
    const targetExerciseResults = exerciseResults.filter(
        (exerciseResult) =>
            exerciseResult.exerciseId === exerciseId &&
            (typeof activityId === "undefined" ||
                exerciseResult.activityId === activityId)
    );
    return targetExerciseResults.length > 0
        ? targetExerciseResults[targetExerciseResults.length - 1]
        : undefined;
};

export const getLastResults = (
    exerciseResults: ExerciseResult<any>[]
): ExerciseResult<any>[] => {
    return exerciseResults.filter(
        (exerciseResult, index) =>
            exerciseResult.exerciseId !== exerciseResults[index + 1]?.exerciseId
    );
};

export const getResultsForActivity = (
    activity: Activity,
    exerciseResults: ExerciseResult<any>[]
): ExerciseResult<any>[] => {
    return exerciseResults.filter((exerciseResult) =>
        activity.exerciseIds.includes(exerciseResult.exerciseId)
    );
};
