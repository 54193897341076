import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Button from "../../../../../../design-system-components/Button/Button";
import ModulesList from "../ModulesList/ModulesList";
import { messages } from "../../SingleClassroom";
import StudentsOverview from "../StudentsOverview/StudentsOverview";
import { ClassroomAnalytics } from "../../../../../../interfaces/Dashboard";
import { dashboardStore } from "../../../../../../contexts/DashboardContext";
import Loader from "../../../../../../components/Loader/Loader";

import "./OverviewTab.scss";

interface Props {
    classroomId: string;
    onAddUserClick: () => void;
}

const OverviewTab = ({ onAddUserClick, classroomId }: Props) => {
    const intl = useIntl();
    const {
        dashboard,
    } = useContext(dashboardStore);
    const classrooms = dashboard ? dashboard.classrooms : undefined

    const [classroomAnalytics, setClassroomAnalytics] = useState<ClassroomAnalytics>();

    useEffect(() => {
        if (classrooms) {
            const currentClassroomAnalytics = classrooms.find(
                (classroom) => classroom.id === classroomId
            );
            setClassroomAnalytics(currentClassroomAnalytics);
        }
    }, [classrooms, classroomId]);

    // TODO there's probably some error handling to do here
    if (!classroomAnalytics) return <Loader />;

    return (
        <div className={`single-classroom__overview ${classroomAnalytics.modulesList.length === 0 ? "single-classroom__overview--no-data" : ""}`}>
            {
                classroomAnalytics.modulesList.length === 0 && (
                    <p>
                        {intl.formatMessage(messages.noStudentsData)}
                    </p>
                )
            }
            {
                classroomAnalytics.modulesList.length > 0 && (
                    <div className="single-classroom__overview--container">
                        <div className="single-classroom__overview__modules">
                            <div className="single-classroom__overview__modules__header">
                                <h2>{intl.formatMessage(messages.moduleTitle)}</h2>
                                <Button
                                    className="single-classroom__overview__modules__prm-access-btn"
                                    type="button"
                                    variant="secondary"
                                    label={intl.formatMessage(messages.PRMLabel)}
                                    asRouterLink={{ to: "/prm/" + classroomId }}
                                    icon={{
                                        path: "settings",
                                        size: "medium"
                                    }}
                                />
                            </div>
                            <ModulesList classroom={classroomAnalytics} />
                        </div>
                        <div className="single-classroom__overview__students">
                            <StudentsOverview
                                classroomId={classroomId}
                                onAddUserClick={onAddUserClick}
                            />
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default OverviewTab;

