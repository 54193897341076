import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { Title } from "../../interfaces/Config";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import { sessionStore, Settled } from "../../contexts/SessionContext";
import useBanditManchot from "../../hooks/useBanditManchot";
import Loader from "../../components/Loader/Loader";
import ModuleCard from "./ModuleCard/ModuleCard";
import NewModuleCard from "./NewModuleCard/NewModuleCard";
import ControlledDialog from "../../design-system-components/ControlledDialog/ControlledDialog";
import htmlHandler from "../../utils/html-handler";
import { AIWhisperer } from "@evidenceb/ai-handler";
import { PRLockStatus } from "../../interfaces/PedagogicalResourcesManagement";

import "./ModuleListStudent.scss";
import { parse, Image } from "@evidenceb/athena-common";
import Button from "../../design-system-components/Button/Button";
import { VisibilityStatus } from "@evidenceb/gameplay-interfaces";
import ResetProgressionModal from "../../components/Specimen/Modal/Modal";
import useAssetsDetails from "../../hooks/useAssetsDetails";
import { useOverlayTriggerState } from "react-stately";
import useLockedModules from "../../hooks/useLockedModules";
import { LoaderStatus } from "../../interfaces/Status";

function ModuleListStudent({ title }: Title) {
    const history = useHistory();
    const { config } = useContext(configStore);
    const {
        session: { prLockStatus, specimen, banditManchot },
        setSession,
    } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const bmInfo = useBanditManchot();
    const [diagnostics, setDiagnostics] = useState<{
        [moduleId: string]: {
            progression: number;
            averageScore: number;
            isModuleFinished: boolean;
        };
    }>();
    const lockedModules = useLockedModules();
    const resetProgressionModal = useOverlayTriggerState({});
    const intl = useIntl();
    const assetsDetails = useAssetsDetails();


    // Get diagnostics
    useEffect(() => {
        if (bmInfo.status !== "success" || diagnostics || !banditManchot)
        return;
        const diag: {
            [moduleId: string]: {
                progression: number;
                averageScore: number;
                isModuleFinished: boolean;
            };
        } = {};
        Object.keys(bmInfo.aiLoadingInfo).forEach((moduleId) => {
            const singleBM = bmInfo.aiLoadingInfo[moduleId];
            if (singleBM.error) return;
            
            const basicDiag = AIWhisperer.getStudentBasicDiagnostic(
                singleBM.instance
                );
                const { isModuleFinished } = AIWhisperer.getNextHierarchyId(
                singleBM.instance
                );
                diag[moduleId] = {
                ...basicDiag,
                isModuleFinished: isModuleFinished ?? false,
            };
        });
        setDiagnostics(diag);
    }, [diagnostics, bmInfo, banditManchot]);

    const resetBMProgression = () => {
        if (!specimen) return;
        setSession((session) => ({
            ...session,
            statements: [],
            banditManchot: undefined,
        }));
        setDiagnostics(undefined);
    };

    if (
        !banditManchot ||
        bmInfo.status === "loading" ||
        (!diagnostics && bmInfo.status !== "error") ||
        lockedModules.status === LoaderStatus.Loading
    )
        return <Loader />;

    return (
        <>
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>

            {bmInfo.status === "error" && (
                <ControlledDialog
                    title={intl.formatMessage({
                        id: "errors-statements-cannotRetrieveHistory-title",
                        defaultMessage: "Impossible to retrieve history",
                    })}
                    description={{
                        $html: intl.formatMessage({
                            id: "errors-statements-cannotRetrieveHistory-description",
                            defaultMessage:
                                "Your progress data is not available at this time. Please try again later.",
                        }),
                    }}
                    primaryButton={{
                        label: intl.formatMessage({
                            id: "errors-statements-cannotRetrieveHistory-goToHomepageButton",
                            defaultMessage: "Go to homepage",
                        }),
                        fn: () => {
                            history.push("/");
                        },
                    }}
                />
            )}

            {specimen && resetProgressionModal.isOpen && (
                <ResetProgressionModal
                    state={resetProgressionModal}
                    onModalDismiss={resetProgressionModal.close}
                    onClick={() => {
                        resetBMProgression();
                        resetProgressionModal.close();
                    }}
                    title={intl.formatMessage({
                        id: "moduleList-student-specimen-modalTitle",
                        defaultMessage: "Reset the student path",
                    })}
                    content={intl.formatMessage({
                        id: "moduleList-student-specimen-modalDescription",
                        defaultMessage:
                            "The progress you have made so far will be deleted. You can restart a student path with different answers to discover a different path.",
                    })}
                    buttonLabel={intl.formatMessage({
                        id: "moduleList-student-specimen-modalButton",
                        defaultMessage: "Reset the student path",
                    })}
                />
            )}

            <div id="module-list">
                <div id="welcome-message">
                    <Image
                        assetsDetails={assetsDetails}
                        src={config.logos.avatar}
                        className="avatar-img"
                        alt=""
                    />
                    <div className="text-content">
                        {areAllModulesLocked(
                            lockedModules.status === LoaderStatus.Success ? lockedModules.payload : [],
                            data.modules.map((mod) => mod.id)
                        ) ? (
                            <FormattedMessage
                                id="moduleList-areAllModulesLocked"
                                defaultMessage="All modules are locked at the moment. You'll be able to start a module once your teacher unlocks it."
                            />
                        ) : (
                            parse(
                                intl.formatMessage(
                                    {
                                        id: "html-moduleList-student-message-default",
                                        defaultMessage:
                                            "Choose the module you want to start working on.",
                                    },
                                    htmlHandler
                                )
                            )
                        )}
                    </div>
                </div>

                {specimen && (
                    <div id="specimen-content">
                        <span>
                            {intl.formatMessage({
                                id: "moduleList-student-specimen-description",
                                defaultMessage: `Click on "Start" to begin a personalized course. Depending on the answers you give to the exercises, the algorithm will adapt the choice of the following ones. If you reset the data and answer differently, the course will also be different. In the full version of the application (not sample), the data will enrich the teacher's dashboard.`,
                            })}
                        </span>
                        <Button
                            icon={{ path: "restart" }}
                            onClick={resetProgressionModal.open}
                            label={intl.formatMessage({
                                id: "moduleList-student-reset-progression",
                                defaultMessage: "Reset student progression",
                            })}
                        />
                    </div>
                )}

                <div id="card-container">
                    {data.modules.map((mod) =>
                        config.features.newStudentModuleList ? (
                            <NewModuleCard
                                key={`card-${mod.id}`}
                                moduleId={mod.id}
                                diagnosis={
                                    diagnostics
                                        ? diagnostics[mod.id]
                                        : undefined
                                }
                                error={
                                    bmInfo.status === "error" ||
                                    bmInfo.aiLoadingInfo[mod.id].error ||
                                    !diagnostics ||
                                    !lockedModules
                                }
                                locked={
                                    bmInfo.status === "error"
                                        ? true
                                        : (
                                              prLockStatus as Settled<PRLockStatus>
                                          ).value.moduleIds.includes(mod.id)
                                }
                                specimenLocked={
                                    specimen &&
                                    mod.visibilityStatus ===
                                        VisibilityStatus.Unavailable
                                }
                            />
                        ) : (
                            <ModuleCard
                                key={`card-${mod.id}`}
                                moduleId={mod.id}
                                diagnosis={
                                    diagnostics
                                        ? diagnostics[mod.id]
                                        : undefined
                                }
                            />
                        )
                    )}
                </div>

                {config.logos.avatarStudentBottomRight && (
                    <div className="avatarBottom">
                        <Image
                            assetsDetails={assetsDetails}
                            src={config.logos.avatarStudentBottomRight}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

const areAllModulesLocked = (
    lockedModules: string[],
    modules: string[]
): boolean => {
    return modules.every((mod) => lockedModules.includes(mod));
};

export default ModuleListStudent;
