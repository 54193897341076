import React, { useCallback, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { ActionTokenError } from "../../../interfaces/AuthAPI";
import { errorStore } from "../../../contexts/ErrorContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { useAthenaTokenlessAPIClient } from "../../../hooks/useAthenaAPIClient";
import { commonMessages } from "../../../utils/messages";
import * as localStorage from "../../../utils/localStorage";
import Button from "../../../design-system-components/Button/Button";
import Loader from "../../../components/Loader/Loader";
import PasswordChangeForm from "./PasswordChangeForm/PasswordChangeForm";
import PasswordChangeError from "./PasswordChangeError/PasswordChangeError";

import "./PasswordChange.scss";

const PasswordChange = () => {
    const { actionToken, type } = useParams<{
        actionToken: string;
        type: "new" | "forgotten";
    }>();
    const intl = useIntl();
    const history = useHistory()
    const { setErrorInfo } = useContext(errorStore);
    const { session } = useContext(sessionStore);
    const athenaAPIClient = useAthenaTokenlessAPIClient();
    const [loadingStatus, setLoadingStatus] = useState<LoadingState>({
        status: "idle",
    });

    const [submitError, setSubmitError] = useState<"mismatch" | "same_password" | undefined>();

    const handlePwdChangeError = useCallback(
        (err: AxiosError) => {
            if (err.response?.status === 401)
                setLoadingStatus({
                    status: "error",
                    error: err.response.data.detail.message as ActionTokenError,
                });
            else {
                setErrorInfo({
                    displayModal: true,
                    modal: {
                        type: "NOTIFICATION",
                        content: {
                            title: intl.formatMessage(
                                err.response?.status === 422
                                    ? messages.invalidToken
                                    : commonMessages.genericErrorMessage
                            ),
                        },
                    },
                });
                history.push("/auth/teacher");
            }
        },
        [setErrorInfo, intl, history]
    );

    // Validate action token
    useEffect(() => {
        if (loadingStatus.status !== "idle") return;
        setLoadingStatus({ status: "loading" });

        (async () => {
            try {
                const { email } = await athenaAPIClient.verifyActionToken(
                    actionToken
                );
                setLoadingStatus({ status: "success", email });
            } catch (err) {
                handlePwdChangeError(err as AxiosError);
            }
        })();
    }, [actionToken, athenaAPIClient, loadingStatus, handlePwdChangeError]);

    const saveNewPassword = useCallback(
        async (newPassword: string) => {
            if (loadingStatus.status !== "success") return;

            try {
                const res = await athenaAPIClient.updatePassword(
                    newPassword,
                    actionToken,
                    session.app!,
                    loadingStatus.email
                );
                // Some errors have a 200 response to fool hackers
                if ("detail" in res)
                    setSubmitError(res.detail);
                else {
                    localStorage.setItem(localStorage.Key.TOKEN, res.token);
                    window.location.replace("/");
                }
            } catch (err) {
                handlePwdChangeError(err as AxiosError);
            }
        },
        [actionToken, session.app, athenaAPIClient, handlePwdChangeError, loadingStatus]
    );

    return (
        <>
            {loadingStatus.status === "loading" && <Loader />}

            {loadingStatus.status === "success" && (
                <div className="password-change__container">
                    {type === "forgotten" && (
                        <Button
                            variant="primary"
                            label={intl.formatMessage(commonMessages.back)}
                            icon={{
                                path: "arrow_left",
                                position: "left",
                                size: "medium",
                            }}
                            asRouterLink={{ to: "/auth/teacher" }}
                        />
                    )}
                    <PasswordChangeForm
                        type={type}
                        email={loadingStatus.email}
                        onSubmit={saveNewPassword}
                        submitError={submitError}
                    />
                </div>
            )}

            {loadingStatus.status === "error" && (
                <PasswordChangeError type={loadingStatus.error} />
            )}
        </>
    );
};

type LoadingState =
    | { status: "idle" }
    | { status: "loading" }
    | { status: "success"; email: string }
    | { status: "error"; error: ActionTokenError };

const messages = defineMessages({
    invalidToken: {
        id: "authentication-invalidToken",
        defaultMessage: "Invalid token",
    },
});

export default PasswordChange;
