import React, { useContext, useMemo, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import { Link, LinkProps } from "react-router-dom";
import { configStore } from "../../../../../contexts/ConfigContext";
import { sessionStore } from "../../../../../contexts/SessionContext";
import { syncStore } from "../../../../../contexts/Sync";
import useStatements from "../../../../../hooks/useSyncStatements";
import ControlledDialog from "../../../../../design-system-components/ControlledDialog/ControlledDialog";
import { ADAPTIVE_TEST_LIFESPAN } from "../../../../../hooks/useAdaptiveTest";
import { PlayerOpts } from "../../../../../interfaces/Player";
import { useIntl } from "react-intl";
import { Statement } from "@xapi/xapi";
import {
    adaptiveTestStatementsFilter,
    byAdaptiveTestNumberStatementsFilter,
    byModuleStatementsFilter,
    chronologicalStatementsSort,
} from "../../../../../utils/statements";
import { AIType } from "@evidenceb/ai-handler";

interface Props {
    className?: string;
    moduleId?: string;
    opts?: Partial<PlayerOpts>;
    aiType?: AIType;
}

const SafeguardedLink = ({
    moduleId,
    opts,
    className,
    aiType,
    ...props
}: LinkProps & Props) => {
    const {
        sync: { unsyncedStatements, syncingStatements, isProcessing },
    } = useContext(syncStore);
    const {
        session: { adaptiveTestNumber, statements },
        setSession,
    } = useContext(sessionStore);
    const {
        config: { displayedDateFormat },
    } = useContext(configStore);
    const { syncStatements } = useStatements();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const history = useHistory();
    const intl = useIntl();

    const currentAdaptiveTestStatements = useMemo(() => {
        if (moduleId && adaptiveTestNumber)
            return statements
                ?.filter(adaptiveTestStatementsFilter)
                .filter(byModuleStatementsFilter(moduleId))
                ?.filter(
                    byAdaptiveTestNumberStatementsFilter(adaptiveTestNumber)
                );
        else return [];
    }, [statements, moduleId, adaptiveTestNumber]);

    if (
        !unsyncedStatements &&
        !syncingStatements &&
        !isProcessing &&
        !opts?.confirmPlayerExit
    )
        return <Link className={className} {...props} />;

    return (
        <>
            {isModalOpen &&
                moduleId &&
                (unsyncedStatements || syncingStatements || isProcessing) && (
                    <ControlledDialog
                        title={intl.formatMessage({
                            id: "errors-statements-navigationWhenUnsyncedStatements-modal-title",
                            defaultMessage: "Leave without saving",
                        })}
                        description={{
                            $html: intl.formatMessage({
                                id: "errors-statements-navigationWhenUnsyncedStatements-modal-description",
                                defaultMessage:
                                    "We are still trying to save your progression data. If you leave now, this data will be lost.",
                            }),
                        }}
                        primaryButton={{
                            label: intl.formatMessage({
                                id: "errors-statements-navigationWhenUnsyncedStatements-modal-syncButton",
                                defaultMessage: "Save now",
                            }),
                            fn: () => {
                                syncStatements();
                                setIsModalOpen(false);
                            },
                        }}
                        secondaryButton={{
                            label: intl.formatMessage({
                                id: "errors-statements-navigationWhenUnsyncedStatements-modal-leaveAnywayButton",
                                defaultMessage: "Leave anyway",
                            }),
                            fn: () => {
                                // The module should be made unavailable because
                                // the bandit manchot instance is now loaded with
                                // statements that weren't synced, and further
                                // playing will create a gap in theuser's history
                                if (
                                    moduleId &&
                                    aiType === AIType.BanditManchot &&
                                    (unsyncedStatements || syncingStatements)
                                )
                                    setSession((curr) => {
                                        return {
                                            ...curr,
                                            banditManchot: {
                                                ...curr.banditManchot,
                                                [moduleId]: {
                                                    ...curr.banditManchot![
                                                        moduleId!
                                                    ],
                                                    error: true,
                                                },
                                            },
                                        };
                                    });
                                history.push(props.to as string);
                            },
                        }}
                    />
                )}

            {isModalOpen &&
                !unsyncedStatements &&
                !syncingStatements &&
                !isProcessing &&
                opts?.confirmPlayerExit &&
                aiType === AIType.AdaptiveTest && (
                    <ControlledDialog
                        title={intl.formatMessage({
                            id: "exitTestInProgress",
                            defaultMessage: "Exit test in progress",
                        })}
                        description={{
                            $html: intl.formatMessage(
                                {
                                    id: "exitTestInProgressDescription",
                                    defaultMessage:
                                        "If you leave the test now, you will have until {date} to finish it. Otherwise, you will have to start over with a new test.",
                                },
                                {
                                    date: getExpiryDate(
                                        currentAdaptiveTestStatements!,
                                        displayedDateFormat
                                    ),
                                }
                            ),
                        }}
                        primaryButton={{
                            label: intl.formatMessage({
                                id: "leaveNow",
                                defaultMessage: "Leave now",
                            }),
                            fn: () => {
                                history.push(props.to as string);
                            },
                        }}
                        secondaryButton={{
                            label: intl.formatMessage({
                                id: "continueTest",
                                defaultMessage: "Continue the test",
                            }),
                            fn: () => {
                                setIsModalOpen(false);
                            },
                        }}
                    />
                )}

            <button
                className={className}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            >
                {props.children}
            </button>
        </>
    );
};

export const getExpiryDate = (
    statements: Statement[],
    displayedDateFormat: string
) => {
    const currentTestStatements = statements.sort(chronologicalStatementsSort);
    if (currentTestStatements.length === 0)
        return moment(Date.now() + ADAPTIVE_TEST_LIFESPAN).format(
            displayedDateFormat
        );
    return moment(currentTestStatements[0].timestamp)
        .add(ADAPTIVE_TEST_LIFESPAN, "ms")
        .format(displayedDateFormat);
};

export default SafeguardedLink;
