import { PerModule, PerObjective } from "@evidenceb/gameplay-interfaces";
import { cloneDeep } from "lodash";
import {
    ComputedDiagnosis,
    Link,
    Recommendations,
    RecommendationsLimits,
    TestMetadata,
} from "../../../interfaces/AdaptiveTests";
import { Data } from "../../../interfaces/Data";
import { computeObjectiveEstimations } from "./diagnosis";
import { getLatestTest } from "./result";

export const getRecommendations = (
    data: Data,
    testsResults: PerModule<TestMetadata[]>,
    mainTests: PerModule<TestMetadata | undefined>,
    rawRecommendations: PerObjective<Recommendations>,
    recommendationsLimits?: RecommendationsLimits
): PerModule<PerObjective<Recommendations>> => {
    const recommendations: PerModule<PerObjective<Recommendations>> = {};
    data.modules.forEach(({ id: moduleId }) => {
        const latestTest = getLatestTest(
            mainTests[moduleId]!,
            testsResults[moduleId]
        );
        if (!latestTest) return;

        const moduleObjectivesDiagnosis = computeObjectiveEstimations(
            data,
            latestTest.diagnosis!.allEstimates!,
            moduleId
        );
        recommendations[moduleId] = getModuleRecommendations(
            rawRecommendations,
            moduleObjectivesDiagnosis,
            recommendationsLimits
        );
    });
    return recommendations;
};

export const getDiagnosisRecommendations = (
    orderedRecommendations: Link[],
    diagnosis: number,
    recommendationsLimits: RecommendationsLimits,
    currentQuantity: number
) => {
    let newLinks = [] as Link[];
    orderedRecommendations.every((link) => {
        if (
            link.ceiling * 100 > diagnosis &&
            (!recommendationsLimits.max ||
                currentQuantity < recommendationsLimits.max)
        ) {
            newLinks.push(link);
            currentQuantity += 1;
        }
        return !areRecommendationsFull(recommendationsLimits, currentQuantity);
    });
    return newLinks;
};

export const getModuleRecommendations = (
    recommendations: PerObjective<Recommendations>,
    diagnosis: PerObjective<ComputedDiagnosis>,
    recommendationsLimits: RecommendationsLimits = {
        min: 1,
        max: undefined,
    }
) => {
    let moduleRecommendations: PerObjective<Recommendations> = {};
    let orderedRecommendations: PerObjective<Recommendations> = {};

    if (!recommendationsLimits.min) recommendationsLimits.min = 1;
    if (
        recommendationsLimits.max &&
        recommendationsLimits.min > recommendationsLimits.max
    )
        recommendationsLimits.max = recommendationsLimits.min;

    var diagPerObjective = Object.keys(diagnosis).map((objective) => {
        return {
            objective: objective,
            diagnosis: diagnosis[objective].meanEstimate,
        };
    });
    // Recommendations will be added beginning from the least acquired objective
    diagPerObjective.sort((first, second) => {
        return first.diagnosis - second.diagnosis;
    });

    let displayedRecommendations = 0;

    diagPerObjective.every((diag, index) => {
        let objectiveId = diag.objective;
        if (
            Object.keys(recommendations).includes(objectiveId) &&
            recommendations[objectiveId].links &&
            recommendations[objectiveId].links.length > 0
        ) {
            orderedRecommendations[objectiveId] = cloneDeep(
                recommendations[objectiveId]
            );
            /**
             * Recommendations whose ceiling is higher than the student's diagnosis
             * will be added from the basicest to the more elaborated (with higher ceilings)
             */
            orderedRecommendations[objectiveId].links.sort((a, b) => {
                return a.ceiling - b.ceiling;
            });
            moduleRecommendations[objectiveId] = cloneDeep(
                orderedRecommendations[objectiveId]
            );
            moduleRecommendations[objectiveId].links =
                getDiagnosisRecommendations(
                    moduleRecommendations[objectiveId].links,
                    diagnosis[objectiveId].meanEstimate,
                    recommendationsLimits,
                    displayedRecommendations
                );
            displayedRecommendations +=
                moduleRecommendations[objectiveId].links.length;
        }
        return !areRecommendationsFull(
            recommendationsLimits,
            displayedRecommendations
        );
    });
    if (displayedRecommendations < recommendationsLimits.min!) {
        diagPerObjective.every((diag) => {
            // Add recommendations with a ceiling inferior to the student's diagnosis but the closest posible to that diagnosis
            orderedRecommendations[diag.objective].links.reverse();
            const extraReco = getExtraRecommendations(
                orderedRecommendations[diag.objective].links,
                (diagnosis[diag.objective].meanEstimate ?? diagnosis[diag.objective].meanEstimate),
                recommendationsLimits.min!,
                displayedRecommendations
            );
            moduleRecommendations[diag.objective].links.push(...extraReco);
            displayedRecommendations += extraReco.length;
            return displayedRecommendations < recommendationsLimits.min!;
        });
    }
    return moduleRecommendations;
};

const areRecommendationsFull = (
    limits: RecommendationsLimits,
    currentQuantity: number
) => {
    if (!limits.max) return false;
    return currentQuantity >= limits.max;
};

export const getExtraRecommendations = (
    orderedRecommendations: Link[],
    diagnosis: number,
    minQuantity: number,
    currentQuantity: number
) => {
    let newLinks = [] as Link[];
    orderedRecommendations.every((link) => {
        if (link.ceiling * 100 <= diagnosis) {
            newLinks.push(link);
            currentQuantity += 1;
        }
        return currentQuantity < minQuantity;
    });
    return newLinks;
};
