import { IntlShape } from "react-intl";
import { Page } from "../interfaces/Config";
import { UserType } from "../interfaces/User";
import { getUrl } from "./dataRetrieval";

export const getDashboardUrl = (pages: Page[]) => {
    return `/${
        getUrl(
            pages.find((page) => page.type === "DASHBOARD")!,
            UserType.Teacher
        ) as string
    }`;
};

export const getClassroomListUrl = getDashboardUrl;

export const getSingleClassroomUrl = (
    pages: Page[],
    classroomId: string,
    intl: IntlShape,
): string => {
    return `${getDashboardUrl(pages)}/${intl.formatMessage({
        id: "dashboard-paths-classes",
        defaultMessage: "classes",
    })}/${classroomId}`;
};

export const getStudentDetailsUrl = (
    pages: Page[],
    studentId: string,
    classroomId: string,
    intl: IntlShape
) => {
    return `${getDashboardUrl(pages)}/${intl.formatMessage({
        id: "dashboard-paths-student",
        defaultMessage: "student",
    })}/${classroomId}/${studentId}`;
};

export const getProgressionOverviewUrl = (
    pages: Page[],
    classroomId: string,
    moduleId: string,
    intl: IntlShape
) => {
    return `${getDashboardUrl(pages)}/${intl.formatMessage({
        id: "dashboard-paths-classes",
        defaultMessage: "classes",
    })}/${intl.formatMessage({
        id: "dashboard-paths-progression",
        defaultMessage: "progression",
    })}/${classroomId}/${moduleId}`;
};

export const getClusteringUrl = (
    pages: Page[],
    classroomId: string,
    moduleId: string,
    intl: IntlShape
) => {
    return `${getDashboardUrl(pages)}/${intl.formatMessage({
        id: "dashboard-paths-classes",
        defaultMessage: "classes",
    })}/${classroomId}/${moduleId}/${intl.formatMessage({
        id: "dashboard-paths-groups",
        defaultMessage: "groups",
    })}`;
};
