/**
 * Returns true if the environment is not clearly a preprod or local or tempdev environment
 * Not 100% accurate especially for speciifc preprod urls
 */
export const isProductionEnvironment = (hostname: string) => {
    if (
        hostname.includes("localhost") ||
        hostname.includes("preprod") ||
        hostname.includes("tempdev")
    )
        return false;
    return true;
};

export const isLocal = (hostname: string) => {
    if (hostname.includes("localhost")) return true;
    return false;
}
