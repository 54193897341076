import React from 'react';
import Button, { ButtonProps } from '../Button/Button'
// SCSS
import './ToggleButton.scss';

export interface ToggleButtonProps extends Omit<ButtonProps, "label" | "center"> {
    /** @default toggleIcon */
    icon?: ButtonProps["icon"];
    /** @default full */
    size?: ButtonProps["size"];
    variant?: ButtonProps["variant"] | undefined;
    /**
     * Admitting the original icon is looking towards right, new direction to give
     * @default rotation towards bottom
     */
    rotationTowards?: "bottom" | "left" | "up" | "right",
    label: string
    /**
     * @default false
     */
    hidelabel?: boolean
}

const toggleIcon = {
    path: "chevron_right",
    size: 12,
    color: "black",
    position: "right" as const
}

const ToggleButton = ({
                        icon = toggleIcon,
                        variant = "invisible",
                        rotationTowards = "bottom",
                        size="full",
                        className,
                        label,
                        hideLabel = false,
                        ...props
                    }: ToggleButtonProps) => {
    return (
        <Button
            className={`ds-toggle-button  ds-toggle-button-${rotationTowards} ${className ?? ""}`}
            icon={icon}
            variant={variant}
            label={label}
            hideLabel={hideLabel}
            size={size}
            {...props}
        />
    );
};

export default ToggleButton
