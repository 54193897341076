import React, { useContext, useEffect, useState, Fragment } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import parse from "html-react-parser";
import cn from 'classnames';
import Drawer from "rc-drawer";
import {
    Activity,
    Objective,
    HtmlString,
    VisibilityStatus,
} from "@evidenceb/gameplay-interfaces";
import { FullDiagnosis, Status } from "@evidenceb/bandit-manchot";
import { AIWhisperer, SuccessfullAI } from "@evidenceb/ai-handler";
import { Data } from "../../../../interfaces/Data";
import { dataStore } from "../../../../contexts/DataContext";
import useBanditManchot from "../../../../hooks/useBanditManchot";
import {
    getActivityById,
    getObjectiveById,
    getItemDescription,
} from "../../../../utils/dataRetrieval";
import { MOBILE_BREAKPOINT } from "../../../../const/layout";
import CurrentObjective from "./CurrentObjective/CurrentObjective";
import ObjectiveBubble from "./ObjectiveBubble/ObjectiveBubble";
import { InfoPanelProps } from "../../PlayerBuilder";

import "./BanditManchotInfoPanel.scss";
import Icon from "../../../../design-system-components/Icon/Icon";
import { sessionStore } from "../../../../contexts/SessionContext";

enum JourneyObjectiveStatus {
    PAST = -1,
    CURRENT = 0,
    IN_PROGRESS = 1,
    FUTURE = 2,
}

type JourneyObjective = {
    status: JourneyObjectiveStatus;
    visibilityStatus: VisibilityStatus;
    description: HtmlString;
};

interface Journey {
    journeyObjectives: JourneyObjective[];
    currentActivities: (Activity & { status: Status })[];
}

/**
 * Info panel that shows the user's progression in the module. Should only be
 * used in conjunction with the bandit manchot playlist manager.
 *
 * Feature activated with the studentChatbotPlayerInfoPanel flag.
 */
const BanditManchotInfoPanel = ({ playlist, getContainer }: InfoPanelProps) => {
    const { data } = useContext(dataStore);
    const bmInfo = useBanditManchot();
    const [open, setOpen] = useState<boolean>(window.innerWidth > MOBILE_BREAKPOINT);
    const [journey, setJourney] = useState<Journey>({
        currentActivities: [],
        journeyObjectives: [],
    });
    const [moduleBM, setModuleBM] = useState<SuccessfullAI>();
    const intl = useIntl();
    const { session } = useContext(sessionStore)

    // Add moduleBM to state
    useEffect(() => {
        if (bmInfo.status !== "success" || moduleBM) return;
        const _moduleBM = bmInfo.aiLoadingInfo[playlist.module.id];
        if (_moduleBM.error) return;
        setModuleBM(_moduleBM);
    }, [bmInfo, playlist.module.id, moduleBM]);

    // Update the journey when the bandit manchot is loaded or the student
    // progresses
    useEffect(() => {
        if (!moduleBM) return;
        setJourney(
            makeStudentJourney(
                data,
                AIWhisperer.getStudentFullDiagnosis(moduleBM.instance),
                playlist.objective!,
                playlist.activity!.id,
                intl
            )
        );
    }, [data, playlist, moduleBM, intl]);

    // No need for particular error checking because it would be caught higher
    // up

    if (playlist.isInitialTest) return null;

    return (
        <Drawer
            className="chatbot-student-drawer"
            rootClassName="bm-info__drawer__container"
            open={open}
            placement="left"
            width={380}
            getContainer={getContainer}
            mask={false}
            motion={{
                motionAppear: true,
                motionName: 'bm-info__drawer--motion'
            }}
        >
            <div className="drawer__content">
                <div className="title" key={"drawer-title"}>
                    <FormattedMessage
                        id="features-studentChatbotPlayerInfoPanel-title"
                        defaultMessage="My path"
                    />
                </div>
                <div
                    className="journey-objectives-container"
                    key={"drawer-objectives"}
                >
                    {journey.journeyObjectives.map(
                        (journeyObjective, index) => (
                            <Fragment key={index}>
                                {journeyObjective.status ===
                                JourneyObjectiveStatus.CURRENT ? (
                                    <CurrentObjective
                                        activities={
                                            journey.currentActivities
                                        }
                                        objective={playlist.objective!}
                                        currentActivityId={
                                            playlist.activity!.id
                                        }
                                    />
                                ) : (
                                    <div className={cn("objective", {
                                        "objective--locked": journeyObjective.visibilityStatus === VisibilityStatus.Unavailable
                                    })}>
                                        <ObjectiveBubble
                                            objectiveFinished={
                                                journeyObjective.status ===
                                                JourneyObjectiveStatus.PAST
                                            }
                                            objectiveInProgress={
                                                journeyObjective.status ===
                                                JourneyObjectiveStatus.IN_PROGRESS
                                            }
                                        />
                                        <div className="objective-description">
                                            {parse(
                                                journeyObjective.description
                                                    .$html
                                            )}
                                            { session.specimen && journeyObjective.visibilityStatus === VisibilityStatus.Unavailable && (
                                                <span className="objective__out-of-specimen-text">
                                                    {intl.formatMessage({id: "specimen-out-of-specimen", defaultMessage: "(out of specimen)"})}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        )
                    )}
                </div>
            </div>
            <div
                className="drawer-handle"
                aria-hidden="true"
                onClick={() => setOpen(!open)}
            >
                {/* drawer handle placeholder */}
                <DrawerExpandIcon open={open} />
            </div>
        </Drawer>
    );
};

const DrawerExpandIcon: React.FC<{ open: boolean }> = ({ open }) => {
    let classes =
        "custom-drawer-handle-icon open" +
        (open ? " opened" : "");
    return (
        <Icon path="chevron_right" size="clickable" className={classes} />
    );
};

const translateStatus = (diagnosticStatus: string): JourneyObjectiveStatus => {
    if (diagnosticStatus === "none") return JourneyObjectiveStatus.FUTURE;
    if (diagnosticStatus === "inProgress")
        return JourneyObjectiveStatus.IN_PROGRESS;

    // Bandit Manchot sometimes gives exercises from completed objectives
    if (diagnosticStatus === "completed") return JourneyObjectiveStatus.PAST;

    throw new Error("Unknown status: " + diagnosticStatus);
};

const makeStudentJourney = (
    data: Data,
    studentDiagnostic: FullDiagnosis,
    currentObjective: Objective,
    currentActivityId: string,
    intl: IntlShape
): Journey => {
    const journeyObjectives = studentDiagnostic.objectives.map(
        (objectiveDiagnosis) => {
            const objective = getObjectiveById(objectiveDiagnosis.id, data);
            const description = getItemDescription(objective, "student");
            return {
                description: {
                    $html: description,
                },
                status:
                    currentObjective.id === objectiveDiagnosis.id
                        ? JourneyObjectiveStatus.CURRENT
                        : translateStatus(objectiveDiagnosis.status),
                visibilityStatus: objective.visibilityStatus,
            };
        }
    );

    const currentObjectiveDiagnosis = studentDiagnostic.objectives.find(
        (objectiveDiagnosis) => objectiveDiagnosis.id === currentObjective.id
    )!;
    const currentActivities: Journey["currentActivities"] =
        currentObjective.activityIds.map((activityId) => {
            const activity = getActivityById(activityId, data);
            if (activity.id === currentActivityId)
                return {
                    ...activity,
                    status: "inProgress",
                };

            const status = currentObjectiveDiagnosis.progression
                // The array needs to be reversed because the
                // progression is an additive array where the updated
                // status of an array is added at the end. So the most
                // up to date status of an activity is the one
                // associated with the last apperance of an object with
                // the correct id in the array
                // Slice is used before because reverse is an in place function
                // and we don't want to mutate the original array
                .slice()
                .reverse()
                .find(
                    (activityDiagnosis) => activityDiagnosis.id === activityId
                )?.status;
            return {
                ...activity,
                status: status ?? "none",
            };
        });

    return {
        journeyObjectives,
        currentActivities,
    };
};

export default BanditManchotInfoPanel;
