import { useEffect, useState } from "react";

type Status = "idle" | "pending" | "success" | "error"

export default function useOnMountRequest<T>(request: () => Promise<T>): ({status: Status, data?: T, error: unknown }){

    const [status, setStatus] = useState<Status>('idle');
    const [data, setData] = useState<T>();
    const [error, setError] = useState<unknown>();

    useEffect(() => {
        if(!request || status !== "idle") return;
        setStatus("pending");
        (async () => {
            try{
                const response = await request();
                setData(response);
                setStatus('success')
            }catch(err){
                setStatus('error')
                setError(err)
            }
        })()
    },[request, status])

    return {status, data, error}

}