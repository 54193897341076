import React, {
    ButtonHTMLAttributes,
    DetailedHTMLProps,
    useImperativeHandle,
    useRef,
} from "react";
import { Link, LinkProps } from "react-router-dom";
import VisuallyHidden from "../../components/VisuallyHidden/VisuallyHidden";
import Icon, { IconProps } from "../Icon/Icon";
import cn from "classnames";
// SCSS
import "./Button.scss";
import { AriaButtonProps, useButton } from "@react-aria/button";

export type ButtonProps = Omit<
    DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >,
    "children" | "ref"
> &
    Omit<AriaButtonProps, "children"> & {
        /** @default "primary" */
        variant?:
            | "primary"
            | "secondary"
            | "secondary-light"
            | "tertiary"
            | "invisible";
        label: string;
        icon?: Pick<IconProps, "path" | "className"> & {
            /** @default medium */
            size?: IconProps["size"];
            position?: "left" | "right";
        };
        center?: boolean;
        /** @default default */
        size?: "medium" | "large" | "full";
        /**  @default rectangular */
        appearance?: "rectangular" | "pill"
        /**
         * Optional color for the button. "primary" and "secondary" reference the
         * primary and secondary colors of the variation.
         * @default default
         */
        color?: "default" | "primary" | "secondary";
        /** @default soft */
        disabledStyle?: "soft" | "hard";
        /** @default false */
        hideLabel?: boolean;
        /** To have the button be an a tag instead */
        asLink?: Omit<
            React.DetailedHTMLProps<
                React.AnchorHTMLAttributes<HTMLAnchorElement>,
                HTMLAnchorElement
            >,
            "children"
        >;
        /** To have the button be a Link instead */
        asRouterLink?: Omit<LinkProps, "children">;
    };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant = "primary",
            label,
            icon,
            center = true,
            size = "medium",
            appearance = "rectangular",
            disabledStyle = "hard",
            className,
            asLink,
            asRouterLink,
            color = "default",
            hideLabel = false,
            ...props
        },
        ref
    ) => {
        
        
        const innerRef = useRef<HTMLButtonElement>(null);
        useImperativeHandle(ref, () => innerRef.current!);
        const {
            buttonProps: { role, ...buttonProps },
        } = useButton(
            { ...props, elementType: asLink || asRouterLink ? "a" : "button" },
            innerRef
        );
        const classNames = cn(
            className,
            "ds-btn",
            `ds-btn--${variant}`,
            `ds-btn--size-${size}`,
            `ds-btn--color-${color}`,
            `ds-btn--appearance-${appearance}`,
            `ds-btn--disabled-${disabledStyle}`,
            {
                "ds-btn--has-icon": icon,
                [`ds-btn--icon-${icon?.position}`]: icon,
                "ds-btn--center": center,
            }
        );

        if (typeof asRouterLink !== "undefined")
            return (
                <Link {...buttonProps} {...asRouterLink} className={classNames}>
                    <ButtonContent
                        label={label}
                        icon={icon}
                        hideLabel={hideLabel}
                    />
                </Link>
            );

        if (typeof asLink !== "undefined")
            return (
                <a {...buttonProps} {...asLink} className={classNames}>
                    <ButtonContent
                        label={label}
                        icon={icon}
                        hideLabel={hideLabel}
                    />
                </a>
            );

        return (
            <button
                {...props}
                {...buttonProps}
                role={role}
                className={classNames}
                ref={innerRef}
            >
                <ButtonContent
                    label={label}
                    icon={icon}
                    hideLabel={hideLabel}
                />
            </button>
        );
    }
);

const ButtonContent = ({
    icon,
    hideLabel,
    label,
}: Pick<ButtonProps, "icon" | "label" | "hideLabel">) => {
    return (
        <>
            {icon && <Icon {...icon} />}
            {hideLabel && (
                <VisuallyHidden className="ds-btn__label">
                    {label}
                </VisuallyHidden>
            )}
            {!hideLabel && <span className="ds-btn__label">{label}</span>}
        </>
    );
};

export default Button;
