import { defineMessages } from "react-intl";

const dashboardMessages = defineMessages({
    students: {
        id: "dashboard-common-students",
        defaultMessage: "Students"
    },
    meanSuccess: {
        id: "dashboard-studentProgression-meanSuccessRate",
        defaultMessage: "Mean success",
    },

    classroomNotFound: {
        id: "dashboard-classroomNotFound",
        defaultMessage: "The requested class was not found.",
    },
    diagTest: {
        id: "dashboard-diagnosisTest",
        defaultMessage: "Diag. test"
    },
});

export default dashboardMessages;
