import React, { useRef } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
// SCSS
import "./ResultPopover.scss";
//Components
import Icon from "../../../../design-system-components/Icon/Icon";
// Interfaces
import { Exercise } from "../../../../interfaces/Dashboard";
import Popover from "../../../../design-system-components/Popover/Popover";
import { useOverlayTriggerState } from "@react-stately/overlays";

interface ResultPopoverProps {
    exercise: Exercise;
}

const ResultPopover = ({ exercise }: ResultPopoverProps) => {
    const intl = useIntl();
    const popoverState = useOverlayTriggerState({})
    //Refs
    const buttonRef = useRef<HTMLButtonElement>(null);
    //Functions
    const getTimeSpent = () => {
        let seconds = intl.formatMessage({
            id: "seconds-short",
            defaultMessage: "sec",
        });
        let minutes = intl.formatMessage({
            id: "minutes-short",
            defaultMessage: "min",
        });
        let hours = intl.formatMessage({
            id: "hours-short",
            defaultMessage: "h",
        });
        switch (true) {
            case exercise.timeSpent < 60:
                return moment(exercise.timeSpent * 1000).format(
                    `ss[${seconds}]`
                );
            case exercise.timeSpent >= 60 && exercise.timeSpent < 3600:
                return moment(exercise.timeSpent * 1000).format(
                    `mm[${minutes}] ss[${seconds}]`
                );
            case exercise.timeSpent >= 3600:
                return moment(exercise.timeSpent * 1000).format(
                    `hh[${hours}] mm[${minutes}] ss[${seconds}]`
                );
        }
    };
    return (
        <>
            <button
                ref={buttonRef}
                className="result-popover__button"
                onClick={() => {
                    popoverState.open()
                }}
            />
            {popoverState.isOpen && (
                <Popover
                    triggerRef={buttonRef}
                    state={popoverState}
                    placement="top"
                >
                    <div role="dialog" className="popover-container">
                        <div
                            className={`popover__message ${exercise.success.isCorrect
                                ? "popover__correct"
                                : "popover__incorrect"
                                }`}
                        >
                            <span className="popover__result">
                                {exercise.success.isCorrect ? (
                                    <>
                                        {intl.formatMessage({
                                            id: "endPlaylist-exerciseCorrect",
                                            defaultMessage: "Correct answer",
                                        })}
                                        <Icon
                                            size="small"
                                            path="done"
                                            color="var(--true)"
                                        />
                                    </>
                                ) : (
                                    <>
                                        {intl.formatMessage({
                                            id: "endPlaylist-exerciseIncorrect",
                                            defaultMessage: "Incorrect answer",
                                        })}
                                        <Icon
                                            size="small"
                                            path="close"
                                            color="var(--false)"
                                        />
                                    </>
                                )}
                            </span>
                            <span className="popover__timespent">
                                <span>
                                    {intl.formatMessage({
                                        id: "timeSpent",
                                        defaultMessage: "Time spent",
                                    })}
                                </span>
                                <span>{getTimeSpent()} </span>
                            </span>
                        </div>
                    </div>
                </Popover>
            )}
        </>
    );
};

export default ResultPopover;
