import react, { useContext, useEffect, useState } from "react";
import { UseAIStatus } from "./useAI";
import { PerModule } from "@evidenceb/gameplay-interfaces";
import { AILoadingInfo } from "@evidenceb/ai-handler";
import { AIWhisperer } from "@evidenceb/ai-handler";
import { dataStore } from "../contexts/DataContext";
import { sessionStore } from "../contexts/SessionContext";
import useBanditManchot from "./useBanditManchot";
import { getModuleById } from "../utils/dataRetrieval";
import { byModuleStatementsFilter } from "../utils/statements";
import { Statement } from "@xapi/xapi";
interface Modules {
    [moduleId: string]: Module;
}
interface Module {
    progression: number;
    averageScore: number;
    isModuleFinished: boolean;
}
export interface SoloModule {
    id: string;
    name: string;
    objectivesNb: number;
    progression: number;
    hasProgression: boolean;
    disabled?: boolean;
}
const useSoloModuleList = (filter?: (soloModule: SoloModule) => boolean) => {
    const { data } = useContext(dataStore);
    const {
        session: { banditManchot, statements },
    } = useContext(sessionStore);
    const bmInfo = useBanditManchot();
    const [soloModules, setSoloModules] = useState<SoloModule[]>([]);
    
    useEffect(() => {
        if (soloModules.length > 0) return;
        const modules = getSoloModules(bmInfo, banditManchot);
        if (!modules) return;
        const soloMods = Object.entries(modules).map(([moduleId, soloModule]) => {
            let module = getModuleById(moduleId, data);
            return {
                id: module.id,
                name: module.title.short ?? module.title.long,
                objectivesNb: module.objectiveIds.length,
                progression: soloModule.progression,
                hasProgression: hasProgression(statements, moduleId)
            };
        });
        if (soloMods.length) setSoloModules(soloMods);
    }, [banditManchot, bmInfo, data, soloModules.length, statements]);

    return filter ? soloModules.filter(filter) : soloModules;
};

export default useSoloModuleList;

const getSoloModules = (
    bmInfo: UseAIStatus<PerModule<AILoadingInfo>>,
    banditManchot: PerModule<AILoadingInfo> | undefined
): Modules | undefined => {
    if (bmInfo.status !== "success" || !banditManchot) return;
    const diag: Modules = {};
    Object.keys(bmInfo.aiLoadingInfo).forEach((moduleId) => {
        const singleBM = bmInfo.aiLoadingInfo[moduleId];
        if (singleBM.error) return;

        const basicDiag = AIWhisperer.getStudentBasicDiagnostic(
            singleBM.instance
        );
        const { isModuleFinished } = AIWhisperer.getNextHierarchyId(
            singleBM.instance
        );
        diag[moduleId] = {
            ...basicDiag,
            isModuleFinished: isModuleFinished ?? false,
        };
    });
    return diag;
};

const hasProgression = (statements: Statement[] | undefined, moduleId: string): boolean => {
    if(!statements) return false
    return statements.some(byModuleStatementsFilter(moduleId))
}
