import React from 'react';
import cn from 'classnames'
import './Tabs.scss';

import { AriaTabListProps, useTab, useTabList, useTabPanel } from '@react-aria/tabs';
import { TabListProps, TabListState, useTabListState } from 'react-stately';
import { Node } from "@react-types/shared"

interface TabsProps {
    className?: string
    /**
     * @default "round"
     */
    appearance?: "round" | "rectangular"
}

const Tabs = ({
    className,
    appearance = "rectangular",
    ...props
}: TabsProps & TabListProps<any> & AriaTabListProps<any>) => {
  let state = useTabListState(props);
  let ref = React.useRef<HTMLDivElement>(null);
  let { tabListProps } = useTabList(props, state, ref);
  return (
    <div className={cn(
      "ds-tabs",
      `ds-tabs--${appearance}`,
      className)}
    >
      <div {...tabListProps} ref={ref}>
        {[...state.collection].map((item) => (
          <Tab
            key={item.key}
            item={item}
            state={state}
            orientation={props.orientation}
          />
        ))}
      </div>
      <TabPanel key={state.selectedItem?.key} state={state} />
    </div>
  );
}

interface TabProps {
    item: Node<any>
    state: TabListState<any>
    orientation: AriaTabListProps<any>['orientation']

}

function Tab({ item, state }: TabProps) {
  let { key, rendered } = item;
  let ref = React.useRef<HTMLDivElement>(null);
  let { tabProps } = useTab({ key }, state, ref);
  return (
    <div {...tabProps} ref={ref}>
      {rendered}
    </div>
  );
}

interface TabPanelProps {
    state: TabProps['state']
}

function TabPanel({ state, ...props }: TabPanelProps) {
  let ref = React.useRef<HTMLDivElement>(null);
  let { tabPanelProps } = useTabPanel(props, state, ref);
  return (
    <div {...tabPanelProps} ref={ref}>
      {state.selectedItem?.props.children}
    </div>
  );
}

export default Tabs