import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { configStore } from '../../../contexts/ConfigContext'
import { DismissableMessages, sessionStore } from '../../../contexts/SessionContext'
import RemovableBanner from '../../../design-system-components/RemovableBanner/RemovableBanner'

import './SpecimenBanner.scss'

const SpecimenBanner = () => {
    const { config } = useContext(configStore)
    const { session: {dismissMessages} } = useContext(sessionStore)

    if (!dismissMessages.specimenGetFullVersionBanner)
        return (
            <RemovableBanner
                messageKey={DismissableMessages.specimenGetFullVersionBanner}
            >
                <FormattedMessage
                    id="specimen-banner-text"
                    defaultMessage="You are using the specimen of {appName}. To access the full version, do not hesitate to contact us {contact_link}."
                    values={{
                        appName: config.client_name,
                        contact_link: (
                            <a
                                href={config.features.specimen?.getFullVersionLink}
                                target="_blank"
                                rel="noreferrer"
                                className='specimen-banner__link'
                            >
                                <FormattedMessage
                                    id="specimen-banner-link-text"
                                    defaultMessage="here"
                                />
                            </a>
                        )
                    }}
                />
            </RemovableBanner>
        )
    return <></>
}

export default SpecimenBanner