import React from 'react'
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import cn from 'classnames'
import Icon from '../../../../design-system-components/Icon/Icon';
import { getModuleActionText, ModuleState } from '../utils';
import blazon from "../../../../assets/images/blazon.svg";

interface CardActionProps {
    /**
     * module can be undefined only if inactive is true
     */
    module?: {
        id: string,
        state: ModuleState
    }
    /**
     * @default false
     */
    inactive?: boolean
}

const CardAction = ({
    module,
    inactive
}: CardActionProps) => {
    const intl = useIntl()
    const history = useHistory()

    if (module && module.state !== ModuleState.Completed)
        return (
            <button
                className="card__action card__button"
                onClick={() => {
                    history.push("/player/" + module.id);
                }}
            >
                <div
                    className={cn("button__icon", {
                        "--in-progress":
                            module.state === ModuleState.InProgress,
                    })}
                >
                    <Icon path="arrow_drop_right" size="large" />
                </div>
                <span className="button__label">
                    {getModuleActionText(module.state, intl)}
                </span>
            </button>
        )
    if (module && module.state === ModuleState.Completed)
        return (
            <div className="card__action">
                <img className="card__blazon" src={blazon} alt="" />
                < span > {getModuleActionText(module.state, intl)}</span >
            </div >
        )
    return (
        <div className="card__action card__button card__button--disabled" >
            <div className="button__icon">
                <Icon path="arrow_drop_right" size="large" />
            </div>
            <span className="button__label">
                {getModuleActionText(ModuleState.New, intl)}
            </span>
        </div>
    )
}

export default CardAction