import React, { useState } from "react";
import cn from "classnames";
import { defineMessages, useIntl } from "react-intl";
import Icon from "../Icon/Icon";
import VisuallyHidden from "../../components/VisuallyHidden/VisuallyHidden";

import "./Input.scss";

interface CustomProps {
    allowReveal?: boolean;
    error?: string | boolean;
    label?: string;
}
export type InputProps = CustomProps &
    React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ type = "text", allowReveal, error, label, ...props }, ref) => {
        const intl = useIntl();
        const [revealPwd, setRevealPwd] = useState<boolean>(false);
        const [isFocused, setIsFocused] = useState<boolean>(false);
        const [value, setValue] = useState<string>("");

        return (
            <div className="ds-input">
                <div
                    className={cn("ds-input__input-container", {
                        "--focused": isFocused,
                        "--disabled": props.disabled,
                        "--error": error,
                        "--empty": !value,
                        "ds-input__input-container--has-button": allowReveal,
                        "ds-input__input-container--has-label": label,
                        "ds-input__input-container--has-placeholder":
                            props.placeholder,
                    })}
                >
                    {label && <label htmlFor="ds-input__input">{label}</label>}

                    <input
                        {...props}
                        id={cn(props.id, "ds-input__input")}
                        ref={ref}
                        type={revealPwd ? "text" : type}
                        onFocus={(e) => {
                            if (props.onFocus) props.onFocus(e);
                            setIsFocused(true);
                        }}
                        onBlur={(e) => {
                            if (props.onBlur) props.onBlur(e);
                            setIsFocused(false);
                        }}
                        onChange={(e) => {
                            if (props.onChange) props.onChange(e);
                            setValue(e.target.value);
                        }}
                    />

                    {allowReveal && (
                        <button
                            type="button"
                            className="ds-input__reveal-pwd-button"
                            onClick={() => setRevealPwd(curr => !curr)}
                        >
                            <Icon path="eye" size="small" />
                            <VisuallyHidden>
                                {revealPwd
                                    ? intl.formatMessage(messages.passwordHide)
                                    : intl.formatMessage(messages.passwordReveal)}
                            </VisuallyHidden>
                        </button>
                    )}
                </div>

                {error && typeof error === "string" && (
                    <span role="alert" className="ds-input__error-msg">
                        <Icon
                            className="ds-input__error-icon"
                            path="warning_outline"
                        />
                        {error}
                    </span>
                )}
            </div>
        );
    }
);

const messages = defineMessages({
    passwordHide: {
        id: "passwordHide",
        defaultMessage: "Hide password",
    },
    passwordReveal: {
        id: "passwordReveal",
        defaultMessage: "Show password",
    }
})

export default Input;
