import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import parse from "html-react-parser";
import { AIType } from "@evidenceb/ai-handler";
import { Data } from "../../../../interfaces/Data";
import {
    LevelData,
    ParentLevelData,
    PRLevel,
    PRLockStatus,
} from "../../../../interfaces/PedagogicalResourcesManagement";
import { dataStore } from "../../../../contexts/DataContext";
import { configStore } from "../../../../contexts/ConfigContext";
import { UsePRManagerReturn } from "../usePRManager";
import { UsePRConfirmationReturn } from "../usePRConfirmation";
import {
    areAllLevelItemsUnlocked,
    getAllLevelItemsToToggle,
} from "../../../../utils/prm";
import { getGraph } from "../../../../utils/ai";
import {
    getActivityById,
    getModuleById,
    getObjectiveById,
} from "../../../../utils/dataRetrieval";
import Icon from "../../../../design-system-components/Icon/Icon";
import Toggle from "../../../../components/Toggle/Toggle";
import VisuallyHidden from "../../../../components/VisuallyHidden/VisuallyHidden";

import "./BatchControls.scss";

interface Props {
    levelData: LevelData;
    setLevelData: React.Dispatch<React.SetStateAction<LevelData>>;
    prLockStatus: PRLockStatus;
    toggleItems: UsePRManagerReturn["toggleItems"];
    confirmToggleAttempt: UsePRConfirmationReturn["confirm"];
}

const BatchControls = ({
    levelData,
    setLevelData,
    prLockStatus,
    toggleItems,
    confirmToggleAttempt,
}: Props) => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const intl = useIntl();

    return (
        <div
            className={`batch-controls ${
                levelData.parentLevel ? "batch-controls--has-parent" : ""
            }`}
        >
            {levelData.parentLevel && (
                <button
                    className="batch-controls__back"
                    onClick={() => {
                        setLevelData(
                            levelData.parentLevel
                                ? levelData.parentLevel
                                : {
                                      level: PRLevel.Modules,
                                      prPool: data.modules,
                                  }
                        );

                        // Reset confirmation
                        if (confirmToggleAttempt) confirmToggleAttempt(false);
                    }}
                >
                    <Icon
                        path="arrow_right"
                        className="batch-controls__back-icon"
                        size={16}
                    />
                    <VisuallyHidden>
                        {levelData.level === PRLevel.Activities && (
                            <FormattedMessage
                                id="prm-goBackToObjectives"
                                defaultMessage="Go back to the list of objectives"
                            />
                        )}
                        {levelData.level === PRLevel.Objectives && (
                            <FormattedMessage
                                id="prm-goBackToModules"
                                defaultMessage="Go back to the list of modules"
                            />
                        )}
                    </VisuallyHidden>
                </button>
            )}

            <div className="batch-controls__content">
                {levelData.parentLevel && (
                    <>
                        <span className="content__title">
                            {getLevelTitle(levelData.parentLevel, data)}
                        </span>
                        <span className="content__separator"></span>
                        <span className="content__list-title">
                            {parse(levelData.parentLevel.listTitle)}
                        </span>
                    </>
                )}

                <div className="control__unlock-all">
                    <span aria-hidden="true">
                        <FormattedMessage
                            id="prm-manager-unlockAll"
                            defaultMessage="unlock all resources"
                        />
                    </span>
                    <Toggle
                        isSelected={areAllLevelItemsUnlocked(
                            levelData,
                            prLockStatus
                        )}
                        onChange={() => {
                            const graph =
                                levelData.level !== PRLevel.Modules
                                    ? getGraph(
                                          config.ai,
                                          data,
                                          levelData.parentLevel!.moduleId
                                      )
                                    : {};
                            const itemsToToggle = getAllLevelItemsToToggle(
                                levelData,
                                graph,
                                data,
                                prLockStatus,
                                config.ai[AIType.BanditManchot]
                            );
                            toggleItems(itemsToToggle);
                        }}
                        accessibleLabel={intl.formatMessage({
                            id: "prm-manager-unlockAll",
                            defaultMessage: "unlock all resources",
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

const getLevelTitle = (levelData: ParentLevelData, data: Data): string => {
    switch (levelData.level) {
        case PRLevel.Modules:
            const mod = getModuleById(levelData.id, data);
            return mod.title.short!;
        case PRLevel.Objectives:
            const obj = getObjectiveById(levelData.id, data);
            return obj.title.short!;
        case PRLevel.Activities:
            const act = getActivityById(levelData.id, data);
            return act.title.short!;
    }
};

export default BatchControls;
