import React, { useContext, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import {
    ClassroomAnalytics,
    ModuleDashboard,
} from "../../../../../../interfaces/Dashboard";
import { UserType } from "../../../../../../interfaces/User";
import { Data } from "../../../../../../interfaces/Data";
import {
    PRLevel,
    PRLockStatus,
} from "../../../../../../interfaces/PedagogicalResourcesManagement";
import { sessionStore } from "../../../../../../contexts/SessionContext";
import { dataStore } from "../../../../../../contexts/DataContext";
import { configStore } from "../../../../../../contexts/ConfigContext";
import { dashboardStore } from "../../../../../../contexts/DashboardContext";
import useAthenaAPIClient from "../../../../../../hooks/useAthenaAPIClient";
import {
    checkPRLockStatusIntegrity,
    getModuleItemsToToggle,
    updatePRLockStatus,
} from "../../../../../../utils/prm";
import { getModuleById } from "../../../../../../utils/dataRetrieval";
import ModuleCard from "../ModuleCard/ModuleCard";
import lockedIcon from "./locked-icon.svg";
import { FormattedMessage } from "react-intl";
import { Config } from "../../../../../../interfaces/Config";
import { AIType } from "@evidenceb/ai-handler";

interface Props {
    classroom: ClassroomAnalytics;
}

const ModulesList = ({ classroom }: Props): JSX.Element => {
    const {
        session: { userId },
    } = useContext(sessionStore);
    const { dashboard } = useContext(dashboardStore);
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const [lockStatus, setLockStatus] = useState<PRLockStatus>();
    const athenaAPIClient = useAthenaAPIClient();

    // Get locked status
    useEffect(() => {
        (async () => {
            try {
                const allClassesLockStatus =
                    await athenaAPIClient.getResourcesLockStatus(
                        userId,
                        UserType.Teacher
                    );
                setLockStatus(checkPRLockStatusIntegrity(allClassesLockStatus[classroom.id], data, config.ai));
            } catch (err) {
                Sentry.captureException(err);
                // TODO add error handling
            }
        })();
    }, [userId, classroom.id, athenaAPIClient, config.ai, data]);

    const { unlockedModules, lockedModules } = useMemo<{
        lockedModules: ModuleDashboard[];
        unlockedModules: ModuleDashboard[];
    }>(() => {
        if (!classroom || !lockStatus)
            return { lockedModules: [], unlockedModules: [] };

        const lockedModules: ModuleDashboard[] = [];
        const unlockedModules: ModuleDashboard[] = [];
        classroom.modulesList.forEach((dashMod) => {
            const mod = getModuleById(dashMod.id, data);
            if (lockStatus.moduleIds.includes(mod.id))
                lockedModules.push(dashMod);
            else unlockedModules.push(dashMod);
        });

        return {
            lockedModules,
            unlockedModules,
        };
    }, [classroom, lockStatus, data]);

    return (
        <>
        
            <div className="active-modules">
                { unlockedModules.map((mod, i) => (
                    <ModuleCard
                        key={"tabs-module" + i}
                        module={mod}
                        classroom={classroom}
                        clustering={dashboard?.clustering}
                        type="unlocked"
                    />
                )) }
            </div>
                   
            <>
                {unlockedModules.length === 0 ? (
                    <>
                        <p className="no-active-modules__title">
                            <FormattedMessage id="singleClass-noModuleActivatedTitle" defaultMessage="There are no activates modules" />
                        </p>
                        <p className="inactive-modules__description">
                            <FormattedMessage id="singleClass-noModuleActivatedDescription" defaultMessage="You can decide to limit access to the contents of the modules by deactivating them. You can reactivate them later. To manage the content of the modules in detail, click on 'Manage modules'." />
                        </p>
                    </>
                ) : (
                    <>
                        <div className="separator">
                            <div className="separator__strike-through"></div>
                            <div className="separator__title">
                                <FormattedMessage id="singleClass-separatorTitle" defaultMessage="Deactivated modules" />
                            </div>
                            <img
                                src={lockedIcon}
                                alt=""
                                className="separator__icon"
                            />
                        </div>

                        <p className="inactive-modules__description">
                            <FormattedMessage id="singleClass-inactiveModuleDescription" defaultMessage="You can decide to limit access to the contents of the modules by deactivating them. You can reactivate them later. To manage the content of the modules in detail, click on 'Manage modules'." />
                        </p>
                    </>
                )}

                <div className="inactive-modules">
                    {lockedModules.map((mod, i) => (
                        <ModuleCard
                            key={"tabs-module" + i}
                            module={mod}
                            classroom={classroom}
                            clustering={dashboard!.clustering}
                            type="locked"
                            activateModule={async () => {
                                try {
                                    const updatedLockStatus =
                                        getNewLockStatus(
                                            lockStatus!,
                                            mod.id,
                                            data,
                                            config.ai[AIType.BanditManchot]
                                        );
                                    await athenaAPIClient.updateResourcesLockStatus(
                                        classroom.id,
                                        updatedLockStatus
                                    );
                                    setLockStatus(updatedLockStatus);
                                } catch (err) {
                                    Sentry.captureException(err);
                                }
                            }}
                        />
                    ))}
                </div>
            </>
            
        </>
    );
};

const getNewLockStatus = (
    currentLockStatus: PRLockStatus,
    moduleToActivate: string,
    data: Data,
    aiConfig: Config["ai"][AIType.BanditManchot]
): PRLockStatus => {
    const itemsToToggle = getModuleItemsToToggle(
        moduleToActivate,
        currentLockStatus,
        data,
        aiConfig
    );
    return updatePRLockStatus(currentLockStatus, [
        ...itemsToToggle,
        { id: moduleToActivate, level: PRLevel.Modules },
    ]);
};

export default ModulesList;
