import React, { Fragment, useContext } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import cn from "classnames";
import { dataStore } from "../../../contexts/DataContext";
import { adaptiveTestsStore } from "../../../contexts/AdaptiveTestsContext";
import { configStore } from "../../../contexts/ConfigContext";
import CardContainer from "../../../design-system-components/CardContainer/CardContainer";
import ModuleCard from "../../../design-system-components/ModuleCard/ModuleCard";
import Icon from "../../../design-system-components/Icon/Icon";
import Button from "../../../design-system-components/Button/Button";
import {
    TestProgressBar,
    OngoingProgressBar,
    ErrorProgressBar,
} from "../Components/TestProgessBar";
import {
    TestMetadata,
    Recommendations,
} from "../../../interfaces/AdaptiveTests";
import { PerObjective } from "@evidenceb/gameplay-interfaces";
import {
    newTestButton,
    resumeTestButton,
    firstTestButton,
    resultsButton,
} from "../CONSTANTS";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { parse } from "@evidenceb/athena-common";
import htmlHandler from "../../../utils/html-handler";

import "./AdaptiveTestModuleList.scss";
import { Image } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../hooks/useAssetsDetails";

const AdaptiveTestsModuleList = () => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const history = useHistory();
    const { adaptiveTests } = useContext(adaptiveTestsStore);
    let { path } = useRouteMatch();
    const intl = useIntl();
    const assetsDetails = useAssetsDetails();

    return (
        <div className="adaptive-tests-dashboard">
            <h3>{intl.formatMessage(messages.title)}</h3>
            <div className="introduction">
                <Image
                    src={config.logos.avatar}
                    className="avatar-img"
                    alt=""
                    assetsDetails={assetsDetails}
                />
                <p>{parse(intl.formatMessage(messages.intro, htmlHandler))}</p>
            </div>
            <CardContainer>
                {data.modules.map((module) => {
                    const mainTest = adaptiveTests.mainTest?.[module.id];
                    const testButtonContent = getPlayButton(mainTest);
                    return (
                        <Fragment key={module.id}>
                            <ModuleCard
                                moduleTitle={{
                                    as: "h4",
                                    content: module.title.short ?? "",
                                }}
                                className={cn("adaptive-test-db__card", {
                                    "no-test": !mainTest,
                                })}
                            >
                                <div
                                    className={
                                        mainTest ? "" : "no-test-pastille"
                                    }
                                />
                                {displayProgressBar(
                                    mainTest,
                                    "desktop",
                                    config.features.dislayEstimateInterval!
                                )}
                                <div className="icon-text recommendations">
                                    <Icon
                                        className="icon-text__icon"
                                        path={
                                            Object.keys(
                                                adaptiveTests.recommendations
                                            ).includes(module.id) &&
                                            Object.keys(
                                                adaptiveTests.recommendations[
                                                    module.id
                                                ]
                                            ).length > 0
                                                ? "colored_letter"
                                                : "gray_letter"
                                        }
                                        size="small"
                                    ></Icon>
                                    <span className="icon-text__text">
                                        {Object.keys(
                                            adaptiveTests.recommendations
                                        ).includes(module.id)
                                            ? getRecommendationsText(
                                                  adaptiveTests.recommendations[
                                                      module.id
                                                  ],
                                                  intl
                                              )
                                            : intl.formatMessage(
                                                  messages.noRecommendations
                                              )}
                                    </span>
                                </div>
                                <Button
                                    className="module-btn"
                                    variant={"primary"}
                                    disabled={mainTest?.error}
                                    {...testButtonContent(intl)}
                                    onClick={() => {
                                        history.push(
                                            "/adaptive-test/" + module.id
                                        );
                                    }}
                                />
                                <Button
                                    className="module-btn"
                                    variant={"secondary"}
                                    {...resultsButton(intl)}
                                    disabled={mainTest ? mainTest!.error : true}
                                    disabledStyle="hard"
                                    onClick={() => {
                                        history.push(
                                            `${path}/test-result/${module.id}`
                                        );
                                    }}
                                />
                            </ModuleCard>

                            <ModuleCard className="mobile-module-card">
                                <div className="mobile-module-card__main">
                                    <div className="mobile-module-card__title">
                                        Test -{" "}
                                        {module.title.short
                                            ? module.title.short
                                            : ""}
                                    </div>
                                    {displayProgressBar(
                                        mainTest,
                                        "mobile",
                                        config.features.dislayEstimateInterval!
                                    )}
                                </div>
                                <Icon
                                    path="chevron_right"
                                    size="small"
                                    color="black"
                                    style={{ margin: "auto" }}
                                    onClick={() => {
                                        !mainTest?.error &&
                                            history.push(
                                                `${path}/test-result/${module.id}`
                                            );
                                    }}
                                />
                            </ModuleCard>
                        </Fragment>
                    );
                })}
            </CardContainer>
        </div>
    );
};

export default AdaptiveTestsModuleList;

const displayProgressBar = (
    mainTest: TestMetadata | undefined,
    device: "desktop" | "mobile" = "desktop",
    displayEstimateInterval: boolean
) => {
    return (
        <>
            {mainTest?.error && <ErrorProgressBar />}

            {mainTest && mainTest?.expirationDate && !mainTest?.error && (
                <OngoingProgressBar startDate={mainTest!.startDate} />
            )}

            {!mainTest?.expirationDate && !mainTest?.error && (
                <TestProgressBar
                    testResult={
                        mainTest?.diagnosis ? mainTest.diagnosis : undefined
                    }
                    device={device}
                    className={
                        device === "mobile"
                            ? "mobile-module-card__progress-bar"
                            : ""
                    }
                    displayEstimateInterval={displayEstimateInterval}
                />
            )}
        </>
    );
};

const getPlayButton = (displayedTest: TestMetadata | undefined) => {
    if (!displayedTest) return firstTestButton;
    if (displayedTest.expirationDate) return resumeTestButton;
    return newTestButton;
};

const getRecommendationsText = (
    recommendations: PerObjective<Recommendations>,
    intl: IntlShape
) => {
    const recommendationsCount = Object.keys(recommendations).reduce(
        (previous, current) => {
            return previous + recommendations[current].links.length;
        },
        0
    );
    if (recommendationsCount > 1)
        return (
            recommendationsCount +
            " " +
            intl.formatMessage({
                id: "learning-recommendations",
                defaultMessage: "Learning recommendations",
            })
        );
    return (
        recommendationsCount +
        " " +
        intl.formatMessage({
            id: "learning-recommendation",
            defaultMessage: "Learning recommendation",
        })
    );
};

const messages = defineMessages({
    title: {
        id: "adaptive-test-dashboard-title",
        defaultMessage: "Evalutation",
    },
    intro: {
        id: "html-adaptive-test-dashboard-introduction",
        defaultMessage:
            "Test your knowledge and get personalized learning recommendations",
    },
    noRecommendations: {
        id: "no-recommendations",
        defaultMessage: "No recommendations",
    },
});
