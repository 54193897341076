import React, { useContext } from "react";
import { useIntl } from "react-intl";
import Case from "case";
import {
    PRLevelIds,
    PRLockStatus,
} from "../../../../../interfaces/PedagogicalResourcesManagement";
import { dataStore } from "../../../../../contexts/DataContext";
import { commonMessages } from "../../../../../utils/messages";
import { getObjectiveById } from "../../../../../utils/dataRetrieval";
import dashboardMessages from "../../../dashboardMessages";
import LockStatusLabel from "../../../../../components/LockStatusLabel/LockStatusLabel";

import "./ObjectiveListLine.scss";
import { TooltipWrapper } from "../../../../../design-system-components/ToolTip/ToolTip";

interface Props {
    objectives: string[];
    prLockStatus?: PRLockStatus;
    moduleId: string;
    testResultColumn?: boolean;
}

const ObjectiveListLine = ({
    objectives,
    prLockStatus,
    moduleId,
    testResultColumn = false,
}: Props) => {
    const { data } = useContext(dataStore);
    const intl = useIntl();
    return (
        <div className="tr objective-list-line">
            {testResultColumn && (
                <div className="th">
                    <div className="objective-list-line__title-container">
                        <span>
                            {intl.formatMessage(dashboardMessages.diagTest)}
                        </span>
                    </div>
                </div>
            )}

            {objectives.map((objectiveId, i) => (
                <div className="th" key={"objective-" + i}>
                    <div className="objective-list-line__title-container">
                        <TooltipWrapper
                            wrapper="button"
                            tooltip={
                                <>
                                    {`${Case.pascal(
                                        intl
                                            .formatMessage(
                                                commonMessages.objectiveShort
                                            )
                                            .charAt(0)
                                    )}${i + 1}. ${getObjectiveById(objectiveId, data)
                                            .title.short
                                        }`}
                                    {prLockStatus &&
                                        prLockStatus[
                                            PRLevelIds.ObjectiveIds
                                        ].includes(objectiveId) && (
                                            <LockStatusLabel
                                                style={{
                                                    marginLeft: "8px",
                                                    position: "relative",
                                                    top: "-2px",
                                                }}
                                                locked={true}
                                            />
                                        )}
                                </>
                            }
                            tooltipClassname={"objective-list-line__title-container__tooltip"}
                        >
                            <span className="objective-list-line__title">
                                {intl.formatMessage(
                                    commonMessages.objectiveShort
                                )}{" "}
                                {i + 1}
                            </span>
                            {prLockStatus &&
                                !prLockStatus[
                                    PRLevelIds.ModuleIds
                                ].includes(moduleId) &&
                                prLockStatus[
                                    PRLevelIds.ObjectiveIds
                                ].includes(objectiveId) && (
                                    <LockStatusLabel
                                        locked={true}
                                        as="div"
                                    />
                                )}
                        </TooltipWrapper>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ObjectiveListLine;
