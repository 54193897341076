import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import ControlledDialog from "../../../design-system-components/ControlledDialog/ControlledDialog";

interface Props {
    setModal: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
    confirmButtonRef: React.RefObject<HTMLButtonElement>;
    url: string;
}

const FailureModal = ({
    setModal,
    confirmButtonRef,
    url,
}: Props): JSX.Element => {
    const history = useHistory();
    const intl = useIntl();

    return (
        <ControlledDialog
            title={intl.formatMessage({
                id: "prm-manager-errorModal-title",
                defaultMessage: "Changes not saved",
            })}
            description={{
                $html: intl.formatMessage({
                    id: "prm-manager-errorModal-description",
                    defaultMessage:
                        "Your changes weren't saved. Try again later.",
                }),
            }}
            primaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-errorModal-primaryButton",
                    defaultMessage: "Try again",
                }),
                fn: () => {
                    setModal(null);
                    confirmButtonRef.current?.click();
                },
            }}
            secondaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-errorModal-secondaryButton",
                    defaultMessage: "Leave without saving",
                }),
                fn: () => {
                    history.push(url);
                },
            }}
        />
    );
};

export default FailureModal;
