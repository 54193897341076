import React from "react";
import { FormattedMessage } from "react-intl";
import cn from "classnames";
import MaterialIcon from "../../../../components/MaterialIcon/MaterialIcon";
import VisuallyHidden from "../../../../components/VisuallyHidden/VisuallyHidden";
import { PlayerHeaderProps } from "../../PlayerBuilder";
import SafeguardedLink from "../Components/SafeguardedLink/SafeguardedLink";

import "./BreadcrumbsHeader.scss";

const BreadcrumbsHeader = ({
    playlistManager: {
        playlist: { module, activity, objective },
    },
    opts,
    className,
}: PlayerHeaderProps) => {
    return (
        <header className={cn("breadcrumbs-header", className)}>
            <ul className="player-header__breadcrumbs">
                <li>{module.title.short}</li>
                <li>{objective?.title.short}</li>
                <li>{activity?.title.short}</li>
            </ul>

            <SafeguardedLink
                to="/"
                className="player-header__exit player-header__leave-link"
                opts={opts}
            >
                <MaterialIcon>close</MaterialIcon>
                <VisuallyHidden>
                    <FormattedMessage
                        id="errors-statements-cannotRetrieveHistory-goToHomepageButton"
                        defaultMessage="Go to homepage"
                    />
                </VisuallyHidden>
            </SafeguardedLink>
        </header>
    );
};

export default BreadcrumbsHeader;
