import { merge } from 'lodash';
import React, { useContext, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { errorI18n } from '../pages/Error/errorI18n';
import { isLocal } from '../utils/env';
import { configStore } from './ConfigContext';
import { errorStore } from './ErrorContext';

interface LangProviderProps {
    children: React.ReactNode
}

const LangProvider = ({
    children
}: LangProviderProps) => {
    const { config } = useContext(configStore);
    const { setErrorInfo } = useContext(errorStore);
    const [messages, setMessages] = useState<any>();

    // Load i18n
    useEffect(() => {
        if (!config.lang) return;

        document.documentElement.setAttribute("lang", config.lang);

        (async () => {
            try {
                const baseMessages = await import(`../../i18n/${config.lang}.json`);
                let variationMessages = {}
                try {
                    variationMessages = await import(`../../i18n/${config.declinaison.replace("/", "-")}.json`);
                } catch { }
                setMessages(merge({}, baseMessages, variationMessages));
            } catch {
                setErrorInfo(curr => ({ ...curr, page: errorI18n.find(page => page.code === "i18n") }))
            }
        })();
    }, [config.lang, setErrorInfo, config.declinaison]);

    const handleError = isLocal(window.location.hostname) ? () => {} : undefined;

    return (
        <IntlProvider locale={config.lang ?? ""} defaultLocale="eng" messages={messages} onError={handleError}>
            {children}
        </IntlProvider>
    )
}

export default LangProvider