import React, { useContext, useMemo } from "react";
import { Title } from "../../interfaces/Config";
import { Helmet } from "react-helmet-async";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { configStore } from "../../contexts/ConfigContext";
import "./Work.scss";
import { sessionStore } from "../../contexts/SessionContext";
import { modeStaticConfig } from "./WorkModeStaticConfig";
import HomeworkButton from "./HomeworkButton/HomeworkButton";
import WorkModes from "./WorkMode/WorkModes";

const Work = ({ title }: Title) => {
    const intl = useIntl();
    const { config } = useContext(configStore);
    const {
        session: { userType },
    } = useContext(sessionStore);

    const workModesConfig = useMemo(
        () =>
            config.workModesConfig?.filter((mode) => {
                const modeData = modeStaticConfig.find(
                    (modeData) => mode.type === modeData.type
                );
                if (
                    !modeData ||
                    (modeData.userType &&
                        !modeData.userType?.includes(userType))
                )
                    return false;
                return true;
            }),
        [config.workModesConfig, userType]
    );

    return (
        <article className="work">
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <header>
                <h1>{intl.formatMessage(messages.workPageTitle)}</h1>
                <div className="work__info">
                    <p>{intl.formatMessage(messages.workPageCatchPhrase)}</p>
                </div>
            </header>
            <WorkModes
                intl={intl}
                pages={config.pages}
                userType={userType}
                workModes={workModesConfig!}
            />
            <HomeworkButton label={intl.formatMessage(messages.homework)} />
        </article>
    );
};

export default Work;

const messages = defineMessages({
    workPageTitle: {
        id: "workMode-title",
        defaultMessage: "My Work",
    },
    workPageCatchPhrase: {
        id: "workMode-catchphrase",
        defaultMessage: "Choose your mode of work !",
    },
    homework: {
        id: "homework",
        defaultMessage: "homework",
    },
});
