import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ModuleDashboard } from "../../../../../interfaces/Dashboard";
import { dataStore } from "../../../../../contexts/DataContext";
import { getModuleById, getResourceIndex, getUrl } from "../../../../../utils/dataRetrieval";
import LockStatusLabel from "../../../../../components/LockStatusLabel/LockStatusLabel";
import { FormattedMessage, useIntl } from "react-intl";
import { UserType } from "../../../../../interfaces/User";
import { configStore } from "../../../../../contexts/ConfigContext";
import { PRLockStatus } from "../../../../../interfaces/PedagogicalResourcesManagement";

interface Props {
    module: ModuleDashboard;
    classroomId: string;
    studentId: string;
    prLockStatus: PRLockStatus | undefined;
}

interface ProgressBarStyle {
    backgroundColor: string;
    border: string;
}

const ModuleTitleInfo = ({ module, classroomId, studentId, prLockStatus }: Props) => {
    const { data } = useContext(dataStore);
    const {config} = useContext(configStore);
    const history = useHistory();
    const intl = useIntl();

    const moduleProgression = Number(( module.students[studentId].moduleProgression * 100 ).toFixed(1));
    const completionState = moduleProgression < 100 ? "inProgress" : "completed";
    const lockState = prLockStatus?.moduleIds.includes(module.id) ? "locked" : "unlocked";
    
    return (
        <TitleContainer
            onClick={() =>
                history.push(
                    `/${getUrl(config.pages.find(page => page.type === "DASHBOARD")!, UserType.Teacher) as string}/${intl.formatMessage({id: "dashboard-paths-classes", defaultMessage: "classes"})}/${intl.formatMessage({id: "dashboard-paths-progression", defaultMessage: "progression"})}/${classroomId}/${module.id}`
                )
            }
        >
            <FieldTitle>
                {getResourceIndex(module.id, data.modules) + 1 + ". " + getModuleById(module.id, data).title.short}
            </FieldTitle>

            {lockState !== "unlocked" && (
                <LockStatusLabel locked={true} />
            )}

            <ProgressBarContainer style={progressBarStyle(completionState, lockState)}>
                <ProgressBar percentage={moduleProgression} style={progressBarStyle(completionState, lockState)}>
                    <ProgressBarPercentage>{`${moduleProgression || 0}%`}</ProgressBarPercentage>
                </ProgressBar>
                <ClassAverage
                    average={Number((module.progression * 100).toFixed(1)) || 0}
                ></ClassAverage>
                <ClassAverageInfo>
                    <FormattedMessage id="dashboard-studentDetails-classAverage" defaultMessage="Class average" />
                    {" "}{Number((module.progression * 100).toFixed(1)) || 0}%
                </ClassAverageInfo>
            </ProgressBarContainer>
        </TitleContainer>
    );
};

const progressBarStyle = (completionState: string, lockState: string | undefined) => {
    let backgroundColor = "none";
    let border = "none";
    if(completionState === "inProgress"){
        if(lockState !== "locked"){
            backgroundColor = "var(--objective-inProgress);"
            border= "2px dashed var(--objective-inProgress);"  
        }else{
            backgroundColor = "var(--objective-disabled-inProgress);"
            border= "2px dashed var(--objective-disabled-inProgress);" 
        }
    }
    if(completionState === "completed"){
        if(lockState !== "locked"){
            backgroundColor = "var(--objective-completed);"
            border= "2px solid var(--objective-completed);"  
        }else{
            backgroundColor = "var(--objective-disabled-completed);"
            border= "2px solid var(--objective-disabled-completed);"  
        }
    }        
    return {
        backgroundColor, border
    }
};


const FieldTitle = styled.h5`
    font-size: 1.2em;
    padding: 0 4px;
    align-self: flex-start;
`;

const ProgressBarContainer = styled.div<{ style: ProgressBarStyle }>`
border: ${props => props.style.border};
box-sizing: border-box;
border-radius: 8px;
background: #fff;
width: 70%;
height: 20px;
margin: 8px 0;
position: relative;
`;
const ClassAverage = styled.div<{ average: number }>`
position: absolute;
left: ${(props) => props.average + "%"};
top: 0;
height: 30px;
border: 1px dashed #555;
`;

const ClassAverageInfo = styled.div`
padding: 16px 0 0 0;
font-size: 0.8em;
`;

//
const ProgressBar = styled.div<{percentage: number, style: ProgressBarStyle}>`
    height: 100%;
    width: ${(props) => (props.percentage || 0) + "%"};
    background: ${props => props.style.backgroundColor}
    position: relative;
`;

const ProgressBarPercentage = styled.div`
    font-size: 0.8em;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
`;

const TitleContainer = styled.div`
    width: 200px;
    margin: 0px 8px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    align-self: center;
`;

export default ModuleTitleInfo;
