import React, {
    DetailedHTMLProps,
    forwardRef,
    HTMLAttributes,
    useEffect,
    useMemo,
} from "react";
import { IntlShape, useIntl } from "react-intl";
import cn from "classnames";
import {
    HtmlString,
    PlayerEventEmitter,
} from "@evidenceb/gameplay-interfaces";
import {
    AssetsDetails,
    ContentBlock,
    Emitter,
    Instruction,
    isHtmlStringInstruction,
    MiniAudioPlayer,
    parse
} from "@evidenceb/athena-common";
import useAssetsDetails from "../../../../../hooks/useAssetsDetails";

import "./WizardInstruction.scss";

interface Props
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    instruction: Instruction;
    /** @default false */
    feedbackPlaceholderAnimation?: boolean;
    playerEventEmitter?: PlayerEventEmitter;
}

const WizardInstruction = forwardRef<HTMLDivElement, Props>(
    (
        {
            instruction,
            feedbackPlaceholderAnimation,
            playerEventEmitter,
            ...props
        },
        ref
    ) => {
        const intl = useIntl();
        const assetsDetails = useAssetsDetails();

        // Stop playing tts when user interacts with gameplay
        const stopEmitter = useMemo(() => new Emitter(), []);
        useEffect(() => {
            const cb = () => {
                stopEmitter.emit();
            };
            playerEventEmitter?.subscribe("GAMEPLAY_INTERACTION", cb);
            return () => {
                playerEventEmitter?.unsubscribe(cb);
            };
        }, [playerEventEmitter, stopEmitter]);

        return (
            <div
                ref={ref}
                {...props}
                className={cn("wizard-instruction", props.className)}
            >
                {isHtmlStringInstruction(instruction) ? (
                    <HTMLStringInstruction
                        instruction={instruction}
                        stopEmitter={stopEmitter}
                        assetsDetails={assetsDetails}
                        intl={intl}
                    />
                ) : (
                    <ContentBlock
                        className="wizard-instruction__content-block"
                        data={instruction}
                        stopEmitter={stopEmitter}
                        assetsDetails={assetsDetails}
                        /* @ts-ignore TMP remove when node & React migration is done */
                        intl={intl}
                    />
                )}

                <div
                    className={cn("wizard-instruction__feedback-placeholder", {
                        "--animate-feedback": feedbackPlaceholderAnimation,
                    })}
                />
            </div>
        );
    }
);

const HTMLStringInstruction = ({
    instruction,
    stopEmitter,
    assetsDetails,
    intl,
}: {
    instruction: HtmlString;
    stopEmitter: Emitter;
    assetsDetails: AssetsDetails;
    intl: IntlShape;
}) => {
    return (
        <>
            {!instruction.$html && instruction.audioUrl ? (
                <div className="wizard-instruction__content wizard-instruction__content--audio-only">
                    <label htmlFor="audio-instruction">
                        {instruction.audioTitle}
                    </label>
                    <MiniAudioPlayer
                        id="audio-instruction"
                        src={instruction.audioUrl}
                        assetsDetails={assetsDetails}
                        intl={intl}
                    />
                </div>
            ) : (
                <div className="wizard-instruction__content">
                    {instruction.audioUrl && (
                        <MiniAudioPlayer
                            stopEmitter={stopEmitter}
                            src={instruction.audioUrl}
                            assetsDetails={assetsDetails}
                            aria-hidden
                            intl={intl}
                        />
                    )}
                    <p>{parse(instruction.$html)}</p>
                </div>
            )}
        </>
    );
};

export default WizardInstruction;
