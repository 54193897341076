import React, { useContext, Fragment, useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import appConfig from "../../config";
import { AdaptiveTestsProvider } from "../../contexts/AdaptiveTestsContext";
// Pages
import Home from "../Home/Home/Home";
import HomeEllaStudent from "../Home/HomeEllaStudent/HomeEllaStudent";
import ModuleList from "../ModuleList/ModuleList";
import DashboardPage from "../DashBoard/Dashboard";
import ContentPage from "../ContentPage/ContentPage";
import Error from "../Error/Error";
import ModuleListStudent from "../ModuleListStudent/ModuleListStudent";

// Components
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Dev from "../../components/Dev/Dev";

import { configStore } from "../../contexts/ConfigContext";
import { sessionStore } from "../../contexts/SessionContext";
import { errorStore } from "../../contexts/ErrorContext";

import useInitApp from "../../hooks/useInitApp";
import { getLabel, getUrl } from "../../utils/dataRetrieval";
import { LoaderStatus } from "../../interfaces/Status";
import PedagogicalResourcesManagement from "../PedagogicalResourcesManagement/PedagogicalResourcesManagement";
import { UserType } from "../../interfaces/User";

import PlayerBuilder from "../PlayerBuilder/PlayerBuilder";
import { makeLoggedInStatement } from "../../utils/statement-builder";
import useAthenaAPIClient from "../../hooks/useAthenaAPIClient";
import pRetry from "p-retry";
import AdaptiveTestsDashboardRouter from "../AdaptiveTestsDashboard/AdaptiveTestDashboardRouter";
import Authentication from "../Authentication/Authentication";
import { AuthMode } from "../../interfaces/Config";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
//SCSS
import "./Root.scss";
import LangProvider from "../../contexts/LangContext";
import WorkInProgressHome from "../Home/WorkInProgressHome/WorkInProgressHome";
import { useFeatureFlag } from "@evidenceb/athena-common";
import SoloModuleList from "../SoloModuleList/SoloModuleList";
import Work from "../Work/Work";

function Root() {
    const { config } = useContext(configStore);
    const { errorInfo } = useContext(errorStore);
    const { status, setStatus } = useInitApp();
    const {
        session: { userType, flags, evidencebId, sessionId },
    } = useContext(sessionStore);
    const [displayHeader, setDisplayHeader] = useState<boolean>(true);
    const athenaAPIClient = useAthenaAPIClient();
    const adsecNavigation = useFeatureFlag("adsecNavigation");
    useEffect(() => {
        setDisplayHeader(!flags.displaySignIn);
    }, [flags.displaySignIn]);

    useEffect(() => {
        if (errorInfo.page) setStatus(LoaderStatus.Error);
    }, [errorInfo, setStatus]);

    // Send log in statement
    useEffect(() => {
        if (
            status !== LoaderStatus.Success ||
            !config.features?.additionalStatements?.studentLogIn
        )
            return;
        const statement = makeLoggedInStatement(
            config.declinaison,
            evidencebId,
            sessionId
        );
        pRetry(
            () => {
                return athenaAPIClient.postStatement(statement);
            },
            { retries: 5 }
        );
    }, [
        status,
        config.declinaison,
        evidencebId,
        athenaAPIClient,
        config.features,
        sessionId,
    ]);

    return (
        <LangProvider>
            <Router basename={appConfig.basePath}>
                {status === LoaderStatus.Success ? (
                    <>
                        <Dev />
                        <Switch>
                            <Route
                                path="/auth/"
                                children={<Authentication />}
                            />
                            {config.auth.mode === AuthMode.Authentication && (
                                <Redirect to={"/auth/"} />
                            )}
                            <Route
                                path="/player/:moduleId/:objectiveId?"
                                children={<PlayerBuilder />}
                            />
                            <Route
                                path="/play/:exerciseId"
                                children={<PlayerBuilder />}
                            />
                            <Route
                                path="/adaptive-test/:moduleId"
                                children={<PlayerBuilder />}
                            />
                            <Route path="/error" children={<Error />} />

                            {/* TEMP: Use the page system when we're ready to ammend config.jsons */}
                            {userType === UserType.Teacher && (
                                <Route
                                    path="/prm/:classroomId"
                                    children={
                                        <PedagogicalResourcesManagement
                                            title={"PRM"}
                                        />
                                    }
                                />
                            )}
                            <Fragment>
                                <div className="content">
                                    {displayHeader && (
                                        <Header
                                            logo={config.logos.header_client}
                                            pages={config.pages}
                                        />
                                    )}

                                    {config.pages.map((page, i) => {
                                        if (
                                            !page.authorized?.includes(
                                                userType
                                            ) &&
                                            !page.fallback
                                        )
                                            return null;

                                        switch (page.type) {
                                            case "HOME":
                                                return (
                                                    <Route
                                                        key={`route-${i}`}
                                                        path={`/${getUrl(
                                                            page,
                                                            userType
                                                        )}`}
                                                        exact
                                                    >
                                                        {userType ===
                                                            UserType.Student &&
                                                            page.fallback
                                                                ?.student && (
                                                                <Redirect
                                                                    to={`/${page.fallback.student}`}
                                                                />
                                                            )}
                                                        {userType ===
                                                            UserType.Teacher &&
                                                            page.fallback
                                                                ?.teacher && (
                                                                <Redirect
                                                                    to={`/${page.fallback.teacher}`}
                                                                />
                                                            )}

                                                        {!page.fallback &&
                                                        userType ===
                                                            UserType.Student ? (
                                                            adsecNavigation ? (
                                                                <WorkInProgressHome />
                                                            ) : (
                                                                <HomeEllaStudent
                                                                    title={`${
                                                                        config.client_name
                                                                    } - ${getLabel(
                                                                        page,
                                                                        userType
                                                                    )}`}
                                                                />
                                                            )
                                                        ) : (
                                                            <Home
                                                                title={`${
                                                                    config.client_name
                                                                } - ${getLabel(
                                                                    page,
                                                                    userType
                                                                )}`}
                                                            />
                                                        )}
                                                    </Route>
                                                );

                                            case "MODULELIST":
                                                return (
                                                    <Route
                                                        key={`route-${i}`}
                                                        path={`/${getUrl(
                                                            page,
                                                            userType
                                                        )}`}
                                                    >
                                                        {config.features
                                                            .cnedModuleList &&
                                                            userType ===
                                                                UserType.Student && (
                                                                <AdaptiveTestsProvider>
                                                                    <AdaptiveTestsDashboardRouter />
                                                                </AdaptiveTestsProvider>
                                                            )}

                                                        {!config.features
                                                            .cnedModuleList &&
                                                            !adsecNavigation &&
                                                            userType ===
                                                                UserType.Student && (
                                                                <ModuleListStudent
                                                                    title={`${
                                                                        config.client_name
                                                                    } - ${getLabel(
                                                                        page,
                                                                        userType
                                                                    )}`}
                                                                />
                                                            )}
                                                        {!config.features
                                                            .cnedModuleList &&
                                                            adsecNavigation &&
                                                            userType ===
                                                                UserType.Student && (
                                                                <SoloModuleList />
                                                            )}

                                                        {userType ===
                                                            UserType.Teacher && (
                                                            <ModuleList
                                                                title={`${
                                                                    config.client_name
                                                                } - ${getLabel(
                                                                    page,
                                                                    userType
                                                                )}`}
                                                            />
                                                        )}
                                                    </Route>
                                                );
                                            case "DASHBOARD":
                                                if (
                                                    userType !==
                                                    UserType.Teacher
                                                )
                                                    return null;
                                                return (
                                                    <Route
                                                        key={`route-${i}`}
                                                        path={`/${getUrl(
                                                            page,
                                                            userType
                                                        )}`}
                                                    >
                                                        <DashboardPage
                                                            title={`${
                                                                config.client_name
                                                            } - ${getLabel(
                                                                page,
                                                                userType
                                                            )}`}
                                                        />
                                                    </Route>
                                                );

                                            case "WORK_MODES":
                                                if (
                                                    userType !==
                                                    UserType.Student
                                                )
                                                    return null;
                                                return (
                                                    <Route
                                                        key={`route-${i}`}
                                                        path={`/${getUrl(
                                                            page,
                                                            userType
                                                        )}`}
                                                    >
                                                        <Work
                                                            title={`${
                                                                config.client_name
                                                            } - ${getLabel(
                                                                page,
                                                                userType
                                                            )}`}
                                                        />
                                                    </Route>
                                                );

                                            case "CONTENTPAGE":
                                                return (
                                                    <Route
                                                        key={`route-${i}`}
                                                        path={`/${getUrl(
                                                            page,
                                                            userType
                                                        )}`}
                                                    >
                                                        <ContentPage
                                                            title={`${
                                                                config.client_name
                                                            } - ${getLabel(
                                                                page,
                                                                userType
                                                            )}`}
                                                        />
                                                    </Route>
                                                );

                                            default:
                                                return null;
                                        }
                                    })}
                                </div>

                                <Footer
                                    logos={config.logos}
                                    pages={config.pages}
                                    contact={config.contact}
                                />
                            </Fragment>
                        </Switch>
                    </>
                ) : status === LoaderStatus.Error ? (
                    errorInfo.page && <Error />
                ) : (
                    <Loader />
                )}
                {/** Init Error modal */}
                <ErrorModal />
            </Router>
        </LangProvider>
    );
}

export default Root;
