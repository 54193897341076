import React from 'react';
// Components
import Icon from '../../../design-system-components/Icon/Icon';
// SCSS
import './Error.scss';
// Interfaces
interface ErrorProps {
    error: string;
}
const Error = ({error}:ErrorProps) => {
    return (
        <div className="auth-error">
            <Icon path="warning_outline" /> <span>{error}</span>
        </div>
    )
}

export default Error