import React, { useContext } from "react";
import { Link } from "../../../../interfaces/AdaptiveTests";
import ToggleDiv from "../../../../design-system-components/ToggleDiv/ToggleDiv";
import { configStore } from "../../../../contexts/ConfigContext";
import { sessionStore } from "../../../../contexts/SessionContext";
import pRetry from "p-retry";
import useAthenaAPIClient from "../../../../hooks/useAthenaAPIClient";
import { makeRecommendationsClickStatement } from "../../../../utils/statement-builder";
import { FormattedMessage, useIntl } from "react-intl";

interface ToggleRecommendationsProps {
    recommendations: Link[];
    /**
     * @default true
     */
    showFirst?: boolean;
    moduleId: string;
}

const ToggleRecommendations = ({
    recommendations,
    moduleId
}: ToggleRecommendationsProps) => {
    const {
        config: { declinaison, features },
    } = useContext(configStore);
    const {
        session: { evidencebId, sessionId },
    } = useContext(sessionStore);
    const athenaAPIClient = useAthenaAPIClient();

    if (recommendations.length === 0)
        return (
            <li>
                <FormattedMessage
                    id="no-recommendations"
                    defaultMessage="No recommendations"
                />
            </li>
        );

    return (
        <>
            {recommendations.map((link, index) => (
                <li key={`reco-${index}`}>
                    <a
                        href={link.link}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            if (
                                !features?.additionalStatements
                                    ?.recommendationsClick
                            )
                                return;

                            const statement = makeRecommendationsClickStatement(
                                declinaison,
                                evidencebId,
                                link.link,
                                sessionId,
                                moduleId
                            );
                            pRetry(
                                () => {
                                    return athenaAPIClient.postStatement(
                                        statement
                                    );
                                },
                                { retries: 5 }
                            );
                        }}
                    >
                        <span>
                            {link.description.length
                                ? link.description
                                : link.link}
                        </span>
                    </a>

                    <span className="recommendation__link-detail">
                        {link.detail}
                    </span>
                </li>
            ))}
        </>
    );
};

interface ToggleRecommendationsDivProps {
    recommendations: Link[];
    /**
     * @default  "open"
     */
    defaultState: "open" | "close";
    moduleId: string;
}

const ToggleRecommendationsDiv = ({
    recommendations,
    defaultState = "open",
    moduleId
}: ToggleRecommendationsDivProps) => {
    const intl = useIntl();

    return (
        <ToggleDiv
            defaultState={"open"}
            label={intl.formatMessage({
                id: "learning-recommendations",
                defaultMessage: "Learning recommendations",
            })}
        >
            {
                <ul className="recommendations__details">
                    <ToggleRecommendations recommendations={recommendations} moduleId={moduleId} />
                </ul>
            }
        </ToggleDiv>
    );
};

export default ToggleRecommendationsDiv;
