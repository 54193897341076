import React, { useContext } from "react";
import { configStore } from "../../../../../contexts/ConfigContext";
import "./ChatBubble.scss";
import cx from "classnames";
import { Image } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../../../hooks/useAssetsDetails";

type ChatBubbleProps = {
    direction: "right" | "left";
    borderColor?: string;
    botAvatar?: "visible" | "hidden";
    className?: string;
    color?: string;
    fullWidth?: boolean;
    halfWidth?: boolean;
    marginBottom?: boolean;
    textColor?: string;
    children: React.ReactNode;
};

export const ChatBubble = ({
    borderColor,
    botAvatar,
    children,
    className,
    color,
    direction,
    fullWidth = false,
    halfWidth = false,
    marginBottom = true,
    textColor,
}: ChatBubbleProps) => {

    const { config } = useContext(configStore);
    const assetsDetails = useAssetsDetails();

    return (
        <div
            className={cx(
                `bubble-container animated animatedFadeInUp fadeInUp ${direction}`,
                className,
                {
                    "full-width": fullWidth,
                    "half-width": halfWidth,
                    "no-margin-bottom": !marginBottom,
                    "with-bot-avatar": botAvatar === "visible",
                }
            )}
        >
            {botAvatar ? (
                botAvatar === "visible" ? (
                    <Image src={config.logos.avatar} alt="" assetsDetails={assetsDetails} />
                ) : (
                    <div className="chatbot-placeholder"></div>
                )
            ) : null}
            <div
                className="chat-bubble"
                style={{
                    backgroundColor: color ?? "",
                    borderColor: borderColor,
                    borderStyle: borderColor ? "solid" : undefined,
                    color: textColor,
                }}
            >
                {children}
            </div>
        </div>
    );
};
export default ChatBubble;
