import React, { useContext } from "react";
//External modules
import { useHistory, useParams } from "react-router-dom";
//Contexts
import { dataStore } from "../../../../contexts/DataContext";
import { configStore } from "../../../../contexts/ConfigContext";
import { dashboardStore } from "../../../../contexts/DashboardContext";
//Components
import Button from "../../../../components/Button/Button";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
//Utils
import { getModuleById, getResourceIndex, getUrl } from "../../../../utils/dataRetrieval";
//SCSS
import "./ClassroomsNav.scss";
//Interfaces
import { ParamTypes, ClassroomAnalytics } from "../../../../interfaces/Dashboard";
import LockStatusLabel from "../../../../components/LockStatusLabel/LockStatusLabel";
import { useIntl } from "react-intl";
import { UserType } from "../../../../interfaces/User";
import { PRLevelIds, PRLockStatus } from "../../../../interfaces/PedagogicalResourcesManagement";
interface ProgressionNavProps {
    classrooms: ClassroomAnalytics[];
    classroom: ClassroomAnalytics;
    prLockStatus?: PRLockStatus
}

const ClassroomsNav = ({
    classrooms,
    classroom,
    prLockStatus,
}: ProgressionNavProps) => {
    //Contexts
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const { dashboard } = useContext(dashboardStore);
    //Hooks
    const history = useHistory();
    const { moduleId, classroomId } = useParams<ParamTypes>();
    const intl = useIntl();
    //Functions
    const viewGroups = () => {
        history.push(
            `/${getUrl(config.pages.find(page => page.type === "DASHBOARD")!, UserType.Teacher) as string}/${intl.formatMessage({id: "dashboard-paths-classes", defaultMessage: "classes"})}/${classroomId}/${moduleId}/${intl.formatMessage({id: "dashboard-paths-groups", defaultMessage: "groups"})}`
        );
    };

    const selectClassroom = (value: string) => {
        let url = `/${getUrl(config.pages.find(page => page.type === "DASHBOARD")!, UserType.Teacher) as string}/${
            intl.formatMessage({id: "dashboard-paths-classes", defaultMessage: "classes"})
        }/${intl.formatMessage({id: "dashboard-paths-progression", defaultMessage: "progression"})}/${value}/${moduleId}`;
        history.push(url);
    };

    const selectModule = (value: string) => {
        let url = `/${getUrl(config.pages.find(page => page.type === "DASHBOARD")!, UserType.Teacher) as string}/${
            intl.formatMessage({id: "dashboard-paths-classes", defaultMessage: "classes"})
        }/${intl.formatMessage({id: "dashboard-paths-progression", defaultMessage: "progression"})}/${classroomId}/${value}`;
        history.push(url);
    };
    
    const isClustering = () => {
        if (config.features.clustering) {
            if (
                dashboard?.clustering &&
                dashboard.clustering[classroomId] &&
                dashboard.clustering[classroomId][moduleId] &&
                typeof dashboard.clustering[classroomId][moduleId].error ===
                    "undefined"
            ) {
                return true;
            }
            return false;
        }
        return false;
    };

    return (
        <div className="classrooms-navigation">
            <CustomSelect
                label={intl.formatMessage({id: "dashboard-common-classes", defaultMessage: "Classes"})}
                onSelectOption={(value) => {
                    selectClassroom(value);
                }}
                selectedOption={classroom.name}
            >
                {classrooms.map(
                    (classroom, i) =>
                        classroom.studentsNumber > 0 &&
                        classroom.modulesList.length > 0 && (
                            <option key={"class-" + i} value={classroom.id}>
                                {intl.formatMessage({id: "dashboard-common-class", defaultMessage: "Class"})} {classroom.name}
                            </option>
                        )
                )}
            </CustomSelect>

            <CustomSelect
                label={intl.formatMessage({id: "dashboard-common-modules", defaultMessage: "Modules"})}
                onSelectOption={(value) => {
                    selectModule(value);
                }}
                selectedOption={
                    <div className="selected-module__wrapper">
                        {`${getResourceIndex(moduleId, data.modules) + 1}. ${getModuleById(moduleId, data).title.short!}`}
                        {prLockStatus && prLockStatus[PRLevelIds.ModuleIds].includes(moduleId) && (
                            <LockStatusLabel locked={true} />
                        )}
                    </div>
                }
            >
                {classroom.modulesList.map((module, i) => (
                    <option key={"module-" + i} value={module.id}>
                        {`${i+1}. ${getModuleById(module.id, data).title.short}`}
                        {prLockStatus && prLockStatus[PRLevelIds.ModuleIds].includes(module.id) &&
                            ` (${intl.formatMessage({id: "prm-manager-listItem-deactivated", defaultMessage: "locked"}).toLowerCase()})`}
                    </option>
                ))}
            </CustomSelect>

            {isClustering() ? (
                <Button
                    type={"primary"}
                    label={
                        intl.formatMessage({id: "dashboard-progressionOverview-seeGroupsOfSelection", defaultMessage: "Show groups of selection"})
                    }
                    icons={[
                        { placement: "left", code: "timeline", noCircle: true },
                        {
                            placement: "right",
                            code: "arrow_forward",
                            noCircle: true,
                        },
                    ]}
                    onClick={viewGroups}
                />
            ) : (
                <Button
                    type={"primary"}
                    disabled={true}
                    label={
                        intl.formatMessage({id: "dashboard-progressionOverview-noClusteringGenerated", defaultMessage: "No clusters were generated"})
                    }
                    icons={[
                        { placement: "left", code: "timeline", noCircle: true },
                    ]}
                />
            )}
        </div>
    );
};

export default ClassroomsNav;
