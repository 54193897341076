import React from "react";
// SCSS
import "./Card.scss";
// Interfaces
export interface CardProps {
    cardTitle?: {
        as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div";
        content: string | JSX.Element;
    };
}
const Card = ({
    cardTitle,
    children,
    className,
    ...props
}: CardProps &
    React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >) => {
    return (
        <article {...props} className={`ds-card ${className ?? ""}`}>
            {cardTitle && (
                <cardTitle.as className="roboto ds-card__card-title">
                    {cardTitle.content}
                </cardTitle.as>
            )}
            <div className="ds-card__card-content">{children}</div>
        </article>
    );
};

export default Card;
