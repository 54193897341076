import { useContext, useEffect, useState } from "react";
import merge from 'lodash.merge'
import { applyTheme } from "../utils/theme-handler";
import { shouldSignIn, removeNonVisible, tokenResolver, versionResolver, registerDebugUtils, applicationResolver, isSpecimenVersion, getSpecimenVariation, flattenBooleansInObject } from "../utils/init";
import { axiosClient } from "../utils/axios-client";
import * as localStorage from "../utils/localStorage";
import { getExercisesWithAvailableGameplays } from "../utils/fetch-gameplays";
import { v4 as uuid } from "uuid";

import chatbotTheme from "./chatbotTheme";

import { configStore } from "../contexts/ConfigContext";
import { contentPagesStore } from "../contexts/ContentPagesContext";
import { dataStore } from "../contexts/DataContext";
import { homeStore } from "../contexts/HomeContext";
import { sessionStore, StatementHistory } from "../contexts/SessionContext";
import { errorStore } from "../contexts/ErrorContext";

import { AuthMode, Config } from "../interfaces/Config";
import {LoaderStatus } from "../interfaces/Status";
import { TokenPayload, User, UserType } from "../interfaces/User";
import { RawData } from "../interfaces/Data";
import { Home } from "../interfaces/Home";
import { ContentPage } from "../interfaces/ContentPage";
import useAthenaAPIClient from "./useAthenaAPIClient";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { AIConfig, AIType } from "@evidenceb/ai-handler";
import { Theme } from "../interfaces/Theme";
import { activateCustomColors } from "../utils/custom-colors";
import axios from "axios";
import appConfig from "../config";
import useSelectApp from "./useSelectApp";
import { Exercise, featureFlagsStore, useFeatureFlags } from "@evidenceb/athena-common";
import { cacheStore } from "../contexts/CacheContext";
import { msConfigResolver } from "../utils/init";
import { isCacheStateInitialized } from "../contexts/infrastructure/cacheUtils";
export interface GlobalConfig {
    // TEMP
    config: Omit<Config, "ai"> & { ai: AIConfig };
    home: Home;
    contentPages: ContentPage[];
    theme: Theme;
}

export default function useInitApp() {

    const { data, setData } = useContext(dataStore);
    const { setHome } = useContext(homeStore);
    const { setContentPages } = useContext(contentPagesStore);
    const { config, setConfig } = useContext(configStore);
    const { session, setSession } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);
    const { setFeatureFlags } = useContext(featureFlagsStore);
    const [status, setStatus] = useState<LoaderStatus>();
    const athenaAPIClient = useAthenaAPIClient();
    const selectApp = useSelectApp();
    const { cacheState, dispatchCacheAction } = useContext(cacheStore);
    const flags = useFeatureFlags();

    useEffect(() => {
        //SENTRY INIT
        if (process.env.NODE_ENV !== "development")
            Sentry.init({
                dsn: "https://d6676273f92a44a49199264cc6cb98e3@sentry.evidenceb-services.com/4503924293894144",
                autoSessionTracking: true,
                integrations: [
                    new Integrations.BrowserTracing({
                        tracingOrigins: ["https://athena-auth", "https://athena-content-access", "https://analytics", "https://athena-analytics", "https://xapi"],
                    }),
                ],
                // We recommend adjusting this value in production, or using tracesSampler
                // for finer control
                tracesSampleRate: 1.0,
                environment: process.env.NODE_ENV,
            });

        (async () => {
            const apiUrls = await axiosClient.init();
            setConfig((config) => { return { ...config, apiUrls } });
        })();

    }, [setConfig]);

    useEffect(() => {
        if (isSpecimenVersion()) {
            (async function initSpecimen() {
                const variation = await getSpecimenVariation()
                athenaAPIClient.setSpecimenVariation(variation)
            })()
        }
    }, [athenaAPIClient])

    useEffect(() => {

        const setError = (code: string) => {
            setErrorInfo(err => {
                return {
                    ...err,
                    page: { code: code }
                }
            });
            setStatus(LoaderStatus.Error)
        }

        if (config.apiUrls.match === "" || typeof status !== "undefined") return;
        const isSpecimen = isSpecimenVersion()
        if (isSpecimen && !athenaAPIClient.specimenVariation) return;

        setStatus(LoaderStatus.Loading);

        (async function initApp() {
            // Token resolution
            let queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const urlToken = urlParams.get('token') as string;
            const localStorageToken = localStorage.getItem<string>(localStorage.Key.TOKEN);
            let token: string;
            let tokenPayload: TokenPayload;

            try {
                if (!isSpecimen) {
                    token = tokenResolver(urlToken, localStorageToken);
                    axiosClient.apiToken = token;
                    localStorage.setItem(localStorage.Key.TOKEN, token);
                }
                tokenPayload = await athenaAPIClient.getTokenPayload();
            } catch (error) {
                // 1st iteration of the "no token authentification", it will be reworked & improved at a later date when we recode the entire useInitApp
                // Check if app exists in applications.json and if it has a "AUTH_REQUIRED" authMode
                try {
                    const { data } = await axios.get(window.location.origin + (appConfig.basePath ?? "/") + "json/applications.json");
                    const { currentApp, config } = applicationResolver(data, window.location.host, window.location.search)
                    if (config.authMode === AuthMode.Authentication && (config.globalConfig || currentApp.globalConfig)) {
                        try {
                            await selectApp(currentApp, config);
                            setStatus(LoaderStatus.Success);
                            return
                        } catch (err) {
                            // Fail to load minimal_globalConfig.json
                            // todo: rework error handling 
                            setError("token")
                            console.error("ERROR: Fail to load minimal_globalConfig.json");
                            return
                        }
                    } else {
                        // app has no authMode = AUTH_REQUIRED
                        // todo: rework error handling 
                        setError("token")
                        if (!currentApp.globalConfig || !config.availableApps)
                            console.error("ERROR: no globalconfig path for app")
                        else
                            console.error("ERROR: app has no authMode = AUTH_REQUIRED");
                        return
                    }
                } catch (err) {
                    // Fail to load applications.json
                    // todo: rework error handling 
                    console.error(err);
                    setError("token")
                    return
                }
            };


            let useHistoryFrom = StatementHistory.LRS
            if (isSpecimen) {
                useHistoryFrom = StatementHistory.noHistory
            }

            // Version resolution
            const urlVersion = urlParams.get('version') as string;
            const localStorageVersion = localStorage.getItem<string>(localStorage.Key.VERSION);
            let version: string;
            try {
                version = versionResolver(tokenPayload, urlVersion, localStorageVersion)
                localStorage.setItem(localStorage.Key.VERSION, version)
            } catch (error) {
                version = '' // If error, version variable needs to be assigned here so it can be a string type
                setError("version")
                return
            }


            // Get user info
            let user: User;
            try {
                user = await athenaAPIClient.getUser(tokenPayload);
            } catch (error) {
                setStatus(LoaderStatus.Error)
                return
            }

            // Get GlobalConfig
            let globalConfig: GlobalConfig;
            try {
                globalConfig = await athenaAPIClient.getGlobalConfig(version);
                setConfig(config => {
                    return {
                        ...config,
                        lang: globalConfig.config.lang,
                    }
                })

            } catch (error) {
                setStatus(LoaderStatus.Error)
                return
            }

            // Resolve authentication
            let displaySignIn: boolean;
            try {
                displaySignIn = await shouldSignIn(user, globalConfig.config.auth.mode, tokenPayload.role.toUpperCase() as UserType, globalConfig.config.declinaison, athenaAPIClient.getClassroom.bind(athenaAPIClient));
                console.log(displaySignIn)
            } catch (error) {
                //TODO error details ?
            }

            // Get data
            let data: RawData, exercises: Exercise[]
            try {
                data = removeNonVisible(
                    await athenaAPIClient.getData(version)
                );
                console.log("DATA", data);
                exercises = await getExercisesWithAvailableGameplays(data.exercises);
            } catch (error) {
                setStatus(LoaderStatus.Error)
                return
            }

            // Register debug tools
            registerDebugUtils(data, athenaAPIClient);

            setSession(session => {
                return {
                    ...session,
                    version,
                    userId: user.id,
                    firstname: user.first_name,
                    userProvider: user.provider,
                    appProvider: user.provider,
                    appVariation: globalConfig.config.declinaison,
                    userType: tokenPayload.role.toUpperCase() as UserType,
                    evidencebId: tokenPayload.sub,
                    sessionId: uuid(),
                    flags: {
                        ...session.flags,
                        useHistoryFrom,
                        displaySignIn: displaySignIn
                    },
                    fragments: user.config.fragments_config ?? [],
                    specimen: isSpecimen,
                }
            })

            // TEMP
            const tempGlobalconfig: Omit<GlobalConfig, "config"> & { config: Config } = {
                ...globalConfig,
                config: {
                    ...globalConfig.config,
                    ai: {
                        [AIType.BanditManchot]: globalConfig.config.ai,
                        [AIType.AdaptiveTest]: {
                            ...globalConfig.config.ai,
                            id: "catsimulation"
                        },
                    }
                }
            }

            setConfig(config => merge({}, config, tempGlobalconfig.config))

            // Feature flags
            const mergedFlags = {...tempGlobalconfig.config.features, ...tempGlobalconfig.config.releaseFlags} as { [key: string]: any }
            mergedFlags.isSpecimen = isSpecimen
            setFeatureFlags(flattenBooleansInObject(mergedFlags));   

            setHome(globalConfig.home)
            setContentPages(globalConfig.contentPages)
            applyTheme(
                { ...globalConfig.theme, chatbot: chatbotTheme }, globalConfig.config.features.customColors ?? false);
            if (globalConfig.config.features.customColors)
                activateCustomColors(globalConfig.theme.customColors ?? {});

            setData({
                modules: data.modules,
                objectives: data.objectives,
                activities: data.activities,
                exercises,
            });
            setStatus(LoaderStatus.Success);

        })();
    }, [selectApp, config.apiUrls, setConfig, setContentPages, setData, setHome, setSession, athenaAPIClient, setErrorInfo, status, athenaAPIClient.specimenVariation, dispatchCacheAction, setFeatureFlags])

    //Add session info to sentry reports
    useEffect(() => {
        const sessionInfo = {
            ...session,
            banditManchot: null, //TEMP: Exclude banditManchot so it doesn't break sentry
            initialHistory: null //TEMP: Exclude initialHistory so it doesn't break sentry
        }
        Sentry.setContext("Session", sessionInfo);
    }, [session])

    // Handle flags init effets
    useEffect(() => {
        if (status !== LoaderStatus.Success) return;

        if (flags.offlineFeatures && !isCacheStateInitialized(cacheState))
            dispatchCacheAction({
                type: "START_INIT",
                payload: {
                    data,
                    assetsUrl: config.apiUrls.endpoints.assetsProxy,
                    version: session.version
                },
            });
    }, [flags, data, config.apiUrls.endpoints.assetsProxy, session.version, status]);

    return { status, setStatus }
}
