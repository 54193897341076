import React from 'react'
import cn from "classnames"
import { Item } from 'react-stately'

//Components
import Icon, { IconProps } from '../../../../../../design-system-components/Icon/Icon';
//SCSS
import "./Settings.scss";
import VisuallyHidden from '../../../../../../components/VisuallyHidden/VisuallyHidden';
import { MenuButton } from '../../../../../../design-system-components/Menu/Menu';

//Interfaces
export interface Setting {
    label: string;
    icon?: IconProps;
    action: () => void;
    key: string;
}
interface SettingsProps {
    icon: IconProps;
    label?: string;
    hideLabel?: boolean;
    settings: Setting[],
    className?: string
}

const Settings = ({ icon, label, hideLabel, settings, className }: SettingsProps) => {

    return (
        <div className={cn("settings", className)}>
            <MenuButton
                buttonClassname="settings__menu-button"
                buttonContent={(
                    <>
                        <Icon {...icon} />
                        {
                            label && !hideLabel &&
                            <>
                                <span className="menu-button__label">{label}</span>
                                <span aria-hidden="true">▾</span>
                            </>
                        }
                        <VisuallyHidden>{label}</VisuallyHidden>
                    </>
                )}
                menuClassname={"settings-options"}
                onAction={(key) => {
                    const setting = settings.find(s => s.key === key)
                    if (setting)
                        setting.action()
                }}
            >
                {
                    settings.map(setting =>
                        <Item key={setting.key} >
                            {setting.label}
                            {setting.icon && <Icon className="settings__item-icon" {...setting.icon} />}
                        </Item>
                    )
                }
            </MenuButton>
        </div>
    )
}

export default Settings
