import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Icon from "../../../../../design-system-components/Icon/Icon";
import { TooltipWrapper } from "../../../../../design-system-components/ToolTip/ToolTip";

import "./Legend.scss";

const Legend = () => {
    const intl = useIntl()

    return (
        <div className="progression-graph-legend">
            <div className="progression-graph-legend__col">
                <div>
                    <span
                        className="progression-graph-legend__color-sample progression-graph-legend__color-sample--expected"
                        aria-hidden="true"
                    >
                        <span></span>
                    </span>
                    <span>
                        <FormattedMessage
                            id="dashboard-studentProgression-legend-successLevelExpected"
                            defaultMessage="Expected score"
                        />
                    </span>
                    <div className="progression-graph-legend__tooltip">
                        <TooltipWrapper
                            wrapper="button"
                            tooltip={intl.formatMessage({
                                id: "dashboard-studentProgression-legend-successLevelExpectedTooltip",
                                defaultMessage: "Calculated from previous student data in order to select the right exercise adapted to student level."
                            })}
                            aria-label={intl.formatMessage({
                                id: "dashboard-studentProgression-legend-successLevelExpectedTooltip",
                                defaultMessage: "Calculated from previous student data in order to select the right exercise adapted to student level."
                            })}
                        >
                            <Icon
                                path="question-circle"
                                size="small"
                            />
                        </TooltipWrapper>
                    </div>
                </div>
                <div>
                    <span
                        className="progression-graph-legend__color-sample progression-graph-legend__color-sample--success-level"
                        aria-hidden="true"
                    >
                        <span></span>
                    </span>
                    <span>
                        <FormattedMessage
                            id="dashboard-studentProgression-legend-successLevelStudent"
                            defaultMessage="Actual score"
                        />
                    </span>
                </div>
            </div>
            <div className="progression-graph-legend__col">
                <div>
                    <Icon size="medium" path="alert_sup" />
                    <span>
                        <FormattedMessage
                            id="dashboard-progressionOverview-legend-progressionAcceleration"
                            defaultMessage="Overcome difficulty"
                        />
                    </span>
                </div>
                <div>
                    <Icon size="medium" path="alert_inf" />
                    <span>
                        <FormattedMessage
                            id="dashboard-progressionOverview-legend-progressionRegression"
                            defaultMessage="Difficulty encountered"
                        />
                    </span>
                </div>
                <div>
                    <Icon size="medium" path="alert_conf" />
                    <span>
                        <FormattedMessage
                            id="dashboard-studentProgression-expectedSuccessRate"
                            defaultMessage="Actual score conform to the predicted score"
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Legend;
