import { HtmlString, Objective, VisibilityStatus } from "@evidenceb/gameplay-interfaces";
import React, { useContext, useState } from "react";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import "./ModuleListItem.scss";
import { useIntl } from "react-intl";
import { getModuleById, initialTestFilter } from "../../../utils/dataRetrieval";
import { configStore } from "../../../contexts/ConfigContext";
import { AIType } from "@evidenceb/ai-handler";
import Case from "case";
import Icon from "../../../design-system-components/Icon/Icon";
import { sessionStore } from "../../../contexts/SessionContext";
import CacheButton from "../../../components/CacheButton/CacheButton";
import { dataStore } from "../../../contexts/DataContext";

interface ModuleListItemProps {
    id: string;
    title: string;
    description: HtmlString;
    objectives: Objective[];
    visibilityStatus: VisibilityStatus
}

const ModuleListItem = ({
    id,
    title,
    description,
    objectives,
    visibilityStatus,
}: ModuleListItemProps) => {
    const { config: { ai } } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const intl = useIntl();
    const [showList, setShowList] = useState<boolean>(false);
    const history = useHistory();
    const objectiveIntl = Case.pascal(intl.formatMessage({ id: "hierarchy-objective-full", defaultMessage: "objective" }));
    const colon = intl.formatMessage({ id: "misc-colon", defaultMessage: ":" });
    const hasInitialTest = !!objectives.find(initialTestFilter(ai[AIType.BanditManchot].initialTest, "isInitialTest"));

    const handleModuleClick =
        visibilityStatus === VisibilityStatus.Visible ||
            visibilityStatus === VisibilityStatus.Unavailable
            ? () => setShowList((curr) => !curr)
            : undefined;

    return (
        <div className={cn(
            "module-list-item", {
            "opened": showList,
            "closed": !showList,
            "module-list-item--unavailable": visibilityStatus === VisibilityStatus.Unavailable,
        })}>
            <div className="header" onClick={handleModuleClick}>
                <button>
                    {visibilityStatus === VisibilityStatus.Visible &&
                        <span className="icon-container-btn">
                            <Icon path="chevron_down" size="large" />
                        </span>
                    }
                    {visibilityStatus === VisibilityStatus.Unavailable &&
                        <Icon className="module-list-item__padlock-icon" path="padlock" size="large" />
                    }
                </button>
                <div className="title">
                    <h2>{title}</h2>
                    <p className="module-list-item__description">
                        {parse(description.$html)}
                        {session.specimen && visibilityStatus === VisibilityStatus.Unavailable &&
                            <span className="module-list-item__out-of-specimen">
                                {intl.formatMessage({ id: "specimen-out-of-specimen", defaultMessage: "(out of specimen)" })}
                            </span>
                        }
                    </p>
                </div>
                <CacheButton cacheType="module" id={id} name={getModuleById(id, data).title.short ?? getModuleById(id, data).title.long} />
            </div>
            <ul>
                {objectives.map((objective, i) => (
                    <li
                        onClick={() => {
                            if (objective.visibilityStatus === VisibilityStatus.Visible)
                                history.push(`/player/${id}/${objective.id}`);
                        }}
                        key={`item-${i}`}
                        className={cn("module-list-item__objective", {
                            "module-list-item__objective--unavailable": objective.visibilityStatus === VisibilityStatus.Unavailable,
                        })}
                    >
                        {objective.visibilityStatus === VisibilityStatus.Visible &&
                            <span className="icon-container-btn">
                                <Icon path="arrow_right" color="white" />
                            </span>
                        }
                        {objective.visibilityStatus === VisibilityStatus.Unavailable &&
                            <Icon className="module-list-item__padlock-icon" path="padlock" size="medium" />
                        }
                        <span className="label">
                            {
                                hasInitialTest ?
                                    i > 0 && `${objectiveIntl} ${i}${colon} `
                                    : `${objectiveIntl} ${i + 1}${colon} `
                            }
                            {parse(objective.descriptions ? objective.descriptions.teacher.$html : objective.description.$html)}
                            {session.specimen && objective.visibilityStatus === VisibilityStatus.Unavailable &&
                                <span className="module-list-item__out-of-specimen">
                                    {intl.formatMessage({ id: "specimen-out-of-specimen", defaultMessage: "(out of specimen)" })}
                                </span>
                            }
                        </span>
                        <CacheButton cacheType="objective" id={objective.id} name={objective.title.short ?? objective.title.long} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ModuleListItem;
