export interface TokenPayload {
    app: string;
    branch: string;
    role: string;
    sub: string; // id
    version: string;
}
export interface User{
    groups: any[]
    id:string;
    external_id: string;
    provider: string;
    first_name: string;
    last_name: string;
    classrooms:string[];
    extra:string;
    variation?: string;
    config: {
        fragments_config: string[]
    };
}

export interface Student extends User{
     /**
     * Tag to tell the backend to create a code for the user when creating one
     */
    createCode?: boolean;
    code?: string;
}

export interface Classroom{
    id:string;
    external_id: string;
    provider: string;
    variation: string;
    name: string;
    level: string;
    school: string;
    students: Student[];
    teacher: string;
    lock_status: {
        moduleIds: string[];
        activityIds: string[];
        objectiveIds: string[];
    };
    extra: string;
    code: string;
}

export enum UserType{
    Teacher = "TEACHER",
    Student = "STUDENT"
}