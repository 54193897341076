import { PerObjective } from "@evidenceb/gameplay-interfaces";
import {
    ComputedDiagnosis,
    Diagnosis,
} from "../../../interfaces/AdaptiveTests";
import { Data } from "../../../interfaces/Data";
import {
    getExerciseHierarchy,
    getModuleById,
} from "../../../utils/dataRetrieval";

export const getModuleDiagnosis = (diagnosis: Diagnosis): ComputedDiagnosis => {
    // Legacy for when the per_objective key didn't exist, with
    // @evidenceb/adaptive-tests@1.2.0 and inferior
    if (
        !diagnosis.per_objective ||
        typeof Object.values(diagnosis.per_objective)[0] !== "object"
    )
        return {
            allEstimates: diagnosis,
            lowEstimate: getMean(Object.values(diagnosis.low_estimate)),
            highEstimate: getMean(Object.values(diagnosis.high_estimate)),
            meanEstimate: getMean(Object.values(diagnosis.mean_estimate)),
        };

    return {
        allEstimates: diagnosis,
        lowEstimate: getMean(
            Object.values(diagnosis.per_objective).map(
                (objDiag) => objDiag.low_estimate
            )
        ),
        highEstimate: getMean(
            Object.values(diagnosis.per_objective).map(
                (objDiag) => objDiag.high_estimate
            )
        ),
        meanEstimate: getMean(
            Object.values(diagnosis.per_objective).map(
                (objDiag) => objDiag.pondered
            )
        ),
    };
};

export const computeObjectiveEstimations = (
    data: Data,
    moduleDiagnosis: Diagnosis,
    moduleId: string
): PerObjective<ComputedDiagnosis> => {
    if (
        !moduleDiagnosis.per_objective ||
        typeof Object.values(moduleDiagnosis.per_objective)[0] !== "object"
    )
        return computeLegacyObjectiveEstimations(
            data,
            moduleDiagnosis,
            moduleId
        );

    return getModuleById(moduleId, data).objectiveIds.reduce(
        (diag, objId) => ({
            ...diag,
            [objId]: {
                lowEstimate: Math.round(
                    100 * moduleDiagnosis.per_objective![objId].low_estimate
                ),
                meanEstimate: Math.round(
                    100 * moduleDiagnosis.per_objective![objId].pondered
                ),
                highEstimate: Math.round(
                    100 * moduleDiagnosis.per_objective![objId].high_estimate
                ),
            },
        }),
        {} as PerObjective<ComputedDiagnosis>
    );
};

// Legacy for when the per_objective key didn't exist, with
// @evidenceb/adaptive-tests@1.2.0 and inferior
const computeLegacyObjectiveEstimations = (
    data: Data,
    moduleDiagnosis: Diagnosis,
    moduleId: string
) => {
    const testObjectiveDiagnosis: PerObjective<ComputedDiagnosis> = {};
    const objectiveEstimations: PerObjective<{
        low: number[];
        high: number[];
        mean: number[];
    }> = {};
    getModuleById(moduleId, data).objectiveIds.forEach((objectiveId) => {
        objectiveEstimations[objectiveId] = { low: [], high: [], mean: [] };
    });
    Object.keys(moduleDiagnosis.low_estimate).forEach((exercise) => {
        const objectiveId = getExerciseHierarchy(exercise, data).objective.id;
        objectiveEstimations[objectiveId].low.push(
            moduleDiagnosis.low_estimate[exercise]
        );
        objectiveEstimations[objectiveId].high.push(
            moduleDiagnosis.high_estimate[exercise]
        );
        objectiveEstimations[objectiveId].mean.push(
            moduleDiagnosis.mean_estimate[exercise]
        );
    });
    for (let objectiveId in objectiveEstimations) {
        const high = getMean(objectiveEstimations[objectiveId].high);
        const low = getMean(objectiveEstimations[objectiveId].low);
        const mean = getMean(objectiveEstimations[objectiveId].mean);
        testObjectiveDiagnosis[objectiveId] = {
            lowEstimate: low,
            highEstimate: high,
            meanEstimate: mean,
        };
    }
    return testObjectiveDiagnosis;
};

const getMean = (estimates: number[]): number => {
    return Math.round(
        (estimates.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
        ) /
            estimates.length) *
            100
    );
};
