import React from "react";
import { useIntl } from "react-intl";
import cn from "classnames";
import Icon from "../../../../design-system-components/Icon/Icon";
import { TooltipWrapper } from "../../../../design-system-components/ToolTip/ToolTip";

import "./ManagerListItem.scss";

type Element = JSX.Element | false | string | null;

export interface Props
    extends React.DetailedHTMLProps<
        React.LiHTMLAttributes<HTMLLIElement>,
        HTMLLIElement
    > {
    status:
    | "locked"
    | "unlocked"
    | "unlockDependency"
    | "lockDependency";
    /**
     * Displays the icon marking an activity as the ancestor of an objective
     * @default false
     */
    displayAncestorIcon?: boolean;
    children: Element | Element[];
    /** @default false */
    isClickable?: boolean;
    /** @default false */
    isModule?: boolean;
}

const ManagerListItem = ({
    status,
    children,
    className,
    isClickable = false,
    displayAncestorIcon = false,
    isModule = false,
    ...props
}: Props): JSX.Element => {
    const intl = useIntl();

    return (
        <li
            className={cn("manager-list-item", `--${status}`, {
                "--ancestor-icon": displayAncestorIcon,
                "list-item--clickable": isClickable,
                "list-item--module": isModule
            }, className)}
            {...props}
        >
            {displayAncestorIcon && status === "unlocked" && (
                <TooltipWrapper
                    wrapper="button"
                    tooltip={intl.formatMessage({
                        id: "html-prm-activityIsAncestor",
                        defaultMessage:
                            "This activity is a prerequisite for an objective",
                    })}
                    aria-label={intl.formatMessage({
                        id: "html-prm-activityIsAncestor",
                        defaultMessage:
                            "This activity is a prerequisite for an objective",
                    })}
                    tooltipClassname="manager-list-item__ancestor-tooltip"
                >
                    <Icon path="prerequisite-icon" size={40} />
                </TooltipWrapper>
            )}
            <div className="manager-list-item__content">{children}</div>
        </li>
    );
};

export default ManagerListItem;
