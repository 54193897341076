import { defineMessages, IntlShape } from "react-intl";

export interface TimeI18n {
    hour: string;
    hours: string;
    minute: string;
    minutes: string;
    second: string;
    seconds: string;
}

export const timeI18n = (intl: IntlShape): TimeI18n => ({
    hour: intl.formatMessage(messages.hour),
    hours: intl.formatMessage(messages.hours),
    minute: intl.formatMessage(messages.minute),
    minutes: intl.formatMessage(messages.minutes),
    second: intl.formatMessage(messages.second),
    seconds: intl.formatMessage(messages.seconds),
});

/**
 * Formats a duration in h/m/s
 * @param duration in seconds
 */
export const formatDuration = (
    duration: number,
    timeI18n: TimeI18n
): string => {
    const h = Math.floor(duration / (60 * 60));
    duration -= h * 60 * 60;
    const m = Math.floor(duration / 60);
    const s = duration - m * 60;
    const hString = h ? `${h}${h > 1 ? timeI18n.hours : timeI18n.hour} ` : "";
    const mString =
        h || m ? `${m}${m > 1 ? timeI18n.minutes : timeI18n.minute} ` : "";

    return `${hString}${mString}${s}${
        s > 1 ? timeI18n.seconds : timeI18n.second
    }`;
};

const messages = defineMessages({
    hour: {
        id: "dashboard-studentProgression-timeFormat-hour",
        defaultMessage: "h",
    },
    minute: {
        id: "dashboard-studentProgression-timeFormat-minute",
        defaultMessage: "min",
    },
    second: {
        id: "dashboard-studentProgression-timeFormat-second",
        defaultMessage: "sec",
    },
    hours: {
        id: "dashboard-studentProgression-timeFormat-hours",
        defaultMessage: "h",
    },
    minutes: {
        id: "dashboard-studentProgression-timeFormat-minutes",
        defaultMessage: "mins",
    },
    seconds: {
        id: "dashboard-studentProgression-timeFormat-seconds",
        defaultMessage: "secs",
    },
});
