import React, {  useEffect } from "react";

import { HomeStudentContent } from "../../../../interfaces/Home";
import { Image, parse } from "@evidenceb/athena-common";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import "./EllaStudent.scss";
import "../../../../components/Button/Button.scss";

import bgSliderLeft from "../../../../assets/home/student/home-slider-background-left.png";
import bgSliderRight from "../../../../assets/home/student/home-slider-background-right.png";
import VideoAsset from "../../../../components/VideoAsset/VideoAsset";
import HomeModulesList from "../HomeModulesList/HomeModulesList";
import GoToTopButton from "../../../../components/GoToTopButton/GoToTopButton";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../../design-system-components/Button/Button";
import useAssetsDetails from "../../../../hooks/useAssetsDetails";

interface StudentProps {
    content: HomeStudentContent | undefined;
}

function EllaStudent({ content }: StudentProps) {
    const history = useHistory();
    const location = useLocation();
    const assetsDetails = useAssetsDetails();

    // Scroll to section when appropriate
    useEffect(() => {
        if (location.state) {
            const sectionId = location.state as string;
            const section = document.getElementById(sectionId);
            if (section)
                section.scrollIntoView()
        }
    }, [location]);

    return (
        <>
            <div className="home-content-student">
                {content ? (
                    <>
                        <div className="slider-container">
                            <div
                                className="background left logos"
                                aria-hidden="true"
                            >
                                {content.logos && (
                                    <Image src={content.logos} alt="" assetsDetails={assetsDetails} />
                                )}
                            </div>
                            <div className="background left" aria-hidden="true">
                                <img src={bgSliderLeft} alt=""/>
                            </div>
                            <div className="background right" aria-hidden="true">
                                <img src={bgSliderRight} alt=""/>
                            </div>
                            <Carousel
                                useKeyboardArrows={true}
                                showStatus={false}
                                showThumbs={false}
                                infiniteLoop
                            >
                                {content.sliders.map((slide, i) => (
                                    <article
                                        key={"slide-" + i}
                                        className={slide.format}
                                    >
                                        <h2>{slide.title}</h2>
                                        <p className={i === 0 ? "main-descr" : ""}>{parse(slide.text.$html)}</p>
                                        {slide.list ? (
                                            <ul
                                                className={
                                                    slide?.listColumn
                                                        ? "hasColumns"
                                                        : ""
                                                }
                                            >
                                                {slide.list.map((item, i) => (
                                                    <li key={"item-" + i}>
                                                        <span>
                                                            {parse(
                                                                item.text.$html
                                                            )}
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : null}
                                        <Button
                                            onClick={() => {
                                                if (slide.btn.url.startsWith("#"))
                                                    history.push("", slide.btn.url.slice(1));
                                                else
                                                    history.push(slide.btn.url);
                                            }}
                                            className="slide-btn"
                                            variant={i === 0 ? "primary" : "tertiary"}
                                            label={slide.btn.label}
                                            size="large"
                                            icon={i === 0 ? undefined : {
                                                path: "arrow_right",
                                                position: "left",
                                                size: 16,
                                            }}
                                            color={i === 0 ? "secondary" : "primary"}
                                        />
                                        {slide.logo ? (
                                            <div
                                                className={`background-icon ${
                                                    slide.logo.endsWith(".svg")
                                                        ? "background-icon--svg"
                                                        : ""
                                                }`}
                                                aria-hidden="true"
                                            >
                                                <Image src={slide.logo} alt="" assetsDetails={assetsDetails} />
                                            </div>
                                        ) : null}
                                    </article>
                                ))}
                            </Carousel>
                        </div>
                        <div id="section-1" className="secondary-content" >
                            <h2>{content.sections.s1.title.main}</h2>
                            <p>{content.sections.s1.text.$html}</p>
                            <HomeModulesList btnLabel={{more: content.sections.s1.btnLabel.more, less: content.sections.s1.btnLabel.less}}/>
                        </div>
                        {
                            content.sections.s2.video &&
                            <div id="section-2" className="secondary-content" >
                                <h2>{content.sections.s2.title.main}</h2>
                                <div className="content">
                                    <VideoAsset
                                        src={content.sections.s2.video.url}
                                        poster={content.sections.s2.video.poster}
                                    />
                                </div>
                            </div>
                        }
                    </>
                ) : null}
            </div>
            <GoToTopButton />
        </>
    );
}

export default EllaStudent;