import React, {useState} from "react";

import "./ToggleDiv.scss";

import ToggleButton from "../ToggleButton/ToggleButton";
import Button, {ButtonProps} from "../Button/Button"

export interface ToggleDivProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement> {
    label: string,
     /**
     * @default ToggleButton
     */
    buttonProps?: ButtonProps
    /**
     * @default closed
     */
    defaultState?: "closed" | "open";
}

export const ToggleDiv= ({label, buttonProps, defaultState = "closed", className, children, ...props} :ToggleDivProps) => {
    const [showToggle, setShowToggle] = useState<"closed" | "open">(defaultState)
    const handleToggle = () => {
        setShowToggle(showToggle === "closed" ? "open" : "closed")
    }

    return (
        <div className={`ds-toggle-div  ${className ?? ""}`} {...props}>
                <div className="ds-toggle-div__button">
                    {buttonProps
                        ? <Button {...buttonProps}  onClick={handleToggle} />
                        : <ToggleButton
                            label={label}
                            rotationTowards={showToggle === "open" ? "up" : "bottom"}
                            onClick={handleToggle}
                        />
                    }
                </div>
                {
                    showToggle === 'open' &&
                        <div className="ds-toggle-div__toggle">
                            {children}
                        </div>
                }
        </div>
    );
};

export default ToggleDiv;
