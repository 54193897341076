import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";
import { Dashboard } from "../interfaces/Dashboard";

// Context Typing
interface DashboardContext {
    dashboard: Dashboard | undefined;
    setDashboard: Dispatch<SetStateAction<Dashboard | undefined>>;
}

// Context init
const dashboardStore = createContext<DashboardContext>({
    dashboard: undefined,
    setDashboard: () => null,
});

// Provider init
const { Provider } = dashboardStore;

// Final setup
const DashboardProvider = ({ children }: { children: JSX.Element }) => {
    const [dashboard, setDashboard] = useState<Dashboard | undefined>(
        undefined
    );
    return <Provider value={{ dashboard, setDashboard }}>{children}</Provider>;
};

export { dashboardStore, DashboardProvider };
