import React, { useContext, useEffect, useState } from 'react';
// External modules
// Contexts
import { dataStore } from '../../../../contexts/DataContext';
import { configStore } from '../../../../contexts/ConfigContext';
// Components
// Utils
import { getItemDescription, getModuleById, getObjectiveById, initialTestFilter } from '../../../../utils/dataRetrieval';
// SCSS
import './HomeModulesList.scss';
// Interfaces
import { Objective, Module } from '@evidenceb/gameplay-interfaces';
import { parse } from "@evidenceb/athena-common";
import { AIType } from '@evidenceb/ai-handler';
import { useIntl } from 'react-intl';
import Icon from '../../../../design-system-components/Icon/Icon';
interface IModule extends Module {
    nbrOfItems: number;
    objectives: Objective[];
    collapsed: boolean;
}
interface IModules {
    [id: string]: IModule;
}
interface HomeModulesListProps {
    btnLabel: {
        more: string;
        less: string;
    }
}
const HomeModulesList = ({ btnLabel }: HomeModulesListProps) => {

    //Contexts
    const { data } = useContext(dataStore);
    const { config:{features, ai} } = useContext(configStore);
    const intl = useIntl();
    //States
    const [modules, setModules] = useState<IModules>({});

    //UseEffets
    useEffect(() => {
        const modulesArray = {} as IModules;
        data.modules.forEach(mod => {
            let objectives = getModuleById(mod.id, data).objectiveIds.map(id => getObjectiveById(id, data)) as Objective[]
            modulesArray[mod.id] = {
                ...mod,
                nbrOfItems: 4,
                objectives: objectives.filter(initialTestFilter(ai[AIType.BanditManchot].initialTest, "notInitialTest")),
                collapsed: true
            }
        })
        setModules(modulesArray)
    }, [ai, data])

    //functions
    const toggleItems = (moduleId: string) => {
        setModules({
            ...modules,
            [moduleId]: {
                ...modules[moduleId],
                nbrOfItems: modules[moduleId].nbrOfItems === modules[moduleId].objectives.length ? 4 : modules[moduleId].objectives.length,
                collapsed: !modules[moduleId].collapsed
            }
        })

    }

    return (
        <div className='HomeModulesList clearfix'>

            {
                modules && Object.keys(modules).map((id, index) =>
                    <div className="module" key={id}>
                        <h3>{!features.teacherModuleListShowFullTitle && index + 1 + ". "}{parse(getItemDescription(modules[id], "student"))}</h3>
                        <div className="objectives">
                            <ul>
                                {
                                    modules[id].objectives.map((objective, i) => {
                                        return i <= modules[id].nbrOfItems &&
                                            <li key={objective.id}>
                                                <h4>
                                                    {`${intl.formatMessage({id:"hierarchy-objective-full", defaultMessage:"objective"})} ${i+1}`}
                                                </h4>
                                                <p>
                                                    { 
                                                        objective.descriptions?.student.$html !== "" ? parse(objective.descriptions!.student.$html) : parse(objective.descriptions?.default.$html)
                                                    }
                                                </p>
                                            </li>
                                    }
                                    )
                                }
                            </ul>
                            <button onClick={() => toggleItems(id)} className={!modules[id].collapsed ? "opened" : ""}>
                                <Icon 
                                    path={
                                        modules[id].collapsed ? "expand" : "collapse"
                                    }
                                    size="medium"
                                />
                                <span>
                                    {
                                        modules[id].collapsed ? btnLabel.more : btnLabel.less
                                    }
                                </span>
                            </button>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default HomeModulesList