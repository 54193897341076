import React, { useContext, useEffect, useReducer } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { configStore } from "../../../contexts/ConfigContext";
import { errorStore } from "../../../contexts/ErrorContext";
import { useAthenaTokenlessAPIClient } from "../../../hooks/useAthenaAPIClient";
import { commonMessages } from "../../../utils/messages";
import Button from "../../../design-system-components/Button/Button";
import Card from "../../../design-system-components/Card/Card";
import Input from "../../../design-system-components/Input/Input";
import Icon from "../../../design-system-components/Icon/Icon";
import { authMessages } from "../auth-messages";
import { sessionStore } from "../../../contexts/SessionContext";

import "./ForgottenPassword.scss";

const LINK_EXPIRY_TIME = moment.duration(7, "days");

const ForgottenPassword = () => {
    const intl = useIntl();
    const athenaAPIClient = useAthenaTokenlessAPIClient();
    const { config } = useContext(configStore);
    const { setErrorInfo } = useContext(errorStore);
    const { session } = useContext(sessionStore);

    useEffect(() => {
        (async () => {
            await import(`moment/locale/${config.lang}`);
            LINK_EXPIRY_TIME.locale(config.lang!);
        })();
    }, [config.lang]);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isDirty },
    } = useForm<{ email: string }>();
    const email = watch("email");

    const [success, markRequestSucessful] = useReducer<() => boolean>(
        () => true,
        false
    );
    const sendPwdResetEmail = async (email: string) => {
        try {
            await athenaAPIClient.requestNewPassword(email, session.app!);
            markRequestSucessful();
        } catch (err) {
            setErrorInfo({
                displayModal: true,
                modal: {
                    type: "NOTIFICATION",
                    content: {
                        title: intl.formatMessage(
                            commonMessages.genericErrorMessage
                        ),
                    },
                },
            });
        }
    };

    return (
        <div className="forgotten-pwd__container">
            <Button
                variant="primary"
                label={intl.formatMessage(authMessages.loginAsTeacher)}
                icon={{
                    path: "arrow_left",
                    position: "left",
                    size: "medium",
                }}
                asRouterLink={{ to: "/auth/teacher" }}
            />

            <Card
                cardTitle={{
                    as: "h1",
                    content: intl.formatMessage(messages.forgottenPwd),
                }}
                className="forgotten-pwd__card"
            >
                {!success && (
                    <div className="forgotten-pwd">
                        <p>
                            {intl.formatMessage(
                                messages.forgottenPwdInstruction,
                                {
                                    appName: config.client_name,
                                }
                            )}
                        </p>

                        <form
                            onSubmit={handleSubmit((e) =>
                                sendPwdResetEmail(e.email)
                            )}
                        >
                            <div>
                                <label htmlFor="email-input">
                                    {intl.formatMessage(messages.academicEmail)}
                                </label>
                                <Input
                                    id="email-input"
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: intl.formatMessage(
                                                authMessages.emailRequired
                                            ),
                                        },
                                        pattern: {
                                            value: emailRegex,
                                            message: intl.formatMessage(
                                                messages.invalidEmail
                                            ),
                                        },
                                    })}
                                    error={errors.email?.message}
                                />
                            </div>

                            <Button
                                type="submit"
                                variant="primary"
                                label={intl.formatMessage(messages.resetPwd)}
                                disabled={!isDirty}
                                center
                            />
                        </form>

                        {config.auth.registerURL && (
                            <p>
                                {intl.formatMessage(messages.noAcount)}{" "}
                                <a href={config.auth.registerURL}>
                                    {intl.formatMessage(messages.register)}
                                </a>
                            </p>
                        )}
                    </div>
                )}

                {success && (
                    <div className="forgotten-password--sent">
                        <p>{intl.formatMessage(messages.success)}</p>
                        <Icon
                            path="illustrations/pwd_change_sent"
                            size={{ width: 476, height: 131 }}
                        />
                        <p>
                            {intl.formatMessage(messages.successDetail, {
                                email,
                                expiry: LINK_EXPIRY_TIME.humanize(),
                            })}
                        </p>
                    </div>
                )}
            </Card>

            <p className="forgotten-pwd__wrong-place">
                {intl.formatMessage(authMessages.notATeacher)}{" "}
                <Link to="/auth/student">
                    {intl.formatMessage(authMessages.loginAsStudent)}
                </Link>
            </p>
        </div>
    );
};

/* eslint-disable */
const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
// Reference: http://emailregex.com/

const messages = defineMessages({
    forgottenPwd: {
        id: "authentication-teacher-forgottenPwd",
        defaultMessage: "Forgotten Password",
    },
    forgottenPwdInstruction: {
        id: "authentication-teacher-forgottenPwdInstruction",
        defaultMessage:
            "Enter the academic email adress you used to create your {appName} account. We will send you an email with a link to reset your password.",
    },
    academicEmail: {
        id: "authentication-teacher-academicEmail",
        defaultMessage: "Academic email",
    },
    resetPwd: {
        id: "authentication-teacher-resetPwd",
        defaultMessage: "Reset password",
    },
    noAcount: {
        id: "authentication-teacher-noAcount",
        defaultMessage: "No account yet?",
    },
    register: {
        id: "authentication-teacher-register",
        defaultMessage: "Register here",
    },
    success: {
        id: "authentication-teacher-passwordChangeRequestSuccess",
        defaultMessage: "The request was sent.",
    },
    successDetail: {
        id: "authentication-teacher-passwordChangeRequestSuccessDetail",
        defaultMessage:
            "We sent an email to {email} with a link to choose your password. The link expires after {expiry}.",
    },
    invalidEmail: {
        id: "authentication-teacher-invalidEmail",
        defaultMessage: "Invalid email",
    },
});

export default ForgottenPassword;
