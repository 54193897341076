import axios from "axios";

import { MicroServices } from "../interfaces/Config";
import { msConfigResolver } from "./init";
import * as localStorage from "./localStorage";
import Error from "../pages/Error/Error";
import appConfig from "../config";


export default class AxiosClient {
    private _apiUrls?: MicroServices;
    private _apiToken?: string;
    public axios;

    get apiUrls(): MicroServices {
        if (!this._apiUrls) {
            console.log("apiUrls is undefined! AthenaAPIClient is still initializing");
            throw Error;
        }
        return this._apiUrls;
    }

    get apiToken(): string {
        if (!this._apiToken) {
            console.log("apiToken is undefined! App is still initializing");
            throw Error;
        }
        return this._apiToken;
    }

    set apiToken(apiToken: string) {
        this._apiToken = apiToken;
    }

    constructor() {
        this.axios = axios.create();
    };

    private async renewApiToken(error: any) {
        const originalRequest = error.config;
        if (
            originalRequest._retry
            || (error.response?.status !== 403 && error.response?.status !== 401)
            || error.response?.data.detail.message === "User session was invalidated."
            || error.response?.data.detail.message === "Impossible to decode token with given secret"
        )
            throw error;
        
        originalRequest._retry = true;
        try {
            const newApiToken = await this.getAndUpdateToken();
            originalRequest.headers["Authorization"] =
                "Bearer " + newApiToken;
            return this.axios.request(originalRequest);
        } catch (error) {
            throw error;
        }
    }

    private async getAndUpdateToken<T>(): Promise<T> {
        try {
            let headers = { Authorization: "Bearer " + this.apiToken };
            // URL set in .env for now
            const res = await this.axios.get(
                `${this.apiUrls.endpoints.auth}/session/refresh-token`,
                { headers: headers }
            );
            let newToken = res.data.token;
            this._apiToken = newToken
            localStorage.setItem(localStorage.Key.TOKEN, newToken);
            return res.data.token as Promise<T>;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async init(): Promise<MicroServices | any> {
        let origin = window.location.origin;
        try {
            const { data } = await this.axios.get(origin + (appConfig.basePath ?? "/") + "json/msConfigs.json");
            try {
                this._apiUrls = msConfigResolver(data, window.location.href);
                console.log(this.apiUrls)
            } catch(error){
                console.log(error)
                return error;
            };
        } catch(error) {
            return error;
        };
        
        this.axios.interceptors.response.use(undefined, (error) => {
            return this.renewApiToken(error);
        });
        return this._apiUrls;
    };
}

export const axiosClient = new AxiosClient();