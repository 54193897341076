import React, { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Dialog from "rc-dialog";
import { HtmlString } from "@evidenceb/gameplay-interfaces";
import { Playlist, PlaylistExecutionStage } from "../../../interfaces/Player";
import { sessionStore } from "../../../contexts/SessionContext";
import { configStore } from "../../../contexts/ConfigContext";
import htmlHandler from "../../../utils/html-handler";
import Button from "../../../design-system-components/Button/Button";

import "rc-dialog/assets/index.css";
import "./TestInfoModal.scss";
import {
    adaptiveTestStatementsFilter,
    byAdaptiveTestNumberStatementsFilter,
    byModuleStatementsFilter,
    chronologicalStatementsSort,
    getLastAdaptiveTestNumber,
    isStatementDiag,
    isStatementFromTest,
    isStatementInModule,
} from "../../../utils/statements";
import { testHasExpired } from "../../../hooks/useAdaptiveTest";
import { getAssetUrl, parse } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../hooks/useAssetsDetails";

interface Props {
    image?: string;
    content: HtmlString;
    btnLabel: string;
    onClose: () => void;
    onButtonClick: () => void;
}

const TestInfoModal = ({
    image,
    content,
    btnLabel,
    onClose,
    onButtonClick,
}: Props) => {
    const assetsDetails = useAssetsDetails();

    return (
        <Dialog
            visible
            closable={false}
            onClose={onClose}
            wrapClassName="test-info-modal"
            maskStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.15)",
            }}
        >
            {image && (
                <div
                    className="test-info-modal__img-container"
                    style={{
                        backgroundImage: `url(${getAssetUrl(image, assetsDetails)})`,
                    }}
                ></div>
            )}
            <p>
                {parse(content.$html)}
                <Button
                    onClick={onButtonClick}
                    label={btnLabel}
                    variant={"primary"}
                />
            </p>
        </Dialog>
    );
};

interface BMTestInfoProps {
    playlist: Playlist;
}
export const BanditManchotTestInfoModal = ({ playlist }: BMTestInfoProps) => {
    const { config } = useContext(configStore);
    const intl = useIntl();
    const [content, setContent] = useState<HtmlString | undefined>(
        playlist.isInitialTest
            ? {
                  $html: intl.formatMessage(
                      {
                          id: "html-exerciseShell-initialTestMessages-start",
                          defaultMessage:
                              "To start, answer a few questions without feedback, so that you can then access to your personalised path.",
                      },
                      htmlHandler
                  ),
              }
            : undefined
    );

    // Show end of initial test modal
    useEffect(() => {
        if (
            playlist.currentExecutionStage ===
            PlaylistExecutionStage.ShowingEndOfInitialTestMessage
        )
            setContent({
                $html: intl.formatMessage(
                    {
                        id: "html-exerciseShell-initialTestMessages-end",
                        defaultMessage:
                            "Well done! You have completed the first set of questions. It's time to start your own personalised path.",
                    },
                    htmlHandler
                ),
            });
    }, [playlist.currentExecutionStage, intl]);

    const closeModal = () => setContent(undefined);

    return content ? (
        <TestInfoModal
            image={config.logos.avatar}
            content={content}
            btnLabel={
                playlist.currentExecutionStage ===
                PlaylistExecutionStage.ShowingEndOfInitialTestMessage
                    ? intl.formatMessage({
                          id: "exerciseShell-initialTestMessages-btnEnd",
                          defaultMessage: "Continue",
                      })
                    : intl.formatMessage({
                          id: "exerciseShell-initialTestMessages-btnStart",
                          defaultMessage: "Begin",
                      })
            }
            onClose={closeModal}
            onButtonClick={closeModal}
        />
    ) : (
        <></>
    );
};

interface ATestInfoProps {
    playlist: Playlist;
}
export const AdaptiveTestInfoModal = ({ playlist }: ATestInfoProps) => {
    const { config } = useContext(configStore);
    const intl = useIntl();
    const {
        session: { statements },
    } = useContext(sessionStore);

    const currentTestStatements = useMemo(() => {
        const moduleTestsStatements = statements
            ?.filter(adaptiveTestStatementsFilter)
            ?.filter(byModuleStatementsFilter(playlist.module.id));

        if (!moduleTestsStatements) return [];

        const latestTest = getLastAdaptiveTestNumber(moduleTestsStatements);

        if (!latestTest) return [];

        const testIsOver = statements?.some((value) => {
            if (!isStatementDiag(value)) return false;
            if (!isStatementInModule(value, playlist.module.id)) return false;
            return isStatementFromTest(value, latestTest);
        });

        if (testIsOver) return [];

        return moduleTestsStatements?.filter(
            byAdaptiveTestNumberStatementsFilter(latestTest)
        );
    }, [statements, playlist.module.id]);

    const [content, setContent] = useState<HtmlString | undefined>(
        playlist.isInitialTest &&
            (!currentTestStatements ||
                currentTestStatements.length === 0 ||
                testHasExpired(
                    currentTestStatements!.sort(chronologicalStatementsSort)[0]
                        .timestamp!
                ))
            ? {
                  $html: intl.formatMessage(
                      {
                          id: "html-exerciseShell-adaptiveTestMessages-start",
                          defaultMessage:
                              "This test has 20 questions. You can pause it at any moment and pick it up later. At the end of the test, you will get personalized learning resources depending on your results.",
                      },
                      htmlHandler
                  ),
              }
            : undefined
    );

    const closeModal = () => setContent(undefined);

    return content ? (
        <TestInfoModal
            image={config.logos.avatar}
            content={content}
            btnLabel={intl.formatMessage({
                id: "exerciseShell-adaptiveTestMessages-btnStart",
                defaultMessage: "Begin",
            })}
            onClose={closeModal}
            onButtonClick={closeModal}
        />
    ) : (
        <></>
    );
};

export type TestInfoModalProps = BMTestInfoProps | ATestInfoProps;
