import { Image } from "@evidenceb/athena-common";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button/Button";
import useAssetsDetails from "../../../hooks/useAssetsDetails";
import "./CardEntry.scss";
interface Entry {
    img: string;
    title: string;
    desc: string;
    buttonLabel: string;
    url: string;
}

const CardEntry = ({ img, title, desc, buttonLabel, url }: Entry) => {
    const assetsDetails = useAssetsDetails();
    
    return (
        <div className="card-entry">
            <Link to={`/${url}`}>
                <div className="img">
                    <Image src={img} assetsDetails={assetsDetails} alt="" />
                </div>
                <div className="content">
                    <h2>{title}</h2>
                    {desc ? <p>{desc}</p> : null}
                    <Button
                        type={"primary"}
                        label={buttonLabel}
                        icons={[ { placement: "right", code:"arrow_forward"} ]}
                    />
                </div>
            </Link>
        </div>
    );
};

export default CardEntry;
