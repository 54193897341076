import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { dashboardStore } from "../../../../../../contexts/DashboardContext";
import { dataStore } from "../../../../../../contexts/DataContext";
import { configStore } from "../../../../../../contexts/ConfigContext";
import { getResourceIndex } from "../../../../../../utils/dataRetrieval";
import { getStudentDetailsUrl } from "../../../../../../utils/navigation-dashboard";
import BorderlessCard from "../../../../../../design-system-components/BorderlessCard/BorderlessCard";
import Chip from "../../../../../../design-system-components/Chip/Chip";
import Table from "../../../../../../design-system-components/Table/Table";
import Button from "../../../../../../design-system-components/Button/Button";
import { TooltipWrapper } from "../../../../../../design-system-components/ToolTip/ToolTip";
import VisuallyHidden from "../../../../../../components/VisuallyHidden/VisuallyHidden";
import { defineMessages, useIntl } from "react-intl";
import dashboardMessages from "../../../../dashboardMessages";

import "./StudentsOverview.scss";

interface Props {
    classroomId: string;
    onAddUserClick: () => void;
}

interface Student {
    id: string;
    firstName: string;
    lastName: string;
    /** Number of the modules the student has positive alerts for */
    supAlerts: number[];
    /** Number of the modules the student has negative alerts for */
    infAlerts: number[];
}

const StudentsOverview = ({ classroomId, onAddUserClick }: Props) => {
    const { dashboard } = useContext(dashboardStore);
    const { data } = useContext(dataStore);
    const {
        config: { features, pages },
    } = useContext(configStore);
    const intl = useIntl();

    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    const studentsList = useMemo<Student[]>(() => {
        if (!dashboard) return [];

        const classroom = dashboard.classrooms.find(
            (classroom) => classroom.id === classroomId
        )!;
        const students = classroom.modulesList.reduce((students, mod) => {
            Object.keys(mod.students).forEach((studentId) => {
                if (!students[studentId]) {
                    students[studentId] = {
                        id: studentId,
                        firstName: mod.students[studentId].firstname,
                        lastName: mod.students[studentId].lastname,
                        supAlerts: [],
                        infAlerts: [],
                    };
                }

                if (mod.students[studentId].studentInDifficulty)
                    students[studentId].infAlerts.push(
                        getResourceIndex(mod.id, data.modules) + 1
                    );
                if (mod.students[studentId].studentInProgress)
                    students[studentId].supAlerts.push(
                        getResourceIndex(mod.id, data.modules) + 1
                    );
            });
            return students;
        }, {} as { [studentId: string]: Student });

        return Object.keys(students)
            .map((studentId) => students[studentId])
            .sort((a, b) => {
                return a.lastName.localeCompare(b.lastName);
            });
    }, [classroomId, dashboard, data.modules]);

    if (studentsList.length === 0) return null;

    return (
        <div className="students-overview">
            <div className="students-overview__title">
                <h2>{intl.formatMessage(dashboardMessages.students)}</h2>
                {features.studentManagement && (
                    <Button
                        className="single-classroom__overview__students__add-students"
                        type="button"
                        variant="secondary"
                        label={intl.formatMessage(messages.add)}
                        onClick={onAddUserClick}
                        icon={{
                            path: "user-add-outlined",
                            size: "medium",
                        }}
                    />
                )}
            </div>

            <BorderlessCard>
                <Table
                    className={`students-overview__students-table ${studentsList.length > 2
                        ? "students-overview__students-table--bottom-border"
                        : "students-overview__students-table--collapse-bottom-padding"
                        }`}
                >
                    <caption>
                        <VisuallyHidden>
                            {intl.formatMessage(
                                messages.studentsOverviewTableDescription
                            )}
                        </VisuallyHidden>
                    </caption>

                    <tr>
                        <th>{intl.formatMessage(messages.name)}</th>
                        <th>
                            {intl.formatMessage(messages.modulesWithAlerts)}
                        </th>
                    </tr>

                    {studentsList
                        .slice(0, isCollapsed ? 2 : undefined)
                        .map((student) => (
                            <tr className="table__row">
                                <td>
                                    <TooltipWrapper
                                        wrapper="div"
                                        tooltip={intl.formatMessage(messages.seeProfile)}
                                        tooltipClassname="student-overview__student-tooltip"
                                    >
                                        <Link
                                            to={getStudentDetailsUrl(pages, student.id, classroomId, intl)}
                                            className="student-overview__student-link"
                                        >
                                            {`${student.firstName} ${student.lastName.toUpperCase()}`}
                                        </Link>
                                    </TooltipWrapper>
                                </td>

                                <td className="alert-cell">
                                    {student.infAlerts.length > 0 && (
                                        <Chip
                                            size="small"
                                            type="inf"
                                            input={student.infAlerts
                                                .map(
                                                    (modIndex) =>
                                                        intl
                                                            .formatMessage(
                                                                messages.mod
                                                            )
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        modIndex
                                                )
                                                .join(" ")}
                                        />
                                    )}
                                    {student.supAlerts.length > 0 && (
                                        <Chip
                                            size="small"
                                            type="sup"
                                            input={student.supAlerts
                                                .map(
                                                    (modIndex) =>
                                                        intl
                                                            .formatMessage(
                                                                messages.mod
                                                            )
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        modIndex
                                                )
                                                .join(" ")}
                                        />
                                    )}
                                    {student.infAlerts.length === 0 &&
                                        student.supAlerts.length === 0 && (
                                            <p className="alert-cell__no-alert">
                                                {intl.formatMessage(
                                                    messages.noAlerts
                                                )}
                                            </p>
                                        )}
                                </td>
                            </tr>
                        ))}
                </Table>

                {studentsList.length > 2 && (
                    <Button
                        className="students-overview__collapse-button"
                        variant="tertiary"
                        label={
                            isCollapsed
                                ? intl.formatMessage(messages.seeOtherStudents)
                                : intl.formatMessage(
                                    messages.reduceStudentsList
                                )
                        }
                        icon={{
                            path: isCollapsed ? "expand" : "collapse",
                            position: "left",
                        }}
                        onClick={() => {
                            setIsCollapsed(!isCollapsed);
                        }}
                    />
                )}
            </BorderlessCard>
        </div>
    );
};

const messages = defineMessages({
    studentsOverviewTableDescription: {
        id: "studentsOverviewTableDescription",
        defaultMessage:
            "List of students and their achievements in the modules",
    },
    name: {
        id: "dashboard-name",
        defaultMessage: "Name",
    },
    modulesWithAlerts: {
        id: "modulesWithAlerts",
        defaultMessage: "Modules with alerts",
    },
    seeProfile: {
        id: "html-seeProfile",
        defaultMessage: "See profile",
    },
    dashboard: {
        id: "dashboard-paths-dashboard",
        defaultMessage: "dashboard",
    },
    student: {
        id: "dashboard-paths-student",
        defaultMessage: "student",
    },
    mod: {
        id: "hierarchy-module-short",
        defaultMessage: "mod",
    },
    noAlerts: {
        id: "noAlerts",
        defaultMessage: "No alerts",
    },
    seeOtherStudents: {
        id: "seeOtherStudents",
        defaultMessage: "See all students",
    },
    reduceStudentsList: {
        id: "reduceStudentsList",
        defaultMessage: "Collapse students list",
    },
    add: {
        id: "singleClassroom-addStudent",
        defaultMessage: "Add a student",
    },
});

export default StudentsOverview;
