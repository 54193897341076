import { ErrorPageI18n } from "../../interfaces/Error";

export const errorI18n: ErrorPageI18n[] = [
    {
        code:"msConfigs",
        content:{
            message: {
                title: {
                    fr: "Oups !",
                    en: "Oops!",
                    it : "Ops!"
                },
                text: {
                    fr:"Nous avons rencontré une erreur pendant le chargement de la page.",
                    en:"This page failed to load.",
                    it :"Errore nel caricamento della pagina."
                }
            },
            btn: {
                label: {
                    fr:"A supprimer",
                    en:"A supprimer",
                    it :"A supprimer"
                }
            }
        }
    },
    {
        code:"token",
        content:{
            message: {
                title: {
                    fr:"Il semble que vous allez un peu vite !",
                    en:"First things first!",
                    it :"Un passo alla volta!"
                },
                text: {
                    fr:"Veuillez d’abord vous connecter à votre compte pour accéder à vos ressources pédagogiques.",
                    en:"Please log in to your account before accessing this ressource.",
                    it :"Per accedere alle tue risorse di apprendimento effettua il log in."
                }
            },
            btn: {
                label: {
                    fr:"Se connecter",
                    en:"Log in",
                    it :"Log in"
                }
            }
        }
    },
    {
        code:"user-info",
        content:{
            message: {
                title: {
                    fr:"Oups !",
                    en:"Oops!",
                    it: "Ops!"
                },
                text: {
                    fr:"Nous avons rencontré une erreur pendant le chargement de la page.",
                    en:"This page failed to load.",
                    it:"Errore nel caricamento della pagina."
                }
            },
            btn: {
                label: {
                    fr:"A supprimer",
                    en:"A supprimer",
                    it:"A supprimer"
                }
            }
        }
    },
    {
        code:"version",
        content:{
            message: {
                title: {
                    fr:"Oups !",
                    en:"Oops!",
                    it:"Ops!"
                },
                text: {
                    fr:"Nous avons rencontré une erreur pendant le chargement de la page.",
                    en:"This page failed to load.",
                    it:"Errore nel caricamento della pagina."
                }
            },
            btn: {
                label: {
                    fr:"A supprimer",
                    en:"A supprimer",
                    it: "A supprimer"
                }
            }
        }
    },
    {
        code:"user",
        content:{
            message: {
                title: {
                    fr:"Oups !",
                    en:"Oops!",
                    it:"Ops!"
                },
                text: {
                    fr:"Nous avons rencontré une erreur pendant le chargement de la page.",
                    en:"This page failed to load.",
                    it:"Errore nel caricamento della pagina."
                }
            },
            btn: {
                label: {
                    fr:"A supprimer",
                    en:"A supprimer",
                    it : "A supprimer"
                }
            }
        }
    },
    {
        code:"config",
        content:{
            message: {
                title: {
                    fr:"Oups !",
                    en:"Oops!",
                    it:"Ops!"
                },
                text: {
                    fr:"Nous avons rencontré une erreur pendant le chargement de la page.",
                    en:"This page failed to load.",
                    it:"Errore nel caricamento della pagina."
                }
            },
            btn: {
                label: {
                    fr:"A supprimer",
                    en:"A supprimer",
                    it: "A supprimer"
                }
            }
        }
    },
    {
        code:"404",
        content:{
            message: {
                title: {
                    fr:"Page introuvable",
                    en:"Page not found",
                    it:"Pagina non trovata"
                },
                text: {
                    fr:"Il semble que la page à laquelle vous voulez accéder n’existe pas. L’adresse est peut-être erronée ou son contenu a été supprimé.",
                    en:"The page you want to access does not seem to exist. The address may be wrong or the content may have been deleted.",
                    it:"La pagina cercata non esiste o non è più disponibile. L'indirizzo potrebbe essere sbagliato o il contenuto potrebbe essere stato cancellato."
                }
            },
            btn: {
                label: {
                    fr:"Retourner à la page d’accueil",
                    en:"Return to homepage",
                    it:"Torna alla Home"
                }
            }
        }
    }, {
        code:"i8n",
        content:{
            message: {
                title: {
                    fr:"Impossible de charger les données de langue",
                    en:"Cannot load language data",
                    it:"Impossibile caricare i dati della lingua"
                },
                text: {
                    fr:"Une erreur est survenue en chargeant les contenus traduits. Veuillez réessayer ultérieurement.",
                    en:"An error occured while retrieving lqnguqge data. Please try again later.",
                    it:"Si è verificato un errore durante il recupero dei dati di lqnguqge. Si prega di riprovare più tardi."
                }
            },
            btn: {
                label: {
                    fr:"",
                    en:"",
                    it:""
                }
            }
        }
    },
    {
        code:"error",
        content:{
            message: {
                title: {
                    fr:"Oups!",
                    en:"Oops!",
                    it:"Ops!"
                },
                text: {
                    fr:"Nous avons rencontré une erreur pendant le chargement de la page.",
                    en:"This page failed to load.",
                    it:"Errore nel caricamento della pagina."
                }
            },
            btn: {
                label: {
                    fr:"Retourner à la page d’accueil",
                    en:"Return to homepage",
                    it:"Torna alla Home."
                }
            }
        }
    }
]

