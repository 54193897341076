import React, { ReactNode, useContext } from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { Student, Objective, Activity } from "../../../interfaces/Dashboard";
import { Data } from "../../../interfaces/Data";
import { Config } from "../../../interfaces/Config";
import { configStore } from "../../../contexts/ConfigContext";
import { commonMessages } from "../../../utils/messages";
import { parse } from "@evidenceb/athena-common";
import { dataStore } from "../../../contexts/DataContext";
import {
    getObjectiveById,
    getResourceIndex,
    hasObjectiveTest,
} from "../../../utils/dataRetrieval";
import {
    LayerModal,
    LayerModalMain,
} from "../../../design-system-components/LayerModal/LayerModal";
import ProgressionGraph from "./ProgressionGraph/ProgressionGraph";
import ModalHeader from "./ModalHeader/ModalHeader";
import DetailsChips from "./ModalHeader/DetailsChips/DetailsChips";

import "./StudentProgression.scss";
import "simplebar/dist/simplebar.min.css";
import { useOverlayTriggerState } from "@react-stately/overlays";

export interface ProgressionData {
    objective: Objective;
    student: Pick<Student, "firstname" | "lastname">;
}

export interface Props extends ProgressionData {
    onDismiss: () => void;
}

const StudentProgression = ({ student, objective, onDismiss }: Props) => {
    const intl = useIntl();
    const { data } = useContext(dataStore);
    const layerModalState = useOverlayTriggerState({isOpen: true})
    const {
        config: { ai },
    } = useContext(configStore);

    return (
        <LayerModal
            state={layerModalState}
            onClose={onDismiss}
            className="student-progression"
            aria-label={intl.formatMessage(
                {
                    id: "a11y-studentProgressionModal-title",
                    defaultMessage: "{name}'s progression",
                },
                { name: `${student.firstname} ${student.lastname}` }
            )}
        >
            <ModalHeader
                student={student}
                onDismiss={onDismiss}
                chips={
                    <DetailsChips
                        nbExercises={getTotalNumberOfExercises(
                            objective.progression.activities
                        )}
                        meanScore={meanSuccess(
                            objective.progression.activities
                        )}
                        timeSpent={{
                            label: intl.formatMessage(
                                objective.status === "completed"
                                    ? messages.objCompletedIn
                                    : messages.timeSpentOnObjective
                            ),
                            time: objective.timeSpent,
                        }}
                        alert={objective.alert}
                    />
                }
                description={objectiveTitle(objective, intl, data, ai)}
            />

            <LayerModalMain className="student-progression__modal-main-layer">
                <ProgressionGraph
                    activities={objective.progression.activities}
                />
            </LayerModalMain>
        </LayerModal>
    );
};

const objectiveTitle = (
    objective: Objective,
    intl: IntlShape,
    data: Data,
    ai: Config["ai"]
): ReactNode => {
    return (
        <>
            {`${intl
                .formatMessage(commonMessages.objectiveShort)
                .charAt(0)
                .toUpperCase()}${calculateIndex(
                objective,
                data,
                ai
            )}${intl.formatMessage({
                id: "misc-colon",
                defaultMessage: ":",
            })}
            `}
            {parse(
                getObjectiveById(objective.id, data, true).description.$html
            )}
        </>
    );
};

const calculateIndex = (objective: Objective, data: Data, ai: Config["ai"]) => {
    const objectives = data.modules.find((mod) =>
        mod.objectiveIds.includes(objective.id)
    )!.objectiveIds;
    let i = getResourceIndex(objective.id, objectives);
    if (hasObjectiveTest(objectives, ai.BANDIT_MANCHOT.initialTest)) return i;
    return i + 1;
};

const getTotalNumberOfExercises = (activities: Activity[]): number => {
    return activities.reduce((total, activity) => {
        return total + activity.exercises.length;
    }, 0);
};

const meanSuccess = (activities: Activity[]): number => {
    const totalScore = activities.reduce((totalScore, activity) => {
        return (
            totalScore +
            activity.exercises.reduce((activityTotalScore, exercise) => {
                return activityTotalScore + exercise.success.current;
            }, 0)
        );
    }, 0);

    return totalScore / getTotalNumberOfExercises(activities);
};

const messages = defineMessages({
    objCompletedIn: {
        id: "dashboard-studentProgression-objectiveCompletedIn",
        defaultMessage: "Objective completed in",
    },
    timeSpentOnObjective: {
        id: "dashboard-studentProgression-timeSpentOnTheObjective",
        defaultMessage: "Time spent on the objective",
    },
});

export default StudentProgression;
