import React from 'react'
import cn from 'classnames'
import { AriaMenuOptions, useMenu, useMenuItem, useMenuTrigger } from '@react-aria/menu';
import { AriaButtonProps, useButton } from "@react-aria/button";
import { MenuTriggerProps, TreeProps, TreeState, useMenuTriggerState, useTreeState } from 'react-stately';
import { Node } from '@react-types/shared'
import Popover from '../Popover/Popover';


interface MenuButtonProps {
    buttonClassname?: string
    buttonContent: React.ReactNode
    menuClassname?: string
    children: React.ReactNode
}

const MenuButton = (props: MenuButtonProps & MenuTriggerProps & AriaMenuOptions<any>) => {
    // Create state based on the incoming props
    let state = useMenuTriggerState(props);

    // Get props for the button and menu elements
    let ref = React.useRef(null);
    let { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref);

    return (
        <>
            <Button
                {...menuTriggerProps}
                buttonRef={ref}
                className={props.buttonClassname}
            >
                {props.buttonContent}
            </Button>
            {state.isOpen &&
                (
                    <Popover state={state} triggerRef={ref} placement="bottom start" hideArrow={true}>
                        <Menu
                            {...props}
                            {...menuProps}
                            className={props.menuClassname}
                        >
                        </Menu>
                    </Popover>
                )}
        </>
    );
}

interface MenuProps {
    className?: string
}

const Menu = (props: TreeProps<any>  & MenuProps) => {
    // Create menu state based on the incoming props
    let state = useTreeState(props);

    // Get props for the menu element
    let ref = React.useRef(null);
    let { menuProps } = useMenu(props, state, ref);

    return (
        <ul
            {...menuProps}
            ref={ref}
            className={cn(props.className)}
        >
            {[...state.collection].map((item) => (
                //TO IMPLEMENT
                // item.type === 'section'
                //   ? <MenuSection key={item.key} section={item} state={state} />
                <MenuItem key={item.key} item={item} state={state} />
            ))}
        </ul>
    );
}

interface MenuItemProps {
    item: Node<any>
    state: TreeState<any>
}

const MenuItem = ({ item, state }: MenuItemProps) => {
    // Get props for the menu item element
    let ref = React.useRef(null);
    let { menuItemProps } = useMenuItem(
        { key: item.key },
        state,
        ref
    );

    return (
        <li
            {...menuItemProps}
            ref={ref}
        >
            {item.rendered}
        </li>
    );
}


interface ButtonProps {
    buttonRef: React.RefObject<HTMLButtonElement>
    className?: string
}

function Button(props: ButtonProps & AriaButtonProps<"button">) {
    let ref = props.buttonRef;
    let { buttonProps } = useButton(props, ref);
    return (
        <button {...buttonProps} ref={ref} className={cn(props.className)}>
            {props.children}
        </button>
    );
}

export { MenuButton }