import React, { useContext, useEffect, useState } from "react";
// External modules
import { defineMessages, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { customAlphabet, urlAlphabet } from "nanoid";
// Contexts
import { configStore } from "../../../../contexts/ConfigContext";
import { errorStore } from "../../../../contexts/ErrorContext";
// Components
import Input from "../../../../design-system-components/Input/Input";
import Button from "../../../../design-system-components/Button/Button";
import Students from "./Students/Students";
import Modal from "../../../../design-system-components/Modal/Modal";
import Icon from "../../../../design-system-components/Icon/Icon";
// Utils
//Hooks
import useAthenaAPIClient from "../../../../hooks/useAthenaAPIClient";
// SCSS
import "./StudentCreator.scss";
// Interfaces
import { Student } from "../../../../interfaces/User";
import { sessionStore } from "../../../../contexts/SessionContext";
import { OverlayTriggerState } from "@react-stately/overlays";
import Dialog from "../../../../design-system-components/Dialog/Dialog";
export interface IFormValues {
    firstname: string;
    lastname: string;
}
interface StudentCreatorProps {
    classroom: {
        name: string;
        id: string;
    };
    onEnd: (students: Student[]) => void;
    onCancel: () => void;
    state: OverlayTriggerState;
}

const StudentCreator = ({
    classroom,
    onEnd,
    onCancel,
    state,
}: StudentCreatorProps) => {
    const intl = useIntl();
    const nanoid = customAlphabet(urlAlphabet, 10);
    const {
        config: { declinaison },
    } = useContext(configStore);
    const {
        session: { fragments, userProvider },
    } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isDirty },
    } = useForm<IFormValues>();
    const [processing, setProcessing] = useState<boolean>(false);

    const athenaAPIClient = useAthenaAPIClient();

    const [students, setStudents] = useState<Student[]>([]);

    const focusFirstNameInput = () => {
        let input = document.getElementById("firstname");
        input?.focus();
    };
    useEffect(() => {
        focusFirstNameInput();
    }, []);

    const createStudents = async () => {
        if (!students.length) return;
        setProcessing(true);
        const studentsData = students.map((student) => {
            return {
                ...student,
                classrooms: [classroom.id],
            };
        });

        try {
            await athenaAPIClient.createStudents(studentsData);
        } catch {
            setErrorInfo((errorInfo) => {
                return {
                    ...errorInfo,
                    displayModal: true,
                    modal: {
                        type: "POPUP",
                        className: "student-creator--errorModal",
                        content: {
                            title: (
                                <>
                                    <Icon
                                        style={{ marginRight: 8 }}
                                        path="warning_outline"
                                        size="medium"
                                    />
                                    {intl.formatMessage(
                                        messages.createStudentErrorTitle
                                    )}
                                </>
                            ),
                            text: (
                                <>
                                    {intl.formatMessage(
                                        messages.createStudentErrorMessage
                                    )}
                                </>
                            ),
                        },
                    },
                };
            });
        }

        setProcessing(false);
        onEnd(students);
    };

    const onStudentSubmit = async (data: IFormValues) => {
        const newStudent = {
            id: "",
            external_id: nanoid(),
            provider: userProvider,
            variation: declinaison,
            first_name: data.firstname,
            last_name: data.lastname,
            classrooms: [classroom.id],
            extra: "none",
            createCode: true,
            config: {
                fragments_config: fragments,
            },
        } as Student;
        let newStudentsArray = students;
        newStudentsArray.push(newStudent);
        setStudents(newStudentsArray);
        reset();
        focusFirstNameInput();
    };

    const removeStudent = (id: string) => {
        setStudents(students.filter((student) => student.external_id !== id));
    };
    return (
        <Modal onClose={onCancel} state={state} isDismissable={true}>
            <Dialog
                title={{
                    as: "h2",
                    content: intl.formatMessage(messages.title, {
                        classroomName: classroom.name,
                    }),
                    className: "student-creator__title",
                }}
                onClose={onCancel}
            >
                <div className="student-creator">
                    <form onSubmit={handleSubmit(onStudentSubmit)}>
                        <div className="student-creator__firstname">
                            <label>
                                {intl.formatMessage(messages.firstName)}
                            </label>
                            <Input
                                id="firstname"
                                error={
                                    errors.firstname?.type === "required"
                                        ? intl.formatMessage(messages.required)
                                        : undefined
                                }
                                {...register("firstname", { required: true })}
                            />
                        </div>
                        <div className="student-creator__lastname">
                            <label>
                                {intl.formatMessage(messages.lastName)}
                            </label>
                            <Input
                                id="lastname"
                                error={
                                    errors.lastname?.type === "required"
                                        ? intl.formatMessage(messages.required)
                                        : undefined
                                }
                                {...register("lastname", { required: true })}
                            />
                        </div>
                        <Button
                            className="student-creator__submit"
                            disabled={!isDirty}
                            variant="secondary"
                            label={intl.formatMessage(messages.add)}
                            icon={{
                                path: "user-add-outlined",
                            }}
                            type="submit"
                        />
                    </form>
                    <Students
                        students={students}
                        onStudentDelete={removeStudent}
                    />
                    <div className="student-creator__actions">
                        <Button
                            className="student-creator__actions--cancel"
                            variant="tertiary"
                            label={intl.formatMessage(messages.cancel)}
                            onClick={onCancel}
                        />
                        <Button
                            className="student-creator__actions--create"
                            variant="primary"
                            label={intl.formatMessage(
                                messages.addStudentCount,
                                { count: students.length }
                            )}
                            onClick={createStudents}
                            disabled={processing || students.length === 0}
                            icon={{
                                path: "user-add-outlined",
                                position: "left",
                                size: "medium",
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        </Modal>
    );
};

export default StudentCreator;

const messages = defineMessages({
    title: {
        id: "studentCreator-title",
        defaultMessage: "{classroomName} - add students",
    },
    firstName: {
        id: "studentCreator-firstName",
        defaultMessage: "First name",
    },
    lastName: {
        id: "studentCreator-lastName",
        defaultMessage: "Last name",
    },
    add: {
        id: "studentCreator-add",
        defaultMessage: "Add student",
    },
    addStudentCount: {
        id: "studentCreator-addStudentCount",
        defaultMessage:
            "Add {count, number} {count, plural, one {student} other {students}}",
    },
    cancel: {
        id: "cancel",
        defaultMessage: "Cancel",
    },
    createStudentErrorTitle: {
        id: "studentCreator-createStudentErrorTitle",
        defaultMessage: "Error",
    },
    createStudentErrorMessage: {
        id: "studentCreator-createStudentErrorMessage",
        defaultMessage: "An error has occured while creating students:",
    },
    required: {
        id: "form-field-required",
        defaultMessage: "This field is required",
    },
});
