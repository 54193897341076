import React, { useContext, useState } from "react";
import {
    defineMessages,
    IntlShape,
    useIntl,
} from "react-intl";
import { HtmlString, Module } from "@evidenceb/gameplay-interfaces";
import { configStore } from "../../../../contexts/ConfigContext";
import { dataStore } from "../../../../contexts/DataContext";
import { getItemDescription, getResourceIndex } from "../../../../utils/dataRetrieval";
import ControlledDialog from "../../../../design-system-components/ControlledDialog/ControlledDialog";
import ProgressBarWithLabel from "../../../../components/ProgressBarWithLabel/ProgressBarWithLabel";
import ModuleMap, { moduleMapMessages } from "../../../../components/ModuleMap/ModuleMap";
import VisuallyHidden from "../../../../components/VisuallyHidden/VisuallyHidden";

import errorPicto from "../error.svg";
import lockedPicto from "./locked.svg";
import { parse } from "@evidenceb/athena-common";
import { sessionStore } from "../../../../contexts/SessionContext";
import CardAction from "../CardAction/CardAction";

interface Props {
    type: "locked" | "error" | "specimen_locked";
    module: Module;
    diagnosis?: {
        progression: number;
        averageScore: number;
        isModuleFinished: boolean;
    };
}

const InactiveModuleCard = ({ type, module, diagnosis }: Props) => {
    const { data } = useContext(dataStore);
    const {
        config: { features },
    } = useContext(configStore);
    const { session } = useContext(sessionStore)
    const [showModal, setShowModal] = useState<boolean>(false);
    const intl = useIntl();

    return (
        <>
            {showModal && type !== 'specimen_locked' && (
                <ControlledDialog
                    {...getModalTitleAndDescription(type, intl)}
                    primaryButton={{
                        label: getModalButtonLabel(type, intl),
                        fn: () => {
                            setShowModal(false);
                        },
                    }}
                />
            )}

            <article className="new-student-module-card --inactive">
                {type !== "specimen_locked" && <img src={lockedPicto} alt="" className="card__locked-picto" />}

                <div className="card__head">
                    {features.showModuleMapInStudentModuleList &&
                        module.studentPath && (
                            <ModuleMap
                                module={module}
                                btnLabel={
                                    <VisuallyHidden>
                                        {intl.formatMessage(
                                            moduleMapMessages.studentBtnLabel
                                        )}
                                    </VisuallyHidden>
                                }
                                modalTitle={intl.formatMessage(
                                    moduleMapMessages.studentModalTitle
                                )}
                            />
                        )}
                    <p className="card__title">
                        {features.teacherModuleListShowFullTitle
                            ? parse(getItemDescription(module, "student"))
                            : parse(
                                getResourceIndex(module.id, data.modules) +
                                1 +
                                ". " +
                                getItemDescription(module, "student")
                            )}
                        {session.specimen &&
                            <span className="card__title__out-of-specimen">
                                {intl.formatMessage({ id: "specimen-out-of-specimen", defaultMessage: "(out of specimen)" })}
                            </span>
                        }
                    </p>

                    {
                        // Placeholder to maintain the title in the center of the
                        // flex div when there is a StudentPath icon
                        module.studentPath && <div></div>
                    }
                </div>

                {type === "error" && (
                    <img
                        src={errorPicto}
                        alt=""
                        className="card__error-picto"
                    />
                )}

                {(type === "locked" || type === "specimen_locked") && (
                    <>
                        <ProgressBarWithLabel
                            progress={diagnosis?.progression ?? 0}
                            label={intl.formatMessage(messages.progression)}
                            color="#BAE7E9"
                        />
                        <ProgressBarWithLabel
                            progress={diagnosis?.averageScore ?? 0}
                            label={intl.formatMessage(messages.successRate)}
                            color="rgba(255, 184, 0, 0.15)"
                        />
                    </>
                )}

                { type !== "specimen_locked" &&
                    <div className="card__action">
                        <button
                            className="card__error-button"
                            onClick={() => {
                                setShowModal(true);
                            }}
                        >
                            {getCardButtonLabel(type, intl)}
                        </button>
                    </div>
                }
                {type === "specimen_locked" &&
                    <CardAction inactive />
                }
            </article>
        </>
    );
};

const getModalTitleAndDescription = (
    type: "locked" | "error",
    intl: IntlShape
): { title: string; description: HtmlString } => {
    switch (type) {
        case "error":
            return {
                title: intl.formatMessage(messages.moduleUnavailable),
                description: {
                    $html: intl.formatMessage(messages.moduleUnavailableDescr),
                },
            };
        case "locked":
            return {
                title: intl.formatMessage(messages.moduleLocked),
                description: {
                    $html: intl.formatMessage(messages.moduleLockedDescr),
                },
            };
    }
};

const getModalButtonLabel = (
    type: "locked" | "error",
    intl: IntlShape
): string => {
    switch (type) {
        case "error":
            return intl.formatMessage(messages.continue);
        case "locked":
            return intl.formatMessage(messages.continueLocked);
    }
};

const getCardButtonLabel = (
    type: "error" | "locked",
    intl: IntlShape
): string => {
    switch (type) {
        case "error":
            return intl.formatMessage(messages.btnModuleUnavailable);
        case "locked":
            return intl.formatMessage(messages.btnModuleLocked);
    }
};

const messages = defineMessages({
    progression: {
        id: "moduleList-student-progression",
        defaultMessage: "Progression",
    },
    successRate: {
        id: "moduleList-student-successRate",
        defaultMessage: "Success rate",
    },
    moduleUnavailable: {
        id: "errors-statements-unavailableModule-moduleCard-modal-title",
        defaultMessage: "Module unavailable",
    },
    moduleUnavailableDescr: {
        id: "errors-statements-unavailableModule-moduleCard-modal-description",
        defaultMessage: "An error occured while trying to load the module.",
    },
    moduleLocked: {
        id: "moduleList-lockedModule-modalTitle",
        defaultMessage: "Module locked",
    },
    moduleLockedDescr: {
        id: "moduleList-lockedModule-modalDescription",
        defaultMessage: "This module was locked by your teacher.",
    },
    continue: {
        id: "errors-statements-unavailableModule-moduleCard-modal-continueButton",
        defaultMessage: "Continue",
    },
    continueLocked: {
        id: "moduleList-lockedModule-modalButton",
        defaultMessage: "Continue",
    },
    btnModuleUnavailable: {
        id: "errors-statements-unavailableModule-moduleCard-description",
        defaultMessage: "Module temporarily unavailable",
    },
    btnModuleLocked: {
        id: "moduleList-lockedModule-modalLink",
        defaultMessage: "Module locked by the teacher",
    },
});

export default InactiveModuleCard;
