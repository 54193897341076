import React, { useContext, useState } from "react";
// External modules
import { useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";
//Hooks
import { useAthenaTokenlessAPIClient } from "../../../hooks/useAthenaAPIClient";
//Contexts
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
// Components
import Button from "../../../design-system-components/Button/Button";
import Input from "../../../design-system-components/Input/Input";
import Card from "../../../design-system-components/Card/Card";
import { authMessages } from "../auth-messages";
import Error from "../Error/Error";
//utilz
import * as localStorage from "../../../utils/localStorage";
import { commonMessages } from "../../../utils/messages";
// SCSS
import "./TeacherAuth.scss";
import { Image } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../hooks/useAssetsDetails";
// Interfaces
export interface IFormValues {
    email: string;
    password: string;
}
const TeacherAuth = () => {
    const {
        config: { auth },
    } = useContext(configStore);
    const {
        session: { app },
    } = useContext(sessionStore);
    const assetsDetails = useAssetsDetails();

    const athenaAPIClient = useAthenaTokenlessAPIClient();
    const intl = useIntl();
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
    } = useForm<IFormValues>();

    const [error, setError] = useState<string | undefined>();

    const onSubmit = async (data: { email: string; password: string }) => {
        let body = {
            email: data.email,
            password: data.password,
            app: app!,
            role: "teacher",
        };
        try {
            let response = await athenaAPIClient.directAuthTeacher(body);
            if (response) {
                localStorage.setItem(localStorage.Key.TOKEN, response.token);
                window.location.replace("/");
            } else {
                setError(
                    intl.formatMessage(messages.loginErrorCredentialsNotValid)
                );
            }
            
        } catch (err) {
            setError(
                intl.formatMessage(messages.loginErrorCredentialsNotValid)
            );
        }
    };

    return (
        <div className="teacher-auth">
            <Button
                variant="primary"
                label={intl.formatMessage(commonMessages.back)}
                icon={{
                    path: "arrow_left",
                    position: "left",
                    size: "medium",
                }}
                asRouterLink={{
                    to: "/auth/",
                }}
            />
            <Card
                className="teacher-auth__card"
                cardTitle={{
                    as: "h1",
                    content: intl.formatMessage(authMessages.teacherAuthTitle),
                }}
            >
                {auth.mascotUrl && (
                    <div className="auth___mascot" aria-hidden={true}>
                        <Image src={auth.mascotUrl} alt="" assetsDetails={assetsDetails} />
                    </div>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="teacher-auth__input--email">
                        <label htmlFor="email">
                            {intl.formatMessage(commonMessages.email)}
                        </label>
                        <Input
                            id="email"
                            type="email"
                            error={
                                errors.email?.type === "required"
                                    ? intl.formatMessage(
                                          authMessages.emailRequired
                                      )
                                    : undefined
                            }
                            {...register("email", { required: true })}
                        />
                    </div>

                    <div className="teacher-auth__input--password">
                        <label htmlFor="password">
                            {intl.formatMessage(commonMessages.password)}
                        </label>
                        <Input
                            id="password"
                            type="password"
                            allowReveal={true}
                            error={
                                errors.password?.type === "required"
                                    ? intl.formatMessage(
                                          authMessages.passwordRequired
                                      )
                                    : undefined
                            }
                            {...register("password", { required: true })}
                        />
                    </div>

                    <Link to="/auth/teacher/pwd" className="forgotten-password">
                        {intl.formatMessage(messages.passwordForgotten)}
                    </Link>

                    <Button
                        type="submit"
                        className="teacher-auth__loginBtn"
                        disabled={!isDirty}
                        variant="primary"
                        label={intl.formatMessage(authMessages.login)}
                        center={true}
                    />

                    {auth.createAccountUrl && (
                        <div className="sign-in">
                            {intl.formatMessage(messages.noAccount)}{" "}
                            <a href={auth.createAccountUrl}>
                                {intl.formatMessage(messages.noAccountLink)}
                            </a>
                        </div>
                    )}
                </form>
                {error && <Error error={error} />}
            </Card>

            <div className="previous-step">
                {intl.formatMessage(authMessages.notATeacher)}{" "}
                <Link to="/auth/student">
                    {intl.formatMessage(authMessages.loginAsStudent)}
                </Link>
            </div>
        </div>
    );
};

export default TeacherAuth;

const messages = defineMessages({
    passwordForgotten: {
        id: "authentication-teacher-passwordForgotten",
        defaultMessage: "I forgot my password",
    },
    loginErrorCredentialsNotValid: {
        id: "authentication-teacher-loginErrorCredentialsNotValid",
        defaultMessage: "Email and/or password are invalid",
    },
    noAccount: {
        id: "authentication-teacher-noAccount",
        defaultMessage: "You don't have an account yet?",
    },
    noAccountLink: {
        id: "authentication-teacher-noAccountLink",
        defaultMessage: "Sign in here",
    },
});
