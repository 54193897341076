import React, { useContext, useEffect } from "react";
import { sessionStore } from "../../contexts/SessionContext";
import PlaySingleExercise from "./PlaySingleExercise/PlaySingleExercise";
import UseLocalHistory from "./UseLocalHistory/UseLocalHistory";
import WindowContextLoader from "./WindowContextLoader/WindowContextLoader";
import { featureFlagsStore } from "@evidenceb/athena-common";
import * as localStorage from "./../../utils/localStorage";
import { isProductionEnvironment } from "../../utils/env";

/**
 * This components adds development functionalities to the specimen.
 */
const Dev = () => {
    const { setFeatureFlags } = useContext(featureFlagsStore);

    const {setSession} = useContext(sessionStore);
    /*
    * Log Exercise details feature
    */ 
    const enableLogExerciseDetails = () => {
        setSession(curr => {
            return{
                ...curr,
                flags:{
                    ...curr.flags,
                    logExerciseDetails: true
                }
            }
        })    
    }
    
    (window as any).DEBUG_UTILS = {
        enableLogExerciseDetails
    }
    
    /*
    * toggle a feature flag
    */ 
   const toggleFeature = (featureName: string, toggle: boolean) => {
       const storageFlags = localStorage.getItem<string[]>(localStorage.Key.DEV_FLAGS);
       if (!storageFlags && toggle)
           localStorage.setItem(localStorage.Key.DEV_FLAGS, [featureName]);
       else if (storageFlags && storageFlags.includes(featureName) && !toggle)
           localStorage.setItem(localStorage.Key.DEV_FLAGS, storageFlags.filter(item => item !== featureName));
       else if (storageFlags && !storageFlags.includes(featureName) && toggle)
           localStorage.setItem(localStorage.Key.DEV_FLAGS, [...storageFlags, featureName]);

       setFeatureFlags(flags => {
            return {
                ...flags,
                [featureName]: toggle
            }
        })   
    }
    useEffect(() => {
        const storageFlags = localStorage.getItem<string[]>(localStorage.Key.DEV_FLAGS);
        if (!storageFlags) return;
        setFeatureFlags((curr) => ({
            ...curr,
            ...storageFlags.reduce((flags, storageFlag) => ({...flags, [storageFlag]: true}), {})
        }))
    }, [setFeatureFlags]);
    if (!isProductionEnvironment(window.location.hostname)) {
        (window as any).DEBUG_UTILS.toggleFeature = toggleFeature
    }
    
    return (
        <>
            <WindowContextLoader />
            <PlaySingleExercise />
            <UseLocalHistory />
        </>
    );
};

export default Dev;
