import React from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import Chip, {
    ChipProps,
} from "../../../../../design-system-components/Chip/Chip";
import { Objective } from "../../../../../interfaces/Dashboard";
import { formatDuration, timeI18n } from "../../../../../utils/format";
import { commonMessages } from "../../../../../utils/messages";
import dashboardMessages from "../../../dashboardMessages";

import "./DetailsChips.scss";

interface Props {
    nbCorrectExercises?: number;
    nbExercises?: number;
    meanScore?: number;
    timeSpent?: {
        time: number;
        label: string;
    };
    alert?: "none" | "positive" | "negative";
}

const DetailsChips = ({ nbCorrectExercises, nbExercises, meanScore, timeSpent, alert }: Props) => {
    const intl = useIntl();

    return (
        <div className="details__chips-container">
            {nbExercises !== undefined && (
                <Chip
                    colorMode="dark"
                    input={`${nbExercises} ${intl.formatMessage(
                        nbExercises === 1
                            ? commonMessages.exercise
                            : commonMessages.exercises
                    )}`}
                />
            )}

            {meanScore !== undefined && (
                <Chip
                    colorMode="dark"
                    label={intl.formatMessage(dashboardMessages.meanSuccess)}
                    input={`${meanScore.toFixed(1).replace(/\.0$/, "")}/1`}
                />
            )}

            {nbCorrectExercises !== undefined && nbExercises !== undefined && (
                <Chip
                    colorMode="dark"
                    label={intl.formatMessage(messages.nbCorrectExercises)}
                    input={`${nbCorrectExercises}/${nbExercises}`}
                />
            )}

            {timeSpent && (
                <Chip
                    colorMode="dark"
                    icon={{
                        path: "time",
                    }}
                    label={timeSpent.label}
                    input={formatDuration(timeSpent.time, timeI18n(intl))}
                />
            )}

            {alert && (
                <Chip
                    colorMode="dark"
                    type={getType(alert)}
                    label={intl.formatMessage(messages.trend)}
                    input={getTrendText(alert, intl)}
                />
            )}
        </div>
    );
};

const getType = (alert: Objective["alert"]): ChipProps["type"] => {
    switch (alert) {
        case "negative":
            return "inf";
        case "none":
            return "conf";
        case "positive":
            return "sup";
        default:
            throw new Error(`Alert type ${alert} not handled`);
    }
};

const getTrendText = (alert: Objective["alert"], intl: IntlShape): string => {
    if (alert === "positive")
        return intl.formatMessage({
            id: "dashboard-studentProgression-superiorSuccessRate",
            defaultMessage: "Actual score superior to the predicted score",
        });
    if (alert === "negative")
        return intl.formatMessage({
            id: "dashboard-studentProgression-inferiorSuccessRate",
            defaultMessage: "Actual score inferior to the predicted score",
        });
    return intl.formatMessage({
        id: "dashboard-studentProgression-expectedSuccessRate",
        defaultMessage: "Actual score conform to the predicted score",
    });
};

const messages = defineMessages({
    trend: {
        id: "dashboard-studentProgression-trend",
        defaultMessage: "Trend",
    },
    nbCorrectExercises: {
        id: "dashboard-studentProgression-nbCorrectExercises",
        defaultMessage: "Score"
    }
});

export default DetailsChips;
