import React, { useContext } from "react";
import { sessionStore, StatementHistory } from "../../../contexts/SessionContext";
import { dataStore } from "../../../contexts/DataContext";
import * as localStorage from "../../../utils/localStorage";
import * as Sentry from "@sentry/react";
import { HistoryItem, PerModule } from "@evidenceb/gameplay-interfaces";

const UseLocalHistory: React.FC = () => {
    const {
        session: { flags },
        setSession,
    } = useContext(sessionStore);
    const { data } = useContext(dataStore);

    (window as any).HISTORY = {
        useLocalHistory: async () => {
            if (flags.useHistoryFrom === StatementHistory.localHistory) return;

            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Use local history",
                level: Sentry.Severity.Info,
            });

            setSession((curr) => {
                return {
                    ...curr,
                    banditManchot: undefined,
                    initialHistory: undefined,
                    flags: {
                        ...curr.flags,
                        useHistoryFrom: StatementHistory.localHistory,
                    },
                };
            });
        },

        useLRSHistory: async () => {
            if (flags.useHistoryFrom === StatementHistory.LRS) return;

            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Use LRS history",
                level: Sentry.Severity.Info,
            });

            setSession((curr) => {
                return {
                    ...curr,
                    banditManchot: undefined,
                    initialHistory: undefined,
                    flags: {
                        ...curr.flags,
                        useHistoryFrom: StatementHistory.LRS,
                    },
                };
            });
        },

        clearLocalHistory: async () => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Clear local history",
                level: Sentry.Severity.Info,
            });

            localStorage.removeItem(localStorage.Key.LOCAL_HISTORY);
            if (flags.useHistoryFrom === StatementHistory.localHistory) {
                setSession((curr) => {
                    return {
                        ...curr,
                        banditManchot: undefined,
                        initialHistory: {
                            status: "settled",
                            value: data.modules.reduce((history, module) => {
                                history[module.id] = [];
                                return history;
                            }, {} as PerModule<HistoryItem[]>),
                        },
                        flags: {
                            ...curr.flags,
                        }, // This is to trigger the UI refresh
                    };
                });
            }
        },
    };

    return <></>;
};

export default UseLocalHistory;
