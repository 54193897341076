import React from "react";
import { FormattedMessage } from "react-intl";
import Icon from "../../../../../design-system-components/Icon/Icon";

interface Props {
    syncingStatements: boolean;
    syncingSuccess?: boolean;
    syncStatements: () => void;
}

const NotificationContent = ({
    syncingStatements,
    syncingSuccess,
    syncStatements,
}: Props) => {
    if (syncingStatements)
        return (
            <p>
                <FormattedMessage id="errors-statements-cannotSendStatement-notification-attemptingToSync" defaultMessage="Saving in progress" />
            </p>
        );

    if (syncingSuccess)
        return (
            <p className="notification-message--with-icon --success">
                <Icon path="checkmark" className="notification-message--with-icon__icon"/>
                <FormattedMessage id="errors-statements-cannotSendStatement-notification-syncSuccess" defaultMessage="Saving complete!" />
            </p>
        );

    return (
        <>
            <p>
                <FormattedMessage id="errors-statements-cannotSendStatement-notification-description" defaultMessage="Your data is being saved. we will try again shortly" />
            </p>
            <button
                onClick={() => {
                    syncStatements();
                }}
            >
                <FormattedMessage id="errors-statements-cannotSendStatement-notification-tryToSyncButton" defaultMessage="Save now" />
            </button>
        </>
    );
};

export default NotificationContent;
