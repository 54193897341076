import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { defineMessages, useIntl } from "react-intl";
import * as Sentry from "@sentry/react";
import { UserType } from "../../../interfaces/User";
import { errorStore } from "../../../contexts/ErrorContext";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import useAthenaAPIClient from "../../../hooks/useAthenaAPIClient";
import {
    checkPRLockStatusIntegrity,
    comparePRLockStatus,
    updatePRLockStatus,
} from "../../../utils/prm";
import { getUrl } from "../../../utils/dataRetrieval";
import {
    PRItem,
    PRLockStatus,
} from "../../../interfaces/PedagogicalResourcesManagement";
import { dataStore } from "../../../contexts/DataContext";

export interface UsePRManagerReturn {
    currentPRLockStatus?: PRLockStatus;
    dirty: boolean;
    toggleItems: (items: PRItem[]) => void;
    reset: () => void;
    save: () => Promise<void>;
}

/**
 * Hook used to handle the logic of activation/deactivation of pedagogical
 * resources
 */
const usePRManager = (classroomId: string): UsePRManagerReturn => {
    const {
        session: { userId },
    } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);
    const history = useHistory();
    const athenaAPIClient = useAthenaAPIClient();

    const [initialPRLockStatus, setInitialPRLockStatus] =
        useState<PRLockStatus>();
    const [currentPRLockStatus, setCurrentPRLockStatus] =
        useState<PRLockStatus>();
    const [dirty, setDirty] = useState<boolean>(false);
    const [lockStatusError, setLockStatusError] = useState<boolean>(false);
    const intl = useIntl();

    // Get initial lock status
    useEffect(() => {
        if (lockStatusError || initialPRLockStatus) return;

        (async () => {
            try {
                const allLockStatus =
                    await athenaAPIClient.getResourcesLockStatus(
                        userId,
                        UserType.Teacher
                    );
                const checkedPRLockStatus = checkPRLockStatusIntegrity(
                    allLockStatus[classroomId],
                    data,
                    config.ai
                );
                setInitialPRLockStatus(checkedPRLockStatus);
                setCurrentPRLockStatus(checkedPRLockStatus);
            } catch (err) {
                Sentry.captureException(err);
                setLockStatusError(true);
                setErrorInfo({
                    displayModal: true,
                    modal: {
                        type: "POPUP",
                        content: {
                            title: intl.formatMessage(messages.errorTitle),
                            text: intl.formatMessage(messages.errorText),
                            btn: {
                                label: intl.formatMessage(
                                    messages.goToDashboard
                                ),
                            },
                        },
                        onClick: () => {
                            history.push(
                                `/${
                                    getUrl(
                                        config.pages.find(
                                            (page) => page.type === "DASHBOARD"
                                        )!,
                                        UserType.Teacher
                                    ) as string
                                }/${intl.formatMessage(
                                    messages.classes
                                )}/${classroomId}`
                            );
                        },
                    },
                });
            }
        })();
    }, [
        userId,
        classroomId,
        athenaAPIClient,
        initialPRLockStatus,
        setErrorInfo,
        history,
        intl,
        lockStatusError,
        config.pages,
        config.ai,
        data,
    ]);

    return {
        currentPRLockStatus,

        dirty,

        toggleItems: (items) => {
            if (!currentPRLockStatus) return;

            const newPRLockStatus = updatePRLockStatus(
                currentPRLockStatus,
                items
            );
            setCurrentPRLockStatus(newPRLockStatus);

            if (
                initialPRLockStatus &&
                comparePRLockStatus(initialPRLockStatus, newPRLockStatus)
            )
                setDirty(false);
            else setDirty(true);
        },

        reset: () => {
            if (!currentPRLockStatus || !initialPRLockStatus) return;

            setCurrentPRLockStatus(initialPRLockStatus);
            setDirty(false);
        },

        save: async () => {
            if (!currentPRLockStatus) return;

            await athenaAPIClient.updateResourcesLockStatus(
                classroomId,
                currentPRLockStatus
            );
        },
    };
};

const messages = defineMessages({
    errorTitle: {
        id: "prm-lockStatusErrorTitle",
        defaultMessage: "Cannot retrieve lock status",
    },
    errorText: {
        id: "prm-lockStatusErrorText",
        defaultMessage:
            "An error ocurred while retrieving the resources' lock status. Try again later.",
    },
    goToDashboard: {
        id: "prm-lockStatusErrorBtn",
        defaultMessage: "Go back to the dashoard",
    },
    classes: {
        id: "dashboard-paths-classes",
        defaultMessage: "classes",
    },
});

export default usePRManager;
