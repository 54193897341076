import React, { useContext, useMemo } from "react";
// Contexts
import { dataStore } from "../../../../contexts/DataContext";
// Components
import Icon from "../../../../design-system-components/Icon/Icon";
// Hooks
// Utils
import { getActivityById } from "../../../../utils/dataRetrieval";
// SCSS
import "./ProgressionGraph.scss";
// Interfaces
import { Activity } from "../../../../interfaces/Dashboard";
import { Link } from "react-router-dom";
import Details from "../Details/Details";
import Legend from "./Legend/Legend";
import SimpleBar from "simplebar-react";
import { FormattedMessage, useIntl } from "react-intl";
import ResultPopover from "../PopoverCustom/ResultPopover";
interface ProgressionGraphProps {
    activities: Activity[];
}
const ProgressionGraph = ({ activities }: ProgressionGraphProps) => {
    const { data } = useContext(dataStore);
    const intl = useIntl();
    // States
    // Functions
    // Calculate maximum success to have most appropriate bar height
    const maximumSuccess = useMemo<number>(() => {
        let number = activities.reduce((max, activity) => {
            const activityMax = activity.exercises.reduce(
                (activityMax, exercise) => {
                    if (
                        exercise.success.current >= exercise.success.expected &&
                        exercise.success.current >= activityMax
                    )
                        return exercise.success.current;
                    if (
                        exercise.success.expected >= exercise.success.current &&
                        exercise.success.expected >= activityMax
                    )
                        return exercise.success.expected;
                    return activityMax;
                },
                0
            );
            return activityMax > max ? activityMax : max;
        }, 0);
        return number;
    }, [activities]);

    return (
        <div className="ProgressionGraph">
            <h2>
                <FormattedMessage
                    id="dashboard-studentProgression-contentTitle"
                    defaultMessage="Student score per exercise"
                />
            </h2>

            <p>
                <FormattedMessage
                    id="dashboard-studentProgression-contentSubtitle"
                    defaultMessage="Compared to the predicted score"
                />
            </p>

            {/* On large screen */}
            <div className="legend-container">
                <Legend />
            </div>
            {/* On smaller screends */}
            <Details
                className="legend-container--details"
                summary={{
                    open: intl.formatMessage({
                        id: "dashboard-studentProgression-hideLegend",
                        defaultMessage: "Hide legend",
                    }),
                    closed: intl.formatMessage({
                        id: "dashboard-studentProgression-showLegend",
                        defaultMessage: "Show legend",
                    }),
                }}
            >
                <Legend />
            </Details>

            <div className="progression-content">
                <div className="axes">
                    <div className="axis__x"></div>
                    <div className="axis__y">
                        <span>
                            <FormattedMessage
                                id="success"
                                defaultMessage="Success"
                            />
                        </span>
                    </div>
                </div>

                <SimpleBar className="graph-container">
                    {activities.map((activity, activityIndex) => (
                        <div
                            className="activity-container"
                            key={"act-" + activityIndex}
                        >
                            <div className="exercises-container">
                                {activity.exercises.map((exercise, i) => (
                                    <div
                                        className="exercise"
                                        key={exercise.id + ("-ex" + i)}
                                    >
                                        <div className="bars">
                                            {/* The height of the bars should have a minimum so that even if the score is 0 the bar still shows */}
                                            <div
                                                className="bar bar--expected"
                                                style={{
                                                    height:
                                                        15 +
                                                        (exercise.success
                                                            .expected /
                                                            maximumSuccess) *
                                                            85 +
                                                        "%",
                                                }}
                                            ></div>
                                            <div
                                                className={`bar bar--${exercise.success.alert} bar--current`}
                                                style={{
                                                    height:
                                                        15 +
                                                        (exercise.success
                                                            .current /
                                                            maximumSuccess) *
                                                            85 +
                                                        "%",
                                                }}
                                            >
                                                <ResultPopover exercise={exercise}/>

                                                {exercise.success.alert ===
                                                    "none" && (
                                                    <Icon
                                                        size="medium"
                                                        color={"white"}
                                                        path="conf_simple"
                                                    />
                                                )}
                                                {exercise.success.alert ===
                                                    "negative" && (
                                                    <Icon
                                                        size="medium"
                                                        color={"white"}
                                                        path="arrow_drop_down"
                                                    />
                                                )}
                                                {exercise.success.alert ===
                                                    "positive" && (
                                                    <Icon
                                                        size="medium"
                                                        color={"white"}
                                                        path="arrow_drop_up"
                                                    />
                                                )}
                                            </div>
                                        </div>

                                        <div className="exercise__legend">
                                            <Link
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                to={"/play/" + exercise.id}
                                            >
                                                {`${intl.formatMessage({
                                                    id: "dashboard-studentProgression-exerciseShort",
                                                    defaultMessage: "ex",
                                                })} ${getContinuousIndex(
                                                    activities,
                                                    activityIndex,
                                                    i
                                                )}`}
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="activity__legend">
                                {getActivityById(activity.id, data).title.long}
                            </div>
                        </div>
                    ))}
                </SimpleBar>
            </div>
        </div>
    );
};

/**
 * Indexing the exercises in the chart should not reset when the same activity
 * appears twice. This function calculates the index of the exercice, taking
 * possible exercices in the same activity that have already appreared
 */
const getContinuousIndex = (
    activities: Activity[],
    currentActivityIndex: number,
    exerciseIndexInCurrentActivity: number
): number => {
    return (
        activities
            .slice(0, currentActivityIndex)
            .reduce((nbOfExercicesInSameActivity, activity) => {
                if (activity.id !== activities[currentActivityIndex].id)
                    return nbOfExercicesInSameActivity;
                return nbOfExercicesInSameActivity + activity.exercises.length;
            }, 0) +
        exerciseIndexInCurrentActivity +
        1
    );
};

export default ProgressionGraph;
