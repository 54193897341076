import React, { useContext, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { configStore } from "../../../contexts/ConfigContext";
import { getSingleClassroomUrl } from "../../../utils/navigation-dashboard";
import { UsePRManagerReturn } from "../Manager/usePRManager";
import CancelModal from "../Modals/CancelModal";
import SuccessModal from "../Modals/SuccessModal";
import FailureModal from "../Modals/FailureModal";

import "./ActionTray.scss";

interface Props extends Pick<UsePRManagerReturn, "reset" | "save" | "dirty"> {
    classroomId: string;
}

const ActionTray = ({ save, reset, classroomId, dirty }: Props) => {
    const { config } = useContext(configStore);
    const history = useHistory();
    const confirmButtonRef = useRef<HTMLButtonElement>(null);
    const [modal, setModal] = useState<JSX.Element | null>(null);
    const intl = useIntl();

    const classroomUrl = useMemo(
        () => getSingleClassroomUrl(config.pages, classroomId, intl),
        [intl, classroomId, config.pages]
    );

    return (
        <>
            {modal}

            <div className="prm__action-tray">
                <button
                    className="action-tray__cancel"
                    onClick={() => {
                        if (!dirty) {
                            history.push(classroomUrl);
                            return;
                        }

                        setModal(
                            <CancelModal reset={reset} setModal={setModal} />
                        );
                    }}
                >
                    <FormattedMessage
                        id="prm-manager-cancel"
                        defaultMessage="Cancel"
                    />
                </button>

                <button
                    ref={confirmButtonRef}
                    className={`action-tray__confirm ${
                        dirty ? "" : "--inactive"
                    }`}
                    onClick={async () => {
                        try {
                            await save();
                            setModal(<SuccessModal url={classroomUrl} />);
                        } catch (err) {
                            setModal(
                                <FailureModal
                                    setModal={setModal}
                                    confirmButtonRef={confirmButtonRef}
                                    url={classroomUrl}
                                />
                            );
                        }
                    }}
                >
                    <FormattedMessage
                        id="prm-manager-confirm"
                        defaultMessage="Confirm"
                    />
                </button>
            </div>
        </>
    );
};

export default ActionTray;
