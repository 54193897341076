import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { configStore } from "../../../../contexts/ConfigContext";
import { getUrl } from "../../../../utils/dataRetrieval";
import { sessionStore } from "../../../../contexts/SessionContext";
import { syncStore } from "../../../../contexts/Sync";

import playerEndingIcon from "../player-ending-icon.png";
import Button from "../../../../design-system-components/Button/Button";
import { PlaylistSummaryProps } from "../DefaultPlaylistSummary/DefaultPlaylistSummary";
import Loader from "../../../../components/Loader/Loader";

import "./AdaptiveTestPlaylistSummary.scss";
import { FormattedMessage, useIntl } from "react-intl";

const AdaptiveTestPlaylistSummary = ({ opts }: PlaylistSummaryProps) => {
    const { config } = useContext(configStore);
    const { moduleId } = useParams<{ moduleId: string }>();
    const {
        session: { userType },
    } = useContext(sessionStore);
    const {
        sync: { isProcessing },
    } = useContext(syncStore);
    const history = useHistory();
    const intl = useIntl();

    if (isProcessing) return <Loader />;

    return (
        <div className="adaptive-test-playlist-summary-container">
            <div className="content">
                <p>
                    <FormattedMessage
                        id="playlist-summary-adaptive-test-congratulations-msg"
                        defaultMessage="You have completed the test!"
                    />
                </p>
                <p>
                    <FormattedMessage
                        id="playlist-summary-adaptive-test-completed-msg"
                        defaultMessage="Your results and learning recommendations are now available!"
                    />
                </p>
                <img
                    className="playlist-summary-icon"
                    alt=""
                    src={playerEndingIcon}
                    aria-hidden="true"
                />

                <nav>
                    <Button
                        className="playlist-summary__module-list-btn"
                        onClick={() => {
                            const page = config.pages.find(
                                (page) => page.type === "MODULELIST"
                            )!;
                            const url = `/${getUrl(
                                page,
                                userType
                            )}/test-result/${moduleId}`;
                            history.push(url);
                        }}
                        variant="primary"
                        label={intl.formatMessage({
                            id: "playlist-summary-adaptive-test-btn-label",
                            defaultMessage:
                                "Go to my results and recommendations",
                        })}
                        color="primary"
                        icon={{
                            path: "arrow_forward",
                            size: "medium",
                            position: "right",
                        }}
                    />
                </nav>
            </div>
        </div>
    );
};

export default AdaptiveTestPlaylistSummary;
