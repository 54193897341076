import React, { useContext, useEffect } from "react";
import { useOverlayTriggerState } from "react-stately";
import cn from "classnames";

import Modal from "../../design-system-components/Modal/Modal";
import Dialog from "../../design-system-components/Dialog/Dialog";

//Components
import Popup from "./Popup/Popup";

//SCSS
import "./ErrorModal.scss";

//Interfaces
import { errorStore } from "../../contexts/ErrorContext";

const ErrorModal = () => {
    //States
    const { errorInfo, setErrorInfo } = useContext(errorStore);
    const state = useOverlayTriggerState({});

    const closeModal = () => setErrorInfo({ displayModal: false });

    useEffect(() => {
        if (errorInfo.displayModal) state.open();
    }, [errorInfo, state]);

    if (!state.isOpen || !errorInfo.modal) return <></>;

    const { type, content, onClick } = errorInfo.modal;

    return (
        <Modal state={state} isDismissable={true} onClose={closeModal}>
            <Dialog
                role="alertdialog"
                title={{
                    as: "h3",
                    content: content.title,
                    className: "error-modal__title",
                }}
                onClose={closeModal}
                className={cn("error-modal__dialog", {
                    "error-modal__dialog--notification":
                        type === "NOTIFICATION",
                    "error-modal__title--no-content":
                        !content.text && !content.btn,
                })}
                absoluteCloseBtn
            >
                {type === "POPUP" && (
                    <Popup
                        content={content}
                        action={onClick}
                        close={closeModal}
                    />
                )}
            </Dialog>
        </Modal>
    );
};

export default ErrorModal;
