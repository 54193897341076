export enum PageState {
    Loading = "LOADING",
    StatementsError = "STATEMENTS_ERROR",
    StatementsRetry = "STATEMENTS_RETRY",
    Loaded = "LOADED",
}

export enum LoaderStatus {
    Loading = "loading",
    Success = "success",
    Idle = "idle",
    Error = "Error"
  }
export type Loader<T> = {status: LoaderStatus.Loading} | {status: LoaderStatus.Success, payload: T} | {status: LoaderStatus.Idle} | {status: LoaderStatus.Error, err: unknown};