import { Icon } from "@evidenceb/athena-common";
import React from "react";
import Card from "../../../design-system-components/Card/Card";
import "./HomeworkButton.scss";

interface HomeworkButtonProps {
    label: string;
}

const HomeworkButton = ({ label }: HomeworkButtonProps) => {
    return (
        <button className="homework-button">
            <Card>
                <div className="homework-button__inner-div">
                    <Icon
                        path="homework"
                        className="homework-button__icon"
                        size={50}
                    />
                    <span className="homework-button__label">
                        {label.charAt(0).toUpperCase() +
                            label.slice(1).toLowerCase()}
                    </span>
                </div>
            </Card>
        </button>
    );
};

export default HomeworkButton;
