import React, { useState } from "react";
import { EmotionalReportController, EMOTIONS } from "../useEmotionalReport";
import { Emotion } from "../../../../../interfaces/EmotionalReport";

import "./EmotionalReportPopup.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Icon from "../../../../../design-system-components/Icon/Icon";
import Modal from "../../../../../design-system-components/Modal/Modal";
import Dialog from "../../../../../design-system-components/Dialog/Dialog";


interface EmotionalReportPopupProps extends Pick<EmotionalReportController, "emotionalReportModal"> {
    recordReport: (clickedButton: Emotion, recordType: "FORCED" | "SELF-REPORT") => void;
}


// See reference for html & accessibility here: https://stackoverflow.com/questions/45449106/how-to-make-a-ul-list-of-buttons-accessible/45491521

const EmotionalReportPopup = ({
    recordReport,
    emotionalReportModal,
}: EmotionalReportPopupProps) => {
    const intl = useIntl()
    const [clickedButtons, setClickedButtons] = useState<Emotion[]>([]);

    return (
        <Modal
            state={emotionalReportModal}
            className="emotional-report emotional-report__popup"
        >
            <Dialog
                title={{
                    as: "h3",
                    content: intl.formatMessage({ id: "exerciseShell-emotionalReport-popupTitle", defaultMessage: "How are you feeling?" })
                }}
            >
                <div className="popup__content">
                    <ul role="listbox">
                        {(Object.keys(EMOTIONS) as Emotion[]).map(
                            (emotion, index) => (
                                <li
                                    key={emotion}
                                    className={
                                        emotion === Emotion.Neutral
                                            ? "popup__emotion-neutral"
                                            : ""
                                    }
                                >
                                    {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
                                    <button
                                        aria-setsize={Object.keys(EMOTIONS).length}
                                        aria-posinset={index + 1}
                                        onClick={() => {
                                            setClickedButtons((curr) => {
                                                if (curr.includes(emotion))
                                                    return curr.filter(
                                                        (clickedEmotion) =>
                                                            clickedEmotion !==
                                                            emotion
                                                    );
                                                return [...curr, emotion];
                                            });
                                        }}
                                        className={`${clickedButtons.includes(emotion)
                                            ? "popup__button--clicked"
                                            : ""
                                            }`}
                                    >
                                        {EMOTIONS[emotion].Component()}
                                        <p>
                                            <FormattedMessage id={`exerciseShell-emotionalReport-${EMOTIONS[emotion].i18nKey
                                                }`} />
                                        </p>
                                    </button>
                                </li>
                            )
                        )}
                    </ul>

                    {clickedButtons.length > 0 && (
                        <button
                            className="popup__send-button"
                            onClick={() => {
                                clickedButtons.forEach((clickedButton) => {
                                    recordReport(clickedButton, "FORCED");
                                });
                            }}
                        >
                            <FormattedMessage id="exerciseShell-emotionalReport-popupSendBtn" defaultMessage="Send" />
                            {" "}<Icon path="send" />
                        </button>
                    )}
                </div>
            </Dialog>
        </Modal>
    );
};

export default EmotionalReportPopup;
