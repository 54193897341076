import React, { useEffect, useReducer, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { useIntl } from "react-intl";
import { HtmlString } from "@evidenceb/gameplay-interfaces";
import { Video } from "../../../../../interfaces/Resources";
import Button from "../../../../../design-system-components/Button/Button";
import VideoResource from "../../../../../components/VideoResource/VideoResource";
import WizardInstruction from "../WizardInstruction/WizardInstruction";
import TranscriptModal from "./TranscriptModal/TranscriptModal";

import "./WizardInterruptingVideo.scss";

interface Props {
    video: Video;
    instruction: HtmlString;
    dismissBtn: {
        label: string;
        fn: () => void;
        opts?: {
            /** @default false */
            disabledUntilVideoEnd?: boolean;
        };
    };
}

const WizardInterruptingVideo = ({ video, instruction, dismissBtn }: Props) => {
    const intl = useIntl();

    const [instructionHeight, setInstructionHeight] = useState(0);
    const instructionRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setInstructionHeight(instructionRef.current?.clientHeight ?? 0);
    }, []);

    const [dismissBtnDisabled, enableDismissBtn] = useReducer(
        () => false,
        dismissBtn?.opts?.disabledUntilVideoEnd ?? false
    );
    
    const transcriptModalState = useOverlayTriggerState({})

    return (
        <div className="wizard-interrupting-video">
            <WizardInstruction
                className="wizard-interrupting-video__instruction"
                instruction={instruction}
                ref={instructionRef}
            />

            <SimpleBar
                style={{
                    width: "100%",
                    height: `calc(100% - ${instructionHeight}px)`,
                }}
            >
                <div className="wizard-interrupting-video__content">
                    <VideoResource
                        video={video}
                        className="wizard-interrupting-video__video"
                        onEnded={() => {
                            enableDismissBtn();
                        }}
                        controls={[
                            { type: "restart" }
                        ]}
                    />

                    <div className="wizard-interrupting-video__btn-list">
                        {video.transcript && (
                            <Button
                                className="btn-list__transcript-btn"
                                variant="secondary"
                                center
                                onClick={() => {
                                    transcriptModalState.toggle()
                                }}
                                icon={{
                                    path: "hoh",
                                    position: "left",
                                    size: 28,
                                }}
                                label={intl.formatMessage({
                                    id: "iCantListenVideo",
                                    defaultMessage:
                                        "I cannot listen to the video",
                                })}
                            />
                        )}

                        {dismissBtn && (
                            <Button
                                className="btn-list__dismiss-btn"
                                center
                                variant="primary"
                                disabledStyle="hard"
                                onClick={dismissBtn.fn}
                                label={dismissBtn.label}
                                icon={{
                                    path: "arrow_right",
                                    position: "right",
                                }}
                                disabled={dismissBtnDisabled}
                            />
                        )}
                    </div>
                </div>
            </SimpleBar>

            {transcriptModalState.isOpen && (
                <TranscriptModal
                    transcriptUrl={video.transcript!}
                    state={transcriptModalState}
                    onOpenTranscript={() => {
                        enableDismissBtn();
                    }}
                />
            )}
        </div>
    );
};

export default WizardInterruptingVideo;
