import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { syncStore } from "../../../../contexts/Sync";
import useStatements from "../../../../hooks/useSyncStatements";
import ControlledDialog from "../../../../design-system-components/ControlledDialog/ControlledDialog";
import ErrorNotification, {
    NotificationType,
} from "../../../../components/ErrorNotification/ErrorNotification";
import NotificationContent from "./NotificationContent/NotificationContent";

import "./StatementsError.scss";

/**
 * Component that handles the error in syncing statements with the LRS. It
 * displays either a notification of a modal when the error first appears
 */
const StatementsError = () => {
    const {
        sync: { unsyncedStatements, syncingStatements },
    } = useContext(syncStore);
    const { syncStatements, syncingSuccess } = useStatements();
    // Handle alert when statements go out of sync
    // This is not reset when it happens again because the user already knows
    // what the notifications mean
    const [wasModalDismissed, setModalDismissed] = useState<boolean>(false);
    const [wasNotificationDismissed, setWasNotificationDismissed] =
        useState<boolean>(false);
    const intl = useIntl();

    // Reset dismissal of notification when new statements are unsynced
    useEffect(() => {
        if (typeof syncingSuccess !== "undefined") return;

        setWasNotificationDismissed(false);
    }, [syncingSuccess]);

    if (
        !syncingStatements &&
        !unsyncedStatements &&
        (typeof syncingSuccess === "undefined" ||
            (syncingSuccess && wasNotificationDismissed))
    )
        return <></>;

    if (!wasModalDismissed)
        return (
            <ControlledDialog
                title={intl.formatMessage({id: "errors-statements-cannotSendStatement-modal-title", defaultMessage: "Progression data update"})}
                description={
                    {$html: intl.formatMessage({id: "errors-statements-cannotSendStatement-modal-description", defaultMessage: "Your data is being saved. Stay online while we update your data."})}
                }
                primaryButton={{
                    label: intl.formatMessage({id: "errors-statements-cannotSendStatement-modal-continueButton", defaultMessage: "Continue"}),
                    fn: () => {
                        setModalDismissed(true);
                    },
                }}
            />
        );

    return (
        <ErrorNotification
            onDismiss={
                !syncingStatements &&
                !wasNotificationDismissed &&
                syncingSuccess
                    ? () => {
                          setWasNotificationDismissed(true);
                      }
                    : undefined
            }
            type={
                !wasNotificationDismissed && syncingSuccess
                    ? NotificationType.Success
                    : syncingStatements
                    ? NotificationType.Neutral
                    : NotificationType.Failure
            }
        >
            <NotificationContent
                syncingStatements={syncingStatements}
                syncingSuccess={syncingSuccess}
                syncStatements={syncStatements}
            />
        </ErrorNotification>
    );
};

export default StatementsError;
