import React, { DetailedHTMLProps, HTMLAttributes, useMemo } from "react";
import cn from "classnames";
import Icon, { IconProps } from "../Icon/Icon";
import { v4 as uuid } from "uuid";
import { VisuallyHidden } from "@evidenceb/athena-common";

import "./CustomSelect.scss";

export interface CustomSelectProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    onSelectOption: (value: string) => void;
    selectedOption: string | JSX.Element | (string | JSX.Element)[];
    label?: string;
    /** @default false */
    hideLabel?: boolean;
    iconProps?: IconProps;
    variant?: "default" | "inline";
}

/**
 * Select component using native select functionalities but adding custom
 * styles.
 * Children should be the various options.
 */
const CustomSelect = ({
    onSelectOption,
    selectedOption,
    label,
    iconProps,
    className,
    children,
    variant = "default",
    hideLabel = false,
    ...props
}: CustomSelectProps) => {
    const id = useMemo<string>(() => uuid(), []);

    return (
        <div
            className={cn(className, "ds-custom-select", {
                "ds-custom-select--inline": variant === "inline",
            })}
        >
            {label && !hideLabel && <label htmlFor={id}>{label}</label>}
            {label && hideLabel && <VisuallyHidden><label htmlFor={id}>{label}</label></VisuallyHidden>}

            <div className="ds-custom-select__select-wrapper">
                <select
                    id={id}
                    className="ds-custom-select__native-select"
                    onChange={(e) => {
                        onSelectOption(e.target.value);
                    }}
                >
                    {children}
                </select>

                <div className="ds-custom-select__visual-select__wrapper">
                    <div className="option">{selectedOption}</div>
                    <div className="visual-select__drop-icon">
                        {iconProps ? (
                            <Icon {...iconProps} />
                        ) : (
                            <Icon
                                size={{ width: 24, height: 33.573 }}
                                path="arrow_drop_down"
                                color="var(--lightblue)"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomSelect;
