import { useContext, useEffect, useReducer, useState } from "react";
import { Statement } from "@xapi/xapi";
import * as Sentry from "@sentry/react";
import { configStore } from "../contexts/ConfigContext";
import { sessionStore, StatementHistory } from "../contexts/SessionContext";
import { dataStore } from "../contexts/DataContext";
import * as localStorage from "../utils/localStorage";
import { getCurrentAgent } from "../utils/statement-builder";
import useAthenaAPIClient from "./useAthenaAPIClient";

const useGetStatements = (opts?: { holdLoad?: boolean }) => {
    const {
        session: {
            statementsFetched,
            statements,
            evidencebId,
            flags: { useHistoryFrom },
        },
        setSession,
    } = useContext(sessionStore);
    const {
        config: { declinaison },
    } = useContext(configStore);
    const { data } = useContext(dataStore);

    const athenaAPIClient = useAthenaAPIClient();

    const [internalHoldLoad, forceLoad] = useReducer(
        () => false,
        opts?.holdLoad ?? false
    );
    const [status, setStatus] = useState<
        "loading" | "success" | "error" | "idle"
    >(statementsFetched ? "success" : "idle");

    // Get statements
    useEffect(() => {
        if (internalHoldLoad || status !== "idle") return;

        setStatus("loading");
        (async () => {
            let statements: Statement[];
            if (useHistoryFrom === StatementHistory.LRS) {
                try {
                    const agent = getCurrentAgent(declinaison, evidencebId);
                    statements = await athenaAPIClient.getStatements(agent);
                } catch (err) {
                    Sentry.captureException(err);
                    setStatus("error");
                    return;
                }
            } else if (useHistoryFrom === StatementHistory.localHistory) {
                statements =
                    localStorage.getItem<Statement[]>(
                        localStorage.Key.LOCAL_HISTORY
                    ) ?? [];
            } else {
                statements = []
            }
            setSession((curr) => {
                return {
                    ...curr,
                    statementsFetched: true,
                    statements: curr.statements ? [...curr.statements, ...statements] : statements,
                };
            });
            setStatus("success");
        })();
    }, [
        statements,
        useHistoryFrom,
        data.modules,
        setSession,
        status,
        declinaison,
        evidencebId,
        athenaAPIClient,
        internalHoldLoad,
    ]);

    if (status === "error" || status === "success") return { status };
    return {
        status,
        forceLoad: internalHoldLoad ? forceLoad : undefined,
    };
};

export default useGetStatements;
