import React, { useContext, useMemo } from "react";
import {
    WorkMode,
    WorkModeStatus,
    WorkModeType,
} from "../../../interfaces/Config";
import "./WorkModes.scss";
import { useHistory } from "react-router-dom";
import { Pagetype, Page } from "../../../interfaces/Config";
import { getUrl } from "../../../utils/dataRetrieval";
import { UserType } from "../../../interfaces/User";
import classNames from "classnames";
import { dataStore } from "../../../contexts/DataContext";
import { modeStaticConfig } from "../WorkModeStaticConfig";
import { WorkModeCard } from "../../../design-system-components/WorkModeCard/WorkModeCard";
import { defineMessages, IntlShape } from "react-intl";
import useLockedModules from "../../../hooks/useLockedModules";
import { LoaderStatus } from "../../../interfaces/Status";

const THREE_ROW_LENTH = 3;

export interface WorkModesInterface {
    workModes: WorkMode[];
    pages: Page[];
    userType: UserType;
    intl: IntlShape;
}
const WorkModes = ({
    workModes,
    pages,
    userType,
    intl,
}: WorkModesInterface) => {
    const { data } = useContext(dataStore);
    const history = useHistory();
    const lockedModules = useLockedModules();
    const lockedModulesPayload = useMemo(() => {
        if (lockedModules.status !== LoaderStatus.Success) return undefined;
        return lockedModules.payload;
    }, [lockedModules]);
    let rowUnits = 0;
    const gridRowLength = THREE_ROW_LENTH;

    return (
        <div
            className="work-modes"
            style={{ gridTemplateColumns: `repeat(${gridRowLength}, 1fr)` }}
        >
            {workModes?.map((mode, index) => {
                const modeData = modeStaticConfig.find(
                    (modeData) => mode.type === modeData.type
                );

                let displayedSize = mode!.displayedSize ?? 1;
                let modeLen = getModeLen(displayedSize);
                rowUnits += modeLen;

                return (
                    <button
                        key={index}
                        className={classNames("work-modes__button ", {
                            "--disabled":
                                mode!.status === WorkModeStatus.Disabled,
                            "--not-clickable":
                                mode!.status === WorkModeStatus.NotClickable,
                        })}
                        style={{
                            gridColumn: `${
                                ((rowUnits - modeLen) % gridRowLength) + 1
                            } / ${
                                ((rowUnits - modeLen) % gridRowLength) +
                                1 +
                                displayedSize
                            }`,
                            gridRow: (rowUnits - modeLen) / gridRowLength + 1,
                        }}
                        onClick={() => {
                            if (mode.url) window.open(mode.url, '_blank');
                            else if (modeData!.linkedPageType)
                                history.push(
                                    getWorkingModeLink(
                                        mode.url,
                                        pages,
                                        userType,
                                        modeData!.linkedPageType
                                    )!
                                );
                        }}
                    >
                        <WorkModeCard
                            {...mode!}
                            iconColor={modeData!.primaryColor}
                            description={getDescription(
                                mode!,
                                intl,
                                data.modules?.filter(
                                    (mod) =>
                                        lockedModulesPayload &&
                                        !lockedModulesPayload?.includes(mod.id)
                                ).length
                            )}
                            name={getModeName(mode.type, intl)}
                            logoPath={modeData!.logoPath}
                        />
                    </button>
                );
            })}
        </div>
    );
};

export default WorkModes;

const getModeName = (modeType: WorkModeType, intl: IntlShape) => {
    switch (modeType) {
        case WorkModeType.Duo:
            return intl.formatMessage(messages.modeDuo);
        case WorkModeType.SoloAI:
            return intl.formatMessage(messages.modeSoloAI);
        case WorkModeType.SoloTeacher:
            return intl.formatMessage(messages.modeTeacher);
        case WorkModeType.Test:
            return intl.formatMessage(messages.modeTest);
        case WorkModeType.Tuto:
            return intl.formatMessage(messages.modeTuto);
        case WorkModeType.Workshop:
            return intl.formatMessage(messages.modeWorkshop);
        default:
            return "";
    }
};

const getModeLen = (modeDisplayedSize: number) => {
    if (modeDisplayedSize === 2) return 2;
    if (modeDisplayedSize === 3) return 3;
    return 1;
};

const getDescription = (
    mode: WorkMode,
    intl: IntlShape,
    moduleLength: number
) => {
    let description: string | undefined = undefined;
    if (mode.status === WorkModeStatus.Disabled)
        description = intl.formatMessage(messages.unavailable);
    else if (mode.type === WorkModeType.SoloAI)
        description = `${moduleLength} ${intl
            .formatMessage(messages.modeModules)
            .toLowerCase()}`;
    return description;
};

const getWorkingModeLink = (
    url: string | undefined,
    pages: Page[],
    userType: UserType,
    pageType: Pagetype | undefined
) => {
    if (url) return url;
    const page = pages.find((page) => page.type === pageType);
    if (!page) return undefined;
    return getUrl(page, userType);
};

const messages = defineMessages({
    modeDuo: {
        id: "mode-duo",
        defaultMessage: "Duo",
    },
    modeSoloAI: {
        id: "mode-solo-ai",
        defaultMessage: "Solo AI",
    },
    modeTeacher: {
        id: "mode-teacher",
        defaultMessage: "Solo Teacher",
    },
    modeTest: {
        id: "mode-test",
        defaultMessage: "Test",
    },
    modeTuto: {
        id: "mode-tuto",
        defaultMessage: "Tuto",
    },
    modeWorkshop: {
        id: "mode-workshop",
        defaultMessage: "Workshop",
    },
    modeModules: {
        id: "dashboard-common-modules",
        defaultMessage: "Modules",
    },
    unavailable: {
        id: "resource-unavailable",
        defaultMessage: "Unavailable",
    },
});
