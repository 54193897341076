import { useContext } from 'react';
//External modules
import { useIntl } from 'react-intl';
//Utils
import * as localStorage from "../../../utils/localStorage";
import { getUrl as getRouteUrl } from "../../../utils/dataRetrieval";
//Hooks
import useAthenaAPIClient from '../../../hooks/useAthenaAPIClient';
//Contexts
import { dashboardStore } from '../../../contexts/DashboardContext';
import { configStore } from '../../../contexts/ConfigContext';
import { sessionStore } from '../../../contexts/SessionContext';
//Interfaces
import { UserType } from '../../../interfaces/User';

export default function useClassroom() {

    //Contexts
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const { setDashboard } = useContext(dashboardStore);
    const athenaAPIClient = useAthenaAPIClient();
    const intl = useIntl();
    //states

    //Functions
    const updateDashboardData = async () => {
        // GET Analytics data to update dashboard context
        // TODO: improve this when Analytics will be refactored, it's too heavy on performance at the moment
        const version = localStorage.getItem<string>(localStorage.Key.VERSION)
        const dashboard = await athenaAPIClient.getTeacherDashboard(
            session.userId,
            version
        );

        // Update dashboard data
        setDashboard(dashboard)
    }

    const getUrl = (
        type: "classroomList" | "classroom" | "progressionOverview" | "viewGroups",
        classroomId?: string,
        moduleId?: string
    ) => {
        if (type === "classroomList")
            return `/${getRouteUrl(config.pages.find(page => page.type === "DASHBOARD")!, UserType.Teacher) as string}/${intl.formatMessage({ id: "dashboard-paths-classes", defaultMessage: "classes" })}`
        if (type === "classroom")
            return `/${getRouteUrl(config.pages.find(page => page.type === "DASHBOARD")!, UserType.Teacher) as string}/${intl.formatMessage({ id: "dashboard-paths-classes", defaultMessage: "classes" })}/${classroomId}`
        if (type === "progressionOverview")
            return `/${getRouteUrl(config.pages.find(page => page.type === "DASHBOARD")!, UserType.Teacher) as string}/${intl.formatMessage({ id: "dashboard-paths-classes", defaultMessage: "classes" })}/${intl.formatMessage({ id: "dashboard-paths-progression", defaultMessage: "progression" })}/${classroomId}/${moduleId}`
        if (type === "viewGroups")
            return `/${getRouteUrl(config.pages.find(page => page.type === "DASHBOARD")!, UserType.Teacher) as string}/${intl.formatMessage({ id: "dashboard-paths-classes", defaultMessage: "classes" })}/${classroomId}/${moduleId}/${intl.formatMessage({ id: "dashboard-paths-groups", defaultMessage: "groups" })}`
        return ''
    }

    return {
        updateDashboardData,
        getUrl
    }
}

