import { AILoadingInfo, AIType, AIWhisperer } from "@evidenceb/ai-handler";
import { useContext, useEffect, useState } from "react";
import { configStore } from "../contexts/ConfigContext";
import { sessionStore } from "../contexts/SessionContext";
import { XAPI_REGISTRY } from "../utils/statement-builder";
import { UseAIStatus } from "./useAI";
import * as Sentry from "@sentry/react";
import { getAIClass } from "../utils/ai";
import { dataStore } from "../contexts/DataContext";
import {
    adaptiveTestStatementsFilter,
    byAdaptiveTestNumberStatementsFilter,
    byModuleStatementsFilter,
    chronologicalStatementsSort,
    toHistoryStatementsMap,
} from "../utils/statements";
import useGetStatements from "./useGetStatements";
import moment from "moment";

export const ADAPTIVE_TEST_LIFESPAN = 1000 * 60 * 60 * 24 * 14;

export const testHasExpired = (startTimestamp: string): boolean => {
    return Date.now() - Date.parse(startTimestamp) > ADAPTIVE_TEST_LIFESPAN;
};

export const testExpiresOn = (startTimestamp: string): string | undefined => {
    const deadline = Date.parse(startTimestamp) + ADAPTIVE_TEST_LIFESPAN;
    if (deadline > Date.now()) return moment(deadline, "milliseconds").format();
    return undefined;
};

const useAdaptiveTest = (
    moduleId: string,
    opts?: {
        holdLoad?: boolean;
    }
): UseAIStatus<AILoadingInfo> => {
    const {
        session: { statements, adaptiveTestNumber },
        setSession,
    } = useContext(sessionStore);
    const {
        config: { ai },
    } = useContext(configStore);
    const { data } = useContext(dataStore);

    const statementsInfo = useGetStatements(opts);

    const [status, setStatus] = useState<UseAIStatus["status"]>("loading");
    const [internalHoldLoad, setInternalHoldLoad] = useState<boolean>(
        opts?.holdLoad ?? false
    );
    const [aiLoadingInfo, setAILoadingInfo] = useState<AILoadingInfo>();

    // Reset when moduleId changes
    useEffect(() => {
        setStatus("loading");
        setAILoadingInfo(undefined);
        setSession((curr) => {
            return {
                ...curr,
                adaptiveTestNumber: undefined,
            };
        });
    }, [moduleId, setSession]);

    // Error if statements couldn't be GET
    useEffect(() => {
        if (statementsInfo.status === "error") setStatus("error");
    }, [statementsInfo]);

    // Set Adaptive Test # & init AI
    useEffect(() => {
        if (
            internalHoldLoad ||
            !statements ||
            adaptiveTestNumber ||
            status !== "loading"
        )
            return;

        const adaptiveTestStatements = statements
            .filter(adaptiveTestStatementsFilter)
            .filter(byModuleStatementsFilter(moduleId))
            .sort(chronologicalStatementsSort);

        (async () => {
            try {
                const AIClass = await getAIClass(
                    AIType.AdaptiveTest,
                    ai[AIType.AdaptiveTest].id
                );
                const aiInfo = AIWhisperer.initAI(
                    AIClass,
                    ai[AIType.AdaptiveTest],
                    data,
                    moduleId
                );
                if (aiInfo.error) {
                    setStatus("error");
                    return;
                }

                const lastAdaptiveTestNumber: number | undefined =
                    +adaptiveTestStatements[adaptiveTestStatements.length - 1]
                        ?.context!.extensions![
                        `${XAPI_REGISTRY}/extensions/adaptive-test`
                    ];
                const lastAdaptiveTestStatements = lastAdaptiveTestNumber
                    ? adaptiveTestStatements.filter(
                          byAdaptiveTestNumberStatementsFilter(
                              lastAdaptiveTestNumber
                          )
                      )
                    : undefined;

                if (
                    !lastAdaptiveTestStatements ||
                    lastAdaptiveTestStatements.length === 0
                ) {
                    // No previous test
                    setSession((curr) => {
                        return {
                            ...curr,
                            adaptiveTestNumber: 1,
                        };
                    });
                    setAILoadingInfo(aiInfo);
                    return;
                }
                if (
                    testHasExpired(
                        lastAdaptiveTestStatements[
                            lastAdaptiveTestStatements.length - 1
                        ].timestamp!
                    )
                ) {
                    // Previous adaptive test expired
                    setSession((curr) => {
                        return {
                            ...curr,
                            adaptiveTestNumber: lastAdaptiveTestNumber + 1,
                        };
                    });
                    setAILoadingInfo(aiInfo);
                    return;
                }

                try {
                    const nextHierarchyIds =
                        AIWhisperer.loadHistoryAndGetNextHierarchyIds(
                            aiInfo.instance,
                            lastAdaptiveTestStatements.map(
                                toHistoryStatementsMap
                            )
                        );
                    if (nextHierarchyIds.isModuleFinished) {
                        // Previous adaptive test was finished
                        setSession((curr) => {
                            return {
                                ...curr,
                                adaptiveTestNumber: lastAdaptiveTestNumber + 1,
                            };
                        });
                        const freshAIInfo = AIWhisperer.initAI(
                            AIClass,
                            ai[AIType.AdaptiveTest],
                            data,
                            moduleId
                        );
                        if (freshAIInfo.error) {
                            setStatus("error");
                            return;
                        }
                        setAILoadingInfo(freshAIInfo);
                        return;
                    }

                    // Continue previous test
                    setSession((curr) => {
                        return {
                            ...curr,
                            adaptiveTestNumber: lastAdaptiveTestNumber,
                        };
                    });
                    setAILoadingInfo({
                        ...aiInfo,
                        initiallyFresh: false
                    });
                } catch (err) {
                    Sentry.captureException(err);
                    setSession((curr) => {
                        return {
                            ...curr,
                            adaptiveTestNumber: lastAdaptiveTestNumber + 1,
                        };
                    });
                    const freshAIInfo = AIWhisperer.initAI(
                        AIClass,
                        ai[AIType.AdaptiveTest],
                        data,
                        moduleId
                    );
                    setAILoadingInfo(freshAIInfo);
                }
            } catch (err) {
                Sentry.captureException(err);
                setStatus("error");
            }
        })();
    }, [
        statements,
        moduleId,
        adaptiveTestNumber,
        status,
        ai,
        data,
        setSession,
        internalHoldLoad,
    ]);

    // Mark as loaded
    useEffect(() => {
        if (status === "loading" && aiLoadingInfo && adaptiveTestNumber)
            setStatus("success");
    }, [status, aiLoadingInfo, adaptiveTestNumber, internalHoldLoad]);

    if (status === "error") return { status };
    if (status === "loading")
        return {
            status,
            forceLoad: internalHoldLoad
                ? () => {
                      setInternalHoldLoad(false);
                      if (statementsInfo?.forceLoad) statementsInfo.forceLoad();
                  }
                : undefined,
        };
    return {
        status,
        aiLoadingInfo: aiLoadingInfo!,
    };
};

export default useAdaptiveTest;
