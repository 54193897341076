import React from "react"
import { OverlayTriggerState } from "react-stately"
import Button from "../../../design-system-components/Button/Button"
import Dialog from "../../../design-system-components/Dialog/Dialog"
import Modal from "../../../design-system-components/Modal/Modal"

import "./Modal.scss"

interface ResetProgressionModalProps {
    state: OverlayTriggerState
    onModalDismiss: () => void
    onClick: () => void

    title: string
    content: string
    buttonLabel: string
}

const ResetProgressionModal = ({
    state,
    onModalDismiss,
    onClick,
    title,
    content,
    buttonLabel,
}: ResetProgressionModalProps) => {
    return (
        <Modal
            state={state}
            className="specimen-modal"            
        >
            <Dialog
                title={{
                    as: "h3",
                    content: title
                }}
                onClose={onModalDismiss}
            >
                <span>
                    {content}
                </span>
                <Button
                    className="specimen-modal__button"
                    label={buttonLabel}
                    onClick={onClick}
                />
            </Dialog>
        </Modal>
    )
}

export default ResetProgressionModal