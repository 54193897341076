import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { dataStore } from "../../../../../contexts/DataContext";
import { getResourceIndex, getSublevelPool, initialTestFilter } from "../../../../../utils/dataRetrieval";
import CustomSelect from "../../../../../components/CustomSelect/CustomSelect";
import Case from "case";
import {
    Activity,
    HierarchyIds,
    Module,
    Objective,
} from "@evidenceb/gameplay-interfaces";
import { Exercise } from "@evidenceb/athena-common";
import parse from "html-react-parser";

import "./PlaylistNavigationBreadcrumbs.scss";
import { AIType } from "@evidenceb/ai-handler";
import { Config } from "../../../../../interfaces/Config";
import { Hierarchy } from "../../../../../interfaces/Hierarchy";
import { configStore } from "../../../../../contexts/ConfigContext";
import Icon from "../../../../../design-system-components/Icon/Icon";
import { commonMessages } from "../../../../../utils/messages";

/**
 * Menu that allows navigation in any part of the hierarchy of exercises.
 */
const PlaylistNavigationBreadCrumbs: React.FC<{
    goToExercise: (hierarchy: Partial<HierarchyIds>) => void;
    currentHierarchy: Omit<Hierarchy, "exercise" | "isInitialTest">;
    currentExerciseIndex: number;
    showActivityInfo: boolean;
}> = ({
    goToExercise,
    currentHierarchy,
    currentExerciseIndex,
    showActivityInfo,
}) => {
    const { data } = useContext(dataStore);
    const {
        config: { features, ai},
    } = useContext(configStore);
    const intl = useIntl();

    return (
        <div className="player__nav-breadcrumbs">
            <CustomSelect
                onSelectOption={(moduleId) => {
                    goToExercise({ moduleId });
                }}
                selectedOption={
                    features.teacherModuleListShowFullTitle ? currentHierarchy.module.title.short! : generateItemTitle(currentHierarchy.module.id, currentHierarchy.module.title.short!, "module", data.modules)
                }
            >
                {data.modules.map((module) => (
                    <option
                        key={module.id}
                        value={module.id}
                        selected={module.id === currentHierarchy.module.id}
                    >
                        {
                            features.teacherModuleListShowFullTitle ? module.title.short! : generateItemTitle(module.id, module.title.short!, "module", data.modules)
                        }
                    </option>
                ))}
            </CustomSelect>

            <CustomSelect
                onSelectOption={(objectiveId) => {
                    goToExercise({
                        moduleId: currentHierarchy.module.id,
                        objectiveId,
                    });
                }}
                selectedOption={
                    generateItemTitle(currentHierarchy.objective.id, currentHierarchy.objective.title.short!, "objective", currentHierarchy.module.objectiveIds, intl, ai)
                }
            >
                {getSublevelPool<Objective>(
                    currentHierarchy.module.objectiveIds,
                    data.objectives
                ).map((objective) => (
                    <option
                        key={objective.id}
                        value={objective.id}
                        selected={
                            objective.id === currentHierarchy.objective.id
                        }
                    >
                        {generateItemTitle(objective.id, objective.title.short!, "objective", currentHierarchy.module.objectiveIds, intl, ai)}
                    </option>
                ))}
            </CustomSelect>

            <div className="activity-selector">
                <CustomSelect
                    onSelectOption={(activityId) => {
                        goToExercise({
                            moduleId: currentHierarchy.module.id,
                            objectiveId: currentHierarchy.objective.id,
                            activityId,
                        });
                    }}
                    selectedOption={
                        generateItemTitle(currentHierarchy.activity.id, currentHierarchy.activity.title.short!, "activity", currentHierarchy.objective.activityIds, intl)
                    }
                >
                    {getSublevelPool<Activity>(
                        currentHierarchy.objective.activityIds,
                        data.activities
                    ).map((activity) => (
                        <option
                            key={activity.id}
                            value={activity.id}
                            selected={
                                activity.id === currentHierarchy.activity.id
                            }
                        >
                            {generateItemTitle(activity.id, activity.title.short!, "activity", currentHierarchy.objective.activityIds, intl)}
                        </option>
                    ))}
                </CustomSelect>
                {showActivityInfo && (
                    <button className="activity-selector__tooltip">
                        <Icon path="warning" size="medium" color="white" className="activity-selector__icon" />
                        <div className="activity-selector__info">
                            <p>
                                {currentHierarchy.activity.title.long}
                                {currentHierarchy.activity.description &&
                                currentHierarchy.activity.title ? (
                                    <FormattedMessage id="misc-colon" defaultMessage=":" />
                                ) : null}
                                <span>
                                    {parse(
                                        currentHierarchy.activity.description
                                            .$html
                                    )}
                                </span>
                            </p>
                        </div>
                    </button>
                )}
            </div>

            <CustomSelect
                onSelectOption={(exerciseId) => {
                    goToExercise({
                        moduleId: currentHierarchy.module.id,
                        objectiveId: currentHierarchy.objective.id,
                        activityId: currentHierarchy.activity.id,
                        exerciseId,
                    });
                }}
                selectedOption={`${Case.pascal(intl.formatMessage(commonMessages.exercise))} ${
                    currentExerciseIndex + 1
                }`}
            >
                {getSublevelPool<Exercise<any, any>>(
                    currentHierarchy.activity.exerciseIds,
                    data.exercises
                ).map((exercise, index) => (
                    <option
                        key={exercise.id}
                        value={exercise.id}
                        selected={index === currentExerciseIndex}
                    >
                        {`${Case.pascal(intl.formatMessage(commonMessages.exercise))} ${
                            index + 1
                        }`}
                    </option>
                ))}
            </CustomSelect>
        </div>
    );
};

export default PlaylistNavigationBreadCrumbs;

const generateItemTitle = (
    id: string, 
    title: string,
    type: "module" | "objective" | "activity",
    pool: Module[] | Module["objectiveIds"] | Objective["activityIds"],
    intl?: any,
    aiConfig?: Config["ai"]
) => {
    if(type === "module")
        return `${getResourceIndex(id, pool) + 1}. ${title}`
    if(type === "objective"){
        let initialTestId = (pool as Module["objectiveIds"]).find(initialTestFilter(aiConfig![AIType.BanditManchot].initialTest, "isInitialTest"))
        let objectiveShortIntl = Case.pascal(intl.formatMessage(commonMessages.objectiveShort)).charAt(0);
        if(initialTestId)
        if(initialTestId === id){
            return `${title}`
        }else{
            return `${objectiveShortIntl}${getResourceIndex(id, pool)}. ${title}`
        }
        return `${objectiveShortIntl}${getResourceIndex(id, pool) + 1}. ${title}`
    }
    if(type === "activity")
        return `${Case.pascal(intl.formatMessage(commonMessages.activityShort).charAt(0))}${getResourceIndex(id, pool) + 1}. ${title}`
    return ""
}
