import React, { useContext, useMemo } from "react";
import { Config } from "../../interfaces/Config";
import { UserType } from "../../interfaces/User";
import { sessionStore } from "../../contexts/SessionContext";
import { configStore } from "../../contexts/ConfigContext";
import { withSingleExercisePlaylistManager } from "./useSingleExercisePlaylistManager";
import { withTeacherPlaylistManager } from "./useTeacherPlaylistManager";
import BanditManchotInfoPanel from "./InfoPanel/BanditManchotInfoPanel/BanditManchotInfoPanel";
import TeacherPlayerInfoPanel from "./InfoPanel/TeacherPlayerInfoPanel/TeacherPlayerInfoPanel";
import PlaylistPlayer, {
    PlaylistPlayerProps,
} from "./PlaylistPlayer/PlaylistPlayer";
import TeacherHeader from "./Header/TeacherHeader/TeacherHeader";
import StudentHeader from "./Header/StudentHeader/StudentHeader";
import BreadcrumbHeader from "./Header/BreadcrumbHeader/BreadcrumbHeader";
import {
    withAIPlaylistManager,
    withAITypeElement,
} from "./useAIPlaylistManager";
import { AIType } from "@evidenceb/ai-handler";
import {
    InitializedPlaylistManager,
    Playlist,
    PlayerOpts,
} from "../../interfaces/Player";
import AdaptiveTestHeader from "./Header/AdaptiveTestHeader/AdaptiveTestHeader";
import {
    AdaptiveTestInfoModal,
    BanditManchotTestInfoModal,
    TestInfoModalProps,
} from "./TestInfoModal/TestInfoModal";
import AdaptiveTestPlaylistSummary from "./PlaylistSummary/AdaptiveTestPlaylistSummary/AdaptiveTestPlaylistSummary";
import DefaultPlaylistSummary, {
    PlaylistSummaryProps,
} from "./PlaylistSummary/DefaultPlaylistSummary/DefaultPlaylistSummary";

export interface InfoPanelProps {
    playlist: Playlist;
    getContainer?: () => HTMLElement;
}

export interface PlayerHeaderProps {
    playlistManager: InitializedPlaylistManager;
    progression?: number;
    opts?: Partial<PlayerOpts>;
    className?: string;
    aiType?: AIType;
}

interface PlayerParts {
    PlaylistPlayer: (
        props: Omit<PlaylistPlayerProps, "playlistManager">
    ) => JSX.Element;
    InfoPanel?: (props: InfoPanelProps) => JSX.Element | null;
    TestInfoModal?: (props: TestInfoModalProps) => JSX.Element | null;
    PlaylistSummary?: (props: PlaylistSummaryProps) => JSX.Element | null;
    Header: (props: PlayerHeaderProps) => JSX.Element;
    opts?: Partial<PlayerOpts>;
}

type PlayerPath = "play" | "player" | "adaptive-test";

const PlayerBuilder = () => {
    const {
        session: { userType },
    } = useContext(sessionStore);
    const {
        config: { features },
    } = useContext(configStore);

    const path = getPath(window.location.pathname);
    const {
        PlaylistPlayer,
        Header,
        InfoPanel,
        TestInfoModal,
        PlaylistSummary,
        opts,
    } = useMemo(
        () => getPlayerParts(userType, path, features),
        [userType, path, features]
    );

    return (
        <PlaylistPlayer
            InfoPanel={InfoPanel}
            TestInfoModal={TestInfoModal}
            PlaylistSummary={PlaylistSummary}
            Header={Header}
            opts={opts}
        />
    );
};

const getPath = (location: string): PlayerPath => {
    if (location.includes("player")) return "player";
    if (location.includes("play")) return "play";
    if (location.includes("adaptive-test")) return "adaptive-test";
    throw new Error("Unconfigured player path");
};

const getPlayerParts = (
    userType: UserType,
    path: PlayerPath,
    features: Config["features"]
): PlayerParts => {
    if (path === "play") return getSingleExercisePlayerParts(features);
    if (path === "player" && userType === UserType.Teacher)
        return getTeacherPlayerParts(features);
    if (path === "player" && userType === UserType.Student)
        return getBanditManchotPlayerParts(features);
    if (path === "adaptive-test" && userType === UserType.Student)
        return getAdaptiveTestPlayerParts();
    throw new Error(
        `Player parts not configured for ${path} path and ${userType} user type.`
    );
};

const getSingleExercisePlayerParts = (
    features: Config["features"]
): PlayerParts => {
    return {
        PlaylistPlayer: withSingleExercisePlaylistManager(PlaylistPlayer),
        InfoPanel: features.teacherInfoPanel
            ? TeacherPlayerInfoPanel
            : undefined,
        Header: BreadcrumbHeader,
        opts: { skipIntro: true },
    };
};

const getTeacherPlayerParts = (features: Config["features"]): PlayerParts => {
    return {
        PlaylistPlayer: withTeacherPlaylistManager(PlaylistPlayer),
        InfoPanel: features.teacherInfoPanel
            ? TeacherPlayerInfoPanel
            : undefined,
        Header: TeacherHeader,
        PlaylistSummary: DefaultPlaylistSummary,
    };
};

const getBanditManchotPlayerParts = (
    features: Config["features"]
): PlayerParts => {
    return {
        PlaylistPlayer: withAIPlaylistManager(
            PlaylistPlayer,
            AIType.BanditManchot
        ),
        InfoPanel: features.studentChatbotPlayerInfoPanel
            ? BanditManchotInfoPanel
            : undefined,
        TestInfoModal: BanditManchotTestInfoModal,
        Header: withAITypeElement(StudentHeader, AIType.BanditManchot),
        PlaylistSummary: DefaultPlaylistSummary,
    };
};

const getAdaptiveTestPlayerParts = (): PlayerParts => {
    return {
        PlaylistPlayer: withAIPlaylistManager(
            PlaylistPlayer,
            AIType.AdaptiveTest
        ),
        Header: withAITypeElement(AdaptiveTestHeader, AIType.AdaptiveTest),
        TestInfoModal: AdaptiveTestInfoModal,
        PlaylistSummary: AdaptiveTestPlaylistSummary,
        opts: {
            confirmPlayerExit: true,
        },
    };
};

export default PlayerBuilder;
