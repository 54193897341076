import { AssetsDetails, getAssetUrl } from "@evidenceb/athena-common";
import { Video } from "../interfaces/Resources";

export const completeVideoUrls = (
    video: Video,
    assetsDetails: AssetsDetails
) => {
    const urlCompleter = (path: string) => getAssetUrl(path, assetsDetails);
    return {
        ...video,
        sources: video.sources.map((source) => ({
            ...source,
            src: urlCompleter(source.src),
        })),
        tracks: video.tracks?.map((track) => ({
            ...track,
            src: urlCompleter(track.src),
        })),
        poster: video.poster && urlCompleter(video.poster),
        transcript: video.transcript && urlCompleter(video.transcript),
    };
};
