import React from "react";
// External modules
import { ReactSVG } from "react-svg";
import appConfig from "../../config";
// SCSS
import "./Icon.scss";
// Interfaces
export interface IconProps extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement> {
    /** @default small */
    /**
     * number set width/height with the same value => {width: x, height: x}
     */
     size?: "small" | "medium" | "large" | "clickable" | number | { width: number, height: number};
    /**
     * name of the file in public/icons folder
     * If there is no extension in the path, it is considered as SVG by default
     */
    path: string;
    color?: string;
    className?: string;
}

const Icon = ({ size = "small", path, color, className, ...props }: IconProps) => {
    return (
        <span
            className={`ds-icon ${typeof size === "string" ? "ds-icon--" + size : ""} ${className ?? ""}`}
            aria-hidden="true"
            {...props}
        >
            {
                !isSvg(path) ?
                    <img
                        src={`${appConfig.basePath ?? "/"}icons/${path}`}
                        alt=""
                        style={
                            typeof size === "number" || typeof size === "object" ?
                                getSize(size)
                                : undefined
                        }
                    /> :
                    <ReactSVG
                        beforeInjection={(svg) => {
                            if (color) svg.setAttribute("fill", color);
                            if (typeof size === "number" || typeof size === "object") {
                                svg.style.width = getSize(size).width;
                                svg.style.height = getSize(size).height;
                            }
                        }}
                        src={`${appConfig.basePath ?? "/"}icons/${path}.svg`}
                        aria-hidden={true}
                    />
            }
        </span>
    );
};
export default Icon;

export const getSize = (
    size: number | { width: number, height: number}
) => {
    if (typeof size === "number")
        return {
            width: size + "px",
            height: size + "px"
        };
    return {
        width: size.width + "px",
        height: size.height + "px"
    } 
}

export const isSvg = (path: string) => {
    let isExtension = path.split('.').length >= 2;
    if(isExtension){
        let extension = path.split('.').pop();
        if(extension === "svg") return true
        return false
    }
    return true
}
