import React, {
    createContext,
    useState,
    Dispatch,
    SetStateAction,
} from "react";
import { UserType } from "../interfaces/User";
import { v4 as uuid } from "uuid";
import { PerModule } from "@evidenceb/gameplay-interfaces";
import { AILoadingInfo } from "@evidenceb/ai-handler";
import { Statement } from "@xapi/xapi";
import { PRLockStatus } from "../interfaces/PedagogicalResourcesManagement";

interface Pending {
    status: "pending";
}
export interface Settled<T> {
    status: "settled";
    value: T;
}
type Requesting<T> = Pending | Settled<T>;

export enum DismissableMessages {
    prmObjectiveLockingConfirmationModal = "prmObjectiveLockingConfirmationModal",
    prmObjectiveUnlockingConfirmationModal = "prmObjectiveUnlockingConfirmationModal",
    specimenGetFullVersionBanner = 'specimenGetFullVersionBanner',
    specimenSwitchUserModal = 'specimenSwitchUserModal',
    specimenTeacherDashboardModal = 'specimenTeacherDashboardModal',
}

export enum StatementHistory {
    localHistory = 'LOCAL_HISTORY',
    noHistory = 'NO_HISTORY',
    LRS = "LRS",
}
export interface Session {
    banditManchot?: PerModule<AILoadingInfo>;
    /**
     * Whether the statements were fetched from the LRS
     */
    statementsFetched: boolean;
    statements?: Statement[];
    prLockStatus?: Requesting<PRLockStatus>;
    adaptiveTestNumber?: number;
    sessionId: string;
    userId: string;
    firstname: string;
    userProvider: string;
    appProvider: string;
    appVariation: string;
    userType: UserType;
    evidencebId: string;
    version: string;
    /**
     * App shortname
     * NB: only available for authentication
     */
    app?: string;
    flags: {
        useHistoryFrom: StatementHistory;
        displaySignIn: boolean;
        /**
         * In the player, allow to see the exercise hierarchy & result in the console (for POs & AI team)
         * @default false
         */
        logExerciseDetails: boolean;
    };
    dismissMessages: { [key in DismissableMessages]: boolean };
    /**
     * Fragments are needed when creating students in the dashboard.
     * It will differentiate the origin from which the teacher is from (ENT or other)
     */
    fragments: string[];
    specimen?: {}
}

interface SessionContext {
    session: Session;
    setSession: Dispatch<SetStateAction<Session>>;
}

const initialState: Session = {
    sessionId: uuid(),
    userId: "",
    firstname: "",
    userProvider: "",
    appProvider: "",
    appVariation: "",
    statementsFetched: false,
    userType: UserType.Student,
    evidencebId: "",
    app: "",
    version: "",
    dismissMessages: {
        prmObjectiveLockingConfirmationModal: false,
        prmObjectiveUnlockingConfirmationModal: false,
        specimenGetFullVersionBanner: false,
        specimenSwitchUserModal: false,
        specimenTeacherDashboardModal: false,
    },
    flags: { useHistoryFrom: StatementHistory.LRS, displaySignIn: false, logExerciseDetails: false },
    fragments:[],
};

// Context init
const sessionStore = createContext<SessionContext>({
    session: initialState,
    setSession: () => null,
});

// Provider init
const { Provider } = sessionStore;

// Final setup
const SessionProvider = ({ children }: { children: JSX.Element }) => {
    const [session, setSession] = useState(initialState);
    return <Provider value={{ session, setSession }}>{children}</Provider>;
};

export { sessionStore, SessionProvider };
