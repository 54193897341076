import React, { useCallback, useContext, useEffect } from "react";

//External modules
import { Helmet } from "react-helmet-async";
import { Switch, Route, useHistory } from "react-router-dom";

//Contexts
import { dashboardStore } from "../../contexts/DashboardContext";
import { sessionStore } from "../../contexts/SessionContext";
import { configStore } from "../../contexts/ConfigContext";

//Components
import ProgressionOverview from "./ProgressionOverview/ProgressionOverview";
import Clustering from "../Clustering/Clustering";
import StudentDetails from "../DashBoard/StudentDetails/StudentDetails";
import Loader from "../../components/Loader/Loader";
import DemoBanner from "../../components/DemoBanner/DemoBanner";
import Search from "./Search/Search";
import ClassroomList from "./Classroom/List/ClassroomList";
import SingleClassroom from "./Classroom/Single/SingleClassroom";

//SCSS
import "./Dashboard.scss";

//Hooks
import useAthenaAPIClient from "../../hooks/useAthenaAPIClient";

//Interfaces
import { Title } from "../../interfaces/Config";
import { defineMessages, useIntl } from "react-intl";
import { errorStore } from "../../contexts/ErrorContext";
import useOnMountRequest from "../../hooks/useOnMountRequest";
import {
    getClusteringUrl,
    getProgressionOverviewUrl,
    getSingleClassroomUrl,
    getStudentDetailsUrl,
} from "../../utils/navigation-dashboard";

function DashboardPage({ title }: Title) {
    //Misc
    const intl = useIntl();

    //Contexts
    const { dashboard, setDashboard } = useContext(dashboardStore);
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);
    const athenaAPIClient = useAthenaAPIClient();
    const history = useHistory();

    const request = useCallback(async () => {
        if (dashboard) {
            return undefined
        }
        return athenaAPIClient.getTeacherDashboard(session.userId, session.version)
    }, [athenaAPIClient, dashboard, session.userId, session.version])

    const { data, error } = useOnMountRequest(request)

    useEffect(() => {
        if (!data) return
        setDashboard(data)
    }, [data, setDashboard])

    useEffect(() => {
        if (error) {
            setErrorInfo({
                displayModal: true,
                modal: {
                    type: "POPUP",
                    content: {
                        title: intl.formatMessage(messages.errorTitle),
                        text: intl.formatMessage(messages.errorTxt),
                        btn: {
                            label: intl.formatMessage(messages.goToHome),
                            cancel: false,
                        },
                    },
                    onClick: () => {
                        history.push("/");
                    },
                },
            });
        }
    }, [error, history, intl, setErrorInfo])

    return (
        <div className="dashboard">
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>

            <div className="search-container">
                <Search />
            </div>

            <Switch>
                <Route
                    exact
                    path={getSingleClassroomUrl(
                        config.pages,
                        ":classroomId",
                        intl
                    )}
                >
                    <>
                        {config.features.demoMode?.displayIn.classDetails ? (
                            <DemoBanner
                                position="right"
                                message={
                                    config.features.demoMode.message.global
                                }
                            />
                        ) : null}
                        {typeof dashboard === "undefined" ? (
                            <Loader />
                        ) : (
                            <SingleClassroom />
                        )}
                    </>
                </Route>

                <Route
                    exact
                    path={getProgressionOverviewUrl(
                        config.pages,
                        ":classroomId",
                        ":moduleId",
                        intl
                    )}
                >
                    <>
                        {config.features.demoMode?.displayIn
                            .progressionOverview && (
                                <DemoBanner
                                    position="right"
                                    message={
                                        config.features.demoMode.message.global
                                    }
                                />
                            )}
                        {typeof dashboard === "undefined" ? (
                            <Loader />
                        ) : (
                            <ProgressionOverview
                                classrooms={dashboard.classrooms}
                            />
                        )}
                    </>
                </Route>

                <Route
                    exact
                    path={getStudentDetailsUrl(
                        config.pages,
                        ":studentId",
                        ":classroomId",
                        intl
                    )}
                >
                    <>
                        {config.features.demoMode?.displayIn.studentDetails && (
                            <DemoBanner
                                position="right"
                                message={
                                    config.features.demoMode.message.global
                                }
                            />
                        )}
                        {typeof dashboard === "undefined" ? (
                            <Loader />
                        ) : (
                            <StudentDetails />
                        )}
                    </>
                </Route>

                {config.features.clustering && (
                    <Route
                        exact
                        path={getClusteringUrl(
                            config.pages,
                            ":classroomId",
                            ":moduleId",
                            intl
                        )}
                    >
                        <>
                            {config.features.demoMode?.displayIn.clustering && (
                                <DemoBanner
                                    position="right"
                                    message={
                                        config.features.demoMode.message.global
                                    }
                                />
                            )}
                            {typeof dashboard === "undefined" ? (
                                <Loader />
                            ) : (
                                <Clustering
                                    classrooms={dashboard.classrooms}
                                    clustering={dashboard.clustering!}
                                />
                            )}
                        </>
                    </Route>
                )}

                {/* Default route (no path) */}

                <Route>
                    <>
                        {config.features.demoMode?.displayIn.classList ? (
                            <DemoBanner
                                position="right"
                                message={
                                    config.features.demoMode.message.global
                                }
                            />
                        ) : null}
                        {typeof dashboard === "undefined" ? (
                            <Loader />
                        ) : (
                            <ClassroomList />
                        )}
                    </>
                </Route>
            </Switch>
        </div>
    );
}

const messages = defineMessages({
    errorTitle: {
        id: "error-dashboardData-title",
        defaultMessage: "Cannot retrieve classes data",
    },
    errorTxt: {
        id: "error-dashboardData-text",
        defaultMessage:
            "An error occured while trying to retrieve your classes' data. Try again later.",
    },
    goToHome: {
        id: "errors-statements-cannotRetrieveHistory-goToHomepageButton",
        defaultMessage: "Go to homepage",
    },
});

export default DashboardPage;
