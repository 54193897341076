import { IntlShape } from "react-intl"

export const newTestButton = (intl: IntlShape) => ({
    "label": intl.formatMessage({id: "launchNewTest", defaultMessage: "Start a new test"}),
    icon: {
        path: "play_arrow",
        position: "left" as const,
        size: { "width": 8, "height": 9}
    },
    size: "medium" as const
})

export const resumeTestButton = (intl: IntlShape) => ({
    "label": intl.formatMessage({id: "resume", defaultMessage: "Resume"}),
    icon: {
        path: "resume_arrow",
        position: "left" as const,
        size: 20
    },
    size: "medium" as const
})

export const resumeTestButtonLong = (intl: IntlShape) => ({
    "label": intl.formatMessage({id: "resumeTest", defaultMessage: "Resume test"}),
    icon: {
        path: "resume_arrow",
        position: "left" as const,
        size: 20
    },
    size: "medium" as const
});

export const firstTestButton = (intl: IntlShape) => ({
    "label": intl.formatMessage({id: "takeFirstTest", defaultMessage: "Take the first test"}),
    icon: {
        path: "play_arrow",
        position: "left" as const,
        size: { width: 8, height: 9}
    },
    size: "medium" as const
})

export const resultsButton = (intl: IntlShape) => ({
    "label": intl.formatMessage({id: "seeResults", defaultMessage: "See results"}),
    icon: {
        path: "arrow_right",
        position: "right" as const,
        size: "small" as const
    },
    size: "medium" as const
})
