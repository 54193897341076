import { AssetsDetails } from "@evidenceb/athena-common";
import { useContext, useMemo } from "react";
import { configStore } from "../contexts/ConfigContext";
import { sessionStore } from "../contexts/SessionContext";

const useAssetsDetails = (
    { forGameplay }: { forGameplay: boolean } = { forGameplay: false }
) => {
    const {
        config: { apiUrls },
    } = useContext(configStore);
    const {
        session: { version },
    } = useContext(sessionStore);

    const assetsDetails = useMemo<AssetsDetails>(
        () => ({
            version,
            contentUrl: apiUrls.endpoints.assetsProxy,
            folderpath: forGameplay ? "content/" : "interface/",
        }),
        [version, apiUrls, forGameplay]
    );

    return assetsDetails;
};

export default useAssetsDetails;
