import {
  findModuleForObjectiveId,
} from './cacheCommon';
import {
  CacheState,
} from './cacheState';

export function isModulePrecached(state: CacheState, moduleId: string): boolean {
  return state.modules.find(module => module.id === moduleId)?.status === "downloaded";
}

export function isModuleDownloading(state: CacheState, moduleId: string): boolean {
  return state.currentTask?.cacheType === "module" && state.currentTask?.id === moduleId;
}

export function isObjectivePrecached(state: CacheState, objectiveId: string): boolean {
  const module = findModuleForObjectiveId(state, objectiveId);
  if (!module) return false;
  return module.objectives.find(objective => objective.id === objectiveId)?.status === "downloaded";
}

export function isObjectiveDownloading(state: CacheState, objectiveId: string): boolean {
  return state.currentTask?.cacheType === "objective" && state.currentTask?.id === objectiveId;
}

// Experimental feature, see: https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/saveData
// Returns true if using a network that has limited use (example: mobile networks that have throttling or completely unusable after 20Go usage)
export function isUsingLimitedDataNetwork(): boolean | undefined {
  if ("connection" in navigator) {
    const connection: any = navigator["connection"];
    return connection.saveData;
  }
  return undefined;
}

// Experimental feature, see https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType
// Returns equivalent connection speed
// Values are to be adjusted
export function isPrecachingEnabledForConnectionType(): boolean | undefined {
  if ("connection" in navigator) {
    const connection: any = navigator["connection"];
    switch (connection.effectiveType) {
      case "4g": // Equivalent to "No throttling"
        return true;
      case "3g": // Equivalent to "Fast 3G"
        return true;
      case "2g": // Equivalent to "Slow 3G"
        return false;
      case "slow-2g": // No equivalent on default debug tools
        return false;
    }
  }
  return undefined;
}

// For more details, see: https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/estimate
export async function isStorageEnoughForGivenDownloadSize(downloadSizeInBits: number): Promise<boolean | undefined> {
  const storageInfo = await navigator.storage.estimate();
  if (storageInfo && storageInfo.quota && storageInfo.usage) {
    return storageInfo.usage + downloadSizeInBits < storageInfo.quota;
  }
  return undefined;
}

export function isCacheStateInitialized(state: CacheState): boolean {
  if (state.assetsUrl && state.version) return true;
  return false;
}
