import {
  openExerciceAssetsCache,
  findModuleForObjectiveId,
  buildModuleStatus,
  buildObjectiveStatus,
  buildExerciceStatus,
  CacheResult,
} from './cacheCommon';
import {
  CacheState,
  ModuleStatus,
  ObjectiveStatus,
  ExerciceStatus,
  AssetStatus,
} from './cacheState';
import {
  StartCachePayload,
} from './cacheAction';
import { ModuleNotFoundError } from '../../errors';

export async function startCache(signal: AbortSignal, state: CacheState, payload: StartCachePayload): Promise<CacheResult> {
  const cache = await openExerciceAssetsCache();

  switch (payload.cacheType) {
    case "module":
      const moduleStatus = await cacheModule(signal, state.modules.find(module => module.id === payload.id)!, cache);
      return {
        cacheType: payload.cacheType,
        id: payload.id,
        result: moduleStatus,
      }
    case "objective":
      const module = findModuleForObjectiveId(state, payload.id);
      if (!module) throw new ModuleNotFoundError("Could not find module ID " + payload.id);

      const objectiveStatus = await cacheObjective(signal, module.objectives.find(objective => objective.id === payload.id)!, cache);
      return {
        cacheType: payload.cacheType,
        id: payload.id,
        result: objectiveStatus,
      };
  }
}

async function cacheModule(signal: AbortSignal, moduleStatus: ModuleStatus, cache: Cache): Promise<ModuleStatus> {
  const objectiveStatuses = new Array<ObjectiveStatus>();

  for (const previousObjectiveStatus of moduleStatus.objectives) {
    if (!signal.aborted) {
      const newObjectiveStatus = await cacheObjective(signal, previousObjectiveStatus, cache);
      objectiveStatuses.push(newObjectiveStatus);
    } else {
      objectiveStatuses.push(previousObjectiveStatus);
    }
  }

  return buildModuleStatus(moduleStatus.id, objectiveStatuses);
}

async function cacheObjective(signal: AbortSignal, objectiveStatus: ObjectiveStatus, cache: Cache, cachedAssets: Set<string> = new Set()): Promise<ObjectiveStatus> {
  const exerciceStatuses = new Array<ExerciceStatus>();

  for (const previousExerciceStatus of objectiveStatus.exercices) {
    if (!signal.aborted) {
      const newExerciceStatus = await cacheExercice(signal, previousExerciceStatus, cache, cachedAssets);
      exerciceStatuses.push(newExerciceStatus);
    } else {
      exerciceStatuses.push(previousExerciceStatus);
    }
  }

  return buildObjectiveStatus(objectiveStatus.id, exerciceStatuses);
}

async function cacheExercice(signal: AbortSignal, exerciceStatus: ExerciceStatus, cache: Cache, cachedAssets: Set<string> = new Set()): Promise<ExerciceStatus> {
  const assetStatuses = new Array<AssetStatus>();

  for (const asset of exerciceStatus.assets) {
    const assetUrl = asset.url;

    if (!cachedAssets.has(assetUrl)) {
      if (!signal.aborted) {
        cachedAssets.add(assetUrl);
        if (await cacheAsset(cache, assetUrl)) {
          assetStatuses.push({ url: assetUrl, status: "downloaded" })
        } else {
          assetStatuses.push({ url: assetUrl, status: "not_downloaded" })
        }
      } else {
        // Not cached but task is aborted case
        assetStatuses.push({ url: assetUrl, status: "not_downloaded" })
      }
    } else {
      // Already cached case
      assetStatuses.push({ url: assetUrl, status: "downloaded" })
    }
  }

  return buildExerciceStatus(exerciceStatus.id, assetStatuses);
}

async function cacheAsset(cache: Cache, assetUrl: string): Promise<boolean> {
  try {
    const response = await fetch(assetUrl, { mode: "no-cors" });
    cache.put(assetUrl, response);
    return true;
  } catch (error) {
    return false;
  }
}
