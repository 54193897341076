import React, { useContext, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { ActivityShell } from "@evidenceb/gameplay-interfaces";
import { UserType } from "../../../interfaces/User";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import useMinitutor from "./useMinitutor";
import ExerciseIdentifier from "./ExerciseIdentifier/ExerciseIdentifier";
import Loader from "../../../components/Loader/Loader";
import ChatbotShell from "../Shell/ChatbotShell/ChatbotShell";
import WizardShell from "../Shell/WizardShell/WizardShell";
import ControlledDialog from "../../../design-system-components/ControlledDialog/ControlledDialog";
import { InfoPanelProps, PlayerHeaderProps } from "../PlayerBuilder";
import { TestInfoModalProps } from "../TestInfoModal/TestInfoModal";
import {
    PlayerOpts,
    Playlist,
    PlaylistManager,
} from "../../../interfaces/Player";
import { PlaylistSummaryProps } from "../PlaylistSummary/DefaultPlaylistSummary/DefaultPlaylistSummary";
import usePlayerEventEmitter from "./usePlayerEventEmitter";

import "./PlaylistPlayer.scss";

export interface PlaylistPlayerProps {
    playlistManager: PlaylistManager;
    InfoPanel?: (props: InfoPanelProps) => JSX.Element | null;
    TestInfoModal?: (props: TestInfoModalProps) => JSX.Element | null;
    PlaylistSummary?: (props: PlaylistSummaryProps) => JSX.Element | null;
    Header: (props: PlayerHeaderProps) => JSX.Element;
    opts?: Partial<PlayerOpts>;
}

const PlaylistPlayer = ({
    playlistManager,
    InfoPanel,
    TestInfoModal,
    PlaylistSummary,
    Header,
    opts,
}: PlaylistPlayerProps) => {
    const { config } = useContext(configStore);
    const {
        session: { userType },
    } = useContext(sessionStore);
    const history = useHistory();
    const intl = useIntl();

    const playerEventEmitter = usePlayerEventEmitter(playlistManager);
    const minitutor = useMinitutor(playerEventEmitter);

    const contentWrapperRef = useRef<HTMLElement>(null);

    if (!playlistManager.initialized && playlistManager.error)
        return (
            <ControlledDialog
                title={intl.formatMessage(messages.historyErrorTitle)}
                description={{
                    $html: intl.formatMessage(messages.historyErrorDescription),
                }}
                primaryButton={{
                    label: intl.formatMessage(messages.historyErrorGoHome),
                    fn: () => {
                        history.push("/");
                    },
                }}
            />
        );

    if (!playlistManager.initialized) return <Loader />;

    const Shell =
        playlistManager.playlist.activity?.shell === ActivityShell.Chatbot
            ? ChatbotShell
            : WizardShell;

    return (
        <>
            <div className="playlist-player-container">
                <Header
                    className="playlist-player__header"
                    playlistManager={playlistManager}
                    progression={playlistManager.playlist.progression}
                    opts={overrideHeaderOpts(opts, playlistManager.playlist)}
                />

                <main
                    className="playlist-player__content-wrapper"
                    ref={contentWrapperRef}
                >
                    {!isPlaylistFinished(playlistManager.playlist) &&
                        InfoPanel && (
                            <InfoPanel
                                playlist={playlistManager.playlist}
                                getContainer={() => contentWrapperRef.current!}
                            />
                        )}

                    {!isPlaylistFinished(playlistManager.playlist) ? (
                        <Shell
                            className="playlist-player__shell"
                            playerEventEmitter={playerEventEmitter}
                            minitutor={minitutor}
                            onGoToNextExercise={
                                playlistManager.goToNextExercise
                            }
                            onExerciseResult={(result, autoGoToNext) => {
                                playlistManager.recordCurrentExerciseResult(
                                    result,
                                    autoGoToNext
                                );
                            }}
                            playlist={playlistManager.playlist}
                            clearHistory={
                                userType === UserType.Student
                                    ? playlistManager.clearHistory
                                    : undefined
                            }
                            opts={opts}
                        />
                    ) : (
                        <>
                            {PlaylistSummary && (
                                <PlaylistSummary
                                    className="playlist-player__summary"
                                    exerciseResults={
                                        playlistManager.playlist.exerciseResults
                                    }
                                    opts={opts}
                                />
                            )}
                        </>
                    )}

                    {config.debug?.exerciseIdentifier &&
                        !isPlaylistFinished(playlistManager.playlist) && (
                            <ExerciseIdentifier
                                moduleId={playlistManager.playlist.module.id}
                                objectiveId={
                                    playlistManager.playlist.objective!.id
                                }
                                activityId={
                                    playlistManager.playlist.activity!.id
                                }
                                exerciseId={
                                    playlistManager.playlist.currentExercise!.id
                                }
                            />
                        )}
                </main>
            </div>

            {userType === UserType.Student && playlistManager.showIdleModal && (
                <ControlledDialog
                    title={intl.formatMessage(messages.timeoutModalTitle)}
                    description={{
                        $html: intl.formatMessage(
                            messages.timeoutModalDescription
                        ),
                    }}
                    primaryButton={{
                        label: intl.formatMessage(
                            messages.timeoutModalContinue
                        ),
                        fn: playlistManager.continueAfterIdle!,
                    }}
                    secondaryButton={{
                        label: intl.formatMessage(messages.timeoutModalQuit),
                        fn: () => {
                            history.push("/");
                        },
                    }}
                />
            )}

            {TestInfoModal && Shell !== ChatbotShell && (
                <TestInfoModal playlist={playlistManager.playlist} />
            )}
        </>
    );
};

const isPlaylistFinished = (playlist: Playlist): boolean =>
    !playlist.currentExercise;

const overrideHeaderOpts = (
    opts: Partial<PlayerOpts> | undefined,
    playlist: Playlist
): Partial<PlayerOpts> | undefined => {
    // Do not confirm exit if there was no initial history and no statements
    // were added or if the playlist if finished
    if (
        opts?.confirmPlayerExit &&
        ((playlist.initiallyFresh && playlist.exerciseResults.length === 0) ||
            !playlist.currentExercise)
    )
        return {
            ...opts,
            confirmPlayerExit: false,
        };
    return opts;
};

const messages = defineMessages({
    historyErrorTitle: {
        id: "errors-statements-cannotRetrieveHistory-title",
        defaultMessage: "Impossible to retrieve history",
    },
    historyErrorDescription: {
        id: "errors-statements-cannotRetrieveHistory-description",
        defaultMessage:
            "Your progress data is not available at this time. Please try again later.",
    },
    historyErrorGoHome: {
        id: "errors-statements-cannotRetrieveHistory-goToHomepageButton",
        defaultMessage: "Go to homepage",
    },
    timeoutModalTitle: {
        id: "exerciseShell-timeoutModalTitle",
        defaultMessage: "Are your still here?",
    },
    timeoutModalDescription: {
        id: "exerciseShell-timeoutModalDescription",
        defaultMessage:
            "It seems that you have been inactive for a while and we want to make sure that you are still there.",
    },
    timeoutModalContinue: {
        id: "exerciseShell-timeoutModalContinueButton",
        defaultMessage: "Continue",
    },
    timeoutModalQuit: {
        id: "exerciseShell-timeoutModalQuitButton",
        defaultMessage: "Leave",
    },
});

export default PlaylistPlayer;
