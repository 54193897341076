import React from "react";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import { UsePRManagerReturn } from "../Manager/usePRManager";
import ControlledDialog from "../../../design-system-components/ControlledDialog/ControlledDialog";

interface Props {
    reset?: UsePRManagerReturn["reset"];
    url?: string;
    setModal?: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
    setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CancelModal = ({
    reset,
    setModal,
    setShowModal,
    url,
}: Props): JSX.Element => {
    const history = useHistory();
    const intl = useIntl();

    return (
        <ControlledDialog
            title={intl.formatMessage({
                id: "prm-manager-cancelModal-title",
                defaultMessage: "Cancel your changes?",
            })}
            description={{
                $html: intl.formatMessage({
                    id: "prm-manager-cancelModal-description",
                    defaultMessage:
                        "Do you want to cancel the changes? If so, they will not be saved.",
                }),
            }}
            primaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-cancelModal-primaryButton",
                    defaultMessage: "Continue with the current changes",
                }),
                fn: () => {
                    if (setModal) setModal(null);
                    if (setShowModal) setShowModal(false);
                },
            }}
            secondaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-cancelModal-secondaryButton",
                    defaultMessage: "Cancel changes",
                }),
                fn: () => {
                    if (reset) reset();
                    if (url) history.push(url);
                    if (setModal) setModal(null);
                    if (setShowModal) setShowModal(false);
                },
            }}
        />
    );
};

export default CancelModal;
