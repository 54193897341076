import { defineMessages } from "@formatjs/intl";
import cn from "classnames";
import React, { useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Button, {
    ButtonProps,
} from "../../design-system-components/Button/Button";
import useAssetsDetails from "../../hooks/useAssetsDetails";
import { Video } from "../../interfaces/Resources";
import { completeVideoUrls } from "../../utils/resources";

import "./VideoResource.scss";

interface Props {
    video: Video;
    controls?: VideoControl[];
}

const VideoResource = ({
    video: _video,
    controls,
    className,
    onEnded,
    onPlay,
    ...props
}: Props &
    Omit<
        React.DetailedHTMLProps<
            React.VideoHTMLAttributes<HTMLVideoElement>,
            HTMLVideoElement
        >,
        "src" | "poster" | "controls" | "crossOrigin" | "children"
    >) => {
    const intl = useIntl();
    const assetsDetails = useAssetsDetails();

    const video = useMemo(
        () => completeVideoUrls(_video, assetsDetails),
        [_video, assetsDetails]
    );

    const videoRef = useRef<HTMLVideoElement>(null);
    const [showControls, setShowControls] = useState<boolean>(false);

    return (
        <div className={cn("video-resource", className)}>
            <video
                {...props}
                ref={videoRef}
                crossOrigin="anonymous"
                controls
                poster={video.poster}
                onEnded={(event) => {
                    if (onEnded) onEnded(event);
                    setShowControls(true);
                }}
                onPlay={(event) => {
                    if (onPlay) onPlay(event);
                    setShowControls(false);
                }}
            >
                {video.sources.map((source) => (
                    <source key={source.src} {...source} />
                ))}
                {video.tracks?.map((track) => (
                    <track key={track.src} {...track} />
                ))}
            </video>

            {showControls && (
                <div className="video-resource__controls">
                    {controls?.map((control, index) => {
                        switch (control.type) {
                            case "restart":
                                return (
                                    <Button
                                        key={control.type + index}
                                        className="video-resource__control-btn --dark"
                                        label={intl.formatMessage(
                                            messages.rewatch
                                        )}
                                        onClick={() => {
                                            if (!videoRef.current) return;
                                            videoRef.current.play();
                                        }}
                                        icon={{
                                            path: "restart",
                                        }}
                                    />
                                );
                            case "custom":
                                const { type, ...btnProps } = control;
                                return (
                                    <Button
                                        key={type + index}
                                        className="video-resource__control-btn"
                                        {...btnProps}
                                    />
                                );
                            default:
                                return <></>;
                        }
                    })}
                </div>
            )}
        </div>
    );
};

type VideoControl =
    | { type: "restart" }
    | ({ type: "custom" } & Pick<ButtonProps, "label" | "onClick" | "icon">);

const messages = defineMessages({
    rewatch: {
        id: "rewatchVideo",
        defaultMessage: "Watch the video again",
    },
});

export default VideoResource;
