import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
    ClassroomAnalytics,
    ClassroomsClustering,
    ModuleDashboard,
} from "../../../../../../interfaces/Dashboard";
import { configStore } from "../../../../../../contexts/ConfigContext";
import { dataStore } from "../../../../../../contexts/DataContext";
import useClassroom from "../../../useClassroom";
import {
    getIdentifiedGroupsNumber,
    getModuleById,
    getResourceIndex,
} from "../../../../../../utils/dataRetrieval";
import Bar from "../../../Bar/Bar";
import ModuleInfo from "../ModuleInfo/ModuleInfo";
import Toggle from "../../../../../../components/Toggle/Toggle";
import ControlledDialog from "../../../../../../design-system-components/ControlledDialog/ControlledDialog";

import { BAR_COLOR_SCHEMES } from "../../../Bar/BAR_COLOR_SCHEMES";
import "./ModuleCard.scss";
import Card from "../../../../../../design-system-components/Card/Card";


interface Props {
    module: ModuleDashboard;
    classroom: ClassroomAnalytics;
    clustering: ClassroomsClustering | undefined;
}
interface UnlockedProps extends Props {
    type: "unlocked";
}
interface LockedProps extends Props {
    type: "locked";
    activateModule: () => Promise<void>;
}

const ModuleCard = (props: UnlockedProps | LockedProps): JSX.Element => {
    const { config } = useContext(configStore);
    const { getUrl } = useClassroom();
    const intl = useIntl();

    const displayClustering = (moduleId: string) => {
        if (
            config.features.clustering &&
            props.clustering &&
            props.clustering[props.classroom.id][moduleId] &&
            typeof props.clustering[props.classroom.id][moduleId].error ===
            "undefined"
        )
            return true;
        return false;
    };

    return (

        <Card
            className="single-classroom__module-card"
            cardTitle={{
                as: "div",
                content: <Title {...props} />
            }}
        >
            {props.module.progression !== 0 && (
                <>
                    <Bar
                        state={
                            props.module.progression === 0
                                ? "noData"
                                : undefined
                        }
                        url={getUrl("progressionOverview", props.classroom.id, props.module.id)}
                        type={"progress"}
                        moduleName={
                            intl.formatMessage({ id: "dashboard-common-progression", defaultMessage: "Progression" })
                        }
                        value={
                            props.module.progression !== 0
                                ? (
                                    props.module.progression * 100
                                ).toFixed(1)
                                : props.module.progression
                        }
                        colors={
                            BAR_COLOR_SCHEMES[
                            (props.type + "Module") as
                            | "unlockedModule"
                            | "lockedModule"
                            ]
                        }
                    />

                    {displayClustering(props.module.id) && (
                        <Bar
                            state={
                                props.module.progression === 0
                                    ? "noData"
                                    : undefined
                            }
                            url={getUrl(
                                "viewGroups",
                                props.classroom.id,
                                props.module.id
                            )}
                            type={"groups"}
                            moduleName={getIdentifiedGroupsNumber(
                                props.clustering!,
                                props.classroom.id,
                                props.module.id,
                                intl.formatMessage({ id: "dashboard-classList-identifiedGroups", defaultMessage: "Identified groups" })
                            )}
                            value={
                                props.module.progression !== 0
                                    ? (
                                        props.module.progression *
                                        100
                                    ).toFixed(1)
                                    : props.module.progression
                            }
                            colors={
                                BAR_COLOR_SCHEMES[
                                (props.type + "Module") as
                                | "unlockedModule"
                                | "lockedModule"
                                ]
                            }
                        />
                    )}
                </>
            )}

            {props.module.progression === 0 && (
                <div className="no-progression">0%</div>
            )}

            <ModuleInfo
                progression={props.module.progression}
                total={props.module.participants}
                inProgress={props.module.alert.hasImproved}
                inDifficulty={props.module.alert.hasDifficulties}
            />
        </Card>

    );
};

const Title = (props: Omit<UnlockedProps | LockedProps, "classroom" | "clustering">) => {
    const { data } = useContext(dataStore);
    const intl = useIntl();
    const [isToggleSelected, setIsToggleSelected] = useState<boolean>(false);
    const [openModuleActivationModal, setOpenModuleActivationModal] =
        useState<boolean>(false);

    // Reinit when module changes
    useEffect(() => {
        setIsToggleSelected(false);
    }, [props.module]);

    return <>
        <h3>
            {`${getResourceIndex(props.module.id, data.modules) + 1}. ${getModuleById(props.module.id, data).title.short}`}
        </h3>

        {props.type === "locked" && (
            <Toggle
                accessibleLabel={
                    intl.formatMessage({ id: "singleClass-toggleActivateModuleAccessibleLabel", defaultMessage: "ActivateModule" }) +
                    getModuleById(props.module.id, data).title
                        .long
                }
                isSelected={isToggleSelected}
                onChange={async (isSelected) => {
                    if (!isSelected) return;

                    setIsToggleSelected(true);
                    setOpenModuleActivationModal(true);
                }}
                pinIcon={isToggleSelected ? "done" : "close"}
            />
        )}

        {openModuleActivationModal && (
            <ControlledDialog
                title={intl.formatMessage({ id: "singleClass-activateModuleModalTitle", defaultMessage: "Activate module" })}
                description={
                    {$html: intl.formatMessage({ id: "singleClass-activateModuleModalDescription", defaultMessage: "Your students will be able to access the module" })}
                }
                primaryButton={{
                    label: intl.formatMessage({ id: "singleClass-activateModuleModalConfirm", defaultMessage: "Activate" }),
                    fn: async () => {
                        try {
                            await (props as LockedProps).activateModule();
                        } catch (err) {
                            setIsToggleSelected(false);
                        }
                        setOpenModuleActivationModal(false);
                    }
                }}
                secondaryButton={{
                    label: intl.formatMessage({ id: "singleClass-activateModuleModalCancel", defaultMessage: "Cancel" }),
                    fn: () => {
                        setIsToggleSelected(false);
                        setOpenModuleActivationModal(false);
                    }
                }}
            />
        )}
    </>
}

export default ModuleCard;
