import { useIntl } from "react-intl";
import useAssetsDetails from "../../../../../../hooks/useAssetsDetails";
import { ExerciseResultProps } from "../ExerciseResult";
import {
    ContentBlock,
    isLegacyFeedbackContent,
} from "@evidenceb/athena-common";
import LegacyFeedbackContent from "../../../WizardShell/WizardFeedback/WizardFeedbackContent/LegacyFeedbackContent/LegacyFeedbackContent";
import React from "react";
import "./FeedbackExplicatif.scss";
import "./../../ChatBubble/ChatBubble.scss";

function FeedbackExplicatif({
    exerciseResult,
    exercise,
}: Pick<ExerciseResultProps, "exercise" | "exerciseResult">) {
    const assetsDetails = useAssetsDetails();
    let feedbackExplicatif = exerciseResult.feedbackExplicatif

    const intl = useIntl();
    if (feedbackExplicatif && isLegacyFeedbackContent(feedbackExplicatif))
        return (
            <div className="bubble-container animated animatedFadeInUp fadeInUp left">
                <div className="chatbot-placeholder"></div>
                <div
                    className={`FeedbackExplicatif ${
                        exerciseResult.correct ? "correct" : "incorrect"
                    }`}
                >
                    {isLegacyFeedbackContent(feedbackExplicatif) &&
                        (Array.isArray(feedbackExplicatif) ? (
                            feedbackExplicatif.map((fb, i) => (
                                //TEMP FIX FOR P4: alignment added to be used only on "indication"-like feedbacks
                                <LegacyFeedbackContent
                                    key={"fb-" + i}
                                    feedback={fb}
                                    alignment
                                    className="feedback-content--legacy"
                                />
                            ))
                        ) : (
                            <LegacyFeedbackContent
                                feedback={feedbackExplicatif.content}
                                className="feedback-content--legacy"
                            />
                        ))}
                </div>
            </div>
        );
    else if (feedbackExplicatif && !isLegacyFeedbackContent(feedbackExplicatif)) {
        return (
            <ContentBlock
                data={feedbackExplicatif}
                assetsDetails={assetsDetails}
                intl={intl}
            />
        );
    }
    return <></>;
}

export default FeedbackExplicatif;
