import htmlTags from "html-tags";
import selfClosingTags from "html-tags/void";

const htmlHandler: { [tag: string]: () => string } = htmlTags.reduce(
    (handlers, tag) => {
        return {
            ...handlers,
            [tag]: (...chunks: any[]) => `<${tag}>${chunks.join()}</${tag}>`,
        };
    },
    {}
);
selfClosingTags.forEach((tag) => {
    htmlHandler[tag] = () => tag;
});

export default htmlHandler;
