import React, { useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { dataStore } from "../../../../contexts/DataContext";
import {
    getActivityById,
    getObjectiveById,
    objectiveNumberSort,
} from "../../../../utils/dataRetrieval";
import htmlHandler from "../../../../utils/html-handler";
import { commonMessages } from "../../../../utils/messages";
import { ConfirmationModalProps } from "../usePRConfirmation";
import Icon from "../../../../design-system-components/Icon/Icon";
import ControlledDialog from "../../../../design-system-components/ControlledDialog/ControlledDialog";

import "./ConfirmationModal.scss";

const UnlockActivityAndAncestorObjectivesConfirmationModal = ({
    confirm,
    confirmation,
}: ConfirmationModalProps) => {
    const intl = useIntl();
    const { data } = useContext(dataStore);

    const activity = useMemo(
        () => getActivityById(confirmation.originId, data),
        [confirmation.originId, data]
    );

    const listOfObjectives = useMemo(
        () =>
            confirmation.dependenciesList
                ?.map((objId) => getObjectiveById(objId, data))
                .sort(...objectiveNumberSort(data)),
        [confirmation, data]
    );

    return (
        <ControlledDialog
            variant={"alert"}
            title={{
                as: "h2",
                className: "unlock-activity-title",
                content: (
                    <>
                        <Icon path="prerequisite-icon" size={30} />
                        {intl.formatMessage(
                            {
                                id: "prm-unlockActivityAndAncestors",
                                defaultMessage:
                                    "Activate {title} and its ancestors",
                            },
                            { title: activity.title.long.toLowerCase() }
                        )}
                    </>
                )
            }}
            aria-label={
                intl.formatMessage(
                    {
                        id: "prm-unlockActivityAndAncestors",
                        defaultMessage: "Activate {title} and its ancestors",
                    },
                    { title: activity.title.long.toLowerCase() }
                )
            }
            description={{
                $html: intl.formatMessage(
                    {
                        id: "html-prm-unlockActivityAndAncestors-description",
                        defaultMessage:
                            "{title} requires objectives to be activated. To ensure consistency, <b>activating it will automatically activate elements it requires.</b>",
                    },
                    {
                        ...htmlHandler,
                        title: activity.title.long,
                    }
                )
            }}
            primaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-listItem-unlock",
                    defaultMessage: "Activate",
                }),

                fn: () => {
                    confirm(true);
                },
            }}
            secondaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-cancel",
                    defaultMessage: "Cancel",
                }),
                fn: () => {
                    confirm(false);
                },
            }}
        >
            {listOfObjectives && (
                <details className="confirmation-modal__dependency-list">
                    <summary>
                        <Icon
                            path="chevron_right"
                            className="dependency-list__icon"
                        />
                        {listOfObjectives.length > 1 ? (
                            <FormattedMessage
                                id="prm-seeObjectivesToBeUnLocked"
                                defaultMessage="See the list of objectives that will be unlocked"
                            />
                        ) : (
                            <FormattedMessage
                                id="prm-seeObjectiveToBeUnLocked"
                                defaultMessage="See the objective that will be unlocked"
                            />
                        )}
                    </summary>
                    <figure>
                        <figcaption>
                            {intl.formatMessage(commonMessages.objectives)}
                        </figcaption>
                        <ol>
                            {listOfObjectives.map((obj) => (
                                <li key={"unlock-" + obj.id}>
                                    {obj.title.short}
                                </li>
                            ))}
                        </ol>
                    </figure>
                </details>
            )}
        </ControlledDialog >
    );
};

export default UnlockActivityAndAncestorObjectivesConfirmationModal;
