import React, { useEffect, useContext, useMemo, useState } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { dataStore } from "../../contexts/DataContext";
import {
    ClassroomAnalytics,
    ClusterInfosClustering,
    Dashboard,
    InfosEleves,
    InfosVariables,
    ModuleCluster,
    ModuleDashboard,
    ParamTypes,
} from "../../interfaces/Dashboard";
import GroupCharacteristics from "./GroupCharacteristics/GroupCharacteristics";
import ClustersVisualRepresentation from "./ClustersVisualRepresentation/ClustersVisualRepresentation";
import Group from "./Group/Group";
import {
    getClusters,
    getModuleById,
    getResourceIndex,
    getStudentName,
} from "../../utils/dataRetrieval";

import "./Clustering.scss";
import Breadcrumbs, {
    Breadcrumb,
} from "../../components/Breadcrumbs/Breadcrumbs";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Button from "../../components/Button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import { configStore } from "../../contexts/ConfigContext";
import { errorStore } from "../../contexts/ErrorContext";
import dashboardMessages from "../DashBoard/dashboardMessages";
import {
    getClassroomListUrl,
    getDashboardUrl,
    getProgressionOverviewUrl,
    getSingleClassroomUrl,
} from "../../utils/navigation-dashboard";
import { sessionStore } from "../../contexts/SessionContext";
import SpecimenHelper from "../DashBoard/SpecimenHelper/SpecimenHelper";

const Clustering: React.FC<{
    classrooms: Dashboard["classrooms"];
    clustering: Required<Dashboard>["clustering"];
}> = ({ classrooms, clustering }) => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);
    const history = useHistory();
    const { path } = useRouteMatch();
    const { classroomId, moduleId } = useParams<ParamTypes>();
    const intl = useIntl();

    const clusters = useMemo(
        () =>
            clustering[classroomId][moduleId] &&
            !clustering[classroomId][moduleId].error
                ? getClusters(
                      clustering[classroomId][moduleId] as ModuleCluster
                  )
                : undefined,
        [clustering, classroomId, moduleId]
    );

    const currentClassroom = useMemo<ClassroomAnalytics | undefined>(
        () => classrooms.find((classroom) => classroom.id === classroomId),
        [classroomId, classrooms]
    );
    useEffect(() => {
        if (!currentClassroom) {
            setErrorInfo({
                displayModal: true,
                modal: {
                    type: "NOTIFICATION",
                    content: {
                        title: intl.formatMessage(
                            dashboardMessages.classroomNotFound
                        ),
                    },
                },
            });
            history.push(getClassroomListUrl(config.pages));
        }
    }, [currentClassroom, setErrorInfo, config.pages, intl, history]);

    const currentModule = useMemo<ModuleDashboard>(
        () =>
            currentClassroom?.modulesList.find(
                (module) => module.id === moduleId
            )!,
        [currentClassroom, moduleId]
    );

    const [selectedStudentId, setSelectedStudentId] = useState<string>();
    const [selectedGroups, setSelectedGroups] = React.useState<number[]>([]);
    useEffect(() => {
        setSelectedStudentId(undefined);
    }, [classroomId, moduleId]);
    useEffect(() => {
        if (!clusters) return;

        const studentClusterIndex = clusters.findIndex((cluster) =>
            cluster.eleves.some((id) => id === selectedStudentId)
        );
        if (!selectedGroups.includes(studentClusterIndex))
            setSelectedStudentId(undefined);
    }, [selectedStudentId, selectedGroups, clusters]);

    const breadcrumbs = useMemo<Breadcrumb[]>(
        () => [
            {
                title: intl.formatMessage({
                    id: "dashboard-common-classes",
                    defaultMessage: "Classes",
                }),
                url: getDashboardUrl(config.pages),
            },
            {
                title: currentClassroom?.name ?? "",
                url: getSingleClassroomUrl(config.pages, classroomId, intl),
            },
            {
                title: intl.formatMessage({
                    id: "dashboard-common-groups",
                    defaultMessage: "Groups",
                }),
                current: true,
            },
        ],
        [classroomId, config.pages, currentClassroom, intl]
    );

    return (
        <>
        <div className="classroom-modules-overview">
            <header>
                <Breadcrumbs items={breadcrumbs} />
            </header>

            <div className="group-body">
                <div className="classroom-navigation">
                    <h1>
                        <FormattedMessage
                            id="dashboard-common-groups"
                            defaultMessage="Groups"
                        />
                    </h1>
                    <div className="selects">
                        <CustomSelect
                            label={intl.formatMessage({
                                id: "dashboard-common-classes",
                                defaultMessage: "Classes",
                            })}
                            onSelectOption={(value) => {
                                history.push(
                                    path
                                        .replace(/:classroomId/, value)
                                        .replace(/:moduleId/, moduleId)
                                );
                            }}
                            selectedOption={
                                currentClassroom ? currentClassroom.name : ""
                            }
                        >
                            {classrooms.map((classroom, i) => (
                                <option
                                    key={"class-" + i}
                                    value={classroom.id}
                                >{`Classe ${classroom.name}`}</option>
                            ))}
                        </CustomSelect>

                        {currentClassroom &&
                            currentClassroom.modulesList.length > 0 && (
                                <CustomSelect
                                    label={intl.formatMessage({
                                        id: "dashboard-common-modules",
                                        defaultMessage: "Modules",
                                    })}
                                    onSelectOption={(value) => {
                                        history.push(
                                            path
                                                .replace(/:moduleId/, value)
                                                .replace(
                                                    /:classroomId/,
                                                    classroomId
                                                )
                                        );
                                    }}
                                    selectedOption={
                                        currentModule
                                            ? getResourceIndex(
                                                  currentModule!.id,
                                                  data.modules
                                              ) +
                                              1 +
                                              ". " +
                                              getModuleById(
                                                  currentModule!.id,
                                                  data
                                              ).title.short!
                                            : ""
                                    }
                                >
                                    {currentClassroom.modulesList.map(
                                        (module, i) => (
                                            <option
                                                key={"module-" + i}
                                                value={module.id}
                                            >
                                                {getResourceIndex(
                                                    module.id,
                                                    data.modules
                                                ) +
                                                    1 +
                                                    ". " +
                                                    getModuleById(
                                                        module.id,
                                                        data
                                                    ).title.short}
                                            </option>
                                        )
                                    )}
                                </CustomSelect>
                            )}
                    </div>
                    <Button
                        type={"primary"}
                        label={intl.formatMessage({
                            id: "clustering-seeProgressionOverview",
                            defaultMessage: "See progression overview",
                        })}
                        icons={[
                            {
                                placement: "right",
                                code: "arrow_forward",
                                noCircle: true,
                            },
                        ]}
                        onClick={() => {
                            history.push(
                                getProgressionOverviewUrl(
                                    config.pages,
                                    classroomId,
                                    moduleId,
                                    intl
                                )
                            );
                        }}
                    />
                </div>

                <div className="clusters-container">
                    {!clusters ? (
                        <FormattedMessage
                            id="dashboard-clustering-noClustersForClassAndModudle"
                            defaultMessage="No clusters for this module"
                        />
                    ) : (
                        <>
                            <div className="clusters__right">
                                <h2>
                                    <FormattedMessage
                                        id="dashboard-clustering-characteristics"
                                        defaultMessage="Characteristics"
                                    />
                                </h2>

                                <div className="characteristics-container">
                                    {currentModule &&
                                        clusters.map((cluster, index) => {
                                            return (
                                                <GroupCharacteristics
                                                    key={
                                                        "GroupCharacteristics-" +
                                                        index
                                                    }
                                                    variables={getVariables(
                                                        clustering[classroomId][
                                                            moduleId
                                                        ] as ModuleCluster
                                                    )}
                                                    cluster={cluster}
                                                    groupIndex={index}
                                                    selected={selectedGroups.includes(
                                                        index
                                                    )}
                                                    onSelectGroup={() => {
                                                        if (
                                                            selectedGroups.includes(
                                                                index
                                                            )
                                                        )
                                                            setSelectedGroups(
                                                                (curr) =>
                                                                    curr.filter(
                                                                        (i) =>
                                                                            i !==
                                                                            index
                                                                    )
                                                            );
                                                        else
                                                            setSelectedGroups(
                                                                (curr) => [
                                                                    ...curr,
                                                                    index,
                                                                ]
                                                            );
                                                    }}
                                                    selectedStudent={
                                                        selectedStudentId &&
                                                        isStudentInCluster(
                                                            selectedStudentId,
                                                            cluster
                                                        )
                                                            ? getStudent(
                                                                  clustering[
                                                                      classroomId
                                                                  ][
                                                                      moduleId
                                                                  ] as ModuleCluster,
                                                                  selectedStudentId
                                                              )
                                                            : undefined
                                                    }
                                                />
                                            );
                                        })}
                                </div>
                            </div>

                            <div className="clusters__left">
                                <ClustersVisualRepresentation
                                    clusters={getClusters(
                                        clustering[classroomId][
                                            moduleId
                                        ] as ModuleCluster
                                    )}
                                    selectedGroups={selectedGroups}
                                    setSelectedGroups={setSelectedGroups}
                                    selectedStudentId={selectedStudentId}
                                />

                                <div className="group-container">
                                    {getClusters(
                                        clustering[classroomId][
                                            moduleId
                                        ] as ModuleCluster
                                    ).map((cluster, index) => (
                                        <Group
                                            key={"GroupContainer-" + index}
                                            groupeName={`${intl.formatMessage({
                                                id: "dashboard-common-group",
                                                defaultMessage: "Group",
                                            })} ${cluster.name}`}
                                            students={cluster.eleves.map(
                                                (studentId) => {
                                                    return {
                                                        id: studentId,
                                                        name: getStudentName(
                                                            classrooms,
                                                            classroomId,
                                                            moduleId,
                                                            studentId
                                                        ),
                                                    };
                                                }
                                            )}
                                            groupIndex={index}
                                            selected={selectedGroups.includes(
                                                index
                                            )}
                                            onSelectGroup={() => {
                                                if (
                                                    selectedGroups.includes(
                                                        index
                                                    )
                                                )
                                                    setSelectedGroups((curr) =>
                                                        curr.filter(
                                                            (i) => i !== index
                                                        )
                                                    );
                                                else
                                                    setSelectedGroups(
                                                        (curr) => [
                                                            ...curr,
                                                            index,
                                                        ]
                                                    );
                                            }}
                                            selectedStudentId={
                                                selectedStudentId
                                            }
                                            setSelectedStudentId={
                                                setSelectedStudentId
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
        {session.specimen &&
            <SpecimenHelper
                dialogTitle={intl.formatMessage({id: "specimen-clustering-helper-title", defaultMessage: "Groups of students with similar needs"})}
                dialogDescription={intl.formatMessage({id: "specimen-clustering-helper-description", defaultMessage: "The clustering algorithm forms groups of students with similar learning characteristics for a given module."})}
            />
        }
        </>
    );
};

const isStudentInCluster = (
    studentId: string,
    cluster: ClusterInfosClustering
) => {
    return cluster.eleves.some((id) => id === studentId);
};

const getVariables = (
    clustering: ModuleCluster
): (InfosVariables & { name: string })[] => {
    return clustering.infosVariables
        .map((variables) =>
            Object.keys(variables).map((variableName) => {
                return {
                    ...variables[variableName],
                    name: variableName,
                };
            })
        )
        .flat();
};

const getStudent = (
    clustering: ModuleCluster,
    studentId: string
): InfosEleves => {
    return clustering.infosEleves.reduce((studentsObject, studentObject) => {
        Object.keys(studentObject).forEach((key) => {
            const studentId = studentObject[key].id;
            studentsObject[studentId] = studentObject[key];
        });
        return studentsObject;
    }, {} as { [studentId: string]: InfosEleves })[studentId];
};

export default Clustering;
