import { AIType } from "@evidenceb/ai-handler";
import { AIClass } from "@evidenceb/ai-handler";
import { makeGraphFromData } from "@evidenceb/parametric-graph";
import { Config } from "../interfaces/Config";
import { MinimalData } from "../interfaces/Data";
import { getActivitiesInModule, getModuleById } from "./dataRetrieval";

export const getAIClass = async (
    type: AIType,
    id: string
): Promise<AIClass> => {
    switch (type) {
        case AIType.BanditManchot:
            return (await import(`@evidenceb/bandit-manchot/build/${id}.js`))
                .default;
        case AIType.AdaptiveTest:
            return (
                await import(`@evidenceb/adaptive-tests/build/catsim/${id}.js`)
            ).default;
        default:
            throw new Error(`Class import not configured for AI ${type}`);
    }
};

export const getParams = (
    config: Required<Config>["ai"],
    data: MinimalData,
    moduleId: string
) => {
    const mod = getModuleById(moduleId, data);
    return {
        ...config[AIType.BanditManchot].baseConfig,
        graph: {
            ...config[AIType.BanditManchot].baseConfig.graph,
            graph: config[AIType.BanditManchot].moduleConfig[mod.id],
            main_act: mod.id,
            exercises_ontology: getActivitiesInModule(mod, data),
            exercisesMetaData: data.exercises,
            initialTest: config[AIType.BanditManchot].initialTest![mod.id],
        },
    };
};

export const getGraph = (
    config: Required<Config>["ai"],
    data: MinimalData,
    moduleId: string
): any => {
    const params = getParams(config, data, moduleId);
    return makeGraphFromData(params, moduleId);
};
