import React from "react";
import { Link } from "react-router-dom";

// Reference for accessibility: https://www.aditus.io/patterns/breadcrumbs/

import "./Breadcrumbs.scss";

interface Props {
    items: Breadcrumb[];
}

export interface Breadcrumb {
    title: string;
    url?: string;
    current?: boolean;
}

const Breadcrumbs = ({ items }: Props) => {
    return (
        <nav aria-label="breadcrumbs" className="breadcrumbs">
            <ul>
                {items.map((item, index) => (
                    <li
                        key={"breadcrumb-item-" + item.title}
                        className={item.current ? "current" : undefined}
                    >
                        {item.url ? (
                            <Link
                                to={item.url}
                                aria-current={item.current ? "location" : false}
                                className="breadcrumbs__item"
                            >
                                {item.title}
                            </Link>
                        ) : (
                            <div>{item.title}</div>
                        )}
                        {index !== items.length - 1 && (
                            <span
                                className="breadcrumb__separator"
                                aria-hidden="true"
                            >
                                -
                            </span>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Breadcrumbs;
