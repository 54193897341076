import React, { useContext, useEffect, useRef, useState } from "react";

//Contexts
import { configStore } from "../../../../../../contexts/ConfigContext";

//Hooks
import useClassroom from "../../../useClassroom";
import useAthenaAPIClient from "../../../../../../hooks/useAthenaAPIClient";

//Components
import Loader from "../../../../../../components/Loader/Loader";
import Settings, { Setting } from "../Settings/Settings";

//SCSS
import "./ClassroomName.scss";

//Interfaces
import { FormattedMessage, useIntl } from "react-intl";
import Icon from "../../../../../../design-system-components/Icon/Icon";
interface ClassroomNameProps {
    id: string;
    className: string;
    name: string;
}

const ClassroomName = ({ id, className, name }: ClassroomNameProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { config } = useContext(configStore);
    const [validatedName, setValidatedName] = useState<string>(name);
    const [classroomName, setClassroomName] = useState<string>(name);
    const intl = useIntl();

    const settings: Setting[] = [];
    if (config.features.classManagement)
        settings.push({
            key: "rename-class",
            label: intl.formatMessage({
                id: "dashboard-renameclass",
                defaultMessage: "Rename class",
            }),
            action: () => setEditStatus("edit"),
        });

    const { updateDashboardData } = useClassroom();
    const athenaAPIClient = useAthenaAPIClient();

    const [editStatus, setEditStatus] = useState<"idle" | "edit" | "saving">(
        "idle"
    );

    const editClassroomName = async (id: string, name: string) => {
        // set state to "saving"
        setEditStatus("saving");

        // Construct body to send to the API
        const body = { name };

        try {
            //Update classroom data
            await athenaAPIClient.updateClassroom(id, body);
            try {
                await updateDashboardData();
                setValidatedName(classroomName);
                // Set state to idle
                setEditStatus("idle");
            } catch (error) {
                setEditStatus("idle");
            }
        } catch (error) {
            setEditStatus("idle");
            setClassroomName(validatedName);
        }
    };

    const cancelEdit = () => {
        setClassroomName(validatedName);
        setEditStatus("idle");
    };

    useEffect(() => {
        if (editStatus === "edit") {
            inputRef.current?.focus();
        }
    }, [editStatus, name]);

    return (
        <div className={`classroom-name ${className ?? ""}`}>
            {editStatus === "idle" && (
                <div className="classroom-name--idle">
                    <h1>
                        <span className="classroom-name__name">
                            <FormattedMessage
                                id="dashboard-common-class"
                                defaultMessage="Class"
                            />
                            {validatedName}
                        </span>
                    </h1>
                    {settings.length > 0 && (
                        <Settings
                            className="classroom-name__settings"
                            label={intl.formatMessage({
                                id: "dashboard-settings",
                                defaultMessage: "Settings",
                            })}
                            hideLabel={true}
                            icon={{ path: "more_vert", size: "large" }}
                            settings={settings}
                        />
                    )}
                </div>
            )}
            {editStatus === "edit" && (
                <div className="classroom-name--edit">
                    <input
                        ref={inputRef}
                        type="text"
                        value={classroomName}
                        onChange={(e) =>
                            setClassroomName(e.currentTarget.value)
                        }
                    />
                    <button
                        className="classroom-name__validate"
                        onClick={() => editClassroomName(id, classroomName)}
                    >
                        <Icon path="checkmark" />
                    </button>
                    <button
                        className="classroom-name__cancel"
                        onClick={cancelEdit}
                    >
                        <Icon path="close" size="medium" />
                    </button>
                </div>
            )}
            {editStatus === "saving" && (
                <div className="classroom-name--saving">
                    <FormattedMessage
                        id="dashboard-saving"
                        defaultMessage="Saving"
                    />
                    <Loader />
                </div>
            )}
        </div>
    );
};

export default ClassroomName;
