import React from 'react';
// External modules
import { defineMessages, useIntl } from 'react-intl';
// Contexts
// Components
import Icon from '../../../../../design-system-components/Icon/Icon';
import { TooltipWrapper } from '../../../../../design-system-components/ToolTip/ToolTip';
// Utils
// SCSS
import './Students.scss';
// Interfaces
import { User } from '../../../../../interfaces/User';
interface StudentsProps {
    students: Partial<User>[];
    onStudentDelete: (id: string) => void;
}
const Students = ({ students, onStudentDelete }: StudentsProps) => {
    const intl = useIntl();
    return (
        <div className="student-creator-list">
            {
                students.length > 0 &&
                <div className="student-creator-list__count">
                    {intl.formatMessage(messages.studentCount, { count: students.length })}
                </div>
            }
            <div className={`student-creator-list__list ${!students.length ? "student-creator-list__list--empty" : ""}`}>
                {
                    students.length ?
                        <>
                            {
                                students.map(student =>
                                    <div className="student-creator-list__item" key={student.external_id}>
                                        <div className="student-creator-list__item__name">
                                            {student.first_name} {student.last_name}
                                        </div>
                                        <div className="student-creator-list__item__actions">
                                            <TooltipWrapper
                                                wrapper='button'
                                                tooltip={intl.formatMessage(messages.remove)}
                                                onClick={() => onStudentDelete(student.external_id!)}
                                            >
                                                <Icon path="close" color="var(--black)" size="medium" />
                                            </TooltipWrapper>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                        : <>{intl.formatMessage(messages.noStudents)}</>
                }
            </div>
        </div>
    )
}

export default Students

const messages = defineMessages({
    noStudents: {
        id: "studentCreator-noStudents", defaultMessage: "No students has been added so far."
    },
    studentCount: {
        id: "studentCreator-studentCount", defaultMessage: "{count, number} {count, plural, one {student} other {students}}"
    },
    remove: {
        id: "studentCreator-remove", defaultMessage: "Remove"
    }
})