import { useEffect, useMemo, useRef } from "react";
import { PlayerEventEmitter } from "@evidenceb/gameplay-interfaces";
import { PlaylistManager } from "../../../interfaces/Player";

const usePlayerEventEmitter = (
    playlistManager: PlaylistManager
): PlayerEventEmitter => {
    const playerEventEmitter = useMemo(() => new PlayerEventEmitter(), []);

    const currentActivityId = useRef<string | undefined>(
        playlistManager.initialized
            ? playlistManager.playlist.activity?.id
            : undefined
    );
    useEffect(() => {
        if (!playlistManager.initialized) return;

        if (
            playlistManager.playlist.activity &&
            playlistManager.playlist.activity.id !== currentActivityId.current
        ) {
            currentActivityId.current = playlistManager.playlist.activity.id;
            playerEventEmitter.dispatch("NEXT_ACTIVITY", {
                id: playlistManager.playlist.activity.id,
            });
        }
    }, [playlistManager, playerEventEmitter]);

    return playerEventEmitter;
};

export default usePlayerEventEmitter;
