import * as Sentry from "@sentry/react";
import axios from "axios";
import qs from "qs";

//Evb Lib
import { VisibilityStatus } from "@evidenceb/gameplay-interfaces";

//Utils
import { clearLocalStorage, resetUser } from "./dev-tools";

//Interfaces
import { Application, ApplicationsConfig, AuthMode, MicroServices, SpecimenJSONConfig } from "../interfaces/Config";
import { TokenPayload, User, UserType } from "../interfaces/User";
import { RawData } from "../interfaces/Data";
import appConfig from "../config"

/**
 * Returns the set of endpoints that matches the first item in the msConfig file
 */
export const msConfigResolver = (
    config: MicroServices[],
    origin: string
): MicroServices => {
    const msConfig = config.find((endpointSets) => origin.includes(endpointSets.match));
    if(!msConfig)
        throw new Error() //TODO: Error message
    return msConfig
    
};

/**
 * Resolve which applications are available and which one should be shown in the
 * semi-autonomous authentication
 */
export const applicationResolver = (
    applications: ApplicationsConfig[],
    host: string,
    queryparams: string
): {
    currentApp: Application;
    config: ApplicationsConfig;
} => {
    const appsConfig = applications.find((app) => host.includes(app.match));
    if (!appsConfig) throw new Error("No match found in application.json");

    const queryParams = qs.parse(queryparams, { ignoreQueryPrefix: true });
    let currentApp: Application | undefined = undefined;
    if (queryParams.appId)
        currentApp = appsConfig.availableApps.find(
            (app) => app.id === queryParams.appId
        );
    if (!currentApp && appsConfig.choiceType === "LANGUAGE" && queryParams.lang)
        currentApp = appsConfig.availableApps.find(
            (app) => app.lang === queryParams.lang
        );
    if (!currentApp && appsConfig.choiceType === "LANGUAGE")
        currentApp = appsConfig.availableApps.find(
            (app) => app.lang && window.navigator.language.startsWith(app.lang)
        );
    if (!currentApp)
        currentApp = appsConfig.availableApps.find((app) => app.default);
    if (!currentApp) currentApp = appsConfig.availableApps[0];

    return {
        currentApp,
        config: appsConfig,
    };
};

export function tokenResolver(urlToken: string | undefined, localStorageToken: string | undefined): string{
   
    if(urlToken)
        return urlToken
    if(localStorageToken)
        return localStorageToken
    throw new Error() //TODO: Error message
}

export function versionResolver(decodedToken: TokenPayload,  urlVersion: string | undefined, localStorageVersion: string | undefined): string{
    if (decodedToken.app !== "*" && decodedToken.version !== "*" && decodedToken.branch !=="*")
        return `${decodedToken.app}/${decodedToken.version}/${decodedToken.branch}`;
    if(urlVersion)
        return urlVersion
    if(localStorageVersion)
        return localStorageVersion 
    throw new Error() //TODO: Error message
}

/**
 * Determine whether signIn should be displayed
 */
export async function shouldSignIn(
    user: User,
    authMode: AuthMode, 
    userType: UserType, 
    variation: string,
    getClassroom: any): Promise<boolean> {

    function isBlank(str: string) {
        return (!str || /^\s*$/.test(str));
    }

    async function hasClassroom(classrooms: string[], variation: string){
        return classrooms.some(async classroomId => {
            const classroom = await getClassroom(classroomId);
            if (classroom.provider === variation || classroom.variation === variation){
                return true
            }
        })
    }

    switch (authMode) {
        case AuthMode.Register:
            if (isBlank(user.first_name) || isBlank(user.last_name))
                return true
            if (userType === UserType.Student && !(await hasClassroom(user.classrooms, variation)))
                return true
            return false
        case AuthMode.RegisterNameOnly:
            if (isBlank(user.first_name) || isBlank(user.last_name))
                return true
            return false
        case AuthMode.DirectAccess:
            return false
    }

    //Default is false
    return false
}

/**
 * Remove Modules/Objectives/Activities/Exercises that are not visible (visibility set in Modules_config on github)
 */
export function removeNonVisible(data: RawData): RawData {

    function isVisible(object: { visibilityStatus: VisibilityStatus }): boolean {
        return object.visibilityStatus === VisibilityStatus.Visible || object.visibilityStatus === VisibilityStatus.Unavailable;
    }
    
    function existsIn(elements: { id: string }[]) {
        const ids = new Set(elements.map((element) => element.id));
        return (id: string): boolean => ids.has(id);
    }

    const exercises = data.exercises.filter(isVisible);
    const existsInExercises = existsIn(exercises);
    const activities = data.activities.filter(isVisible).map((activity) => ({
        ...activity,
        exerciseIds: activity.exerciseIds.filter(existsInExercises),
    }));
    const existsInActivities = existsIn(activities);
    const objectives = data.objectives.filter(isVisible).map((objective) => ({
        ...objective,
        activityIds: objective.activityIds.filter(existsInActivities),
    }));
    const existsInObjectives = existsIn(objectives);
    const modules = data.modules.filter(isVisible).map((module) => ({
        ...module,
        objectiveIds: module.objectiveIds.filter(existsInObjectives),
    }));
    return { modules, objectives, activities, exercises };
}

/**
 * Dev tools registration
 */
export function registerDebugUtils(data: RawData, athenaAPIClient: any) {
    (window as any).DEBUG_UTILS = {
        getModuleById: (id: string) =>
            data.modules.find((module) => module.id === id),
        getObjectiveById: (id: string) =>
            data.objectives.find((objective) => objective.id === id),
        getActivityById: (id: string) =>
            data.activities.find((activity) => activity.id === id),
        getExerciseById: (id: string) =>
            data.exercises.find((exercise) => exercise.id === id),
        resetUser: (url:string, userType: string | null) => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Reset user",
                level: Sentry.Severity.Info,
            });
            //TODO: better handling of url parameter
            resetUser(url, userType, athenaAPIClient)
        },
        deleteClassroom: (id: string) => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Class deleted",
                level: Sentry.Severity.Info,
            });
            //TODO: better handling of url parameter
            athenaAPIClient.deleteClassroom(id)
        },
        clearLocalStorage: () => {
            Sentry.addBreadcrumb({
                category: "window.console",
                message: "Clear local storage",
                level: Sentry.Severity.Info,
            });
            clearLocalStorage()
        },
        submitUserFeedback(){
            let error = new Error('User Feedback Submitted');
            Sentry.captureException(error);
            Sentry.showReportDialog();
        }
    };
}

export function isSpecimenVersion() {
    if (window.location.toString().match("demo."))
        return true
    // Maybe temporary condition
    if (window.location.toString().match("specimen"))
        return true
    return false
}

/**
 * Because a specimen must work without any token, we don't have any information
 * about the variation to use.
 * So, as msConfig.json works, we recover the variation in specimen.json file
 * (in public folder) according to a match with the current url.
 * @returns string
 */
export async function getSpecimenVariation() {

    const origin = window.location.origin
    const { data } = await axios.get<SpecimenJSONConfig[]>(origin + (appConfig.basePath ?? "/") + "json/specimen.json");

    const specimen = data.find((specimen) => window.location.href.includes(specimen.match));
    if (!specimen)
        throw Error("No variation found for this specimen.")
    return specimen.variation
}

/**
 * Function that flattens an object A then returns an object B where B contains only the keys in A that are of type boolean; and does it recursively.
 */
export const flattenBooleansInObject = (
    obj: { [key: string]: any }, 
    results?: {[key: string]: boolean}, 
    parentKey?: string
): {[key: string]: boolean} => {
    let currentResults = results ?? {}
    for (const [key, value] of Object.entries(obj)) {
        const currentKey = parentKey ? `${parentKey}/${key}` : key;
        if (typeof value === "boolean")
            currentResults[currentKey] = value;
        else if (!Array.isArray(value) && typeof value === "object")
            flattenBooleansInObject(value, currentResults, currentKey);
    }
    return currentResults
};