import React, { useContext, useEffect, useState } from "react";
import { Data } from "../interfaces/Data";
import { Config } from "../interfaces/Config";
import useAthenaAPIClient from "./useAthenaAPIClient";
import { VisibilityStatus } from "@evidenceb/gameplay-interfaces";
import { checkPRLockStatusIntegrity } from "../utils/prm";
import { UserType } from "../interfaces/User";
import * as Sentry from "@sentry/react";
import { sessionStore } from "../contexts/SessionContext";
import { dataStore } from "../contexts/DataContext";
import { configStore } from "../contexts/ConfigContext";
import { Loader, LoaderStatus } from "../interfaces/Status";
import { useFeatureFlag } from "@evidenceb/athena-common";
// Interfaces

const useLockedModules = (): Loader<string[]> => {
    const {
        session: { userId },
    } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const athenaAPIClient = useAthenaAPIClient();
    const isSpecimen = useFeatureFlag("isSpecimen");
    const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.Idle);
    const [err, setErr] = useState<unknown>();

    const [lockedModules, setLockedModules] = useState<string[]>([]);
    useEffect(() => {
        if (status !== LoaderStatus.Idle) return;
        setStatus(LoaderStatus.Loading);
        (async () => {
            try {
                const lockedModules = isSpecimen
                    ? data.modules
                          .filter(
                              (mod) =>
                                  mod.visibilityStatus ===
                                  VisibilityStatus.Unavailable
                          )
                          .map((mod) => mod.id)
                    : await getSoloResourcesLockStatus(
                          data,
                          config.ai,
                          userId,
                          athenaAPIClient.getResourcesLockStatus
                      );
                setLockedModules(lockedModules);
                setStatus(LoaderStatus.Success);
            } catch (err) {
                Sentry.captureException(err);
                setStatus(LoaderStatus.Error);
                setErr(err);
            }
        })();
    }, [athenaAPIClient, config.ai, data, isSpecimen, status, userId]);

    if (status === LoaderStatus.Loading || status === LoaderStatus.Idle) return { status };
    if (status === LoaderStatus.Error) return { status, err };
    return {
        status,
        payload: lockedModules,
    };
};

export default useLockedModules;

const getSoloResourcesLockStatus = async (
    data: Data,
    ai: Config["ai"],
    userId: string,
    getResourcesLockStatus: ReturnType<
        typeof useAthenaAPIClient
    >["getResourcesLockStatus"]
): Promise<string[]> => {
    const resourcesStatus = await getResourcesLockStatus(
        userId,
        UserType.Student
    );
    if (Object.keys(resourcesStatus).length > 0) {
        const classroomId = Object.keys(resourcesStatus)[0];
        const lockStatus = checkPRLockStatusIntegrity(
            resourcesStatus[classroomId],
            data,
            ai
        );
        return lockStatus.moduleIds;
    } else {
        return [];
    }
};
