import React, { useContext, useState, useRef, useEffect } from "react";
import cn from "classnames";
import {
    ActivityShell,
    HtmlString,
    NextActivityPlayerEvent,
} from "@evidenceb/gameplay-interfaces";
import { UserType } from "../../../../interfaces/User";
import { ActivityVideoTutorialResource } from "../../../../interfaces/Resources";
import { configStore } from "../../../../contexts/ConfigContext";
import { sessionStore } from "../../../../contexts/SessionContext";
import EmotionalReport from "../../PlaylistPlayer/EmotionalReport/EmotionalReport";
import StatementsError from "../../PlaylistPlayer/StatementsError/StatementsError";
import useEmotionalReport from "../../PlaylistPlayer/EmotionalReport/useEmotionalReport";
import Loader from "../../../../components/Loader/Loader";
import { useIntl } from "react-intl";
import {
    ExerciseResult,
    Shell,
    PlaylistExecutionStage,
} from "../../../../interfaces/Player";
import WizardFeedback from "./WizardFeedback/WizardFeedback";
import WizardInstruction from "./WizardInstruction/WizardInstruction";
import WizardMascotZone, {
    VideoTutorial,
} from "./WizardMascotZone/WizardMascotZone";

import "./WizardShell.scss";
import { logExerciseDetails } from "../../../../utils/dataRetrieval";
import { dataStore } from "../../../../contexts/DataContext";

import "rc-dialog/assets/index.css";
import "./WizardShell.scss";
import { GameplayMode, getAssetUrl } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../../hooks/useAssetsDetails";
import SpecimenBanner from "../../../../components/Specimen/SpecimenBanner/SpecimenBanner";

/**
 * The WizardShell instanciates the Gameplay component from the library and
 * handles interactions with it.
 */
const WizardShell = ({
    playlist,
    onGoToNextExercise,
    onExerciseResult,
    minitutor,
    playerEventEmitter,
    className,
}: Shell) => {
    const { config } = useContext(configStore);
    const {
        session: { flags },
    } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const { session } = useContext(sessionStore);
    const emotionalReportController = useEmotionalReport();
    const gameplayAssetsDetails = useAssetsDetails({ forGameplay: true });
    const assetsDetails = useAssetsDetails();
    const intl = useIntl();

    // Add activity tutorial video btn
    const [videoTutorial, setVideoTutorial] = useState<VideoTutorial>();
    useEffect(() => {
        if (!config.features.minitutor?.activityTutorialVideo) return;

        const cb = (payload: NextActivityPlayerEvent["payload"]) => {
            if (!payload?.id) return;
            const resource = config.resources?.find(
                (resource) =>
                    resource.type === "activity-video-tutorial" &&
                    (resource.activityId === payload.id ||
                        resource.activityIds?.includes(payload.id))
            ) as ActivityVideoTutorialResource | undefined;
            if (!resource) setVideoTutorial(undefined);
            else
                setVideoTutorial({
                    ...resource,
                    activityId: payload.id,
                });
        };
        playerEventEmitter.subscribe("NEXT_ACTIVITY", cb);
        return () => {
            playerEventEmitter.unsubscribe(cb);
        };
    }, [config.resources, playerEventEmitter, config.features.minitutor]);

    const [overrideInstruction, setOverrideInstruction] =
        useState<HtmlString | undefined>();
    useEffect(() => {
        setOverrideInstruction(undefined);
    }, [playlist.currentExercise, playlist.currentTry]);

    const exerciseZoneRef = useRef<HTMLDivElement>(null);

    if (!playlist || !playlist.currentExercise) return <Loader />;

    if (minitutor?.display?.type === "replace")
        return <>{minitutor.display.content}</>;

    return (
        <div
            className={cn("wizard-shell-container", className, {
                "--pannel-offset":
                    config.features.studentChatbotPlayerInfoPanel &&
                    session.userType === UserType.Student &&
                    !playlist.isInitialTest,
            })}
        >
            <div className="wizard__exercise-zone" ref={exerciseZoneRef}>
                <div className="notifications__container">
                    <StatementsError />
                </div>

                {(playlist.currentExercise.instruction ||
                    overrideInstruction) && (
                    <WizardInstruction
                        className="instruction"
                        instruction={
                            (overrideInstruction ??
                                playlist.currentExercise.instruction)!
                        }
                        feedbackPlaceholderAnimation={
                            !playlist.isInitialTest &&
                            isExerciseSolved(playlist.currentExerciseResult)
                        }
                        playerEventEmitter={playerEventEmitter}
                    />
                )}
                {session.specimen && <SpecimenBanner />}
                <div
                    className={cn("gp-wrapper", {
                        solved: isExerciseSolved(
                            playlist.currentExerciseResult
                        ),
                    })}
                    style={{
                        backgroundImage: config.features.playerBackground
                            ? `url(${getAssetUrl(
                                  config.features.playerBackground,
                                  assetsDetails
                              )})`
                            : "none",
                    }}
                    onClick={() => {
                        playerEventEmitter.dispatch("GAMEPLAY_INTERACTION");
                    }}
                >
                    <playlist.currentExercise.Gameplay
                        key={playlist.currentExercise.id + playlist.currentTry}
                        mode={
                            playlist?.currentExecutionStage ===
                            PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
                                ? GameplayMode.NonInteractive
                                : GameplayMode.Interactive
                        }
                        data={playlist.currentExercise.data}
                        nonInteractiveOptions={
                            playlist.comingNext === "retry"
                                ? {
                                      ...playlist.currentExercise
                                          .executionOptions,
                                      showCorrectAnswer: false,
                                  }
                                : playlist.currentExercise.executionOptions
                        }
                        showCorrectAnswer={
                            !isExerciseSolved(playlist.currentExerciseResult) ||
                            playlist.comingNext === "retry"
                                ? false
                                : playlist.currentExercise.showCorrectAnswer!
                        }
                        exerciseResult={playlist.currentExerciseResult}
                        onExerciseResult={(result) => {
                            if (flags.logExerciseDetails)
                                logExerciseDetails(
                                    result,
                                    playlist,
                                    data,
                                    config.ai.BANDIT_MANCHOT.initialTest!
                                );
                            if (
                                config.features.emotionalReport &&
                                session.userType === UserType.Student &&
                                playlist.comingNext !== "retry" &&
                                !playlist.isInitialTest
                            )
                                emotionalReportController.recordValidatedExercise();
                            onExerciseResult(result, playlist.isInitialTest);
                        }}
                        onTrackingEvent={(statement) => {
                            // TODO: Handle tracking when AI should be implemented
                            console.log("Tracking:", statement);
                        }}
                        assetsDetails={gameplayAssetsDetails}
                        onRequirePlayerAction={(action) => {
                            switch (action.type) {
                                case "SET_INSTRUCTION":
                                    setOverrideInstruction(action.payload);
                                    break;
                            }
                        }}
                        shell={ActivityShell.Wizard}
                        /* @ts-ignore TMP remove when react upgrade is completed */
                        intl={intl}
                    />

                    <WizardMascotZone
                        className="mascot-zone"
                        videoTutorial={videoTutorial}
                    />
                </div>

                {config.features.emotionalReport &&
                    session.userType === UserType.Student &&
                    !playlist.isInitialTest && (
                        <div
                            className={`emotional-report__container--wizard ${
                                isExerciseSolved(playlist.currentExerciseResult)
                                    ? "emotional-report__container--feedback"
                                    : ""
                            }`}
                        >
                            <EmotionalReport
                                {...emotionalReportController}
                                hideSpeechBubbles={
                                    !playlist.isInitialTest &&
                                    isExerciseSolved(
                                        playlist.currentExerciseResult
                                    ) &&
                                    playlist.currentExercise.executionOptions
                                        ?.showFeedback
                                }
                            />
                        </div>
                    )}
            </div>

            {!playlist.isInitialTest &&
                isExerciseSolved(playlist.currentExerciseResult) && (
                    <WizardFeedback
                        playlist={playlist}
                        onNextExercise={() => {
                            onGoToNextExercise(playlist.comingNext);
                        }}
                        getMaxToggleHeight={() =>
                            exerciseZoneRef.current?.clientHeight
                        }
                    />
                )}
        </div>
    );
};

export const isExerciseSolved = (
    exerciseResult: ExerciseResult<any> | undefined
): boolean => typeof exerciseResult !== "undefined";

export default WizardShell;
