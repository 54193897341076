import React, { useContext, useMemo } from "react";
import {
    defineMessages,
    useIntl,
} from "react-intl";
import ControlledDialog from "../../../../design-system-components/ControlledDialog/ControlledDialog";
import { dataStore } from "../../../../contexts/DataContext";
import htmlHandler from "../../../../utils/html-handler";
import { commonMessages } from "../../../../utils/messages";
import Icon from "../../../../design-system-components/Icon/Icon";
import {
    getObjectiveById,
    objectiveNumberSort,
} from "../../../../utils/dataRetrieval";
import { ConfirmationModalProps } from "../usePRConfirmation";

import "./ConfirmationModal.scss";

const LockActivityAndDescendantObjectivesConfirmationModal = ({
    confirm,
    confirmation,
    levelData,
}: ConfirmationModalProps) => {
    const intl = useIntl();
    const { data } = useContext(dataStore);

    const activity = useMemo(() => {
        const activity = levelData.prPool.find(
            (activity) =>
                confirmation.itemsToToggle.some(
                    (item) => item.id === activity.id
                ) &&
                levelData.prPool.some(
                    (item) =>
                        item.id === activity.id &&
                        item.isPrerequisiteOf?.some((objId) =>
                            confirmation.itemsToToggle.some(
                                (item) => item.id === objId
                            )
                        )
                )
        );
        if (!activity) throw new Error("No prerequisite activity");
        return activity;
    }, [levelData.prPool, confirmation.itemsToToggle]);

    const listOfObjectives = useMemo(
        () =>
            confirmation.dependenciesList
                ?.map((objId) => getObjectiveById(objId, data))
                .sort(...objectiveNumberSort(data)),
        [confirmation, data]
    );

    return (
        <ControlledDialog
            variant="alert"
            title={{
                as: "h2",
                className: "lock-activity-title",
                content: (
                    <>
                        <Icon path="prerequisite-icon" size={30} />
                        {intl.formatMessage(messages.title, {
                            title: activity.title.long.toLowerCase(),
                        })}
                    </>
                )
            }}
            aria-label={intl.formatMessage(messages.title, {
                title: activity.title.long.toLowerCase(),
            })}
            description={{
                $html: intl.formatMessage(
                    typeof confirmation.dependenciesList?.length === "number" &&
                        confirmation.dependenciesList.length > 1
                        ? messages.lockDescendantsDescription
                        : messages.lockDescendantDescription,
                    {
                        ...htmlHandler,
                        title: activity.title.long.toLowerCase(),
                    }
                ),
            }}
            primaryButton={{
                label: intl.formatMessage(messages.lock),
                fn: () => {
                    confirm(true);
                },
            }}
            secondaryButton={{
                label: intl.formatMessage(messages.cancel),
                fn: () => {
                    confirm(false);
                },
            }}
        >
            {listOfObjectives && (
                <details className="confirmation-modal__dependency-list">
                    <summary>
                        <Icon
                            path="chevron_right"
                            className="dependency-list__icon"
                        />
                        {intl.formatMessage(
                            listOfObjectives.length > 1
                                ? messages.seeListOfObjs
                                : messages.seeObj
                        )}
                    </summary>
                    <figure>
                        <figcaption>
                            {intl.formatMessage(commonMessages.objectives)}
                        </figcaption>
                        <ol>
                            {listOfObjectives.map((obj) => (
                                <li key={"lock-" + obj.id}>
                                    {obj.title.short}
                                </li>
                            ))}
                        </ol>
                    </figure>
                </details>
            )}
        </ControlledDialog>
    );
};

const messages = defineMessages({
    title: {
        id: "prm-lockActivityAndDependencies",
        defaultMessage: "Deactivate {title} and its dependencies",
    },
    lockDescendantsDescription: {
        id: "html-prm-lockActivityAndDescendants-description",
        defaultMessage:
            "{title} is the prerequisite of active objectives. To ensure consistency, <b>deactivating it will automatically deactivate the objectives for which it is a prerequisite.</b>",
    },
    lockDescendantDescription: {
        id: "html-prm-lockActivityAndDescendant-description",
        defaultMessage:
            "{title} is the prerequisite of an active objective. To ensure consistency, <b>deactivating it will automatically deactivate the objective for which it is a prerequisite.</b>",
    },
    lock: {
        id: "prm-manager-listItem-lock",
        defaultMessage: "Deactivate",
    },
    cancel: {
        id: "prm-manager-cancel",
        defaultMessage: "Cancel",
    },
    seeListOfObjs: {
        id: "prm-seeObjectivesToBeLocked",
        defaultMessage: "See the list of objectives that will be locked",
    },
    seeObj: {
        id: "prm-seeObjectiveToBeLocked",
        defaultMessage: "See the objective that will be locked",
    }
});

export default LockActivityAndDescendantObjectivesConfirmationModal;
