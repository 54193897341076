import { HtmlString } from '@evidenceb/gameplay-interfaces';
import React, {useContext, MouseEvent} from 'react';
import { parse } from "@evidenceb/athena-common";
import {configStore} from "../../contexts/ConfigContext";
import './DemoBanner.scss';
import Icon from '../../design-system-components/Icon/Icon';
import useAssetsDetails from '../../hooks/useAssetsDetails';
import { Image } from '@evidenceb/athena-common';

interface DemoBannerProps {
    message: HtmlString | undefined;
    position: 'top' | 'right' | 'custom'
}

function DemoBanner( { message, position }: DemoBannerProps) {
    const {config} = useContext(configStore)
    const assetsDetails = useAssetsDetails();
    const toggle = (e: MouseEvent<HTMLDivElement>) => {
        e.currentTarget?.classList.toggle('opened')
    }
    return (
        <div className={`demo-banner opened ${position}`} onClick={toggle}>
            <button>
                <Icon path="chevron_left" size="medium" className="open" />
                <Icon path="chevron_right" size="medium" className="close" />
            </button>
            {config.logos.demo && <Image assetsDetails={assetsDetails} className="logo" src={config.logos.demo} />}

            <p>{parse(message!.$html)}</p>
        </div>
    )
}

export default DemoBanner
