import { Exercise, GameplayMode, isHtmlStringInstruction } from "@evidenceb/athena-common";
import {
    ActivityShell,
    PartialExerciseResult,
} from "@evidenceb/gameplay-interfaces";
import parse from "html-react-parser";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { configStore } from "../../../../contexts/ConfigContext";
import { dataStore } from "../../../../contexts/DataContext";
import { sessionStore } from "../../../../contexts/SessionContext";
import useAssetsDetails from "../../../../hooks/useAssetsDetails";
import { Playlist } from "../../../../interfaces/Player";
import { logExerciseDetails } from "../../../../utils/dataRetrieval";
import ChatBubble from "./ChatBubble/ChatBubble";

type ExerciseProps = {
    currentExercise: Exercise;
    onExerciseResult: (exerciseResult: PartialExerciseResult<any>) => void;
    playlist: Playlist;
    shell?: ActivityShell;
};

const CurrentExercise: React.FC<ExerciseProps> = ({
    currentExercise,
    onExerciseResult,
    playlist,
    shell,
}): JSX.Element => {
    const intl = useIntl();
    const {
        session: { flags },
    } = useContext(sessionStore);
    const {
        config: { ai },
    } = useContext(configStore);
    const { data } = useContext(dataStore);
    const assetsDetails = useAssetsDetails({forGameplay: true});
    
    return (
        <div className="current-exercise">
            <div className="current-exercise-chat-bubble">
                <ChatBubble
                    direction="left"
                    fullWidth={true}
                    color="var(--chatbot-color-bot-bubble)"
                    botAvatar="visible"
                >
                    {currentExercise.instruction && (
                        <p className="current-instruction">
                            {isHtmlStringInstruction(currentExercise.instruction) && parse(currentExercise.instruction.$html)}
                            {/* TODO: HtmlString audio and Content[][] type instructions are not yet implemented for the Chatbot */}
                        </p>
                    )}

                    <ChatBubble
                        direction="right"
                        fullWidth={true}
                        marginBottom={false}
                        color=""
                    >
                        {currentExercise.messages?.length ? (
                            <div className="additional-instructions">
                                {currentExercise.messages?.map((message) => (
                                    <p
                                        className="additional-instruction"
                                        key={message.$html}
                                    >
                                        {parse(message.$html)}
                                    </p>
                                ))}
                            </div>
                        ) : null}
                        <currentExercise.Gameplay
                            mode={GameplayMode.Interactive}
                            data={currentExercise.data}
                            onExerciseResult={(exerciseResult) => {
                                onExerciseResult(exerciseResult);
                                if (flags.logExerciseDetails)
                                    logExerciseDetails(
                                        exerciseResult,
                                        playlist,
                                        data,
                                        ai.BANDIT_MANCHOT.initialTest!
                                    );
                            }}
                            shell={shell}
                            onTrackingEvent={() => null}
                            // TODO:
                            // - make optional
                            // - don't use
                            showCorrectAnswer={false}
                            /* @ts-ignore */
                            intl={intl}
                            assetsDetails={assetsDetails}
                        />
                    </ChatBubble>
                </ChatBubble>
            </div>
        </div>
    );
};

export { CurrentExercise };
