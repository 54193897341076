import React, { useEffect, useState, useContext, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import * as Sentry from "@sentry/react";
import {
    ClassroomAnalytics,
    ModuleDashboard,
    ParamTypes,
} from "../../../interfaces/Dashboard";
import { UserType } from "../../../interfaces/User";
import { PRLockStatus } from "../../../interfaces/PedagogicalResourcesManagement";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { errorStore } from "../../../contexts/ErrorContext";
import { dataStore } from "../../../contexts/DataContext";
import { checkPRLockStatusIntegrity } from "../../../utils/prm";
import {
    getClassroomListUrl,
    getSingleClassroomUrl,
} from "../../../utils/navigation-dashboard";
import useAthenaAPIClient from "../../../hooks/useAthenaAPIClient";
import Breadcrumbs, {
    Breadcrumb,
} from "../../../components/Breadcrumbs/Breadcrumbs";
import ProgressionTable from "./ProgressionTable/ProgressionTable";
import ClassroomsNav from "./ClassroomsNav/ClassroomsNav";
import LockedResourceWarningBanner from "./LockedResourceWarningBanner/LockedResourceWarningBanner";
import dashboardMessages from "../dashboardMessages";
import SpecimenHelper from "../SpecimenHelper/SpecimenHelper";

interface Props {
    classrooms: ClassroomAnalytics[];
}

const ProgressionOverview = ({ classrooms }: Props) => {
    const { config } = useContext(configStore);
    const {
        session: { userId, specimen },
    } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const { setErrorInfo } = useContext(errorStore);
    const history = useHistory();
    const { moduleId, classroomId } = useParams<ParamTypes>();
    const athenaAPIClient = useAthenaAPIClient();
    const intl = useIntl();

    const classroom = useMemo(() => {
        const classroom = classrooms.find(
            (classroom) => classroom.id === classroomId
        );
        return classroom;
    }, [classrooms, classroomId]);
    useEffect(() => {
        if (!classroom) {
            setErrorInfo({
                displayModal: true,
                modal: {
                    type: "NOTIFICATION",
                    content: {
                        title: intl.formatMessage(
                            dashboardMessages.classroomNotFound
                        ),
                    },
                },
            });
            history.push(getClassroomListUrl(config.pages));
        }
    }, [classroom, history, setErrorInfo, config.pages, intl]);

    const module = useMemo<ModuleDashboard | undefined>(
        () => classroom?.modulesList.find((module) => module.id === moduleId),
        [moduleId, classroom]
    );

    const breadcrumbs = useMemo<Breadcrumb[] | undefined>(() => {
        if (!classroom) return;
        return [
            {
                title: intl.formatMessage({
                    id: "dashboard-common-classes",
                    defaultMessage: "Classes",
                }),
                url: getClassroomListUrl(config.pages),
            },
            {
                title: classroom.name,
                url: getSingleClassroomUrl(config.pages, classroomId, intl),
            },
            {
                title: intl.formatMessage({
                    id: "dashboard-common-progression",
                    defaultMessage: "Progression",
                }),
                current: true,
            },
        ];
    }, [classroom, intl, config.pages, classroomId]);

    const [prLockStatus, setPRLockStatus] = useState<PRLockStatus | "error">();
    useEffect(() => {
        if (prLockStatus || !classroom) return;

        (async () => {
            try {
                const allClassesLockStatus =
                    await athenaAPIClient.getResourcesLockStatus(
                        userId,
                        UserType.Teacher
                    );
                setPRLockStatus(
                    checkPRLockStatusIntegrity(
                        allClassesLockStatus[classroom.id],
                        data,
                        config.ai
                    )
                );
            } catch (e) {
                Sentry.captureException(e);
                setErrorInfo({
                    displayModal: true,
                    modal: {
                        type: "NOTIFICATION",
                        content: {
                            title: intl.formatMessage({
                                id: "prm-lockStatusErrorText",
                                defaultMessage:
                                    "An error ocurred while retrieving the resources' lock status. Try again later.",
                            }),
                        },
                    },
                });
                setPRLockStatus("error");
            }
        })();
    }, [
        athenaAPIClient,
        userId,
        prLockStatus,
        classroom,
        setErrorInfo,
        config.ai,
        data,
        intl,
    ]);

    if (!classroom) return <></>;

    return (
        <>
            <div className="classrooms-modules-overview">
                {prLockStatus && prLockStatus !== "error" && (
                    <LockedResourceWarningBanner
                        moduleId={moduleId}
                        classroomId={classroomId}
                        prLockStatus={prLockStatus}
                    />
                )}

                <header>
                    {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
                    <h1>
                        <FormattedMessage
                            id="dashboard-common-progression"
                            defaultMessage="Progression"
                        />
                    </h1>
                    <ClassroomsNav
                        classrooms={classrooms}
                        classroom={classroom}
                        prLockStatus={
                            prLockStatus === "error" ? undefined : prLockStatus
                        }
                    />
                </header>

                {module ? (
                    <ProgressionTable
                        students={module.students}
                        objectives={module.objectives}
                        prLockStatus={
                            prLockStatus === "error" ? undefined : prLockStatus
                        }
                        moduleId={moduleId}
                    />
                ) : (
                    <p>{intl.formatMessage(messages.noProgressionForModule)}</p>
                )}
            </div>
            {specimen &&
                <SpecimenHelper
                    dialogTitle={intl.formatMessage(messages.specimenProgressionOverviewHelperTitle)}
                    dialogDescription={intl.formatMessage(messages.specimenProgressionOverviewHelperDescription)}

                />
            }
        </>
    );
};

const messages = defineMessages({
    noProgressionForModule: {
        id: "dashboard-noProgression",
        defaultMessages: "There is no progression for this module",
    },
    specimenProgressionOverviewHelperTitle: {
        id: "specimen-progression-overview-helper-title", defaultMessage: "Class Progression",
    },
    specimenProgressionOverviewHelperDescription: {
        id: "specimen-progression-overview-helper-description", defaultMessage: `Here is a global view of the progress of the class, with details by student. The validated objectives are in dark blue, those in progress in light blue.\nBy clicking on a box, you will see the details of the student's progress in the objective.\nBy selecting a student, you will be able to consult his entire history.`,
    },

});

export default ProgressionOverview;
