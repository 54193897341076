import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import Case from "case";
import { parse, useFeatureFlag } from "@evidenceb/athena-common";
import { dataStore } from "../../contexts/DataContext";
import { Title } from "../../interfaces/Config";
import { getObjectiveById } from "../../utils/dataRetrieval";
import ModuleListItem from "./ModuleListItem/ModuleListItem";

import "./ModuleList.scss";

function ModuleList({ title }: Title) {
    const { data } = useContext(dataStore);
    const intl = useIntl();
    const teacherModuleListShowFullTitle = useFeatureFlag(
        "teacherModuleListShowFullTitle"
    );

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <div className="modules-list">
                <h1 className="staatliches">
                    {intl.formatMessage(messages.title)}
                </h1>
                {intl.formatMessage(messages.info) !== "" && (
                    <div className="info">
                        <p>{parse(intl.formatMessage(messages.info))}</p>
                    </div>
                )}

                {data.modules.map((module, i) => (
                    <ModuleListItem
                        key={module.id}
                        id={module.id}
                        title={
                            teacherModuleListShowFullTitle
                                ? module.title.short!
                                : `${Case.pascal(
                                      intl.formatMessage({
                                          id: "hierarchy-module-full",
                                          defaultMessage: "module",
                                      })
                                  )} ${i + 1}`
                        }
                        description={module.description}
                        objectives={module.objectiveIds.map((objectiveId) =>
                            getObjectiveById(objectiveId, data, false)
                        )}
                        visibilityStatus={module.visibilityStatus}
                    />
                ))}
            </div>
        </>
    );
}

const messages = defineMessages({
    title: {
        id: "moduleList-title",
        defaultMessage: "Modules list",
    },
    info: {
        id: "moduleList-info",
        defaultMessage:
            "In the module section, you can consult all the exercises available in the module. But in their workspace, students follow a personalized path and only access exercises provided by the AI.",
    },
});

export default ModuleList;
