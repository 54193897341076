import React, { Fragment } from "react";
import { defineMessages, useIntl } from "react-intl";
import { commonMessages } from "../../../../../utils/messages";
import Icon from "../../../../../design-system-components/Icon/Icon";
import VisuallyHidden from "../../../../../components/VisuallyHidden/VisuallyHidden";
import {
    MIN_PASSWORD_LENGTH,
    PwdCriteriaCompliance,
} from "../PasswordChangeForm";

import "./PasswordCriteria.scss";

interface Props {
    pwdCriteriaCompliance: PwdCriteriaCompliance;
    showPwdCriteria: boolean;
}

/**
 * Widget that displays the critria for the password and updates as the user
 * types. Accessibility relies on:
 * - using aria-live="polite" (or role="alert" after form submit) so that screen
 *   readers are notified of the changes in password compliance
 * - the criteria always being in the DOM, with a hidden text that tells the
 *   user whether the listed criteria are met or not not to rely on color or the
 *   icon
 */
const PasswordCriteria = ({
    pwdCriteriaCompliance: { criteria, level },
    showPwdCriteria,
}: Props) => {
    const intl = useIntl();

    const PwdCriteriaContainer =
        (level === "alert" &&
            criteria.some((criterion) => !criterion.compliant)) ||
        showPwdCriteria
            ? Fragment
            : VisuallyHidden;

    return (
        <PwdCriteriaContainer>
            <div
                className="pwd-criteria"
                role={level === "alert" ? "alert" : undefined}
                aria-live={level === "polite" ? "polite" : undefined}
            >
                <p>
                    {level === "alert" &&
                        intl.formatMessage(commonMessages.error) +
                            intl.formatMessage(commonMessages.colon)}
                    {intl.formatMessage(messages.pwdMustContain)}
                </p>
                <ul>
                    {criteria.map((criterion) => (
                        <Criterion
                            key={criterion.name}
                            compliant={criterion.compliant}
                            label={intl.formatMessage(
                                messages[criterion.name],
                                { minChar: MIN_PASSWORD_LENGTH }
                            )}
                        />
                    ))}
                </ul>
            </div>
        </PwdCriteriaContainer>
    );
};

const Criterion = ({
    label,
    compliant,
}: {
    label: string;
    compliant: boolean;
}) => {
    const intl = useIntl();

    return (
        <li
            className={`pwd-criteria__criterion pwd-criteria__criterion--${
                compliant ? "compliant" : "not-compliant"
            }`}
        >
            <Icon
                className="pwd-criteria__criterion-icon"
                path={compliant ? "valid" : "invalid"}
            />
            {label}{" "}
            <VisuallyHidden as="span">
                {compliant
                    ? intl.formatMessage(messages.criterionMet)
                    : intl.formatMessage(messages.criterionNotMet)}
            </VisuallyHidden>
        </li>
    );
};

const messages = defineMessages({
    criterionMet: {
        id: "authentication-pwd-criterionMet-a11y",
        defaultMessage: "(this criterion is met)",
    },
    criterionNotMet: {
        id: "authentication-pwd-criterionNotMet-a11y",
        defaultMessage: "(this criterion is not met)",
    },
    pwdMustContain: {
        id: "authentication-pwd-pwdMustContain",
        defaultMessage: "Password must contain:",
    },
    minLength: {
        id: "authentication-pwd-pwdLengthCriterion",
        defaultMessage: "at least {minChar} characters",
    },
    oneNumber: {
        id: "authentication-pwd-pwdOneNumberCriterion",
        defaultMessage: "at least one number",
    },
    oneSymbol: {
        id: "authentication-pwd-pwdOneSymbolCriterion",
        defaultMessage: "at least one symbol",
    },
    oneUppercase: {
        id: "authentication-pwd-pwdOneUppercaseCriterion",
        defaultMessage: "at least one uppercase letter",
    }
});

export default PasswordCriteria;
