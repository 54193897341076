import { AIType } from "@evidenceb/ai-handler";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
    LayerModal,
    LayerModalMain,
} from "../../../design-system-components/LayerModal/LayerModal";
import { Student } from "../../../interfaces/Dashboard";
import DetailsChips from "./ModalHeader/DetailsChips/DetailsChips";
import ModalHeader from "./ModalHeader/ModalHeader";
import TestProgressionResults from "./TestProgressionResults/TestProgressionResults";

import "./StudentProgression.scss";
import { OverlayTriggerState } from "react-stately";

interface Props {
    student: Student;
    state: OverlayTriggerState
    onDismiss: () => void;
    moduleTitle: string;
}

const StudentTestProgression = ({ student, onDismiss, moduleTitle, state }: Props) => {
    const intl = useIntl();

    const testResult = student.testsResults?.find(
        (test) => test.type === AIType.BanditManchot
    )!;

    return (
        <LayerModal
            state={state}
            onClose={onDismiss}
            aria-label={intl.formatMessage(
                messages.testResultModalTitle,
                { name: `${student.firstname} ${student.lastname}` }
            )}
            className="student-progression"
            preventScroll
        >
            <ModalHeader
                student={student}
                chips={
                    <DetailsChips
                        nbExercises={testResult.exercises.length ?? 0}
                        timeSpent={{
                            time: testResult.timeSpent,
                            label: intl.formatMessage(
                                messages.diagTestTimeSpent
                            ),
                        }}
                        nbCorrectExercises={testResult.exercises.filter(ex => ex.isCorrect).length}
                    />
                }
                onDismiss={onDismiss}
                description={intl.formatMessage(messages.diagTestDescr, {
                    module: moduleTitle,
                })}
            />

            <LayerModalMain
                title={intl.formatMessage(messages.diagTestResult)}
                className="student-progression__modal-main-layer"
            >
                <TestProgressionResults testResult={testResult} className="student-test-progression__results" />
            </LayerModalMain>
        </LayerModal>
    );
};

const messages = defineMessages({
    testResultModalTitle: {
        id: "a11y-studentTestResultModal-title",
        defaultMessage: "{name}'s test result",
    },
    diagTestDescr: {
        id: "dashboard-diagTestDescription",
        defaultMessage: "Diagnosis test for module {module}",
    },
    diagTestTimeSpent: {
        id: "dashboard-diagTestTimeSpent",
        defaultMessage: "Time spent on the test",
    },
    diagTestResult: {
        id: "dashboard-diagTestResult",
        defaultMessage: "Result of the diagnosis test",
    },
});

export default StudentTestProgression;
