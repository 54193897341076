import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useIdleTimer, IIdleTimer } from "react-idle-timer";
import * as Sentry from "@sentry/react";
import {
    HierarchyIds,
    PartialExerciseResult,
    ActivityShell,
    PlaylistProperties,
} from "@evidenceb/gameplay-interfaces";
import { AIType, AIWhisperer, SuccessfullAI } from "@evidenceb/ai-handler";
import { dataStore } from "../../contexts/DataContext";
import { errorStore } from "../../contexts/ErrorContext";
import { PlayerProgressionError } from "../../errors";
import useStatements from "../../hooks/useSyncStatements";
import {
    getHierarchyFromHierarchyId,
    getModuleById,
} from "../../utils/dataRetrieval";
import { PlaylistPlayerProps } from "./PlaylistPlayer/PlaylistPlayer";
import useAI from "../../hooks/useAI";

import { sessionStore } from "../../contexts/SessionContext";
import { useIntl } from "react-intl";
import {
    AIPlaylistManagerOpts,
    ComingNext,
    ExerciseResult,
    InitializedPlaylistManager,
    PlaylistExecutionStage,
    PlaylistManager,
} from "../../interfaces/Player";
import {
    makeAdaptiveTestDiagnosisStatement,
    makeHistoryStatement,
} from "../../utils/statement-builder";
import { configStore } from "../../contexts/ConfigContext";
import { commonMessages } from "../../utils/messages";
import { Hierarchy } from "../../interfaces/Hierarchy";
import { Exercise, Feedback } from "@evidenceb/athena-common";

/*
Here's a little illustration to help with understanding the idle timer

                totalTimeElapsed
 <-------------------------------------------------->
    totalActiveTime             totalIdleTime
 <-------------------> <---------------------------->
                          timeout       modal time
                       <-----------> <-------------->
|                     |             |                |
ex start         last active   modal shown          now
*/
const TIMEOUT = 5 * 60 * 1000;
const MAX_MODAL_DURATION_BEFORE_RESET = 30 * 1000;

/**
 * This is the AI playlist manager. It uses the AI to determine which are the
 * exercises that are played. An AI user should:
 * - not be able to navigate freely between exercises
 * - be assigned their next exercise depending on their history by the AI
 */
const useAIPlaylistManager = (
    moduleId: string,
    aiType: AIType
): PlaylistManager => {
    const { data } = useContext(dataStore);
    const { setErrorInfo } = useContext(errorStore);
    const {
        session: { adaptiveTestNumber, sessionId, evidencebId },
    } = useContext(sessionStore);
    const intl = useIntl();
    const {
        config: { declinaison, ai: aiConfig },
    } = useContext(configStore);

    const opts = useMemo(() => getPlaylistManagerOpts(aiType), [aiType]);

    const navigationHistory = useHistory();
    const ai = useAI(aiType, moduleId);
    const { sendStatement } = useStatements();
    const timer = useIdleTimer({
        timeout: TIMEOUT,
        startOnMount: true,
        onIdle: () => {
            setShowIdleModal(true);
        },
    });

    // List of exercise hierarchy given by the ai for the current session
    const [hierarchyList, setHierarchyList] =
        useState<(Hierarchy & Partial<PlaylistProperties>)[]>();
    // Index of the current exercise in the hierarchy list
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number>(0);
    // List of exercise results for the current session
    // TODO: Refactor exerciseResult and history
    const [exerciseResults, setExerciseResults] = useState<
        ExerciseResult<any>[]
    >([]);
    const [currentExerciseResult, setCurrentExerciseResult] = useState<
        ExerciseResult<any> | undefined
    >(undefined);
    const [currentTry, setCurrentTry] = useState<number>(1);
    // Nature of the current step
    const [currentExecutionStage, setCurrentExecutionStage] =
        useState<PlaylistExecutionStage>(
            PlaylistExecutionStage.PlayingCurrentExercise
        );
    // Nature of the next step after the current exercise
    const [comingNext, setComingNext] = useState<ComingNext | undefined>(
        undefined
    );
    const [forceGoToNext, setForceGoToNext] = useState<boolean>(false);
    const [showIdleModal, setShowIdleModal] = useState<boolean>(false);
    const [progression, setProgression] = useState<number>();
    const [error, setError] = useState<boolean>(false);

    // Initialize playlist
    useEffect(() => {
        if (error || ai.status !== "success") return;

        try {
            const nextHierarchyIds = AIWhisperer.getNextHierarchyId(
                (ai.aiLoadingInfo as SuccessfullAI).instance
            );
            if (nextHierarchyIds.isModuleFinished) setHierarchyList([]);
            else
                setHierarchyList([
                    {
                        ...getHierarchyFromHierarchyId(nextHierarchyIds, data),
                        isInitialTest: nextHierarchyIds.isInitialTest,
                    },
                ]);
        } catch (err) {
            Sentry.captureException(err);
            setErrorInfo({
                displayModal: true,
                modal: {
                    type: "POPUP",
                    content: {
                        title: intl.formatMessage({
                            id: "aiError",
                            defaultMessage: "Our AI encountered an error",
                        }),
                        text: intl.formatMessage({
                            id: "aiErrorDescription",
                            defaultMessage:
                                "We cannot communicate with our AI. Try again later.",
                        }),
                        btn: {
                            label: intl.formatMessage(commonMessages.goHome),
                        },
                    },
                    onClick: () => {
                        navigationHistory.push("/");
                    },
                },
            });
            setError(true);
        }
    }, [data, ai, navigationHistory, setErrorInfo, error, opts, intl]);

    // Reinit current try when current exercise changes
    useEffect(() => {
        setCurrentTry(1);
    }, [currentExerciseIndex]);

    const goToNextExercise = useCallback(() => {
        if (!comingNext)
            throw new PlayerProgressionError(
                "goToNextExercise called before validating exercise"
            );

        if (comingNext === "retry") setCurrentTry((curr) => curr + 1);
        else setCurrentExerciseIndex((curr) => curr + 1);

        setComingNext(undefined);
        setCurrentExerciseResult(undefined);
        setCurrentExecutionStage(PlaylistExecutionStage.PlayingCurrentExercise);

        timer.reset();
        timer.start();
    }, [comingNext, timer]);

    // Force go to next when flag is set
    useEffect(() => {
        if (!forceGoToNext) return;
        goToNextExercise();
        setForceGoToNext(false);
    }, [forceGoToNext, goToNextExercise]);

    // Update the progression when the bandit manchot is updated with a new history
    useEffect(() => {
        if (ai.status !== "success" || !opts?.progression) return;
        const moduleBM = ai.aiLoadingInfo;
        if (moduleBM.error) return;
        const diagnostic = AIWhisperer.getStudentBasicDiagnostic(
            moduleBM.instance
        );
        setProgression(diagnostic.progression);
    }, [ai, moduleId, opts]);

    if (error || ai.status === "error")
        return {
            initialized: false,
            error: error ?? ai.status,
        };

    if (!hierarchyList || ai.status === "loading")
        return { initialized: false };

    return {
        initialized: true,

        playlist: {
            module: getModuleById(moduleId, data),
            objective: hierarchyList[currentExerciseIndex]
                ? hierarchyList[currentExerciseIndex].objective
                : undefined,
            activity: hierarchyList[currentExerciseIndex]
                ? hierarchyList[currentExerciseIndex].activity
                : undefined,
            exercises: hierarchyList.map((hierarchy) => hierarchy.exercise),
            currentExercise: hierarchyList[currentExerciseIndex]
                ? hierarchyList[currentExerciseIndex].exercise
                : undefined,
            currentTry,
            currentExerciseResult,
            isInitialTest: hierarchyList[currentExerciseIndex]
                ? hierarchyList[currentExerciseIndex].isInitialTest
                : false,
            comingNext,
            exerciseResults,
            currentExecutionStage,
            progression,
            initiallyFresh: ai.aiLoadingInfo.initiallyFresh,
        },

        recordCurrentExerciseResult: (partialExerciseResult, autoGoToNext) => {
            const feedback = getFeedback(
                hierarchyList[currentExerciseIndex].exercise.feedback[
                currentTry - 1
                ],
                partialExerciseResult
            );
            const exerciseResult: ExerciseResult<any> = {
                ...partialExerciseResult,
                exerciseId: hierarchyList[currentExerciseIndex].exercise.id,
                try: currentTry,
                feedback: feedback.content,
                feedbackType: feedback.type,
                feedbackExplicatif: feedback.explicatifContent,
                activityId: hierarchyList[currentExerciseIndex].activity!.id,
            };
            setCurrentExerciseResult(exerciseResult);
            setExerciseResults((curr) => [...curr, exerciseResult]);

            setCurrentExecutionStage(
                PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
            );

            if (
                isRetryNext(
                    exerciseResult,
                    currentTry,
                    hierarchyList![currentExerciseIndex].exercise
                )
            ) {
                setComingNext("retry");
                return;
            }

            // Update history
            const historyItem = {
                exerciseId: hierarchyList[currentExerciseIndex].exercise.id,
                activityId: hierarchyList[currentExerciseIndex].activity.id,
                objectiveId: hierarchyList[currentExerciseIndex].objective.id,
                score: calculateScore(
                    partialExerciseResult,
                    currentTry,
                    opts?.scoreOverride
                ),
            };
            const duration = timer.getElapsedTime();

            const statement = makeHistoryStatement(
                {
                    ...historyItem,
                    moduleId,
                    isInitialTest:
                        hierarchyList[currentExerciseIndex].isInitialTest ??
                        false,
                    isAdaptiveTest: aiType === AIType.AdaptiveTest,
                    answer: partialExerciseResult.answer,
                    duration,
                    success: partialExerciseResult.correct,
                },
                sessionId,
                evidencebId,
                declinaison,
                aiConfig[aiType].id,
                adaptiveTestNumber
            );

            sendStatement(statement);
            // Get next exercise
            let newHierarchyIds: HierarchyIds & Partial<PlaylistProperties>;
            try {
                newHierarchyIds =
                    AIWhisperer.updateHistoryAndGetNextHierarchyIds(
                        (ai.aiLoadingInfo as SuccessfullAI).instance,
                        historyItem
                    );
            } catch (err) {
                Sentry.captureException(err);
                setError(true);
                return;
            }

            const whatsComingNext = getWhatsComingNext(
                currentExerciseIndex,
                hierarchyList.map((hierarchy) => hierarchy.exercise),
                currentTry,
                exerciseResult,
                newHierarchyIds
            );
            setComingNext(whatsComingNext);

            if (whatsComingNext !== "endOfPlaylist") {
                setHierarchyList((curr) => [
                    ...curr!,
                    {
                        ...getHierarchyFromHierarchyId(newHierarchyIds, data),
                        isInitialTest: newHierarchyIds.isInitialTest,
                    },
                ]);
            }

            // Send adaptive test diag statement
            if (
                whatsComingNext === "endOfPlaylist" &&
                aiType === AIType.AdaptiveTest
            ) {
                const diag = AIWhisperer.getStudentDiagnosis(
                    ai.aiLoadingInfo.instance!
                );
                console.log("CNED diag", diag);
                sendStatement(
                    makeAdaptiveTestDiagnosisStatement(
                        declinaison,
                        evidencebId,
                        sessionId,
                        moduleId,
                        adaptiveTestNumber!,
                        diag
                    )
                );
            }

            // Show end of initial test message when needed
            if (
                hierarchyList[currentExerciseIndex].isInitialTest &&
                !newHierarchyIds.isInitialTest
            ) {
                if (
                    hierarchyList![currentExerciseIndex].activity.shell ===
                    ActivityShell.Chatbot
                )
                    // Timeout to show message after chatbot thinking animation delay
                    setTimeout(() => {
                        setCurrentExecutionStage(
                            PlaylistExecutionStage.ShowingEndOfInitialTestMessage
                        );
                    }, 1000);
                else
                    setCurrentExecutionStage(
                        PlaylistExecutionStage.ShowingEndOfInitialTestMessage
                    );
            }

            if (autoGoToNext) setForceGoToNext(true);

            if (opts?.progression) {
                const diagnostic = AIWhisperer.getStudentBasicDiagnostic(
                    ai.aiLoadingInfo.instance!
                );
                setProgression(diagnostic.progression);
            }
        },

        goToNextExercise,

        goToExercise: () => {
            throw new PlayerProgressionError(
                "Students cannot navigate to a specific exercise"
            );
        },

        clearHistory: () => {
            setExerciseResults([]);
        },

        showIdleModal,

        continueAfterIdle: () => {
            const idleModalTime = getModalTime(timer);
            if (idleModalTime > MAX_MODAL_DURATION_BEFORE_RESET) {
                timer.reset();
                timer.start();
            } else {
                timer.resume();
            }
            setShowIdleModal(false);
        },
    } as InitializedPlaylistManager;
};

const getWhatsComingNext = (
    currentExerciseIndex: number,
    exerciseList: Exercise[],
    currentTry: number,
    exerciseResult: ExerciseResult<any>,
    nextHierarchyIds: HierarchyIds & Partial<PlaylistProperties>,
    opts?: AIPlaylistManagerOpts
): InitializedPlaylistManager["playlist"]["comingNext"] => {
    if (!exerciseResult) return undefined;

    if (
        !opts?.forbidRetries &&
        isRetryNext(
            exerciseResult,
            currentTry,
            exerciseList[currentExerciseIndex]
        )
    )
        return "retry";

    if (nextHierarchyIds.isModuleFinished) return "endOfPlaylist";

    return "nextExercise";
};

const isRetryNext = (
    exerciseResult: ExerciseResult<any>,
    currentTry: number,
    exercise: Exercise<any, any>
): boolean => {
    // For backwards compatibility
    const numberOfTries =
        exercise.executionOptions?.numberOfTries || exercise?.numberOfTries;

    if (!exerciseResult.correct && currentTry < numberOfTries!) return true;
    return false;
};

/**
 * Determines how long the idle modal has been up
 */
const getModalTime = (timer: IIdleTimer): number => {
    return timer.getTotalIdleTime() - TIMEOUT;
};

const calculateScore = (
    result: PartialExerciseResult,
    tryNumber: number,
    scoreOverride?: AIPlaylistManagerOpts["scoreOverride"]
) => {
    if (scoreOverride) return scoreOverride(result, tryNumber);
    if (result.score) return result.score / tryNumber;
    if (result.correct) return 1 / tryNumber;
    return 0;
};

const getPlaylistManagerOpts = (
    aiType: AIType
): Partial<AIPlaylistManagerOpts> => {
    switch (aiType) {
        case AIType.BanditManchot:
            return {
                progression: true,
            };
        case AIType.AdaptiveTest:
            return {
                forbidRetries: true,
                scoreOverride: (result) => (result.correct ? 1 : 0),
            };
        default:
            throw new Error(
                `PlaylistManagerOpts not configured for ${aiType} AI`
            );
    }
};

export const getFeedback = (
    feedback: Feedback,
    exerciseResult: PartialExerciseResult
) => {
    let type;
    let content;
    let explicatifContent;

    if (!exerciseResult.correct) {
        content = feedback["incorrect"];
        type = "incorrect";
        explicatifContent = feedback["explicatifIncorrect"] || null;
    } else if (feedback["moderate"] && (exerciseResult.score ?? 0) < 1) {
        content = feedback["moderate"];
        type = "moderate";
        explicatifContent = null;
    } else {
        type = "correct";
        content = feedback["correct"];
        explicatifContent = feedback["explicatifCorrect"] || null;
    }

    return {
        type: type as "correct" | "incorrect" | "moderate",
        content: content,
        explicatifContent: explicatifContent,
    };
};

export default useAIPlaylistManager;

/**
 * HOC that inject the AI playlist manager in the passed component
 */
export const withAIPlaylistManager: (
    WrappedComponent: (props: PlaylistPlayerProps) => JSX.Element,
    aiType: AIType
) => ({
    InfoPanel,
}: Omit<PlaylistPlayerProps, "playlistManager">) => JSX.Element = (
    WrappedComponent,
    aiType
) => {
        return (props) => {
            const { moduleId } = useParams<{ moduleId: string }>();
            const playlistManager = useAIPlaylistManager(moduleId, aiType);

            return (
                <WrappedComponent playlistManager={playlistManager} {...props} />
            );
        };
    };

export const withAITypeElement = (WrappedComponent: any, aiType: AIType) => {
    return (props: any) => {
        return <WrappedComponent aiType={aiType} {...props} />;
    };
};
