import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ModuleDashboard } from "../../../../../interfaces/Dashboard";
import MaterialIcon from "../../../../../components/MaterialIcon/MaterialIcon";
import { FormattedMessage, useIntl } from "react-intl";
import { configStore } from "../../../../../contexts/ConfigContext";
import { getStudentDetailsUrl } from "../../../../../utils/navigation-dashboard";

interface Props {
    students: ModuleDashboard["students"];
    classroomId: string;
}

const StudentNamesColumn = ({ students, classroomId }: Props) => {
    const intl = useIntl();
    const {config} = useContext(configStore);

    const sortByName = (a: string, b: string) => {
        return students[a].lastname.localeCompare(students[b].lastname);
    };

    return (
        <ul>
            {Object.keys(students)
                .sort(sortByName)
                .map((studentId, i) => (
                    <li key={studentId}>
                        <Link to={getStudentDetailsUrl(config.pages, studentId, classroomId, intl)}>
                            <span className="inside-container">
                                <span className="name-container">
                                    <span className="name">
                                        {students[studentId].firstname}{" "}
                                        {students[studentId].lastname}
                                    </span>
                                    <span className="label">
                                        <FormattedMessage id="dashboard-common-seeProfile" defaultMessage="See profile" />
                                        <MaterialIcon className="icon">
                                            chevron_right
                                        </MaterialIcon>
                                    </span>
                                </span>
                            </span>
                            <span
                                className="scalable-background"
                                aria-hidden="true"
                            ></span>
                        </Link>
                    </li>
                ))}
        </ul>
    );
};

export default StudentNamesColumn;
