import {
    HierarchyIds,
    PlaylistProperties,
} from "@evidenceb/gameplay-interfaces";
import { Agent, Statement } from "@xapi/xapi";
import { isArray, mergeWith } from "lodash";
import { v4 as uuid } from "uuid";
import { Emotion } from "../interfaces/EmotionalReport";

export const VERBS = {
    failed: {
        id: "http://adlnet.gov/expapi/verbs/failed",
        display: {
            "en-US": "failed",
        },
    },
    passed: {
        id: "http://adlnet.gov/expapi/verbs/passed",
        display: {
            "en-US": "passed",
        },
    },
    expressed: {
        id: "https://w3id.org/xapi/dod-isd/verbs/expressed",
        display: {
            "en-US": "expressed",
        },
    },
    "logged-in": {
        id: "https://w3id.org/xapi/adl/verbs/logged-in",
        display: {
            "en-US": "logged-in",
        },
    },
    accessed: {
        id: "https://w3id.org/xapi/netc/verbs/accessed",
        display: {
            "en-US": "accessed",
        },
    },
    completed: {
        id: "http://adlnet.gov/expapi/verbs/completed",
        display: {
            "en-US": "completed",
        },
    },
    viewed: {
        id: "http://id.tincanapi.com/verb/viewed",
        display: {
            "en-US": "viewed",
        },
    },
};
export const XAPI_REGISTRY = "http://xapiressource.prod.evidenceb-services.com";
export const ADAPTIVE_TEST_CATEGORY = `${XAPI_REGISTRY}/category/adaptive-test`;

const durationToISO = (duration: number): string => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = (duration % 3600) % 60;
    if (hours > 0) {
        return `PT${hours}H${minutes}M${seconds}S`;
    } else if (minutes > 0) {
        return `PT${minutes}M${seconds}S`;
    } else {
        return `PT${seconds}S`;
    }
};

export const getCurrentAgent = (
    declinaison: string,
    evidencebId: string
): Agent => {
    return {
        account: {
            name: evidencebId,
            homePage: `${XAPI_REGISTRY}/homepages/${declinaison}`,
        },
        objectType: "Agent",
    };
};

const makeStatement = (
    variation: string,
    evidencebId: string,
    sessionId: string,
    ...statementParts: Partial<Statement>[]
): Statement => {
    if (
        !statementParts.some((part) => typeof part.verb !== "undefined") ||
        !statementParts.some((part) => typeof part.object !== "undefined")
    )
        throw new Error("Incomplete statement");

    const statementBasis: Partial<Statement> = {
        timestamp: new Date().toISOString(),
        id: uuid(),
        actor: getCurrentAgent(variation, evidencebId),
        context: {
            registration: sessionId,
        },
    };

    return mergeWith(
        {},
        statementBasis,
        ...statementParts,
        (objValue: any, srcValue: any) => {
            if (isArray(objValue) && isArray(srcValue))
                return [...objValue, ...srcValue];
        }
    );
};

const makeAIStatementParts = (aiId: string): Partial<Statement> => {
    return {
        context: {
            extensions: {
                [`${XAPI_REGISTRY}/extenstions/ai`]: {
                    id: aiId,
                },
            },
        },
    };
};

const makeAdaptiveTestStatementParts = (
    adaptiveTestNumber: number
): Partial<Statement> => {
    return {
        context: {
            contextActivities: {
                category: [{ id: ADAPTIVE_TEST_CATEGORY }],
            },
            extensions: {
                [`${XAPI_REGISTRY}/extensions/adaptive-test`]:
                    adaptiveTestNumber,
            },
        },
    };
};

const makeExerciseResultStatementParts = (
    historyItem: HierarchyIds &
        Partial<PlaylistProperties> & {
            score: number;
            success: boolean;
            answer?: any;
            duration: number;
        }
): Partial<Statement> => {
    return {
        verb: historyItem.success ? VERBS.passed : VERBS.failed,
        object: {
            id: XAPI_REGISTRY + "/exercise/" + historyItem.exerciseId,
            objectType: "Activity",
        },
        result: {
            score: {
                scaled: historyItem.score,
                raw: historyItem.score,
                min: 0,
                max: 1,
            },
            success: historyItem.success,
            response: JSON.stringify(historyItem.answer),
            duration: durationToISO(historyItem.duration / 1000),
        },
        context: {
            contextActivities: {
                parent: [
                    {
                        id: `${XAPI_REGISTRY}/module/${historyItem.moduleId}/objective/${historyItem.objectiveId}/activity/${historyItem.activityId}`,
                        objectType: "Activity",
                    },
                ],
            },
            extensions: {
                [`${XAPI_REGISTRY}/extensions/common`]: {
                    initialTest: historyItem.isInitialTest,
                    isAdaptiveTest: historyItem.isAdaptiveTest,
                },
            },
        },
    };
};

const makeEmotionalStatementParts = (
    emotion: Emotion,
    reportType: "FORCED" | "SELF-REPORT"
): Partial<Statement> => {
    return {
        verb: VERBS.expressed,
        object: {
            id: XAPI_REGISTRY + "/emotion/" + emotion.toLowerCase(),
            objectType: "Activity",
        },
        context: {
            contextActivities: {
                other: [
                    {
                        id: `${XAPI_REGISTRY}/emotional-report-type/${reportType.toLowerCase()}`,
                        objectType: "Activity",
                    },
                ],
            },
        },
    };
};

const makeLoggedInStatementParts = (variation: string): Partial<Statement> => {
    return {
        verb: VERBS["logged-in"],
        object: {
            objectType: "Activity",
            id: `${XAPI_REGISTRY}/objects/variation/${variation}`,
        },
    };
};

const makeRecommendationsClickStatementParts = (
    linkUrl: string,
    moduleId: string
): Partial<Statement> => {
    return {
        verb: VERBS["accessed"],
        object: {
            objectType: "Activity",
            id: linkUrl,
            definition: {
                type: `${XAPI_REGISTRY}/activities/recommendation`,
            },
        },
        context: {
            contextActivities: {
                parent: [
                    {
                        id: `${XAPI_REGISTRY}/module/${moduleId}`,
                        objectType: "Activity",
                    },
                ],
            }
        }
    };
};

const makeInternalResourceViewedStatementParts = (
    type: "voluntary" | "compulsory"
): Partial<Statement> => {
    return {
        verb: VERBS["viewed"],
        context: {
            extensions: {
                [`${XAPI_REGISTRY}/extensions/imposed-viewing`]:
                    type === "compulsory",
            },
        },
    };
};

const makeActivityVideoTutorialStatementParts = (
    linkUrl: string,
    activityId: string
): Partial<Statement> => {
    return {
        object: {
            objectType: "Activity",
            id: linkUrl,
            definition: {
                type: `${XAPI_REGISTRY}/activities/activity-video-tutorial`,
            },
        },
        context: {
            extensions: {
                [`${XAPI_REGISTRY}/extensions/activity`]: {
                    id: activityId,
                },
            },
        },
    };
};

const makeAdaptiveTestDiagnosisStatementParts = (
    moduleId: string,
    diagnosis: any
): Partial<Statement> => {
    return {
        verb: VERBS.completed,
        object: {
            objectType: "Activity",
            id: `${XAPI_REGISTRY}/module/${moduleId}`,
            definition: {
                type: `${XAPI_REGISTRY}/activities/adaptive-test`,
            },
        },
        result: {
            completion: true,
            extensions: {
                [`${XAPI_REGISTRY}/extensions/adaptive-test-diagnosis`]:
                    diagnosis,
            },
        },
    };
};

export const makeEmotionalReportStatement = (
    variation: string,
    evidencebId: string,
    emotion: Emotion,
    sessionId: string,
    reportType: "FORCED" | "SELF-REPORT"
): Statement => {
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeEmotionalStatementParts(emotion, reportType)
    );
};

export const makeLoggedInStatement = (
    variation: string,
    evidencebId: string,
    sessionId: string
): Statement => {
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeLoggedInStatementParts(variation)
    );
};

export const makeRecommendationsClickStatement = (
    variation: string,
    evidencebId: string,
    linkUrl: string,
    sessionId: string,
    moduleId: string
): Statement => {
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeRecommendationsClickStatementParts(linkUrl, moduleId)
    );
};

export const makeAdaptiveTestDiagnosisStatement = (
    variation: string,
    evidencebId: string,
    sessionId: string,
    moduleId: string,
    adaptiveTestNumber: number,
    diagnosis: any
): Statement => {
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeAIStatementParts("catsimulation"),
        makeAdaptiveTestStatementParts(adaptiveTestNumber),
        makeAdaptiveTestDiagnosisStatementParts(moduleId, diagnosis)
    );
};

export const makeHistoryStatement = (
    historyItem: HierarchyIds &
        Partial<PlaylistProperties> & {
            score: number;
            success: boolean;
            answer?: any;
            duration: number;
        },
    sessionId: string,
    evidencebId: string,
    variation: string,
    aiId: string,
    adaptiveTestNumber?: number
): Statement => {
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeAIStatementParts(aiId),
        makeExerciseResultStatementParts(historyItem),
        adaptiveTestNumber
            ? makeAdaptiveTestStatementParts(adaptiveTestNumber)
            : {}
    );
};

export const makeActivityVideoTutorialWatchedStatement = (
    variation: string,
    evidencebId: string,
    sessionId: string,
    videoUrl: string,
    activityId: string,
    viewingType: "voluntary" | "compulsory"
): Statement => {
    return makeStatement(
        variation,
        evidencebId,
        sessionId,
        makeInternalResourceViewedStatementParts(viewingType),
        makeActivityVideoTutorialStatementParts(videoUrl, activityId)
    );
};
