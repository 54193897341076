import React from "react";
import cn from "classnames";
import parse from "html-react-parser";
import { HtmlString } from "@evidenceb/gameplay-interfaces";
import Modal from "../Modal/Modal";
import Dialog, {
    DialogProps,
} from "../Dialog/Dialog";
import { useOverlayTriggerState } from "@react-stately/overlays";

import "./ControlledDialog.scss";

export interface Props {
    title: DialogProps["title"] | string;
    description: HtmlString;
    primaryButton: {
        label: string;
        fn: () => void;
    };
    secondaryButton?: {
        label: string;
        fn: () => void;
    };
    children?: JSX.Element | JSX.Element[];
    /** @default default */
    variant?: "default" | "alert";
}

/**
 * ControlledDialog is a Dialog directly placed in a Modal with a state that
 * cannot be controlled without not render this ControlledDialog
 */
const ControlledDialog = ({
    title,
    description,
    primaryButton,
    secondaryButton,
    variant = "default",
    children,
}: Props) => {
    const state = useOverlayTriggerState({ isOpen: true });

    return (
        <Modal state={state}>
            <Dialog
                title={getDialogTitle(title)}
                className={`ds-controlled-dialog ds-controlled-dialog--${variant}`}
            >
                <div className="ds-controlled-dialog__content">
                    <p>{parse(description.$html)}</p>

                    {children && <div>{children}</div>}
                </div>

                <div className="ds-controlled-dialog__buttons-container">
                    <button
                        className="ds-controlled-dialog__primary-btn"
                        onClick={primaryButton.fn}
                    >
                        {primaryButton.label}
                    </button>

                    {secondaryButton && (
                        <button
                            className="ds-controlled-dialog__secondary-btn"
                            onClick={secondaryButton.fn}
                        >
                            {secondaryButton.label}
                        </button>
                    )}
                </div>
            </Dialog>
        </Modal>
    );
};

const TITLE_CLASSNAME = "ds-controlled-dialog__title";

const getDialogTitle = (title: Props["title"]): DialogProps["title"] => {
    if (!title) return undefined;
    if (typeof title === "string")
        return {
            as: "h3",
            content: title,
            className: TITLE_CLASSNAME,
        };
    return {
        ...title,
        className: cn(title.className, TITLE_CLASSNAME),
    };
};

export default ControlledDialog;
