import React, { useContext, useEffect, useRef, useState } from "react";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { getCurrentAgent } from "../../../utils/statement-builder";
import * as Sentry from "@sentry/react";
import { UserType } from "../../../interfaces/User";
import useAthenaAPIClient from "../../../hooks/useAthenaAPIClient";
import { useIntl } from "react-intl";
import Button from "../../../design-system-components/Button/Button";

// Reference for the method used to download a json generated in the frontend : https://javascript.plainenglish.io/how-to-create-download-and-upload-files-in-react-apps-80893da4247a

enum State {
    inactive = "inactive",
    loading = "loading",
    error = "error",
}

const STATES_ICONS: { [key in State]: string } = {
    inactive: "arrow_circle_down",
    loading: "hourglass_empty",
    error: "cancel",
};

/**
 * Button that allows to download all the user data in json format
 */
const UserDataDownloadButton: React.FC = () => {
    const {
        session: { userType, evidencebId },
    } = useContext(sessionStore);
    const {
        config: { declinaison, features },
    } = useContext(configStore);
    const athenaAPIClient = useAthenaAPIClient();
    const [state, setState] = useState<State>(State.inactive);
    const [fileDownloadURL, setFileDownloadURL] = useState<string>();
    const hiddenLink = useRef<HTMLAnchorElement>(null);
    const agent = getCurrentAgent(declinaison, evidencebId);
    const intl = useIntl();

    // When the file download link is set, trigger the download and the end of the download process
    useEffect(() => {
        if (!fileDownloadURL || hiddenLink.current === null) return;

        hiddenLink.current.click();

        URL.revokeObjectURL(fileDownloadURL);
        setFileDownloadURL(undefined);
    }, [fileDownloadURL, hiddenLink]);

    return (
        <>
            <Button
                className="user-data-download-button"
                disabled={state !== State.inactive}
                onClick={async () => {
                    if (state !== State.inactive) return;

                    setState(State.loading);

                    const promises = [];
                    promises.push(
                        athenaAPIClient.getTokenPayload()
                    );
                    if (userType === UserType.Student)
                        promises.push(
                            athenaAPIClient.getStatements(agent)
                        );

                    const requestResults = await Promise.allSettled(promises);
                    if (
                        requestResults.some(
                            (result) => result.status === "rejected"
                        )
                    ) {
                        // [TODO] Error handling
                        Sentry.captureException(
                            requestResults
                                .filter((res): res is PromiseRejectedResult => res.status === "rejected")
                                .map((res) => res.reason)
                        );
                        setState(State.error);
                        setTimeout(() => {
                            setState(State.inactive);
                        }, 3000);
                        return;
                    }
                    const userData: { "user-info": any; statements?: any } = {
                        "user-info": await (
                            requestResults[0] as PromiseFulfilledResult<any>
                        ).value,
                    };
                    if (userType === UserType.Student)
                        userData["statements"] = await (
                            requestResults[1] as PromiseFulfilledResult<any>
                        ).value;

                    const json = JSON.stringify(userData);
                    const blob = new Blob([json], { type: "application/json" });
                    setFileDownloadURL(URL.createObjectURL(blob));

                    // The useEffect picks up the rest of the download process
                }}
                label={intl.formatMessage({id: "misc-userDataDownload", defaultMessage: "Download personal data"})}
                icon={{
                    path: STATES_ICONS[state],
                    position: "left"
                }}
                color={features.footerBtnVariant?.color ?? "secondary"}
                variant={features.footerBtnVariant?.variant ?? "primary"}
                center={true}
            />

            {fileDownloadURL && (<>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a
                    href={fileDownloadURL}
                    ref={hiddenLink}
                    style={{ display: "none" }}
                    aria-hidden={true}
                ></a>
            </>)}
        </>
    );
};

export default UserDataDownloadButton;
