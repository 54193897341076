import axios from "axios";
import { merge } from "lodash";
import { useContext, useMemo } from "react";
import { configStore } from "../contexts/ConfigContext";
import { sessionStore } from "../contexts/SessionContext";
import { Application, ApplicationsConfig, Config } from "../interfaces/Config";
import { applyTheme } from "../utils/theme-handler";

const useSelectApp = () => {
    const { config, setConfig } = useContext(configStore);
    const { setSession } = useContext(sessionStore);

    const selectApp = useMemo(
        () =>
            async (
                app: Application,
                applicationsConfig?: ApplicationsConfig
            ) => {
                const appOverrides: Partial<Config> = {
                    client_name: app.client_name,
                    lang: app.lang,
                    declinaison: app.app,
                };
                let newMinimalconfig = {};
                if (app.globalConfig || applicationsConfig?.globalConfig) {
                    const { data: minimalConfig } = await axios.get(
                        `${config.apiUrls.endpoints.assetsProxy}${
                            app.globalConfig || applicationsConfig?.globalConfig
                        }`
                    );
                    newMinimalconfig = minimalConfig.config;
                    applyTheme(minimalConfig.theme);
                }
                setConfig((config) =>
                    merge(
                        {},
                        config,
                        newMinimalconfig,
                        applicationsConfig ?? {},
                        appOverrides
                    )
                );
                setSession((session) => ({
                    ...session,
                    app: app.app,
                }));
            },
        [config.apiUrls.endpoints.assetsProxy, setConfig, setSession]
    );

    return selectApp;
};

export default useSelectApp;
