import React, { useContext, useEffect, useState } from "react"
import SimpleBar from "simplebar-react";
import { Link, useHistory } from "react-router-dom";
import { OverlayTriggerState, useOverlayTriggerState } from "@react-stately/overlays";
import { Item } from "react-stately";
import { defineMessages, useIntl } from "react-intl";

import useClassroom from '../useClassroom';
import { dataStore } from "../../../../contexts/DataContext";
import { configStore } from "../../../../contexts/ConfigContext";
import { dashboardStore } from "../../../../contexts/DashboardContext";
import Bar from "../Bar/Bar";
import { getIdentifiedGroupsNumber, getModuleById } from "../../../../utils/dataRetrieval";
import Icon from "../../../../design-system-components/Icon/Icon";
import Button from "../../../../design-system-components/Button/Button";
import Modal from "../../../../design-system-components/Modal/Modal";
import Tabs from "../../../../design-system-components/Tabs/Tabs";
import "./ClassroomList.scss";
import { sessionStore } from "../../../../contexts/SessionContext";
import useAthenaAPIClient from "../../../../hooks/useAthenaAPIClient";
import { Classroom, UserType } from "../../../../interfaces/User";
import { errorStore } from "../../../../contexts/ErrorContext";
import { ClassroomCreatorModal, ClassroomCreatorButton } from "../ClassroomCreator/ClassroomCreator";
import { PRLockStatus } from "../../../../interfaces/PedagogicalResourcesManagement";
import Loader from "../../../../components/Loader/Loader";
import Dialog from "../../../../design-system-components/Dialog/Dialog";
import DashboardStaticSpecimenModal from "../../../../components/Specimen/Modal/Modal"
import SpecimenHelper from "../../SpecimenHelper/SpecimenHelper";
import { useFeatureFlag } from "@evidenceb/athena-common";

const ClassroomList = () => {

    const intl = useIntl();
    const { getUrl } = useClassroom();
    const athenaAPIClient = useAthenaAPIClient();
    const history = useHistory();

    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const { session: { userId, ...session }, setSession } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);

    const { dashboard, setDashboard } = useContext(dashboardStore);
    const classroomCreatorModal = useOverlayTriggerState({})
    const [classroomCreatedData, setClassroomCreatedData] = useState<{ id: string, code: string }>({ id: "", code: "" })
    const createdClassroomModal = useOverlayTriggerState({})
    const [lockStatus, setLockStatus] = useState<{ [classroomId: string]: PRLockStatus }>();
    const specimenModal = useOverlayTriggerState({})

    const hasClassManagement = useFeatureFlag('classManagement');
    const hasClustering = useFeatureFlag('clustering');

    const onClassroomCreationEnd = (classroom: Classroom) => {
        if (!dashboard) return;

        //add newly created classroom in dashboard context
        let classroomItem = {
            id: classroom.id,
            external_id: classroom.external_id,
            level: classroom.level,
            name: classroom.name,
            studentsNumber: 0,
            alert: {
                isAlert: false,
            },
            modulesList: [],
            code: classroom.code
        }
        const updatedDashboard = {
            ...dashboard,
            classrooms: [...dashboard.classrooms, classroomItem]
        };
        setDashboard(updatedDashboard)
        setClassroomCreatedData({
            id: classroom.id,
            code: classroom.code
        })
        createdClassroomModal.open()
        classroomCreatorModal.close()
    }
    const onModalDismiss = () => {
        createdClassroomModal.close()
    }
    const goToClassroom = () => {
        if (config.features.studentManagement) {
            history.push(getUrl("classroom", classroomCreatedData.id) + "?tabIndex=1")
        } else {
            history.push(getUrl("classroom", classroomCreatedData.id))
        }
    }

    // Get LockStatus
    useEffect(() => {
        if (lockStatus) return;
        (async () => {
            try {
                const lockStatus = await athenaAPIClient.getResourcesLockStatus(userId, UserType.Teacher);
                setLockStatus(lockStatus);
            } catch (err) {
                if (athenaAPIClient.handleTokenError(err)) {
                    setLockStatus({}); // To prevent infinite loop of retries of the request
                } else {
                    setErrorInfo({
                        displayModal: true,
                        modal: {
                            type: "NOTIFICATION",
                            content: {
                                title: intl.formatMessage(messages.lockStatusErrorTitle)
                            }
                        }
                    })
                }
                setLockStatus({}); // To prevent infinite loop of retries of the request
            }
        })();
    }, [userId, athenaAPIClient, lockStatus, setErrorInfo, intl]);

    if (!dashboard) return <Loader />;

    return (
        <>
            <div className="classroom-list-container">
                <div className="classroom-list__header">
                    <h1>{intl.formatMessage(messages.classes)}</h1>
                    {
                        hasClassManagement && dashboard.classrooms.length > 0 &&
                            <ClassroomCreatorButton
                                state={classroomCreatorModal}
                            />
                    }
                </div>
                {
                    dashboard.classrooms.length > 0 &&
                    <div className="tabs-container">
                        {dashboard.classrooms.map(classroom => (
                            <Tabs 
                                key={classroom.id}
                                appearance="round"
                                aria-label=""
                            >
                                <Item key="class-info" title={classroom.name}>
                                <ClassroomInfo
                                    code={classroom.code ?? classroom.external_id}
                                    link={getUrl("classroom", classroom.id)}
                                />
                                <span>{intl.formatMessage(messages.progressionsTitle)}</span>
                                {classroom.modulesList.length === 0 ?  <NoData /> : <SimpleBar style={{ height: 300 }} autoHide={false}>
                                    {classroom.modulesList.map((module, i) =>
                                        <Bar
                                            key={module.id}
                                            state={module.progression === 0 ? "noData" : undefined}
                                            url={getUrl("progressionOverview", classroom.id, module.id)}
                                            type={"progress"}
                                            moduleName={`${i + 1}. ${getModuleById(module.id, data).title.short!}`}
                                            value={module.progression !== 0 ? (module.progression * 100).toFixed(1) : module.progression}
                                        />)}
                                </SimpleBar>}
                            </Item>
                            
                            {hasClustering && dashboard.clustering ?
                                <Item key="clusters" title={intl.formatMessage(messages.groups)}>
                                    <span>{intl.formatMessage(messages.groupsTitle)}</span>
                                    {classroom.modulesList.length === 0 ?  <NoData /> : <SimpleBar style={{ height: 300 }} autoHide={false}>
                                        {
                                            classroom.modulesList.map((module, i) =>
                                                <Bar
                                                    key={module.id}
                                                    state={module.progression === 0 ? "noData" : undefined}
                                                    url={getUrl("viewGroups", classroom.id, module.id)}
                                                    type={"moduleGroups"}
                                                    moduleName={getModuleById(module.id, data).title.short!}
                                                    value={getIdentifiedGroupsNumber(dashboard.clustering!, classroom.id, module.id, intl.formatMessage(messages.identifiedGroups))}
                                                />
                                            )
                                        }
                                    </SimpleBar>}
                                </Item> : <></>}
                            </Tabs>
                        ))}
                    </div>
                }
                {
                    dashboard.classrooms.length === 0 &&
                        <NoClassrooms
                            modalState={classroomCreatorModal}
                        />
                }
                {session.specimen &&
                    <SpecimenHelper
                        dialogTitle={intl.formatMessage(messages.specimenClassroomHelpDialogTitle)}
                        dialogDescription={intl.formatMessage(messages.specimenClassroomHelpDialogDescription, {
                            educational_space_link: (
                                <Link to={`/${config.features.specimen?.educationSpaceLink}`}>
                                    {intl.formatMessage(messages.specimenClassroomHelpDialogLinkLabel)}
                                </Link>
                            )
                        })}
                    />
                }
            </div>
            { classroomCreatorModal.isOpen &&
                <ClassroomCreatorModal
                    state={classroomCreatorModal}
                    onEnd={onClassroomCreationEnd}
                    className="classroom-list__classroom-creator"
                />
            }
            {
                createdClassroomModal.isOpen &&
                <Modal
                    className="classroom-created-modal"
                    state={createdClassroomModal}
                    onClose={onModalDismiss}
                    isDismissable={true}
                >
                    <Dialog
                        title={{
                            as: "h2",
                            content: intl.formatMessage(messages.classroomCreated)
                        }}
                        aria-label={intl.formatMessage(messages.classroomCreated)}
                    >
                        <div className="classroom-created-modal-content">
                            <div className="classroom-created-modal-content__code">
                                {intl.formatMessage(messages.code)} <span>{classroomCreatedData.code}</span>
                            </div>
                            <p>{intl.formatMessage(messages.classroomCreatedMessage)}</p>
                            <div className="classroom-created-modal-content__actions">
                                <Button
                                    variant="primary"
                                    label={intl.formatMessage(messages.classroomCreatedContinue)}
                                    icon={{
                                        path: "arrow_right",
                                        position: "right"
                                    }}
                                    onClick={goToClassroom}
                                />
                            </div>
                        </div>
                    </Dialog>
                </Modal>
            }
            {session.specimen && specimenModal.isOpen && !session.dismissMessages.specimenTeacherDashboardModal &&(
                <DashboardStaticSpecimenModal
                    state={specimenModal}
                    onModalDismiss={specimenModal.close}
                    onClick={() => {
                        specimenModal.close()
                        setSession(session => ({ ...session, dismissMessages: { ...session.dismissMessages, specimenTeacherDashboardModal: true } }))
                    }}

                    title={intl.formatMessage({ id: "dashboard-specimen-modalTitle", defaultMessage: "Welcome to the dashboard !" })}
                    content={intl.formatMessage({ id: "dashboard-specimen-modalDescription", defaultMessage: "Here you can view the progress of your classes as well as the individual progress of each student.\nPlease note that the data shown here is fictitious. " })}
                    buttonLabel={intl.formatMessage({ id: "dashboard-specimen-modalButton", defaultMessage: "Browse the dashboard" })}
                />
            )}
        </>
    )
}

export default ClassroomList

interface NoClassroomsProps {
    modalState: OverlayTriggerState
}
const NoClassrooms = ({ modalState }: NoClassroomsProps) => {
    const intl = useIntl();
    const { config: { features } } = useContext(configStore);
    return (
        <div className="no-classrooms">
            <div>
                <Icon path="sleeping.png" size={{ width: 201, height: 143 }} />
                <div>{intl.formatMessage(messages.noClassrooms)}</div>
                {
                    features.classManagement &&
                        <ClassroomCreatorButton
                            state={modalState}
                        />
                }
            </div>
        </div>
    )
}

const NoData = () => {
    const intl = useIntl();
    return (
        <p className="no-data">
            {intl.formatMessage(messages.noStudentsData)}
        </p>
    )
}

interface ClassroomInfoProps {
    code: string;
    link: string;
}
const ClassroomInfo = ({ code, link }: ClassroomInfoProps) => {
    const intl = useIntl();
    const { config: { features } } = useContext(configStore);
    return (
        <div className="classroomInfos">
            {features.classCode !== false && code &&
                <div className="code">
                    <span>{intl.formatMessage(messages.code)}</span>
                    <span> {code}</span>
                </div>
            }
            <Link to={link}>
                <Icon path="equalizer" size="small" />
                <span>{intl.formatMessage(messages.seeAll)}</span>
                <Icon path="chevron_right" size="medium" />
            </Link>
        </div>
    )
}

const messages = defineMessages({
    lockStatusErrorTitle: {
        id: "dashboard-classList-lockStatusErrorTitle", defaultMessage: "Unable to retrieve resources' lock status"
    },
    classes: {
        id: "dashboard-common-classes", defaultMessage: "Classes"
    },
    groups: {
        id: "dashboard-common-groups", defaultMessage: "Groups"
    },
    identifiedGroups: {
        id: "dashboard-classList-identifiedGroups", defaultMessage: "Identified groups"
    },
    progressionsTitle: {
        id: "dashboard-classList-progressionsTitle", defaultMessage: "Modules progression"
    },
    groupsTitle: {
        id: "dashboard-classList-groupsTitle", defaultMessage: "Group progression"
    },
    noStudentsData: {
        id: "dashboard-common-noStudentsData", defaultMessage: "There is no data at the moment."
    },
    code: {
        id: "classDetails-code", defaultMessage: "Class code:"
    },
    seeAll: {
        id: "dashboard-seeAll", defaultMessage: "See all"
    },
    noClassrooms: {
        id: "dashboard-common-noClassrooms", defaultMessage: "There is no classrooms at the moment."
    },
    createClassroom: {
        id: "dashboard-classList-createClassroom", defaultMessage: "Create a classroom"
    },
    classroomCreated: {
        id: "dashboard-classList-form-success", defaultMessage: "The class has been successfully added"
    },
    classroomCreatedMessage: {
        id: "dashboard-classList-form-message", defaultMessage: "This code has to be provided to the students before their first log in. The code will always be displayed in the dashboard.",
    },
    classroomCreatedContinue: {
        id: "dashboard-classList-form-continue", defaultMessage: "Continue",
    },
    specimenClassroomHelpDialogTitle: {
        id: "specimen-dashboard-classlist-help-dialog-title", defaultMessage: "Welcome to the dashboard !",
    },
    specimenClassroomHelpDialogDescription: {
        id: "specimen-dashboard-classlist-help-dialog-description", defaultMessage: "Here you will find all your classes with an overview of their progress in the different modules. To learn more, go to the {educational_space_link}.",
    },
    specimenClassroomHelpDialogLinkLabel: {
        id: "specimen-dashboard-classlist-help-dialog-link-label", defaultMessage: "pedagogical support page",
    },
})