import React from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { PRLevel } from "../../../../../interfaces/PedagogicalResourcesManagement";
import { Confirmation, UsePRConfirmationReturn } from "../../usePRConfirmation";
import Toggle from "../../../../../components/Toggle/Toggle";

import "./ConfirmationItemControls.scss";

interface Props {
    confirm: UsePRConfirmationReturn["confirm"];
    confirmation: Confirmation;
}

const ConfirmationItemControls = ({ confirmation, confirm }: Props) => {
    const intl = useIntl();

    return (
        <>
            <div>
                <Toggle
                    className="confirmation-item-control__toggle"
                    isReadOnly={true}
                    isSelected={confirmation.type === "unlock"}
                    onChange={() => {}}
                    accessibleLabel="" // It's ok because the toggle is inactive
                    pinIcon={
                        confirmation.type === "unlock" ? "checkmark" : "close"
                    }
                ></Toggle>
            </div>

            <div className="list-item__child--highlighted">
                {getConfirmationText(confirmation, intl)}
            </div>

            <button
                className="list-item__child--highlighted --bold"
                onClick={() => {
                    confirm!(true);
                }}
            >
                {confirmation.type === "lock" ? (
                    <FormattedMessage
                        id="prm-manager-listItem-lock"
                        defaultMessage="Deactivate"
                    />
                ) : (
                    <FormattedMessage
                        id="prm-manager-listItem-unlock"
                        defaultMessage="Activate"
                    />
                )}
            </button>

            <button
                className="list-item__child--highlighted"
                onClick={() => {
                    confirm!(false);
                }}
            >
                <FormattedMessage
                    id="prm-manager-listItem-cancel"
                    defaultMessage="Cancel"
                />
            </button>
        </>
    );
};

const getConfirmationText = (confirmation: Confirmation, intl: IntlShape) => {
    if (confirmation.level === PRLevel.Objectives) {
        if (confirmation.type === "lock")
            return intl.formatMessage({
                id: "prm-manager-listItem-lockObjectivePrompt",
                defaultMessage: "Deactivate dependent objectives?",
            });
        return intl.formatMessage({
            id: "prm-manager-listItem-unlockObjectivePrompt",
            defaultMessage: "Activate dependent objectives?",
        });
    } else {
        if (confirmation.type === "lock")
            return intl.formatMessage({
                id: "prm-manager-listItem-lockActivityPrompt",
                defaultMessage: "Deactivate dependent activities?",
            });
        return intl.formatMessage({
            id: "prm-manager-listItem-unlockActivityPrompt",
            defaultMessage: "Activate dependent activities?",
        });
    }
};

export default ConfirmationItemControls;
