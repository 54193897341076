import React, { useContext } from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { dataStore } from "../../../../contexts/DataContext";
import { TestResult } from "../../../../interfaces/Dashboard";
import { Data } from "../../../../interfaces/Data";
import {
    getExerciseById,
    getObjectiveById,
    getResourceIndex,
} from "../../../../utils/dataRetrieval";
import { commonMessages } from "../../../../utils/messages";
import { parse } from "@evidenceb/athena-common";
import Icon from "../../../../design-system-components/Icon/Icon";
import Chip from "../../../../design-system-components/Chip/Chip";
import VisuallyHidden from "../../../../components/VisuallyHidden/VisuallyHidden";

import "./TestProgressionResults.scss";

// Solution used for making the rows clickable is inspired the second one
// outlined in this article : https://robertcooper.me/post/table-row-links It's
// edited so that it's an overlay on top of the table cell with a hidden text to
// that the text reflects the action of the link rather than the content of the
// cell

interface Props {
    testResult: TestResult;
    className?: string;
}

const TestProgressionResults = ({ testResult, className }: Props) => {
    const intl = useIntl();
    const { data } = useContext(dataStore);

    const hasInvolvedActivities = testResult.exercises.some(
        (exercise) => exercise.involvedActivities && exercise.involvedActivities.length > 0
    );

    return (
        <SimpleBar
            className={className}
            style={{ overflowX: "hidden" }}
            autoHide={false}
        >
            <table className="test-progression-results">
                <thead>
                    <tr>
                        <th>{intl.formatMessage(commonMessages["#"])}</th>
                        {hasInvolvedActivities && (
                            <th>
                                {intl.formatMessage(messages.involvedActivity)}
                            </th>
                        )}
                        <th>{intl.formatMessage(messages.studentAnswer)}</th>
                        <th>
                            {intl.formatMessage(messages.exerciseInstruction)}
                        </th>
                        <th>
                            <VisuallyHidden>
                                {intl.formatMessage(
                                    messages.a11yOpenExerciseColumnHeader
                                )}
                            </VisuallyHidden>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {testResult.exercises.map((exercise, index) => {
                        const Link = withIdLinkToExercise(exercise.id);
                        return (
                            <tr key={exercise.id}>
                                <td className="test-progression-results__exercise-index">
                                    <Link />
                                    {index + 1}
                                </td>
                                {hasInvolvedActivities && (
                                    <td className="test-progression-results__involved-activity">
                                        <Link />
                                        {exercise.involvedActivities &&
                                                exercise.involvedActivities.map(
                                                    ({
                                                        activityId,
                                                        objectiveId,
                                                    }) =>
                                                        involvedActivityIdentifier(
                                                            activityId,
                                                            objectiveId,
                                                            data,
                                                            intl
                                                        )
                                                )
                                                .join(", ")}
                                    </td>
                                )}
                                <td>
                                    <Link />
                                    <Chip
                                        type={
                                            exercise.isCorrect
                                                ? "correct"
                                                : "incorrect"
                                        }
                                        input={intl.formatMessage(
                                            exercise.isCorrect
                                                ? commonMessages.correct
                                                : commonMessages.incorrect
                                        )}
                                        className="test-progression-results__correctness"
                                        size="big"
                                        icon={{
                                            path: exercise.isCorrect
                                                ? "checkmark"
                                                : "invalid",
                                            size: 12,
                                        }}
                                    />
                                </td>
                                <td>
                                    <Link />
                                    {parse(
                                        getExerciseById(exercise.id, data)
                                            .instruction?.$html ?? ""
                                    )}
                                </td>
                                <td>
                                    <Link allowFocusable />
                                    <Icon
                                        className="test-progression-results__open-exercise"
                                        path="edit_open_in_new"
                                        size={24}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </SimpleBar>
    );
};

export default TestProgressionResults;

const involvedActivityIdentifier = (
    activityId: string,
    objectiveId: string,
    data: Data,
    intl: IntlShape
) => {
    const module = data.modules.find((mod) =>
        mod.objectiveIds.includes(objectiveId)
    )!;

    return `${intl.formatMessage(
        commonMessages.objectiveShort
    )}${getResourceIndex(objectiveId, module.objectiveIds)}, ${intl
        .formatMessage(commonMessages.activityShort)
        .charAt(0)}${getResourceIndex(
        activityId,
        getObjectiveById(objectiveId, data).activityIds
    ) + 1}`;
};

const LinkToExercise = ({
    allowFocusable,
    exerciseId,
}: {
    allowFocusable?: boolean;
    exerciseId: string;
}) => {
    const intl = useIntl();

    return (
        <Link
            className="test-progression-results__exercise-link"
            target="_blank"
            rel="noopener noreferrer"
            to={"/play/" + exerciseId}
            tabIndex={allowFocusable ? 0 : -1}
            aria-hidden={allowFocusable ? false : true}
        >
            {allowFocusable && (
                <VisuallyHidden>
                    {intl.formatMessage(messages.a11yOpenExercise)}
                </VisuallyHidden>
            )}
        </Link>
    );
};

const withIdLinkToExercise =
    (exerciseId: string) => (props: { allowFocusable?: boolean }) =>
        <LinkToExercise {...props} exerciseId={exerciseId} />;

const messages = defineMessages({
    involvedActivity: {
        id: "dashboard-involvedActivity",
        defaultMessage: "Evaluated element",
    },
    studentAnswer: {
        id: "dashboard-studentAnswer",
        defaultMessage: "student's answer",
    },
    exerciseInstruction: {
        id: "dashboard-exerciseInstruction",
        defaultMessage: "exercise instruction",
    },
    a11yOpenExercise: {
        id: "dashboard-a11y-OpenExercise",
        defaultMessage: "Open the exercise in a new tab",
    },
    a11yOpenExerciseColumnHeader: {
        id: "dashboard-a11y-linkToOpenExercise",
        defaultMessage: "Link to open the exercise in a new tab",
    },
});
