import React from "react"
import cn from 'classnames'
import { AriaPopoverProps, DismissButton, Overlay, usePopover } from "@react-aria/overlays";
import { OverlayTriggerState } from "@react-stately/overlays";

import "./Popover.scss";

interface PopoverProps {
  children: React.ReactNode
  state: OverlayTriggerState
  hideArrow?: boolean
}

function Popover({ children, state, offset = 8, hideArrow = false, ...props }: PopoverProps & Omit<AriaPopoverProps, "popoverRef">) {
  let popoverRef = React.useRef<HTMLDivElement>(null);
  let { popoverProps, underlayProps, arrowProps, placement } = usePopover({
    ...props,
    offset,
    popoverRef
  }, state);

  return (
    <Overlay>
      <div {...underlayProps} className="ds-popover__underlay" />
      <div
        {...popoverProps}
        ref={popoverRef}
        className={cn("ds-popover")}
      >
        {!hideArrow &&
          <svg {...arrowProps} className="ds-popover__arrow" data-placement={placement}>
            <path d="M0 0 L9 9 L18 0 Z" />
          </svg>
        }
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
}

export default Popover