import React from "react";
import Button from "../../Button/Button";
import { ErrorContent } from "../../../interfaces/Error";

import "./Popup.scss";

interface PopupProps {
    content: Omit<ErrorContent, "title">;
    action?: () => void;
    close?: () => void;
}

const Popup = ({ content, action, close }: PopupProps) => {
    const callToAction = () => {
        action && action();
        close && close();
    };

    if (!content.text && !content.btn) return null;

    return (
        <div className="error-popup">
            {content.text && (
                <div className="error-popup__content">{content.text}</div>
            )}
            <div className="alert-buttons">
                {content.btn && (
                    <>
                        {content.btn.cancel && (
                            <Button
                                type={"secondary"}
                                label="Cancel"
                                centeredLabel={true}
                                onClick={close}
                            />
                        )}
                        <Button
                            type={"primary"}
                            label={content!.btn.label}
                            centeredLabel={true}
                            onClick={callToAction}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default Popup;
