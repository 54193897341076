import { AIType } from "@evidenceb/ai-handler";
import { Module } from "@evidenceb/gameplay-interfaces";
import React, { useContext, useReducer } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import MaterialIcon from "../../../../components/MaterialIcon/MaterialIcon";
import VisuallyHidden from "../../../../components/VisuallyHidden/VisuallyHidden";
import { configStore } from "../../../../contexts/ConfigContext";
import { dataStore } from "../../../../contexts/DataContext";
import { Config } from "../../../../interfaces/Config";
import { Data } from "../../../../interfaces/Data";
import {
    PRLevelIds,
    PRLockStatus,
} from "../../../../interfaces/PedagogicalResourcesManagement";
import {
    getModuleById,
    getObjectiveById,
    initialTestFilter,
} from "../../../../utils/dataRetrieval";

import "./LockedResourceWarningBanner.scss";

interface Props {
    classroomId: string;
    prLockStatus: PRLockStatus;
    moduleId: string;
}

const LockedResourceWarningBanner = ({
    classroomId,
    prLockStatus,
    moduleId,
}: Props) => {
    const {
        config: { ai },
    } = useContext(configStore);
    const { data } = useContext(dataStore);
    const intl = useIntl();
    const [dismissed, dismiss] = useReducer(() => true, false);

    const module = getModuleById(moduleId, data);

    if (!isAnyResourceLocked(module, prLockStatus, data, ai[AIType.BanditManchot])) return null;

    const { warningTxt, linkTxt } = getText(prLockStatus, module, intl);

    return (
        <>
            {!dismissed && (
                <div className="locked-module-warning-banner">
                    <p>
                        <MaterialIcon className="warning-banner__info-icon">
                            info
                        </MaterialIcon>
                        <span>{warningTxt}</span>
                        <Link to={`/prm/${classroomId}`}>{linkTxt}</Link>
                    </p>
                    <button
                        onClick={() => {
                            dismiss();
                        }}
                    >
                        <MaterialIcon>close</MaterialIcon>
                        <VisuallyHidden>
                            <FormattedMessage
                                id="misc-modalClose"
                                defaultMessage="Close"
                            />
                        </VisuallyHidden>
                    </button>
                </div>
            )}
        </>
    );
};

const isAnyResourceLocked = (
    module: Module,
    prLockStatus: PRLockStatus,
    data: Data,
    aiConfig: Config["ai"][AIType.BanditManchot]
) => {
    return (
        prLockStatus[PRLevelIds.ModuleIds].includes(module.id) ||
        module.objectiveIds.filter(initialTestFilter(aiConfig.initialTest, "notInitialTest")).some((objId) => {
            const objective = getObjectiveById(objId, data);
            return (
                prLockStatus[PRLevelIds.ObjectiveIds].includes(objId) ||
                objective.activityIds.some(
                    (actId) =>
                        prLockStatus[PRLevelIds.ActivityIds].includes(
                            actId
                        )
                )
            );
        })
    );
};

const getText = (
    prlockStatus: PRLockStatus,
    module: Module,
    intl: IntlShape
): { warningTxt: string | undefined; linkTxt: string | undefined } => {
    if (prlockStatus[PRLevelIds.ModuleIds].includes(module.id))
        return {
            warningTxt: intl.formatMessage({
                id: "dashboard-progressionOverview-lockedModuleWarning",
                defaultMessage:
                    "This module is disabled. All of its content is therefore not integrated into your students' personalized paths.",
            }),
            linkTxt: intl.formatMessage({
                id: "dashboard-progressionOverview-LockedModuleLinkToPRM",
                defaultMessage: "Go to the module management page",
            }),
        };

    return {
        warningTxt: intl.formatMessage({
            id: "dashboard-progressionOverview-lockedResourceWarning",
            defaultMessage:
                "This resource is disabled. All of its content is therefore not integrated into your students' personalized paths.",
        }),
        linkTxt: intl.formatMessage({
            id: "dashboard-progressionOverview-LockedResourceLinkToPRM",
            defaultMessage: "Go to the resource management space",
        }),
    };
};

export default LockedResourceWarningBanner;
