import React from "react";
import { EMOTIONS } from "../useEmotionalReport";

import "./EmotionalReportWidget.scss";
import { Emotion } from "../../../../../interfaces/EmotionalReport";
import { FormattedMessage } from "react-intl";

// See reference for html & accessibility here: https://stackoverflow.com/questions/45449106/how-to-make-a-ul-list-of-buttons-accessible/45491521

// Do not use Neutral emotion in widget
const _EMOTIONS: {
    [key: string]: {
        i18nKey: "frustrated" | "bored" | "confused" | "neutral";
        Component: () => JSX.Element;
    };
} = {};
Object.keys(EMOTIONS).forEach((emotion) => {
    if (emotion === Emotion.Neutral) return;
    _EMOTIONS[emotion] = EMOTIONS[emotion as Emotion];
});

const EmotionalReportWidget: React.FC<{
    clickedButtons: Emotion[] | null;
    recordReport: (clickedButton: Emotion, recordType: "SELF-REPORT" | "FORCED") => void;
    hideSpeechBubbles?: boolean;
}> = ({ clickedButtons, recordReport, hideSpeechBubbles = false }) => {
    return (
        <ul
            className="emotional-report emotional-report-widget__container"
            role="listbox"
        >
            {(Object.keys(_EMOTIONS) as Emotion[]).map((emotion, index) => (
                <li
                    className={`${
                        clickedButtons?.includes(emotion)
                            ? "emotional-report-widget__button--clicked"
                            : ""
                    }`}
                >
                    {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
                    <button
                        onClick={() => recordReport(emotion, "SELF-REPORT")}
                        aria-setsize={Object.keys(_EMOTIONS).length}
                        aria-posinset={index + 1}
                        className={`${
                            hideSpeechBubbles
                                ? "button--hide-speech-bubble"
                                : ""
                        }`}
                        disabled={clickedButtons?.includes(emotion)}
                    >
                        <p><FormattedMessage id={`exerciseShell.emotionalReport${_EMOTIONS[emotion].i18nKey}`} /></p>
                        <div className="bot__container">
                            {_EMOTIONS[emotion].Component()}
                        </div>
                    </button>
                </li>
            ))}
        </ul>
    );
};

export default EmotionalReportWidget;
