import { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { union } from 'lodash'
import { UserType } from "../interfaces/User";
import { sessionStore } from "../contexts/SessionContext";
import { dataStore } from "../contexts/DataContext";
import { configStore } from "../contexts/ConfigContext";
import useAthenaAPIClient from "./useAthenaAPIClient";
import { chooseStudentClassroom } from "../utils/user";
import {
    completeLockStatus,
    isBMWithLockStatus,
} from "../utils/pedagogical-ressources";
import { getAIClass } from "../utils/ai";
import { UseAIStatus } from "./useAI";
import { Activity, HistoryItem, Module, Objective, PerModule, VisibilityStatus } from "@evidenceb/gameplay-interfaces";
import {
    AILoadingInfo,
    AIType,
    AIWhisperer,
    ErroredAI,
} from "@evidenceb/ai-handler";
import {
    bmStatementsFilter,
    byModuleStatementsFilter,
    chronologicalStatementsSort,
    toHistoryStatementsMap,
} from "../utils/statements";
import useGetStatements from "./useGetStatements";
import { checkPRLockStatusIntegrity } from "../utils/prm";
import { PRLockStatus } from "../interfaces/PedagogicalResourcesManagement";
import {
    activityInModuleFilter,
    getModuleById,
    getObjectiveById,
    objectiveInModuleFilter,
} from "../utils/dataRetrieval";
import { Data } from "../interfaces/Data";

/**
 * A hook to use the bandit manchot that makes sure it is properly initialized
 * @param {Object} [opts]
 * @param {boolean} [opts.holdLoad=false]
 */
const useBanditManchot = (opts?: {
    holdLoad?: boolean;
}): UseAIStatus<PerModule<AILoadingInfo>> => {
    const {
        session: {
            banditManchot,
            statements,
            prLockStatus,
            userId,
            userType,
            specimen,
        },
        setSession,
    } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);

    const athenaAPIClient = useAthenaAPIClient();
    const statementsStatus = useGetStatements(opts);

    const [status, setStatus] = useState<UseAIStatus["status"]>(
        userType === UserType.Teacher ? "error" : "loading"
    );
    const [internalHoldLoad, setInternalHoldLoad] = useState<boolean>(
        opts?.holdLoad ?? false
    );

    // Mark as error if statements error
    useEffect(() => {
        if (statementsStatus.status === "error") setStatus("error");
    }, [statementsStatus]);

    // Get lock status
    useEffect(() => {
        if (internalHoldLoad || prLockStatus || status === "error") return;

        setSession((curr) => {
            return {
                ...curr,
                prLockStatus: {
                    status: "pending",
                },
            };
        });

        (async () => {
            try {
                const allClassroomsLockStatus =
                    await athenaAPIClient.getResourcesLockStatus(
                        userId,
                        UserType.Student
                    );
                const studentClassroomId = chooseStudentClassroom(
                    Object.keys(allClassroomsLockStatus)
                );

                setSession((curr) => {
                    return {
                        ...curr,
                        prLockStatus: {
                            status: "settled",
                            value: completeLockStatus(
                                checkPRLockStatusIntegrity(
                                    allClassroomsLockStatus[studentClassroomId],
                                    data,
                                    config.ai
                                )
                            ),
                        },
                    };
                });
            } catch (err) {
                Sentry.captureException(err);
                setStatus("error");
                setSession((curr) => {
                    return {
                        ...curr,
                        prLockStatus: undefined,
                    };
                });
            }
        })();
    }, [
        prLockStatus,
        athenaAPIClient,
        setSession,
        status,
        userId,
        internalHoldLoad,
        config.ai,
        data,
    ]);

    // Init Bandit Manchot
    useEffect(() => {
        if (
            internalHoldLoad ||
            banditManchot ||
            !statements ||
            !prLockStatus ||
            prLockStatus.status !== "settled" ||
            status === "error"
        )
            return;

        (async () => {
            try {
                const AIClass = await getAIClass(
                    AIType.BanditManchot,
                    config.ai[AIType.BanditManchot].id
                );
                const freshBM = data.modules.reduce((bmCluster, module) => {
                    try {
                        const bm = AIWhisperer.initAI(
                            AIClass,
                            config.ai.BANDIT_MANCHOT,
                            data,
                            module.id
                        );
                        return {
                            ...bmCluster,
                            [module.id]: bm,
                        };
                    } catch (err) {
                        Sentry.captureException(err);
                        return {
                            ...bmCluster,
                            [module.id]: { error: true } as ErroredAI,
                        };
                    }
                }, {} as PerModule<AILoadingInfo>);
                const history = data.modules.reduce((history, mod) => {
                    return {
                        ...history,
                        [mod.id]: statements
                            .filter(bmStatementsFilter)
                            .filter(byModuleStatementsFilter(mod.id))
                            .sort(chronologicalStatementsSort)
                            .map(toHistoryStatementsMap),
                    };
                }, {} as PerModule<HistoryItem[]>);
                const loadedBM = AIWhisperer.loadAllModulesHistory(
                    freshBM,
                    history,
                    (err: any) => {
                        Sentry.captureException(err);
                    }
                );
                let withLockStatusBM: PerModule<AILoadingInfo>;
                if (isBMWithLockStatus(loadedBM)) {
                    const lockStatus = prLockStatus.value

                    if (specimen) {
                        const specimenLockStatus = getUnavailableRessourceIds(data);
                        let key: keyof PRLockStatus
                        for (key in lockStatus)
                            lockStatus[key] = union(lockStatus[key], specimenLockStatus[key])
                    }
                    withLockStatusBM = deactivateResources(
                        loadedBM,
                        data,
                        lockStatus
                    );
                }
                setSession((curr) => {
                    return {
                        ...curr,
                        banditManchot: withLockStatusBM ?? loadedBM,
                    };
                });
            } catch (err) {
                Sentry.captureException(err);
                setStatus("error");
            }
        })();
    }, [
        statements,
        prLockStatus,
        banditManchot,
        config.ai,
        data,
        setSession,
        status,
        internalHoldLoad,
        specimen
    ]);

    // Mark as success
    useEffect(() => {
        if (!banditManchot || status === "error") return;

        setStatus("success");
    }, [banditManchot, status]);

    if (status === "error") return { status };
    if (status === "loading")
        return {
            status,
            forceLoad: opts?.holdLoad
                ? () => {
                    setInternalHoldLoad(false);
                    if (statementsStatus.forceLoad)
                        statementsStatus.forceLoad();
                }
                : undefined,
        };
    return { status, aiLoadingInfo: banditManchot! };
};

/**
 * 
 * Get all unavailable MOA as a `PRLockStatus`
 */
const getUnavailableRessourceIds = (
    data: Data,
): PRLockStatus => {
    const filterUnavailable = (e: Module | Objective | Activity) => e.visibilityStatus === VisibilityStatus.Unavailable
    const getOnlyIds = (e: Module | Objective | Activity) => e.id

    const specimenLockStatus = {
        moduleIds: data.modules.filter(filterUnavailable).map(getOnlyIds),
        objectiveIds: data.objectives.filter(filterUnavailable).map(getOnlyIds),
        activityIds: data.activities.filter(filterUnavailable).map(getOnlyIds),
    }

    // Include module and objective dependencies
    for (let module of specimenLockStatus.moduleIds)
        specimenLockStatus.objectiveIds =  union(specimenLockStatus.objectiveIds, getModuleById(module, data).objectiveIds)
    for (let objective of specimenLockStatus.objectiveIds)
        specimenLockStatus.activityIds = union(specimenLockStatus.activityIds, getObjectiveById(objective, data).activityIds)
    return specimenLockStatus
}

/**
 * For the Bandit Manchot
 * Mark resources as locked
 */
export const deactivateResources = (
    bmCluster: PerModule<AILoadingInfo>,
    data: Data,
    prLockStatus: PRLockStatus
): PerModule<AILoadingInfo> => {
    const withLockedStatusBMCluster: PerModule<AILoadingInfo> = {};
    data.modules.forEach((module) => {
        const bm = bmCluster[module.id];
        if (bm.error) {
            withLockedStatusBMCluster[module.id] = bm;
            return;
        }

        const resourcesToLock: Omit<PRLockStatus, "moduleIds"> = {
            objectiveIds: prLockStatus.objectiveIds.filter(
                objectiveInModuleFilter(module.id, data)
            ),
            activityIds: prLockStatus.activityIds.filter(
                activityInModuleFilter(module.id, data)
            ),
        };

        bm.instance.lock!([
            ...resourcesToLock.objectiveIds,
            ...resourcesToLock.activityIds,
        ]);

        withLockedStatusBMCluster[module.id] = {
            ...bm,
            lockStatusAdded: true,
        };
    });
    return withLockedStatusBMCluster;
};

export default useBanditManchot;
