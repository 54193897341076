import React, { Dispatch, SetStateAction, useContext } from "react";
import styled from "styled-components";
import { AIType } from "@evidenceb/ai-handler";
import {
    ModuleDashboard,
    Objective,
    TestResult,
} from "../../../../../interfaces/Dashboard";
import {
    PRLevelIds,
    PRLockStatus,
} from "../../../../../interfaces/PedagogicalResourcesManagement";
import { dataStore } from "../../../../../contexts/DataContext";
import {
    getModuleById,
    getResourceIndex,
} from "../../../../../utils/dataRetrieval";
import ObjectiveProgression from "../../../../../components/ProgressionTableCell/ObjectiveProgression";
import TestProgression from "../../../../../components/ProgressionTableCell/TestProgression";
import COLOR_SCHEME from "../../../ProgressionOverview/ProgressionTable/COLOR_SCHEME";
import { ProgressionData } from "../../../StudentProgression/StudentProgression";
import { ObjectivesFilter } from "../Filter/Filter";

interface Props {
    module: ModuleDashboard;
    classroomId: string;
    studentId: string;
    setProgression: Dispatch<SetStateAction<ProgressionData | undefined>>;
    prLockStatus: PRLockStatus | undefined;
    filter?: ObjectivesFilter;
}

interface TagContainerProps {
    moduleId: string | null;
}

const ObjectivesList = ({
    module,
    classroomId,
    studentId,
    prLockStatus,
    filter,
}: Props) => {
    const { data } = useContext(dataStore);

    const objectivesFilter = (objective: Objective) => {
        if (objective.status === filter) return objective;
        if (objective.alert === filter) return objective;
        if (!filter) return objective;
    };

    const testResult = getTestResult(
        module.students[studentId].testsResults,
        filter
    );

    return (
        <TagOverflowContainer
            className="test"
            /* @ts-ignore TS doesn't allow to set variables but we can */
            style={
                COLOR_SCHEME[
                    prLockStatus &&
                    prLockStatus[PRLevelIds.ModuleIds].includes(module.id)
                        ? "locked"
                        : "unlocked"
                ]
            }
        >
            <TagContainer
                className={filter ? "isFiltered" : ""}
                moduleId={module.id}
                style={{
                    width: module.students[studentId]?.objectives.length * 119,
                }}
            >
                {testResult && (
                    <TestProgression
                        student={module.students[studentId]}
                        module={getModuleById(module.id, data)}
                        prLockStatus={prLockStatus}
                        showTitle
                    />
                )}

                {module.students[studentId]?.objectives
                    .filter(objectivesFilter)
                    .map((objective, i) => (
                        <ObjectiveProgression
                            key={`objective-progression-${i}`}
                            objective={objective}
                            student={module.students[studentId]}
                            prLockStatus={prLockStatus}
                            classroomId={classroomId}
                            type="dashed"
                            showObjectiveTitle={true}
                            objectiveIndex={
                                getResourceIndex(
                                    objective.id,
                                    module.objectives
                                ) + 1
                            }
                        />
                    ))}
            </TagContainer>
        </TagOverflowContainer>
    );
};

const getTestResult = (
    tests: TestResult[] | undefined,
    filter: ObjectivesFilter | undefined
): TestResult | undefined => {
    const testResult = tests?.find(
        (test) => test.type === AIType.BanditManchot
    );
    if (testResult && filter && testResult.status !== filter) return undefined;
    return testResult;
};

const TagOverflowContainer = styled.div`
    overflow: hidden;
    width: 100%;
    position: relative;
`;

const TagContainer = styled.div<TagContainerProps>`
    display: flex;
    width: 100%;
    gap: 4px;
    &.isFiltered {
        .objective-progression {
            width: 116px;
        }
    }
`;

export default ObjectivesList;
