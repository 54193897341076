import React, { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import { useIntl } from "react-intl";
import cn from "classnames";
import { UserType } from "../../../../interfaces/User";
import { dataStore } from "../../../../contexts/DataContext";
import { configStore } from "../../../../contexts/ConfigContext";
import { sessionStore } from "../../../../contexts/SessionContext";
import { getExerciseById } from "../../../../utils/dataRetrieval";
import StatementsError from "../../PlaylistPlayer/StatementsError/StatementsError";
import ExerciseResult, { GoToNext } from "./ExerciseResult/ExerciseResult";
import Welcome from "./Welcome";
import { CurrentExercise } from "./CurrentExercise";
import ChatBubble from "./ChatBubble/ChatBubble";
import useEmotionalReport from "../../PlaylistPlayer/EmotionalReport/useEmotionalReport";
import EmotionalReport from "../../PlaylistPlayer/EmotionalReport/EmotionalReport";
import htmlHandler from "../../../../utils/html-handler";
import {
    Playlist,
    PlaylistExecutionStage,
    Shell,
} from "../../../../interfaces/Player";

import "./ChatbotShell.scss";
import { ActivityShell } from "@evidenceb/gameplay-interfaces";

interface Props extends Shell {
    playlist: Playlist;
    clearHistory?: () => void;
}

const ChatbotShell = ({
    playlist,
    onExerciseResult,
    onGoToNextExercise,
    clearHistory,
    opts,
    className,
}: Props) => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const {
        session: { userType },
    } = useContext(sessionStore);
    const emotionalReportController = useEmotionalReport();
    const [started, setStarted] = useState<boolean>(
        opts?.skipIntro ? true : false
    );
    const [playlistHasInitialTest, setPlaylistHasInitialTest] =
        useState<boolean>();
    const [showWelcome, setShowWelcome] = useState<boolean>(
        opts?.skipIntro ? false : true
    );
    const intl = useIntl();

    useEffect(() => {
        if (playlist.isInitialTest) setPlaylistHasInitialTest(true);
    }, [playlist.isInitialTest]);

    return (
        <div className={cn("chatbot-layout", className)}>
            <div className="notifications__container">
                <StatementsError />
            </div>

            <div className="chatbot-core">
                {showWelcome && (
                    <Welcome
                        onStart={() => setStarted(true)}
                        started={started}
                        welcomeMessages={
                            playlistHasInitialTest
                                ? [
                                      {
                                          $html: intl.formatMessage({
                                              id: "html-chatbot-welcomeMessages",
                                              defaultMessage: "Welcome!",
                                          }),
                                      },
                                      {
                                          $html: intl.formatMessage({
                                              id: "html-chatbot-initialTestMessages-start",
                                              defaultMessage:
                                                  "First, answer a few questions without seeing the answer",
                                          }),
                                      },
                                  ]
                                : [
                                      {
                                          $html: intl.formatMessage({
                                              id: "html-chatbot-welcomeMessages",
                                              defaultMessage: "Welcome!",
                                          }),
                                      },
                                  ]
                        }
                        startButtonText={{
                            $html: intl.formatMessage({
                                id: "html-chatbot-start",
                                defaultMessage: "Start",
                            }),
                        }}
                    />
                )}
                <>
                    {playlist!.exerciseResults.map((exerciseResult, index) => {
                        const isLastExerciseResult =
                            index === playlist!.exerciseResults.length - 1;
                        return (
                            <ExerciseResult
                                exercise={getExerciseById(
                                    exerciseResult.exerciseId,
                                    data,
                                    true,
                                )}
                                exerciseResult={exerciseResult}
                                key={index}
                                onGoToNextExercise={
                                    isLastExerciseResult &&
                                    playlist?.currentExecutionStage ===
                                        PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
                                        ? () => {
                                              if (
                                                  config.features
                                                      .emotionalReport &&
                                                  userType ===
                                                      UserType.Student &&
                                                  playlist.comingNext !==
                                                      "retry" &&
                                                  !playlist.isInitialTest
                                              )
                                                  emotionalReportController.recordValidatedExercise();
                                              onGoToNextExercise(
                                                  playlist.comingNext
                                              );
                                          }
                                        : undefined
                                }
                                comingNext={playlist?.comingNext}
                            />
                        );
                    })}
                </>
                {started &&
                    playlist?.currentExercise &&
                    playlist?.currentExecutionStage ===
                    PlaylistExecutionStage.PlayingCurrentExercise &&
                    !emotionalReportController.emotionalReportModal.isOpen ? (
                    <>
                        <CurrentExercise
                            currentExercise={playlist.currentExercise}
                            key={`current-${playlist.currentExercise.id}-${playlist.currentTry}`}
                            onExerciseResult={onExerciseResult}
                            playlist={playlist}
                            shell={ActivityShell.Chatbot}
                        />
                        <div className="current-exercise__background-shader"></div>
                    </>
                ) : null}
                {started &&
                    playlist?.currentExecutionStage ===
                    PlaylistExecutionStage.ShowingEndOfInitialTestMessage && (
                        <>
                            <ChatBubble
                                direction={"left"}
                                botAvatar="visible"
                                color="var(--chatbot-color-bot-bubble)"
                            >
                                {parse(
                                    intl.formatMessage(
                                        {
                                            id: "html-chatbot-initialTestMessages-end",
                                            defaultMessage:
                                                "Congratulations! You've completed the first series of exercises. Your personalized path can now begin.",
                                        },
                                        htmlHandler
                                    )
                                )}
                            </ChatBubble>
                            <GoToNext
                                onGoToNextExercise={() => {
                                    if (
                                        config.features.emotionalReport &&
                                        userType === "STUDENT" &&
                                        playlist.comingNext !== "retry" &&
                                        !playlist.isInitialTest
                                    )
                                        emotionalReportController.recordValidatedExercise();
                                    if (clearHistory) clearHistory();
                                    setShowWelcome(false);
                                    setPlaylistHasInitialTest(false);
                                    onGoToNextExercise(playlist.comingNext);
                                }}
                                comingNext={playlist.comingNext}
                            />
                        </>
                    )}
            </div>

            {config.features.emotionalReport &&
                userType === UserType.Student &&
                !playlist.isInitialTest && (
                    <EmotionalReport {...emotionalReportController} />
                )}
        </div>
    );
};
export default ChatbotShell;
