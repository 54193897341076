import React, { useState } from "react"
import Button from "../../../design-system-components/Button/Button"

import "./SpecimenHelper.scss"
import Icon from "../../../design-system-components/Icon/Icon"
import { useIntl } from "react-intl"

interface SpecimenHelperProps {
    dialogTitle: string
    dialogDescription: React.ReactNode
}

const SpecimenHelper = ({
    dialogTitle,
    dialogDescription,
}: SpecimenHelperProps) => {
    const intl = useIntl()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="specimen-helper">
            <div className="specimen-helper__wrapper">
                {!isOpen &&
                    <Button
                        label={intl.formatMessage({ id: "specimen-dashboard-helper-button-label", defaultMessage: "Understand the dashboard", })}
                        icon={{
                            path: "bulb"
                        }}
                        appearance="pill"
                        onClick={() => setIsOpen(true)}
                    />
                }
                {isOpen &&
                    //  Maybe could use Dialog when dialog design system component will be done. (cf. node ugprade)
                    <div className="specimen-helper__dialog">
                        <div className="specimen-helper__dialog__header">
                            <h3>{dialogTitle}</h3>
                            <Icon size="large" path="close" onClick={() => setIsOpen(false)} />
                        </div>
                        <p className="specimen-helper__dialog__description">
                            {dialogDescription}
                        </p>
                    </div>
                }
            </div>
        </div>
    )
}

export default SpecimenHelper