import React, { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { dataStore } from "../../../../contexts/DataContext";
import { DismissableMessages } from "../../../../contexts/SessionContext";
import { getObjectiveById } from "../../../../utils/dataRetrieval";
import { ConfirmationModalProps } from "../usePRConfirmation";
import ControlledDialog from "../../../../design-system-components/ControlledDialog/ControlledDialog";
import MessageDismiss from "../../../../components/MessageDismiss/MessageDismiss";
import { PRLevel } from "../../../../interfaces/PedagogicalResourcesManagement";
import htmlHandler from "../../../../utils/html-handler";

const UnlockObjectiveAndAncestorObjectivesConfirmationModal = ({
    confirm,
    confirmation,
}: ConfirmationModalProps) => {
    const intl = useIntl();
    const { data } = useContext(dataStore);

    const objective = useMemo(
        () => getObjectiveById(confirmation.originId, data),
        [confirmation.originId, data]
    );

    return (
        <ControlledDialog
            title={intl.formatMessage(
                {
                    id: "prm-unlockObjectiveAndDependencies",
                    defaultMessage:
                        "Activate objective {title} and its dependencies",
                },
                { title: objective.title.short?.toLowerCase() }
            )}
            description={{
                $html: intl.formatMessage(
                    {
                        id: "html-prm-unlockObjectiveAndAncestors-description",
                        defaultMessage:
                            "Objective {title} has {numberOfDependencies} other objectives as its prerequisites. To ensure consistency, <b>activating it will automatically activate elements it requires.</b>",
                    },
                    {
                        ...htmlHandler,
                        title: objective.title.short!.toLowerCase(),
                        numberOfDependencies:
                            "" +
                            (confirmation.itemsToToggle.filter(
                                (item) => item.level === PRLevel.Objectives
                            ).length -
                                1),
                    }
                ),
            }}
            primaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-listItem-unlock",
                    defaultMessage: "Activate",
                }),
                fn: () => {
                    confirm(true);
                },
            }}
            secondaryButton={{
                label: intl.formatMessage({
                    id: "prm-manager-cancel",
                    defaultMessage: "Cancel",
                }),
                fn: () => {
                    confirm(false);
                },
            }}
        >
            <MessageDismiss
                sessionMessageKey={
                    DismissableMessages.prmObjectiveUnlockingConfirmationModal
                }
            />
        </ControlledDialog>
    );
};

export default UnlockObjectiveAndAncestorObjectivesConfirmationModal;
