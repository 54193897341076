import { HierarchyIds, HistoryItem } from "@evidenceb/gameplay-interfaces";
import { Statement, Activity } from "@xapi/xapi";
import {
    ADAPTIVE_TEST_CATEGORY,
    VERBS,
    XAPI_REGISTRY,
} from "./statement-builder";

type StatementsFilter = (
    value: Statement,
    index: number,
    array: Statement[]
) => boolean;

type StatementsMap<T> = (
    value: Statement,
    index: number,
    array: Statement[]
) => T;

type StatementsSort = (a: Statement, b: Statement) => number;

type StatementsFind = (
    value: Statement,
    index: number,
    array: Statement[]
) => Statement | undefined;

/**
 * @param id id of the module
 * @return a filter function that filters statements from the modile
 */
export const byModuleStatementsFilter = (id: string): StatementsFilter => {
    return (value: Statement) => {
        return isStatementInModule(value, id);
    };
};

export const isStatementInModule = (
    statement: Statement,
    id: string | undefined
) => {
    let moduleId: string | undefined = undefined;
    try {
        const { moduleId: _moduleId } = contextToHierarchyId(
            statement.context!
        );
        moduleId = _moduleId;
    } catch {}
    if (!moduleId) {
        try {
            moduleId = objectToModuleId(statement.object! as Activity);
        } catch {
            throw new Error(
                "Cannot find module Id of statement " + statement.id
            );
        }
    }
    return moduleId === id;
};

/**
 * Filter function to filter statements that are part of the Bandit Manchot
 * history
 * @param value
 * @param index
 * @param array
 * @return boolean
 */
export const bmStatementsFilter: StatementsFilter = (value) => {
    if (value.verb.id !== VERBS.failed.id && value.verb.id !== VERBS.passed.id)
        return false;
    if (
        value.context?.contextActivities?.category?.some(
            (category) => category.id === ADAPTIVE_TEST_CATEGORY
        )
    )
        return false;
    return true;
};

/**
 * @param value
 * @returns an history item with only score and exerciseId
 */
export const toHistoryStatementsMap: StatementsMap<HistoryItem> = (value) => {
    return {
        exerciseId: objectToExerciseId(value.object as Activity),
        score: value.result!.score!.raw!,
        // No need to add duration and timestamp as they are only used
        // for the full diagnosis
    };
};

/**
 * Sort function to sort the statements in chronological order
 */
export const chronologicalStatementsSort: StatementsSort = (a, b) => {
    if (!a.timestamp) return -1;
    if (!b.timestamp) return 1;
    return Date.parse(a.timestamp) - Date.parse(b.timestamp);
};

/**
 *
 */
export const adaptiveTestStatementsFilter: StatementsFilter = (value) => {
    if (value.verb.id !== VERBS.failed.id && value.verb.id !== VERBS.passed.id)
        return false;
    if (
        !value.context?.contextActivities?.category ||
        value.context.contextActivities.category.every(
            (category) => category.id !== ADAPTIVE_TEST_CATEGORY
        )
    )
        return false;
    return true;
};

/**
 * @param number reference number of the targeted adaptive test
 * @returns a filter function that filters the adaptive test statements
 * pertaining to a specific test
 */
export const byAdaptiveTestNumberStatementsFilter = (
    number: number
): StatementsFilter => {
    return (value: Statement) => isStatementFromTest(value, number);
};

export const isStatementFromTest = (
    statement: Statement,
    testNumber: Number
) => {
    if (!statement.context?.extensions) return false;
    return (
        statement.context?.extensions[
            `${XAPI_REGISTRY}/extensions/adaptive-test`
        ] === testNumber
    );
};

export const adaptiveTestDiagsStatementsFilter: StatementsFilter = (
    statement
) => {
    return isStatementDiag(statement);
};

export const isStatementDiag = (statement: Statement) => {
    return (
        statement.verb.id === VERBS.completed.id &&
        (statement.context?.contextActivities?.category?.some(
            (category) => category.id === ADAPTIVE_TEST_CATEGORY
        ) ??
            false)
    );
};

export const activityVideoTutorialWatchedStatementsFilter: StatementsFilter = (
    statement
) => {
    return (
        statement.verb.id === VERBS.viewed.id &&
        "objectType" in statement.object &&
        statement.object.objectType === "Activity" &&
        (statement.object as Activity).definition?.type ===
            `${XAPI_REGISTRY}/activities/activity-video-tutorial`
    );
};

export const byAdaptiveTestNumberStatementsFind =
    (testNumber: number): StatementsFind =>
    (statement) => {
        if (!statement.context?.extensions) return undefined;
        return statement.context?.extensions[
            `${XAPI_REGISTRY}/extensions/adaptive-test`
        ] === testNumber
            ? statement
            : undefined;
    };

/*****************************************************************************/

/**
 * @param statements list of chronologically ordered statements
 * @returns the number of the last adaptive test statement or undefined if there is no statement
 */
export const getLastAdaptiveTestNumber = (
    statements: Statement[]
): number | undefined => {
    return +statements[statements.length - 1]?.context!.extensions![
        `${XAPI_REGISTRY}/extensions/adaptive-test`
    ];
};

/*****************************************************************************/

const contextToHierarchyId = (
    context: Required<Statement>["context"]
): Omit<HierarchyIds, "exerciseId" | "isInitialTest"> => {
    const re = new RegExp(
        `${XAPI_REGISTRY}/module/(.*?)/objective/(.*?)/activity/(.*)`,
        ""
    );
    const result = re[Symbol.match](context.contextActivities!.parent![0].id);
    return {
        moduleId: result![1],
        objectiveId: result![2],
        activityId: result![3],
    };
};

const objectToExerciseId = (object: Activity): string => {
    const re = new RegExp(`${XAPI_REGISTRY}/exercise/(.*)`);
    const result = re[Symbol.match](object.id);
    return result![1];
};

const objectToModuleId = (object: Activity): string => {
    const re = new RegExp(`${XAPI_REGISTRY}/module/(.*)`);
    const result = re[Symbol.match](object.id);
    return result![1];
};
