import React from "react";
import { FormattedMessage } from "react-intl";
import Icon from "../../../../../design-system-components/Icon/Icon";

const TableLegend = () => {
    return (
        <ul className="table-legend">
            <li className="box validated">
                <FormattedMessage id="dashboard-progressionOverview-legend-validObjective" defaultMessage="Completed objective" />
            </li>
            <li className="box in-progress">
                <FormattedMessage id="dashboard-progressionOverview-legend-currentObjective" defaultMessage="Current objective" />
            </li>
            <li className="box objective-not-started">
                <FormattedMessage id="dashboard-progressionOverview-legend-objectiveNotStarted" defaultMessage="Objective not started" />
            </li>
            <li className="circle has-improved">
                <Icon path="alert_sup" size="small" />
                <FormattedMessage id="dashboard-progressionOverview-legend-progressionAcceleration" defaultMessage="Overcome difficulty" />
            </li>
            <li className="circle has-difficulties">
                <Icon path="alert_inf" size="small" />
                <FormattedMessage id="dashboard-progressionOverview-legend-progressionRegression" defaultMessage="Difficulty encountered" />
            </li>
        </ul>
    );
};

export default TableLegend;
