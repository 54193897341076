import { Exercise, UntypedFeedback } from "@evidenceb/athena-common";
import {
    Activity,
    HierarchyIds,
    Module,
    Objective,
    PartialExerciseResult,
    PlayerEventEmitter,
} from "@evidenceb/gameplay-interfaces";
import { UseMinitutorReturn } from "../pages/PlayerBuilder/PlaylistPlayer/useMinitutor";

export interface PlayerOpts {
    /**
     * Used to tell the chatbot shell to skip the welcome message
     * @default false
     */
    skipIntro: boolean;
    /**
     * Ask the user a confirmation before leaving the player
     * @default false
     */
    confirmPlayerExit: boolean;
}

/**
 * PLAYLIST
 */

/**
 * Describes the sequence of exercises available in the player, and information
 * about these exercises and the progression
 */
export interface Playlist {
    exercises: Exercise[];
    exerciseResults: ExerciseResult<any>[];

    module: Module;
    objective?: Objective;
    activity?: Activity;
    currentExercise?: Exercise;
    /**
     * Whether the current exercise is part of the initial test (this property
     * is used in the context of the Bandit Manchot playlist manager)
     */
    isInitialTest?: boolean;
    currentExecutionStage: PlaylistExecutionStage;
    currentTry?: number;
    currentExerciseResult?: ExerciseResult<any>;

    /**
     * Describes what comes when the user wants to move forward in the playlist
     * after an exercise
     */
    comingNext?: ComingNext;
    /**
     * % of progression in the module
     */
    progression?: number;

    /** Whether the history of the user was empty when the ai was initialized */
    initiallyFresh: boolean;
}

export type ComingNext =
    | "nextExercise"
    | "nextActivity"
    | "endOfPlaylist"
    | "retry";

export enum PlaylistExecutionStage {
    PlayingCurrentExercise = "PLAYING_CURRENT_EXERCISE",
    ShowingCurrentExerciseResultFeedback = "SHOWING_CURRENT_EXERCISE_RESULT_FEEDBACK",
    ShowingEndOfInitialTestMessage = "SHOWING_END_OF_INITIAL_TEST_MESSAGE",
}

export interface ExerciseResult<Answer = any>
    extends PartialExerciseResult<Answer> {
    exerciseId: string;
    activityId: string;
    try: number;
    feedback?: UntypedFeedback;
    feedbackExplicatif?: UntypedFeedback;
    feedbackType?: "correct" | "incorrect" | "moderate";
}

/**
 * PLAYLIST MANAGER
 */

/**
 * The playlist manager handles the sequence of exercises and the possible
 * actions a user can take on that sequence
 */
export type PlaylistManager =
    | InitializedPlaylistManager
    | UninitializedPlaylistManager;

export interface InitializedPlaylistManager {
    initialized: true;

    playlist: Playlist;

    recordCurrentExerciseResult: (
        exerciseResult: PartialExerciseResult<any>,
        /** @default false */
        autoGoToNextExercise?: boolean
    ) => void;
    goToNextExercise: (comingNext: ComingNext | undefined) => void;
    goToExercise: (hierarchy: Partial<HierarchyIds>) => void;
    endPlaylist?: () => void;
    /**
     * Function used to clear the history of exercises in the playlist but not
     * from the AI. It is used in partiular in the context of the Chatbot Player
     * to hide the past exercises from the flow of messages
     */
    clearHistory?: () => void;
    /**
     * Whether the alert warning an idle user should be shown
     */
    showIdleModal?: boolean;
    /**
     * Function to resume playing after being idle
     */
    continueAfterIdle?: () => void;
}

export interface UninitializedPlaylistManager {
    initialized: false;
    error?: unknown;
}

export interface AIPlaylistManagerOpts {
    /**
     * Whether or not the AI should be used to calculate the progression of the
     * user in the module as a %
     * @default false
     */
    progression: boolean;
    /**
     * Whether or not the AI allows retries
     * @default false
     */
    forbidRetries: boolean;
    /**
     * Function to apply to the score before sending the statement
     */
    scoreOverride: (result: PartialExerciseResult, tryNumber: number) => number;
}

/**
 * SHELL
 */

export interface Shell<Answer = any> {
    onGoToNextExercise: (comingNext: ComingNext | undefined) => void;
    onExerciseResult: (
        exerciseResult: PartialExerciseResult<Answer>,
        /** @default false */
        autoGoToNextExercise?: boolean
    ) => void;
    playlist: Playlist;
    playerEventEmitter: PlayerEventEmitter;
    minitutor: UseMinitutorReturn;
    opts?: Partial<PlayerOpts>;
    className?: string;
}
