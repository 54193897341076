import { defineMessages } from "react-intl";

/**
 * Common i18n messages for the app
 */
export const commonMessages = defineMessages({
    back: {
        id: "back",
        defaultMessage: "Back",
    },
    colon: {
        id: "misc-colon",
        defaultMessage: ":",
    },
    close: {
        id: "misc-modalClose",
        defaultMessage: "Close"
    },
    email: {
        id: "email",
        defaultMessage: "Email",
    },
    error: {
        id: "error",
        defaultMessage: "Error",
    },
    genericErrorMessage: {
        id: "genericErrorMessage",
        defaultMessage: "An error occurred. Please try again later.",
    },
    password: {
        id: "password",
        defaultMessage: "Password",
    },
    "#": {
        id: "#",
        defaultMessage: "#",
    },
    correct: {
        id: "correct",
        defaultMessage: "correct"
    },
    incorrect: {
        id: "incorrect",
        defaultMessage: "incorrect"
    },

    exercise: {
        id: "hierarchy-exercise-full",
        defaultMessage: "Exercise",
    },
    exercises: {
        id: "dashboard-studentProgression-exercises",
        defaultMessage: "Exercises",
    },
    activityShort: { id: "hierarchy-activity-short", defaultMessage: "act" },
    objectiveShort: {
        id: "hierarchy-objective-short",
        defaultMessage: "obj",
    },
    objectives: {
        id: "dashboard-common-objectives",
        defaultMessage: "Objectives"
    },
    goHome: {
        id: "goBackHome",
        defaultMessage: "Go back to the homepage",
    }
});
