import React from "react";
import { IntlShape, useIntl } from "react-intl";
import { MinimalDataItem } from "@evidenceb/gameplay-interfaces";
import { LevelData } from "../../../../../interfaces/PedagogicalResourcesManagement";
import { UsePRConfirmationReturn } from "../../usePRConfirmation";
import { UsePRManagerReturn } from "../../usePRManager";
import Toggle from "../../../../../components/Toggle/Toggle";

interface Props {
    item: MinimalDataItem;
    isItemLocked: boolean;
    levelData: LevelData;
    attemptToggle: UsePRConfirmationReturn["attemptToggle"];
    prLockStatus: Required<UsePRManagerReturn>["currentPRLockStatus"];
}

const ToggleItemControls = ({
    attemptToggle,
    isItemLocked,
    item,
    prLockStatus,
    levelData,
}: Props) => {
    const intl = useIntl();

    return (
        <div className="list-item__toggle-container">
            <Toggle
                isSelected={!isItemLocked}
                onChange={() => {
                    attemptToggle(item.id, prLockStatus, levelData);
                }}
                accessibleLabel={getToggleAccessibleLabel(
                    isItemLocked,
                    intl,
                    item.title.long
                )}
                pinIcon={isItemLocked ? "close" : "checkmark"}
            />
            <span className="toggle__state">
                {getToggleText(isItemLocked, intl)}
            </span>
        </div>
    );
};

const getToggleAccessibleLabel = (
    isItemLocked: boolean,
    intl: IntlShape,
    title: string
): string => {
    if (isItemLocked)
        return intl.formatMessage(
            {
                id: "prm-manager-listItem-accessibleUnlock",
                defaultMessage: "Activate {title}",
            },
            { title }
        );
    return intl.formatMessage(
        {
            id: "prm-manager-listItem-accessibleLock",
            defaultMessage: "Deactivate {title}",
        },
        { title }
    );
};

export const getToggleText = (
    isItemLocked: boolean,
    intl: IntlShape
): string => {
    if (isItemLocked)
        return intl.formatMessage({
            id: "prm-manager-listItem-lock",
            defaultMessage: "Deactivate",
        });
    return intl.formatMessage({
        id: "prm-manager-listItem-unlock",
        defaultMessage: "Activate",
    });
};

export default ToggleItemControls;
