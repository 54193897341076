import React, { useContext } from "react";
import { configStore } from "../../../../contexts/ConfigContext";

import "./ExerciseIdentifier.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { commonMessages } from "../../../../utils/messages";
import useAssetsDetails from "../../../../hooks/useAssetsDetails";
import { Image } from "@evidenceb/athena-common";

const ExerciseIdentifier: React.FC<{
    moduleId: string;
    objectiveId: string;
    activityId: string;
    exerciseId: string;
}> = ({ moduleId, objectiveId, activityId, exerciseId }) => {
    const {
        config: { client_name, logos },
    } = useContext(configStore);
    const intl = useIntl();
    const assetsDetails = useAssetsDetails();

    return (
        <div className="exercise-identifier">
            <div className="container">
                <div className="logo">
                    <Image src={logos.header_client} alt={client_name} assetsDetails={assetsDetails} />
                </div>
                <div className="identifier">
                    <span><FormattedMessage id="hierarchy-module-short" defaultMessage="mod" /></span>.{moduleId}
                    <>
                        {" - "}
                        <span>{intl.formatMessage(commonMessages.objectiveShort)}</span>.
                        {objectiveId}
                    </>
                    <>
                        {" - "}
                        <span>{intl.formatMessage(commonMessages.activityShort)}</span>.
                        {activityId}
                    </>
                    <>
                        {" - "}
                        <span><FormattedMessage id="hierarchy-exercise-short" defaultMessage="ex" /></span>.
                        {exerciseId}
                    </>
                </div>
            </div>
        </div>
    );
};

export default ExerciseIdentifier;
