import { MinimalDataItem } from "@evidenceb/gameplay-interfaces";

/**
 * The level describes the PR items that are displayed in the manager at one
 * point (either all modules, or the objectives within a selected module, etc.)
 */
export interface LevelData {
    /** What level of hierarchy is currently displayed */
    level: PRLevel;
    /** 
     * The list of items from that level. isAncestor serves to mark activities
     * that are the prerequisite of a different objective
     */
    prPool: (MinimalDataItem & { isPrerequisiteOf?: string[] })[];
    /**
     * Data relating to the parent of the current level (eg the module the
     * objectives are from)
     */
    parentLevel?: ParentLevelData;
}
export interface ParentLevelData extends LevelData {
    /** Module id that is either the parent, or grandparent of the resource */
    moduleId: string;
    /** id of the parent */
    id: string;
    listTitle: string;
}

/**
 * Represents a collection of locked pedagogical resources as stored in the db
 */
 export interface PRLockStatus {
    moduleIds: string[];
    objectiveIds: string[];
    activityIds: string[];
}

/**
 * Describes the position in the data hierarchy of an item
 */
export enum PRLevel {
    Modules = "modules",
    Objectives = "objectives",
    Activities = "activities",
}
export enum PRLevelIds {
    ModuleIds = "moduleIds",
    ObjectiveIds = "objectiveIds",
    ActivityIds = "activityIds",
}

/**
 * Represents a PR item
 */
 export interface PRItem {
    id: string;
    level: PRLevel;
}
