import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

let matomoInstance;

if (process.env.NODE_ENV === "production") {
    matomoInstance = createInstance({
        urlBase: "https://charlemagne.evidenceb-services.com",
        siteId: 1,
        linkTracking: false,
    });
}

const rootElement = document.getElementById("root")
if (rootElement) {
    const root = createRoot(rootElement)
    root.render(
        <>
            {typeof matomoInstance === "undefined" ? (
                <App />
            ) : (
                <MatomoProvider value={matomoInstance}>
                    <App />
                </MatomoProvider>
            )}
        </>
    );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();