import React, { useContext } from "react";
import moment from "moment";
import CustomProgressBar from "../../../design-system-components/CustomProgressBar/CustomProgressBar";
import Icon from "../../../design-system-components/Icon/Icon";
import { configStore } from "../../../contexts/ConfigContext";
import { ADAPTIVE_TEST_LIFESPAN } from "../../../hooks/useAdaptiveTest";
import { FormattedMessage, useIntl } from "react-intl";
import htmlHandler from "../../../utils/html-handler";
import { ComputedDiagnosis } from "../../../interfaces/AdaptiveTests";

interface ProgressBarElements {
    testResult:
        | Omit<ComputedDiagnosis, "allEstimates">
        | undefined;
    className?: string;
    displayEstimateInterval: boolean;
    /**
     * @default desktop
     */
    device?: "desktop" | "mobile";
}

interface OngoingProgressBarProps {
    startDate: string;
}

const computeTestDeadline = (startDate: string, outputFormat: string) => {
    const startMoment = moment(startDate);
    return startMoment
        .add(ADAPTIVE_TEST_LIFESPAN, "milliseconds")
        .format(outputFormat);
};

export const TestProgressBar = ({
    testResult,
    className,
    displayEstimateInterval,
    device = "desktop",
}: ProgressBarElements) => {
    const estimateCouple =
        displayEstimateInterval && testResult
            ? { min: testResult.lowEstimate, max: testResult.highEstimate }
            : undefined;
    const intl = useIntl();

    return (
        <CustomProgressBar
            className={`progress ${className ? className : ""}`}
            progress={testResult ? testResult.meanEstimate : 0}
            label={intl.formatMessage({
                id: "learningLevel",
                defaultMessage: "Level of learning",
            })}
            labelTooltip={{
                $html: intl.formatMessage({
                    id: "html-adaptive-test-acquisition-explained",
                    defaultMessage:
                        "Estimated from the test answers, with a margin of error represented by the blue bubble",
                }, htmlHandler),
            }}
            estimate={estimateCouple}
            defaultText={
                testResult
                    ? device === "desktop"
                        ? intl.formatMessage(
                              {
                                  id: "estimated-around",
                                  defaultMessage:
                                      "estimated at about {estimation}",
                              },
                              { estimation: testResult.meanEstimate.toString() }
                          )
                        : intl.formatMessage(
                              {
                                  id: "estimated-around-short",
                                  defaultMessage: "about {estimation}",
                              },
                              { estimation: testResult.meanEstimate.toString() }
                          )
                    : intl.formatMessage({
                          id: "unknown",
                          defaultMessage: "unknown",
                      })
            }
        />
    );
};

export const OngoingProgressBar = ({ startDate }: OngoingProgressBarProps) => {
    const { config } = useContext(configStore);
    return (
        <div className="icon-text progress">
            <Icon className="icon-text__icon" path="warning" size={16.67} color="#E66342" />
            <span className="icon-text__text">
                <FormattedMessage
                    id="ongoingTestExpiration"
                    defaultMessage="You have until {date} to finish this test"
                    values={{
                        date: computeTestDeadline(
                            startDate,
                            config.displayedDateFormat
                        ).toString(),
                    }}
                />
            </span>
        </div>
    );
};

export const ErrorProgressBar = () => {
    return (
        <div className="icon-text">
            <div>
                <Icon className="icon-text__icon" path="warning" size={16.67} color="#E66342" />
                <span className="icon-text__text">
                    <FormattedMessage
                        id="errorSingleAdaptiveTest"
                        defaultMessage="Test unavailable. An error ocurred while retrieving data."
                    />
                </span>
            </div>
        </div>
    );
};
