import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { OverlayTriggerState } from "@react-stately/overlays";
import { configStore } from "../../../../../../contexts/ConfigContext";
import Button from "../../../../../../design-system-components/Button/Button";
import Modal from "../../../../../../design-system-components/Modal/Modal";

import "./TranscriptModal.scss";
import Dialog from "../../../../../../design-system-components/Dialog/Dialog";
import { getAssetUrl, Image } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../../../../hooks/useAssetsDetails";
interface Props {
    state: OverlayTriggerState
    onOpenTranscript: () => void;
    transcriptUrl: string;
}

const TranscriptModal = ({
    state,
    onOpenTranscript,
    transcriptUrl,
}: Props) => {
    const intl = useIntl();
    const {
        config: { logos },
    } = useContext(configStore);
    const assetsDetails = useAssetsDetails();

    return (
        <Modal
            className="transcript-modal"
            onClose={state.close}
            state={state}
            isDismissable={true}
        >
            <Dialog
                title={{
                    as: "h2",
                    content: intl.formatMessage(messages.title),
                }}
                onClose={state.close}
            >
                <div className="transcript-modal__content">
                    {logos.mascotIcon && (
                        <Image
                            src={logos.mascotIcon}
                            alt=""
                            assetsDetails={assetsDetails}
                        />
                    )}
                    <p>{intl.formatMessage(messages.descr)}</p>
                </div>

                <footer>
                    <Button
                        className="transcript-modal__btn"
                        variant="primary"
                        label={intl.formatMessage(messages.link)}
                        asLink={{
                            href: getAssetUrl(transcriptUrl, assetsDetails),
                            target: "_blank",
                            onClick: () => {
                                onOpenTranscript();
                                state.close();
                            },
                        }}
                    />
                </footer>
            </Dialog>
        </Modal>
    );
};

const messages = defineMessages({
    link: {
        id: "openTranscriptInNewPage",
        defaultMessage: "Open transcript in a new tab",
    },
    descr: {
        id: "openTranscriptDescr",
        defaultMessage:
            "Click on the following button to access the text of the video. Don't hesitate to ask for help from an adult!",
    },
    title: {
        id: "youCantListenViden",
        defaultMessage: "You cannot listen to the video?",
    },
});

export default TranscriptModal;
