import { useCallback, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Statement } from "@xapi/xapi";
import { sessionStore, StatementHistory } from "../contexts/SessionContext";
import { syncStore } from "../contexts/Sync";
import * as localStorage from "../utils/localStorage";
import useAthenaAPIClient from "./useAthenaAPIClient";

const AUTOMATIC_SYNCING_ATTEMPTS_FREQUENCY = 3 * 60 * 1000;

interface UseSyncStatementsReturn {
    /**
     * Attempt to send the given statement to the LRS or localStorage
     */
    sendStatement: (statements: Statement) => Promise<void>;
    /**
     * If there are out of sync statements, try to sync them
     */
    syncStatements: () => void;
    /**
     * True if syncing was successful, false if not, undefined if there was no
     * recent syncing attempt
     */
    syncingSuccess?: boolean;
}

/**
 * A hook to handle synchronizing statements with the LRS, especially in case of
 * POST failure.
 * Must be use within the Sync context.
 */
const useSyncStatements = (): UseSyncStatementsReturn => {
    const {
        session: {
            flags: { useHistoryFrom },
        },
        setSession,
    } = useContext(sessionStore);
    const {
        sync: { unsyncedStatements, syncingStatements },
        setSync,
    } = useContext(syncStore);

    const [statementsToSync, setStatementsToSync] = useState<Statement[]>([]);
    const [syncingSuccess, setSyncingSuccess] = useState<boolean>();
    const [syncingInterval, setSyncingInterval] = useState<NodeJS.Timeout>();
    const athenaAPIClient = useAthenaAPIClient();

    const syncStatements = useCallback(
        (
            syncingStatements: boolean,
            unsyncedStatements: Statement[] | undefined,
            useHistoryFrom: StatementHistory
        ) => {
            if (
                syncingStatements ||
                !unsyncedStatements ||
                useHistoryFrom === StatementHistory.localHistory ||
                useHistoryFrom === StatementHistory.noHistory
            )
                return;

            // We use an intermediary statementsToSync list to prevent the
            // situation where the user continues to play and statements are
            // added to the unsynced statements list while we are trying to
            // sync and we might override those new statements
            setStatementsToSync(unsyncedStatements);
            setSync((curr) => {
                return {
                    ...curr,
                    unsyncedStatements: undefined,
                };
            });
        },
        [setSync]
    );

    // Handle automatic syncing
    useEffect(() => {
        if (syncingInterval && !statementsToSync && !unsyncedStatements) {
            // Remove syncing interval when queue is empty
            clearInterval(syncingInterval);
            setSyncingInterval(undefined);
        } else if (
            !syncingInterval &&
            (statementsToSync.length > 0 || unsyncedStatements)
        ) {
            // Add interval when queue is not empty and interval is not set
            setSyncingInterval(
                setInterval(() => {
                    syncStatements(
                        syncingStatements,
                        unsyncedStatements,
                        useHistoryFrom
                    );
                }, AUTOMATIC_SYNCING_ATTEMPTS_FREQUENCY)
            );
        }
    }, [
        statementsToSync,
        unsyncedStatements,
        syncingInterval,
        athenaAPIClient,
        syncingStatements,
        useHistoryFrom,
        syncStatements,
    ]);

    // Try to sync when statements are added to statementsToSync
    useEffect(() => {
        if (statementsToSync.length === 0) return;

        (async () => {
            setSync((curr) => {
                return {
                    ...curr,
                    syncingStatements: true,
                };
            });

            let tmpSyncingSuccess = true;

            // Try to sync the statements in the order they where added. If
            // syncing fails, do not try syncing following statements because
            // it will potentially mess up the student's path if a previous
            // statement is never synced
            for (let i = 0; i < statementsToSync.length; i++) {
                try {
                    await athenaAPIClient.postStatement(statementsToSync[i]);
                } catch (err) {
                    setSync((curr) => {
                        return {
                            ...curr,
                            unsyncedStatements: curr.unsyncedStatements
                                ? [
                                    ...statementsToSync.slice(i),
                                    ...curr.unsyncedStatements,
                                ]
                                : statementsToSync.slice(i),
                        };
                    });
                    tmpSyncingSuccess = false;
                    Sentry.captureException(err);
                    break;
                }
            }

            setSyncingSuccess(tmpSyncingSuccess);
            setSync((curr) => {
                return {
                    ...curr,
                    syncingStatements: false,
                };
            });
            setStatementsToSync([]);
            setTimeout(() => {
                setSyncingSuccess(undefined);
            }, 10000);
        })();
    }, [statementsToSync, athenaAPIClient, setSync]);

    const sendStatement = useCallback(
        async (statement: Statement) => {
            // Add statement to context
            setSession((curr) => ({
                ...curr,
                statements: curr.statements
                    ? [...curr.statements, statement]
                    : [statement],
            }));

            if (
                useHistoryFrom === StatementHistory.LRS &&
                (unsyncedStatements || statementsToSync.length > 0)
            ) {
                // If there are already unsynced statements, do not try to send
                // more statements to the LRS in case the previous statements
                // are lost, so as not to create any problems in a user's path
                setSync((curr) => {
                    return {
                        ...curr,
                        unsyncedStatements: curr.unsyncedStatements
                            ? [...curr.unsyncedStatements, statement]
                            : [statement],
                    };
                });
            } else if (useHistoryFrom === StatementHistory.LRS) {
                try {
                    setSync((curr) => {
                        return {
                            ...curr,
                            isProcessing: true,
                        };
                    });
                    await athenaAPIClient.postStatement(statement);
                } catch (err) {
                    if ((err as any).response?.status === 400) {
                        // Malformed statement
                        Sentry.captureException(err);
                    } else {
                        setSync((curr) => {
                            return {
                                ...curr,
                                unsyncedStatements: curr.unsyncedStatements
                                    ? [...curr.unsyncedStatements, statement]
                                    : [statement],
                            };
                        });
                    }
                } finally {
                    setSync((curr) => {
                        return {
                            ...curr,
                            isProcessing: false,
                        };
                    });
                }
            } else if (useHistoryFrom === StatementHistory.localHistory) {
                const statements =
                    localStorage.getItem<Statement[]>(
                        localStorage.Key.LOCAL_HISTORY
                    ) ?? [];
                localStorage.setItem(
                    localStorage.Key.LOCAL_HISTORY,
                    JSON.stringify([...statements, statement])
                );
            }
        },
        [
            setSession,
            setSync,
            useHistoryFrom,
            unsyncedStatements,
            statementsToSync,
            athenaAPIClient,
        ]
    );

    return {
        sendStatement,

        syncStatements: () => {
            syncStatements(
                syncingStatements,
                unsyncedStatements,
                useHistoryFrom
            );
        },

        syncingSuccess,
    };
};

export default useSyncStatements;
