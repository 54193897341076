import React from "react";
import cn from "classnames";
import { Image, LegacyContent, parse } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../../../../../hooks/useAssetsDetails";

interface FeedbackContentProps {
    feedback: LegacyContent;
    //TEMP FIX FOR P4
    alignment?: boolean;
    className?: string;
}

const LegacyFeedbackContent = ({
    feedback,
    alignment,
    className
}: FeedbackContentProps) => {
    const assetsDetails = useAssetsDetails();
    
    return (
        <div className={className}>
            {feedback.title && <h2>{parse(feedback.title)}</h2>}
            <div
                className={cn("feedback__content", {
                    ["feedback__content--" + feedback.alignment]:
                        alignment && feedback.alignment,
                })}
            >
                {feedback.text && <p>{parse(feedback.text.$html)}</p>}
                {/*Legacy image*/}
                {feedback.image && (
                    <div style={{ textAlign: "center" }}>
                        <Image
                            src={feedback.image}
                            alt={feedback.imageAlt}
                            assetsDetails={assetsDetails}
                        />
                    </div>
                )}
                {/*------*/}
                {feedback.img && (
                    <Image
                        src={feedback.img.src}
                        alt={feedback.img.alt}
                        style={{
                            maxWidth: feedback.img.width ?? "auto",
                            maxHeight: feedback.img.height ?? "auto",
                        }}
                        assetsDetails={assetsDetails}
                    />
                )}
            </div>
        </div>
    );
};

export default LegacyFeedbackContent;
