import React from "react";
import { FormattedMessage } from "react-intl";

import "./LockStatusLabel.scss";

interface Props {
    locked?: boolean;
    style?: React.CSSProperties;
    as?: "span" | "div";
}

const LockStatusLabel = ({ locked, style, as }: Props) => {
    const Wrapper = as ?? "span";

    return (
        <Wrapper className="lock-status-label" style={style}>
            {locked && <FormattedMessage id="prm-manager-listItem-locked" defaultMessage="locked" />}
            {!locked && <FormattedMessage id="prm-manager-listItem-unlocked" defaultMessage="unlocked" />}
        </Wrapper>
    );
};

export default LockStatusLabel;
