import React, { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import {
    LevelData,
    PRLevel,
} from "../../interfaces/PedagogicalResourcesManagement";
import { PageState } from "../../interfaces/Status";
import { ErrorInfo } from "../../interfaces/Error";
import { configStore } from "../../contexts/ConfigContext";
import { dashboardStore } from "../../contexts/DashboardContext";
import { dataStore } from "../../contexts/DataContext";
import { sessionStore } from "../../contexts/SessionContext";
import { errorStore } from "../../contexts/ErrorContext";
import useAthenaAPIClient from "../../hooks/useAthenaAPIClient";
import { getSingleClassroomUrl } from "../../utils/navigation-dashboard";
import Icon from "../../design-system-components/Icon/Icon";
import { getModuleById } from "../../utils/dataRetrieval";
import Loader from "../../components/Loader/Loader";
import Manager from "./Manager/Manager";
import usePRManager from "./Manager/usePRManager";
import ActionTray from "./ActionTray/ActionTray";
import SafeguardedLeaveLink from "./SafeguardedLeaveLink/SafeguardedLeaveLink";
import ModuleMap from "../../components/ModuleMap/ModuleMap";

import "./PedagogicalResourcesManagement.scss";

interface Props {
    title: string;
}

const PedagogicalResourcesManagement = ({ title }: Props) => {
    const { classroomId } = useParams<{ classroomId: string }>();
    const { dashboard, setDashboard } = useContext(dashboardStore);
    const { config } = useContext(configStore);
    const { session } = useContext(sessionStore);
    const { data } = useContext(dataStore);
    const { setErrorInfo } = useContext(errorStore);
    const prManager = usePRManager(classroomId);
    const athenaAPIClient = useAthenaAPIClient();
    const intl = useIntl();
    const history = useHistory();

    const [pageState, setPageState] = useState<PageState>(PageState.Loading);
    const [className, setClassName] = useState<string>();
    const [levelData, setLevelData] = useState<LevelData>({
        level: PRLevel.Modules,
        prPool: data.modules,
    });

    const dashboardError: ErrorInfo = useMemo(
        () => ({
            displayModal: true,
            modal: {
                type: "POPUP",
                content: {
                    title: intl.formatMessage(messages.errorTitle),
                    text: intl.formatMessage(messages.errorText),
                    btn: {
                        label: intl.formatMessage(messages.goHome),
                        cancel: false,
                    },
                },
                onClick: () => {
                    history.push("/");
                },
            },
        }),
        [intl, history]
    );

    // Load dashboard if it wasn't already
    useEffect(() => {
        if (dashboard) return;

        (async () => {
            try {
                const dashboardData = await athenaAPIClient.getTeacherDashboard(
                    session.userId,
                    config.declinaison
                );
                if (dashboardData.classrooms) {
                    setDashboard(dashboardData);
                } else {
                    setErrorInfo(dashboardError);
                }
            } catch (err) {
                setErrorInfo(dashboardError);
            }
        })();
    }, [
        dashboardError,
        dashboard,
        config,
        session,
        setDashboard,
        setErrorInfo,
        athenaAPIClient,
    ]);

    // Set page name
    useEffect(() => {
        if (!dashboard) return;
        if (!dashboard.classrooms || dashboard.classrooms.length === 0) {
            setErrorInfo(dashboardError);
            return;
        }

        const classroom = dashboard.classrooms.find(
            (classroom) => classroom.id === classroomId
        );
        if (!classroom) {
            setErrorInfo(dashboardError);
            return;
        }

        setClassName(classroom.name);
        setPageState(PageState.Loaded);
    }, [dashboard, classroomId, dashboardError, setErrorInfo]);

    if (pageState === PageState.Loading) return <Loader />;

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <div className="pedagogical-resources-management">
                <aside>
                    <div>
                        <nav>
                            <SafeguardedLeaveLink
                                className="sidebar__backlink"
                                to={getSingleClassroomUrl(
                                    config.pages,
                                    classroomId,
                                    intl
                                )}
                                dirty={prManager.dirty}
                            >
                                <Icon
                                    path="chevron_right"
                                    className="backlink__icon"
                                    color="#4E4E4E"
                                    size={20}
                                />
                                <span>{className}</span>
                            </SafeguardedLeaveLink>
                        </nav>

                        {levelData.parentLevel && (
                            <ModuleMap
                                module={getModuleById(
                                    levelData.parentLevel.moduleId,
                                    data
                                )}
                                btnLabel={
                                    <span className="module-map__button-title">
                                        {intl.formatMessage(
                                            messages.moduleMapBtnLabel
                                        )}
                                    </span>
                                }
                                modalTitle={intl.formatMessage(
                                    messages.moduleMapTitle
                                )}
                            />
                        )}
                    </div>
                </aside>

                <main>
                    <div className="prm__content-container">
                        <h1>
                            <span>{className}</span>
                            <span>
                                {intl.formatMessage(
                                    levelData.level === PRLevel.Modules
                                        ? messages.prmTitleModule
                                        : messages.prmTitle
                                )}
                            </span>
                        </h1>
                        <p className="prm__description">
                            {intl.formatMessage(
                                levelData.level === PRLevel.Modules
                                    ? messages.prmDescriptionModule
                                    : levelData.level === PRLevel.Objectives
                                    ? messages.prmDescriptionObjective
                                    : messages.prmDescriptionActivity
                            )}
                        </p>

                        <div className="prm__manager-container">
                            <Manager
                                prLockStatus={prManager.currentPRLockStatus}
                                toggleItems={prManager.toggleItems}
                                levelData={levelData}
                                setLevelData={setLevelData}
                            />
                        </div>
                    </div>

                    <ActionTray {...prManager} classroomId={classroomId} />
                </main>
            </div>
        </>
    );
};

const messages = defineMessages({
    errorTitle: {
        id: "error-dashboardData-title",
        defaultMessage: "Cannot retrieve classes data",
    },
    errorText: {
        id: "error-dashboardData-text",
        defaultMessage:
            "An error occured while trying to retrieve your classes' data. Try again later.",
    },
    goHome: {
        id: "errors-statements-cannotRetrieveHistory-goToHomepageButton",
        defaultMessage: "Go to homepage",
    },
    prmTitleModule: {
        id: "prm-home-title",
        defaultMessage: "Manage the module's resources",
    },
    prmTitle: {
        id: "prm-home-titleGeneric",
        defaultMessage: "Manage the resource",
    },
    prmDescriptionModule: {
        id: "prm-home-descriptionModule",
        defaultMessage:
            "You can decide to limit access to the contents of the modules by deactivating them. You can reactivate them later. Click on a module to access its content and manage access to the objectives. ",
    },
    prmDescriptionObjective: {
        id: "prm-home-descriptionObjective",
        defaultMessage:
            "You can decide to limit access to the contents of the resource by deactivating them. You can reactivate them later.",
    },
    prmDescriptionActivity: {
        id: "prm-home-descriptionActivity",
        defaultMessage:
            "You can decide to limit access to the contents of the resource by deactivating them. You can reactivate them later.",
    },
    moduleMapTitle: {
        id: "prm-moduleMap",
        defaultMessage: "Module map",
    },
    moduleMapBtnLabel: {
        id: "prm-moduleMap",
        defaultMessage: "Module map",
    },
});

export default PedagogicalResourcesManagement;
