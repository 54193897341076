import React from "react";
import { FormattedMessage } from "react-intl";
import Card from "../../../design-system-components/Card/Card";

const LogoutConfirmation = () => {
  return <Card>
    <FormattedMessage id="auth-lougoutConfirmation" defaultMessage="Please sign in using your ENT to access the resource" />
  </Card>
};

export default LogoutConfirmation;