import React, {
    DetailedHTMLProps,
    HTMLAttributes,
    PropsWithChildren,
} from "react";
import cn from "classnames";
import { merge } from "lodash";
import {
    PRLevelIds,
    PRLockStatus,
} from "../../../interfaces/PedagogicalResourcesManagement";
import LockStatusLabel from "../../LockStatusLabel/LockStatusLabel";

import COLOR_SCHEME from "../../../pages/DashBoard/ProgressionOverview/ProgressionTable/COLOR_SCHEME";

interface Props {
    status: "none" | "inProgress" | "completed";
    title?: string;
    prLock?: {
        status: PRLockStatus;
        level: PRLevelIds;
        id: string;
    };
}

const ProgressionContainer = ({
    status,
    children,
    title,
    prLock,
    ...props
}: PropsWithChildren<Props> &
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    return (
        <div
            {...props}
            className={cn(
                "objective-progression",
                `--${status}`,
                props.className
            )}
            style={merge(
                {},
                prLock && prLock.status[prLock.level].includes(prLock.id)
                    ? COLOR_SCHEME.locked
                    : undefined,
                props.style
            )}
        >
            {title && (
                <>
                    <div className="objective-title">{title}</div>

                    {prLock &&
                    prLock.status[prLock.level].includes(prLock.id) ? (
                        <LockStatusLabel
                            locked
                            style={{ margin: "4px auto 8px auto" }}
                        />
                    ) : (
                        <div className="objective-title__lock-label-placeholder">
                            {/* placeholder */}
                        </div>
                    )}
                </>
            )}

            {children}
        </div>
    );
};

export default ProgressionContainer;
