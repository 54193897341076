import { useContext, useMemo, useState } from "react";
import { Statement } from "@xapi/xapi";
import { Classroom, TokenPayload, Student, UserType } from "../interfaces/User";
import * as Sentry from "@sentry/react";
import { axiosClient } from "../utils/axios-client";
import * as localStorage from "../utils/localStorage";
import { configStore } from "../contexts/ConfigContext";
import { errorStore } from "../contexts/ErrorContext";
import { sessionStore } from "../contexts/SessionContext";
import { completeLockStatus } from "../utils/pedagogical-ressources";
import { PRLockStatus } from "../interfaces/PedagogicalResourcesManagement";
import { ClassroomData, ClassroomPayload, StudentPayload, TeacherPayload, UserData } from "../interfaces/Authentication";
import axios, { AxiosError } from "axios";
import { Account } from "../interfaces/AuthAPI";
import { handleActionTokenError } from "../utils/api-client-utils";
import { Dashboard } from "../interfaces/Dashboard";
import { isSpecimenVersion } from "../utils/init";


const useAthenaAPIClient = () => {
    // const error5xx = /(40[0-9]|4[1-9][0-9])/g;

    const {
        config: { apiUrls },
    } = useContext(configStore);
    const { setErrorInfo } = useContext(errorStore);
    const { session } = useContext(sessionStore)
    // when specimen variation is set, some requests
    // are changed to a specific url where variation needs to be used
    // It creates probably technical debt so it needs also to be refactor 
    const [ specimenVariation, setSpecimenVariation ] = useState<false | string>(isSpecimenVersion() ? session.version : false)

    // const captureError5xx = (error: any) => {
    //     let status = error.response.status.toString();
    //     if (status.match(error5xx)) {
    //         Sentry.captureException(error);
    //     }
    // };

    const athenaAPIClient = useMemo(() => {
        const handleTokenError = (error: any) => {
            if (
                error?.response?.data?.detail?.message ===
                "User session was invalidated."
            ) {
                setErrorInfo((err) => {
                    return {
                        ...err,
                        page: { code: "token" },
                    };
                });
                return true;
            }
            return false;
        };

        const specimen = {
            getTokenPayload: (): Promise<TokenPayload> => {
                return axiosClient.axios
                    .get(`${apiUrls.endpoints.users}/user-info/?variation=${specimenVariation}`,)
                    .then((response) => {
                        return response.data as TokenPayload;
                    })
                    .catch((error) => {
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "token" },
                            };
                        });
                        console.log(error);
                        throw error;
                    });
            },
            async getUser() {
                const { data } = await axiosClient.axios.get(
                    `${apiUrls.endpoints.users}/user?variation=${specimenVariation}`
                )
                return data
            },
            async getResourcesLockStatus(
                userId: string,
                userType: UserType,
            ): Promise<{ [classroomId: string]: PRLockStatus }> {
                const res = await axiosClient.axios.get(
                    `${
                        apiUrls.endpoints.users
                    }/lock_status/${userType.toLowerCase()}/${userId}/?variation=${specimenVariation}`,
                );
                const allLockStatus = res.data as {
                    [classroomId: string]: Partial<PRLockStatus>;
                };
                return Object.keys(allLockStatus).reduce(
                    (lockStatus, classroomId) => {
                        return {
                            ...lockStatus,
                            [classroomId]: completeLockStatus(
                                allLockStatus[classroomId]
                            ),
                        };
                    },
                    {} as { [classroomId: string]: PRLockStatus }
                );
            },
            getClassroom(classroomId: string): Promise<Classroom> {
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.users}/api/classrooms/${classroomId}/?variation=${specimenVariation}`,
                    )
                    .then((response) => {
                        return response.data as Promise<Classroom>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        throw error;
                    });
            },
            getTeacherDashboard(
                teacherId: string,
                version: string | undefined
            ): Promise<Dashboard> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evb-origin": window.location.origin,
                    version: version,
                };
                return axiosClient.axios
                    .get(`${apiUrls.endpoints.analytics}/teacher/${teacherId}?version=${specimenVariation}`,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Dashboard>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        throw error;
                    });
            },

        }

        return {
            setSpecimenVariation,
            specimenVariation,
            handleTokenError,
            getTokenPayload: async (): Promise<TokenPayload> => {
                if (isSpecimenVersion())
                    return await specimen.getTokenPayload()
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(apiUrls.endpoints.users + "/user-info/", {
                        headers: headers,
                    })
                    .then((response) => {
                        return response.data as TokenPayload;
                    })
                    .catch((error) => {
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "token" },
                            };
                        });
                        console.log(error);
                        throw error;
                    });
            },

            async getUser<T>(tokenPayload: TokenPayload): Promise<T> {
                if (isSpecimenVersion())
                    return await specimen.getUser()
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users +
                            "/api/" +
                            tokenPayload.role +
                            "s/" +
                            tokenPayload.sub +
                            "/",
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "token" },
                            };
                        });
                        console.log(error);
                        throw error;
                    });
            },

            getStudent<T>(userId: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users +
                            "/api/students/" +
                            userId +
                            "/",
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        return error;
                    });
            },

            getTeacher<T>(userId: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users +
                            "/api/teachers/" +
                            userId +
                            "/",
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        return error;
                    });
            },

            createStudents(data: Student[]): Promise<Student[]> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .post(apiUrls.endpoints.users + "/api/students/", data, {
                            headers: headers
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Student[]>;
                    })
                    .catch((error) => {
                        console.error(error);
                        if (handleTokenError(error)) throw error;
                        throw error;
                    });
            },

            updateUser<T>(
                type: string,
                userId: string,
                data: object
            ): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .patch(
                        apiUrls.endpoints.users + "/api/" + type + "s/" + userId + "/",  data,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        throw error;
                    });
            },

            async getClassroom(classroomId: string): Promise<Classroom> {
                if (isSpecimenVersion())
                    return await specimen.getClassroom(classroomId)
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users + "/api/classrooms/" + classroomId + "/",
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Classroom>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        throw error;
                    });
            },

            getClassroomsByCode(classroomCode: string): Promise<Classroom[]> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users + "/api/classrooms/?code=" + classroomCode,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Classroom[]>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        throw error;
                    });
            },

            getClassroomsByExternalId<T>(
                variation: string,
                externalId: string
            ): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                let params = `?variation=${variation}&external_id=${externalId}`;
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.users + "/api/classrooms/" + params,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        return error;
                    });
            },

            updateClassroom(
                classroomId: string | undefined,
                data: object
            ): Promise<Classroom> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .patch(
                        apiUrls.endpoints.users +
                            "/api/classrooms/" +
                            classroomId +
                            "/",
                        data,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Classroom>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        throw error;
                    });
            },

            createClassroom(data: { name: string | undefined }): Promise<Classroom> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .post(apiUrls.endpoints.users + "/api/classrooms/", data, {
                        headers: headers,
                    })
                    .then((response) => {
                        return response.data as Promise<Classroom>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        throw error;
                    });
            },

            deleteClassroom<T>(id: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .delete(apiUrls.endpoints.users + "/api/classrooms/" + id, {
                        headers: headers,
                    })
                    .then((response) => {
                        console.log("Classroom deleted, reload the page");
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        console.log(error);
                        return error;
                    });
            },

            getGlobalConfig<T>(version: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evb-origin": window.location.origin,
                };
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.content}/content?target=config${
                            version !== "" ? "&version=" + version : ""
                        }`,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;

                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "config" },
                            };
                        });
                        /* captureError5xx(error) */
                        /* Sentry.captureException(error); */
                        console.log(error);
                        throw error;
                    });
            },

            getData<T>(version: string): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evb-origin": window.location.origin,
                };
                return axiosClient.axios
                    .get(
                        `${apiUrls.endpoints.content}/content?target=questions${
                            version !== "" ? "&version=" + version : ""
                        }`,
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<T>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        setErrorInfo((err) => {
                            return {
                                ...err,
                                page: { code: "data" },
                            };
                        });
                        console.log(error);
                        throw error;
                    });
            },

            async getTeacherDashboard(
                teacherId: string,
                version: string | undefined
            ): Promise<Dashboard> {
                if (isSpecimenVersion())
                    return await specimen.getTeacherDashboard(teacherId, version)
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evb-origin": window.location.origin,
                    version: version,
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.analytics +
                            "/teacher/" +
                            teacherId + 
                            "/",
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        return response.data as Promise<Dashboard>;
                    })
                    .catch((error) => {
                        if (handleTokenError(error)) throw error;
                        throw error;
                    });
            },

            postStatement<T>(statement: Statement): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evidenceb-app": "athena",
                    "Content-Type": "application/json",
                };
                return axiosClient.axios
                    .post(apiUrls.endpoints.statements!, statement, {
                        headers: headers,
                    })
                    .then((res) => {
                        return res.data as Promise<T>;
                    });
            },

            getStatements(agent: object): Promise<Statement[]> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                    "x-evidenceb-app": "athena",
                };
                return axiosClient.axios
                    .get(
                        apiUrls.endpoints.statements! +
                            "?agent=" +
                            encodeURIComponent(JSON.stringify(agent)),
                        {
                            headers: headers,
                        }
                    )
                    .then((res) => {
                        return res.data as Promise<{
                            more: any;
                            statements: Statement[];
                        }>;
                    })
                    .then((res) => res.statements);
            },

            logout<T>(): Promise<T> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                // URL set in .env for now
                return axiosClient.axios
                    .get(`${apiUrls.endpoints.auth}/session/logout`, {
                        headers: headers,
                    })
                    .then((res) => {
                        return res.data.SessionKilled as Promise<T>;
                    })
                    .catch((error) => {
                        console.log(error);
                        return error;
                    });
            },

            async getResourcesLockStatus(
                userId: string,
                userType: UserType
            ): Promise<{ [classroomId: string]: PRLockStatus }> {
                if (isSpecimenVersion())
                    return await specimen.getResourcesLockStatus(userId, userType)
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                const res = await axiosClient.axios.get(
                    `${
                        apiUrls.endpoints.users
                    }/lock_status/${userType.toLowerCase()}/${userId}/`,
                    {
                        headers: headers,
                    }
                );
                const allLockStatus = res.data as {
                    [classroomId: string]: Partial<PRLockStatus>;
                };
                return Object.keys(allLockStatus).reduce(
                    (lockStatus, classroomId) => {
                        return {
                            ...lockStatus,
                            [classroomId]: completeLockStatus(
                                allLockStatus[classroomId]
                            ),
                        };
                    },
                    {} as { [classroomId: string]: PRLockStatus }
                );
            },

            async updateResourcesLockStatus(
                classroomId: string,
                lockStatus: PRLockStatus
            ): Promise<void> {
                let headers = {
                    Authorization:
                        "Bearer " +
                        localStorage.getItem<string>(localStorage.Key.TOKEN),
                };
                await axiosClient.axios.patch(
                    `${apiUrls.endpoints.users}/api/classrooms/${classroomId}/`,
                    { lock_status: lockStatus },
                    {
                        headers: headers,
                    }
                );
            },


        };
    }, [apiUrls, setErrorInfo, specimenVariation, setSpecimenVariation]);

    return athenaAPIClient;
};

/**
 * This is the client used in the authentication process. Since we do not want
 * to verify that the user has a valid token, we use the generic axios client
 * and not our custom one.
 */
export const useAthenaTokenlessAPIClient = () => {
    const {
        config: { apiUrls },
    } = useContext(configStore);

    const { session } = useContext(sessionStore);

    const tokenlessClient = useMemo(
        () => ({
            async directAuthStudent(body: StudentPayload) {
                try {
                    const res = await axios.post<UserData[]>(
                        apiUrls.endpoints.auth + "/direct-auth/student",
                        body
                    );
                    // TEMP: The list represents the variations the user has
                    // access to. For now we choose the first one because a user
                    // shouldn't have access to multiple variations
                    return res.data[0];
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },

            async directAuthTeacher(body: TeacherPayload) {
                try {
                    const res = await axios.post<(TokenPayload & { token: string })[]>(
                        apiUrls.endpoints.auth + "/direct-auth/teacher",
                        body
                    );

                    const payload = res.data.find((payload) => `${payload.app}/${payload.version}` === session.app);

                    return payload;

                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },

            async classroomCodeValidation(body: ClassroomPayload) {
                try {
                    const res = await axios.post<ClassroomData>(
                        apiUrls.endpoints.auth + "/direct-auth/classroom",
                        body
                    );
                    return res.data;
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            },

            async verifyActionToken(actionToken: string) {
                try {
                    const res = await axios.post<Account>(
                        apiUrls.endpoints.auth +
                            "/direct-auth/verify-action-token?token=" +
                            actionToken
                    );
                    return res.data;
                } catch (err) {
                    handleActionTokenError(err as AxiosError);
                    throw err;
                }
            },

            async updatePassword(
                newPwd: string,
                actionToken: string,
                app: string,
                email: string
            ) {
                try {
                    const res = await axios.post<
                        | (TokenPayload & { token: string })[]
                        | { detail: "same_password" | "mismatch" }
                    >(
                        apiUrls.endpoints.auth +
                            "/direct-auth/update-password",
                        {
                            action_token: actionToken,
                            password: newPwd,
                            app,
                            role: "teacher",
                            email,
                        }
                    );
                    const payload = Array.isArray(res.data) ? res.data.find((payload) => `${payload.app}/${payload.version}` === session.app) : res.data
                    if (payload == undefined) {
                        throw new Error(`No Teacher found for variation ${app}`)
                    }
                    return payload

                } catch (err) {
                    handleActionTokenError(err as AxiosError);
                    throw err;
                }
            },

            async requestNewPassword(email: string, variation: string) {
                try {
                    await axios.post(
                        `${apiUrls.endpoints.auth}/email-registration/request-new-password`,
                        {
                            email,
                            variation,
                            status: "forgot_password"
                        }
                    );
                } catch (err) {
                    Sentry.captureException(err);
                    throw err;
                }
            },
        }),
        [apiUrls, session.app]
    );

    return tokenlessClient;
};

export default useAthenaAPIClient;
