import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ActionTokenError } from "../../../../interfaces/AuthAPI";
import { configStore } from "../../../../contexts/ConfigContext";
import Button from "../../../../design-system-components/Button/Button";
import Card from "../../../../design-system-components/Card/Card";
import Icon from "../../../../design-system-components/Icon/Icon";

import "./PasswordChangeError.scss";

interface Props {
    type: ActionTokenError;
}

const PasswordChangeError = ({ type }: Props) => {
    const intl = useIntl();
    const { config } = useContext(configStore);

    return (
        <Card
            cardTitle={{
                as: "h1",
                content: intl.formatMessage(messages.signup, {
                    appName: config.client_name,
                }),
            }}
            className="password-change-error"
        >
            <div className="password-change-error__content">
                <Icon
                    className="pwd-change-error-icon"
                    path="illustrations/token-error"
                    style={{ height: 128, width: 172 }}
                />
                <p>{intl.formatMessage(messages[type])}</p>
                <Button
                    className="pwd-change-error-redirection"
                    variant="primary"
                    center
                    asRouterLink={{ to: "/auth/teacher/pwd" }}
                    label={intl.formatMessage(messages.renewPwdChangeRequest)}
                />
            </div>
        </Card>
    );
};

const messages = defineMessages({
    signup: {
        id: "authentication-pwd-signupToApp",
        defaultMessage: "Sign up to {appName}",
    },
    expired: {
        id: "authentication-pwd-expiredTokenMessage",
        defaultMessage:
            "The link has expired. Please renew your password change request.",
    },
    consumed: {
        id: "authentication-pwd-consumedTokenMessage",
        defaultMessage:
            "The link has already been used. Please renew your password change request.",
    },
    renewPwdChangeRequest: {
        id: "authentication-pwd-renewPwdChangeRequest",
        defaultMessage: "Renew your password change request",
    },
});

export default PasswordChangeError;
