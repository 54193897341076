import React from "react";
import CardEntry from "../../CardEntry/CardEntry";
import { IHomeContent } from "../../../../interfaces/Home";
import { parse } from "@evidenceb/athena-common";
import "./Content.scss";
import { Image } from '@evidenceb/athena-common';
import useAssetsDetails from '../../../../hooks/useAssetsDetails';

interface TeacherProps {
    content: IHomeContent | undefined;
}

function Content({ content }: TeacherProps) {
    const assetsDetails = useAssetsDetails();

    return (
        <div className="home-content">
            {content ? (
                <>
                    <div className="top-content">
                        <div className="content">
                            <div className="block">
                                <h1 className="staatliches">
                                    {parse(content.main.title)}
                                </h1>
                                <p>{parse(content.main!.text)}</p>
                            </div>
                            <div className="block">
                                {content.main.img && (
                                    <Image
                                        src={content.main.img}
                                        alt={content.main.imgAlt ?? ""}
                                        assetsDetails={assetsDetails}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="entries">
                        {content.entries.map((entry, i) =>
                            entry.display ? (
                                <CardEntry
                                    key={`card-${i}`}
                                    title={entry.title}
                                    img={entry.img}
                                    desc={entry.desc}
                                    buttonLabel={entry.buttonLabel}
                                    url={entry.url}
                                />
                            ) : null
                        )}
                    </div>
                    <div className="secondary-content">
                        {content.secondaries.map((content, i) => (
                            <div className="container" key={`container-${i}`}>
                                <div className="block">
                                    {content.img && (
                                        <div className="img">
                                            <Image assetsDetails={assetsDetails} src={content.img} alt="" />
                                        </div>
                                    )}
                                    <div className="content">
                                        <h2 className="staatliches">
                                            {parse(content.title)}
                                        </h2>
                                        {parse(content.content)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
        </div>
    );
}

export default Content;
