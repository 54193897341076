import { defineMessages } from "react-intl";

export default defineMessages({
    studentBtnLabel: {
        id: "moduleList-student-studentPathIconAlt",
        defaultMessage: "Vizualize the student's path in the module",
    },
    studentModalTitle: {
        id: "moduleList-student-studentPathTitle",
        defaultMessage: "Student path in the module",
    },
});
