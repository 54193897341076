import React, {
  useReducer,
} from 'react';

import { 
  buildModuleStatus, 
  findModuleForObjectiveId, 
  openExerciceAssetsCache 
} from './cacheCommon';
import {
  CacheState,
  initialState,
  ModuleStatus,
  ObjectiveStatus,
} from './cacheState';
import { CacheAction } from './cacheAction';
import { initCacheState } from './cacheInitMethods';
import { startCache } from './cacheStartMethods';
import { startUncache } from './cacheRemoveMethods';

const cacheReducer = (state: CacheState, action: CacheAction) => {
  switch (action.type) {
    case "INIT":
      const initState = initCacheState(action.payload);
      console.log("CACHE_INIT_STATE", initState);
      return initState;

    case "UPDATE_TASK":
      return {
        modules: state.modules,
        assetsUrl: state.assetsUrl,
        version: state.version,
        currentTask: {
          abortController: action.payload.abortController,
          cacheType: action.payload.cacheType,
          id: action.payload.id,
        }
      };

    case "CANCEL_TASK":
      state.currentTask?.abortController.abort();
      return {
        modules: state.modules,
        assetsUrl: state.assetsUrl,
        version: state.version,
        currentTask: undefined,
      };

    case "PUT_CACHE_RESULT":
      switch (action.payload.cacheType) {
        case "module":
          const updatedModules = state.modules.filter(module => module.id !== action.payload.id);
          updatedModules.push(action.payload.result as ModuleStatus);
          return {
            modules: updatedModules,
            assetsUrl: state.assetsUrl,
            version: state.version,
            currentTask: undefined,
          };
        case "objective":
          const moduleForObjective = findModuleForObjectiveId(state, action.payload.id);
          if (!moduleForObjective) return state;

          const updateObjectives = moduleForObjective.objectives.filter(objective => objective.id !== action.payload.id);
          updateObjectives.push(action.payload.result as ObjectiveStatus);

          const updatedModulesForObjective = state.modules.filter(module => module.id !== moduleForObjective.id);
          updatedModulesForObjective.push(buildModuleStatus(moduleForObjective.id, updateObjectives))
          return {
            modules: updatedModulesForObjective,
            assetsUrl: state.assetsUrl,
            version: state.version,
            currentTask: undefined,
          };

      }
      break;

    default:
      return state;
  }
};

const cacheMiddleware = async (state: CacheState, action: CacheAction, dispatchCacheAction: React.Dispatch<CacheAction>) => {
  switch (action.type) {
    case "START_INIT":
      const cache = await openExerciceAssetsCache();
      const cacheKeys = (await cache.keys()).map(key => key.url);

      dispatchCacheAction({
        type: "INIT",
        payload: {
          data: action.payload.data,
          cacheKeys: cacheKeys,
          assetsUrl: action.payload.assetsUrl,
          version: action.payload.version,
        },
      });
      break;

    case "START_CACHE":
      const startCacheAbortController = new AbortController();
      const { signal: abortStartCacheSignal } = startCacheAbortController;
      startCache(abortStartCacheSignal, state, action.payload).then(result => {
        dispatchCacheAction({
          type: "PUT_CACHE_RESULT",
          payload: {
            cacheType: result.cacheType,
            id: result.id,
            result: result.result,
          },
        });
      });
      dispatchCacheAction({
        type: "UPDATE_TASK",
        payload: {
          abortController: startCacheAbortController,
          cacheType: action.payload.cacheType,
          id: action.payload.id,
        },
      });
      break;

    case "START_UNCACHE":
      const startUncacheAbortController = new AbortController();
      const { signal: abortStartUncacheSignal } = startUncacheAbortController;
      startUncache(abortStartUncacheSignal, state, action.payload).then(result => {
        dispatchCacheAction({
          type: "PUT_CACHE_RESULT",
          payload: {
            cacheType: result.cacheType,
            id: result.id,
            result: result.result,
          },
        });
      });
      dispatchCacheAction({
        type: "UPDATE_TASK",
        payload: {
          abortController: startUncacheAbortController,
          cacheType: action.payload.cacheType,
          id: action.payload.id,
        },
      });
      break;
  }
}

export const CacheReducerWithMiddleware = () => {
  const [cacheState, dispatchCacheAction] = useReducer(cacheReducer, initialState);

  const dispatchCacheActionWithMiddleware: React.Dispatch<CacheAction> = (action: CacheAction) => {
    cacheMiddleware(cacheState, action, dispatchCacheAction);
    return dispatchCacheAction(action);
  }
  return [cacheState, dispatchCacheActionWithMiddleware];
}
