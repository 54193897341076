import React, { useCallback, useContext } from "react";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { defineMessages, useIntl } from "react-intl";
import { HtmlString } from "@evidenceb/gameplay-interfaces";
import { Video } from "../../../../../interfaces/Resources";
import { sessionStore } from "../../../../../contexts/SessionContext";
import { configStore } from "../../../../../contexts/ConfigContext";
import { parse } from "@evidenceb/athena-common";
import useSyncStatements from "../../../../../hooks/useSyncStatements";
import { makeActivityVideoTutorialWatchedStatement } from "../../../../../utils/statement-builder";
import Button from "../../../../../design-system-components/Button/Button";
import VideoResource from "../../../../../components/VideoResource/VideoResource";
import VisuallyHidden from "../../../../../components/VisuallyHidden/VisuallyHidden";
import Modal from "../../../../../design-system-components/Modal/Modal";
import Dialog from "../../../../../design-system-components/Dialog/Dialog";

import "./WizardVideoTutorialBtn.scss";
import { getAssetUrl } from "@evidenceb/athena-common";
import useAssetsDetails from "../../../../../hooks/useAssetsDetails";

interface Props {
    video: Video;
    instruction: HtmlString;
    title: string;
    activityId: string;
}

const WizardVideoTutorialBtn = ({
    video,
    instruction,
    title,
    activityId,
}: Props) => {
    const intl = useIntl();
    const {
        config: { declinaison },
    } = useContext(configStore);
    const {
        session: { sessionId, evidencebId },
    } = useContext(sessionStore);
    const { sendStatement } = useSyncStatements();
    const assetsDetails = useAssetsDetails();
    
    const modalState = useOverlayTriggerState({ defaultOpen: false })

    const sendVideoWatchedStatement = useCallback(() => {
        sendStatement(
            makeActivityVideoTutorialWatchedStatement(
                declinaison,
                evidencebId,
                sessionId,
                getAssetUrl(video.sources[0].src, assetsDetails)!,
                activityId,
                "voluntary"
            )
        );
    }, [
        declinaison,
        evidencebId,
        sessionId,
        video,
        activityId,
        sendStatement,
        assetsDetails
    ]);

    return (
        <>
            <button
                className="wizard-video-tutorial-btn"
                onClick={() => {
                    modalState.open()
                }}
            >
                <VisuallyHidden>
                    {intl.formatMessage(messages.watchTutorialVideo)}
                </VisuallyHidden>
                <span aria-hidden>?</span>
            </button>

            {modalState.isOpen && (
                <Modal
                    className="wizard-video-tutorial-modal"
                    state={modalState}
                    onClose={modalState.close}
                    isDismissable={true}
                >
                    <Dialog
                        title={{
                            content: title,
                            as: "h2"
                        }}
                        onClose={modalState.close}
                        className="wizard-video-tutorial-dialog"
                    >
                        <div className="tutorial-modal__instruction">
                            <p>{parse(instruction.$html)}</p>
                        </div>

                        <VideoResource
                            className="tutorial-modal__video"
                            video={video}
                            onEnded={() => {
                                sendVideoWatchedStatement();
                            }}
                            controls={[
                                { type: "restart" },
                                {
                                    type: "custom",
                                    label: intl.formatMessage(
                                        messages.goToExercise
                                    ),
                                    onClick: () => {
                                        modalState.close();
                                    },
                                },
                            ]}
                        />
                        {video.transcript && (
                            <div className="tutorial-modal__transcript">
                                <p>{intl.formatMessage(messages.cantListen)}</p>
                                <Button
                                    className="transcript__btn"
                                    variant="primary"
                                    asLink={{
                                        href: getAssetUrl(video.transcript, assetsDetails),
                                        onClick: () => {
                                            sendVideoWatchedStatement();
                                        },
                                        target: "_blank",
                                    }}
                                    center
                                    label={intl.formatMessage(
                                        messages.openTranscript
                                    )}
                                />
                            </div>
                        )}
                    </Dialog>
                </Modal>
            )}
        </>
    );
};

const messages = defineMessages({
    openTranscript: {
        id: "openTranscriptInNewPage",
        defaultMessage: "Open transcript in a new tab",
    },
    cantListen: {
        id: "youCantListenViden",
        defaultMessage: "You cannot listen to the video?",
    },
    close: {
        id: "misc-modalClose",
        defaultMessage: "Close",
    },
    watchTutorialVideo: {
        id: "watchTutorialVideo",
        defaultMessage: "Watch a tutorial video",
    },
    goToExercise: {
        id: "goToExercise",
        defaultMessage: "Go back to the exercise",
    },
});

export default WizardVideoTutorialBtn;
