import React, { useContext } from 'react';
// External modules
import { useForm } from 'react-hook-form';
import { OverlayTriggerState } from 'react-stately';
import { defineMessages, useIntl } from 'react-intl';
import { customAlphabet, urlAlphabet } from 'nanoid';
// Contexts
import { configStore } from '../../../../contexts/ConfigContext';
import { errorStore } from '../../../../contexts/ErrorContext';
import { sessionStore } from '../../../../contexts/SessionContext';
// Components
import Button from '../../../../design-system-components/Button/Button';
import Input from '../../../../design-system-components/Input/Input';
import Modal, { ModalProps } from '../../../../design-system-components/Modal/Modal';
// Utils
//Hooks
import useAthenaAPIClient from '../../../../hooks/useAthenaAPIClient';
// SCSS
import './ClassroomCreator.scss';
// Interfaces;
import { Classroom } from '../../../../interfaces/User';
import Dialog from '../../../../design-system-components/Dialog/Dialog';
interface IFormValues {
    "classroomName": string;
}
interface ClassroomCreatorProps {
    onEnd: (classroom: Classroom) => void;
    state: ModalProps['state']
}
const ClassroomCreatorModal = ({ onEnd, className, state }: ClassroomCreatorProps & React.DetailsHTMLAttributes<HTMLDivElement>) => {
    const intl = useIntl()
    const { register, handleSubmit, formState: { errors, isDirty } } = useForm<IFormValues>();
    const { config: { declinaison } } = useContext(configStore);
    const { session: { userId, userProvider } } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);
    const athenaAPIClient = useAthenaAPIClient()
    const nanoid = customAlphabet(urlAlphabet, 10)

    const createClassroom = async (data: IFormValues) => {
        const classroom = {
            external_id: nanoid(),
            name: data.classroomName,
            provider: userProvider,
            variation: declinaison,
            level: 'none',
            school: '',
            teachers: [userId],
            extra: 'none',
            createCode: true
        }
        try {
            let newClassroom = await athenaAPIClient.createClassroom(classroom);
            onEnd(newClassroom)
        } catch (err) {
            setErrorInfo((errorInfo) => {
                return {
                    ...errorInfo,
                    displayModal: true,
                    modal: {
                        type: "NOTIFICATION",
                        content: {
                            title: intl.formatMessage(messages.createClassroomError)
                        }
                    }

                };
            });
        }
    }

    return (
        <Modal
            state={state}
            className={className}
            isDismissable={true}
        >
                <Dialog
                    title={{
                        as: "h2",
                        content: intl.formatMessage(messages.title),
                        className: "classroom-creator__title"
                    }}
                    onClose={state.close}
                    aria-label={intl.formatMessage(messages.title)}
                >
                    <div className="classroom-creator">
                        <form onSubmit={handleSubmit(createClassroom)}>
                            <div className='classroom-creator__name'>
                                <label htmlFor="classroom-name">{intl.formatMessage(messages.name)}</label>
                                <Input
                                    id="classroom-name"
                                    {...register("classroomName", {
                                        required: { value: true, message: intl.formatMessage(messages.required) }
                                    })}
                                    error={errors.classroomName?.message}
                                />
                                <div className="classroom-creator__name__message">{intl.formatMessage(messages.message)}</div>
                            </div>
                            <div className='classroom-creator__actions'>
                                <Button
                                    type="button"
                                    className="classroom-creator__actions--cancel"
                                    variant="tertiary"
                                    label={intl.formatMessage(messages.cancel)}
                                    onClick={state.close}
                                />
                                <Button
                                    type="submit"
                                    variant="primary"
                                    label={intl.formatMessage(messages.createClassroom)}
                                    disabled={!isDirty}
                                />
                            </div>
                        </form>
                    </div>
                </Dialog>
        </Modal>
    )
}

interface ClassroomCreatorButtonProps {
    state: OverlayTriggerState
}

const ClassroomCreatorButton = ({
    state
}: ClassroomCreatorButtonProps) => {
    const intl = useIntl()

    return (
        <Button
            variant="secondary"
            label={intl.formatMessage(messages.createClassroom)}
            icon={{
                path: "add",
                size: "medium"
            }}
            onClick={state.open}
        />
    )
}

export { ClassroomCreatorModal, ClassroomCreatorButton }

const messages = defineMessages({
    name: {
        id: "classroomCreator-name", defaultMessage: "Classroom name"
    },
    cancel: {
        id: "classroomCreator-cancel", defaultMessage: "Cancel"
    },
    title: {
        id: "classroomCreator-title", defaultMessage: "Create a classroom"
    },
    message: {
        id: "classroomCreator-message", defaultMessage: "The name of the classroom is not definitive, you can modify it later in the dashboard."
    },
    required: {
        id: "form-field-required", defaultMessage: "This field is required"
    },
    createClassroomError: {
        id: "classroomCreator-createClassroomError", defaultMessage: "An error has occured while creating the classroom"
    },
    createClassroom: {
        id: "classroomCreator-createClassroom", defaultMessage: "Create classroom"
    }
})