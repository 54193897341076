import React, { useContext, useMemo } from 'react';
// External modules
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
// Contexts
import { sessionStore } from '../../../contexts/SessionContext';
//Hooks
import useSoloModuleList, { SoloModule } from '../../../hooks/useSoloModuleList';
import useBanditManchot from '../../../hooks/useBanditManchot';
// Components
import Loader from '../../../components/Loader/Loader';
import ResourceCard from '../../../design-system-components/ResourceCard/ResourceCard';
import ControlledDialog from '../../../design-system-components/ControlledDialog/ControlledDialog';
import { LoaderStatus } from '../../../interfaces/Status';
// Utils
// SCSS
import './WorkInProgressHome.scss';
import useLockedModules from '../../../hooks/useLockedModules';
// Interfaces

const WorkInProgressHome = () => {
    const { session: { firstname } } = useContext(sessionStore);
    const intl = useIntl();
    const history = useHistory();
    const bmInfo = useBanditManchot();
    const lockedModules = useLockedModules();
    const filterModulesWithProgression = useMemo(() => {
        if (lockedModules.status !== LoaderStatus.Success) return undefined;
        return (soloModule: SoloModule) =>
            !lockedModules.payload.includes(soloModule.id) && soloModule.hasProgression
    }, [lockedModules]);
    const soloModules = useSoloModuleList(filterModulesWithProgression);
    const workmodesSolo = getComputedStyle(document.body).getPropertyValue('--workmodes-solo');
    const workmodesSolo20 = getComputedStyle(document.body).getPropertyValue('--workmodes-solo-20');
    return (
        <>
            {
                (bmInfo.status === "error" || lockedModules.status === LoaderStatus.Error) && (
                    <ControlledDialog
                        title={intl.formatMessage(messages.errorTitle)}
                        description={
                            { $html: intl.formatMessage(messages.errorDescription) }
                        }
                        primaryButton={{
                            label: intl.formatMessage(messages.errorBtn),
                            fn: () => {
                                history.push("/");
                            },
                        }}
                    />
                )
            }
            {
                (bmInfo.status === "loading" || lockedModules.status === LoaderStatus.Loading) && <Loader />
            }
            {
                bmInfo.status === "success" && lockedModules.status === LoaderStatus.Success &&
                <div className='work-in-progress-home'>
                    <h1>{intl.formatMessage(messages.workInProgressHomeWelcome, { name: firstname })}</h1>
                    <h2>{intl.formatMessage(messages.workInProgressHomeMessage)}</h2>
                    <div className="work-in-progress-home__activities">
                        {
                            soloModules.map((soloModule) =>
                                <ResourceCard
                                    key={soloModule.id}
                                    iconPath={"resource-solo-ai"}
                                    name={{ as: "h3", content: soloModule.name }}
                                    objectivesNb={soloModule.objectivesNb}
                                    progression={soloModule.progression}
                                    progressBarColors={{
                                        foreground: workmodesSolo,
                                        background: workmodesSolo20
                                    }}
                                    onClick={() => {
                                        history.push("/player/" + soloModule.id);
                                    }}
                                />
                            )
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default WorkInProgressHome

const messages = defineMessages({
    workInProgressHomeWelcome: {
        id: "workInProgressHome-welcome",
        defaultMessage: "Welcome {name}!",
    },
    workInProgressHomeMessage: {
        id: "workInProgressHome-message",
        defaultMessage: "Resume my activities",
    },
    errorTitle: {
        id: "errors-statements-cannotRetrieveHistory-title",
        defaultMessage: "Impossible to retrieve history"
    },
    errorDescription: {
        id: "errors-statements-cannotRetrieveHistory-description",
        defaultMessage: "Your progress data is not available at this time. Please try again later."
    },
    errorBtn: {
        id: "errors-statements-cannotRetrieveHistory-goToHomepageButton",
        defaultMessage: "Go to homepage"
    }
});
