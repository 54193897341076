import React, { useState, useEffect, useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
    ClassroomAnalytics,
    ModuleDashboard,
    Student,
} from "../../../interfaces/Dashboard";
import { PRLockStatus } from "../../../interfaces/PedagogicalResourcesManagement";
import { UserType } from "../../../interfaces/User";
import { errorStore } from "../../../contexts/ErrorContext";
import { dashboardStore } from "../../../contexts/DashboardContext";
import { configStore } from "../../../contexts/ConfigContext";
import { sessionStore } from "../../../contexts/SessionContext";
import { dataStore } from "../../../contexts/DataContext";
import { checkPRLockStatusIntegrity } from "../../../utils/prm";
import useAthenaAPIClient from "../../../hooks/useAthenaAPIClient";
import {
    getClassroomListUrl,
    getClusteringUrl,
    getDashboardUrl,
    getProgressionOverviewUrl,
    getSingleClassroomUrl,
} from "../../../utils/navigation-dashboard";
import StudentIcon from "./StudentIcon/StudentIcon";
import TimeSpentChart from "./TimeSpentChart/TimeSpentChart";
import GroupCard from "./GroupCard/GroupCard";
import Progress from "./Progress/Progress";
import StudentProgression, {
    ProgressionData,
} from "../StudentProgression/StudentProgression";
import Breadcrumbs, {
    Breadcrumb,
} from "../../../components/Breadcrumbs/Breadcrumbs";
import Loader from "../../../components/Loader/Loader";
import dashboardMessages from "../dashboardMessages";

import "./StudentDetails.scss";
import Icon from "../../../design-system-components/Icon/Icon";
import SpecimenHelper from "../SpecimenHelper/SpecimenHelper";

interface ParamTypes {
    classroomId: string;
    studentId: string;
}

export default function StudentDetails() {
    const { classroomId, studentId } = useParams<ParamTypes>();
    const { dashboard } = useContext(dashboardStore);
    const { config } = useContext(configStore);
    const { data } = useContext(dataStore);
    const {
        session: { userId, specimen },
    } = useContext(sessionStore);
    const { setErrorInfo } = useContext(errorStore);
    const history = useHistory();
    const athenaAPIClient = useAthenaAPIClient();
    const intl = useIntl();

    const [progression, setProgression] = useState<
        ProgressionData | undefined
    >();

    const [prLockStatus, setPRLockStatus] = useState<PRLockStatus>();
    useEffect(() => {
        if (prLockStatus) return;

        (async () => {
            try {
                const allLockStatus =
                    await athenaAPIClient.getResourcesLockStatus(
                        userId,
                        UserType.Teacher
                    );
                setPRLockStatus(
                    checkPRLockStatusIntegrity(
                        allLockStatus[classroomId],
                        data,
                        config.ai
                    )
                );
            } catch (err) {
                Sentry.captureException(err);
                setErrorInfo({
                    displayModal: true,
                    modal: {
                        type: "POPUP",
                        content: {
                            title: intl.formatMessage({
                                id: "prm-lockStatusErrorTitle",
                                defaultMessage: "Cannot retrieve lock status",
                            }),
                            text: (
                                <>
                                    {intl.formatMessage({
                                        id: "prm-lockStatusErrorText",
                                        defaultMessage:
                                            "An error ocurred while retrieving the resources' lock status. Try again later.",
                                    })}
                                </>
                            ),
                        },
                    },
                });
            }
        })();
    }, [
        athenaAPIClient,
        classroomId,
        config.ai,
        data,
        setErrorInfo,
        userId,
        prLockStatus,
        intl,
    ]);

    const classroom = useMemo<ClassroomAnalytics | undefined>(
        () => dashboard!.classrooms.find((el) => classroomId === el.id),
        [dashboard, classroomId]
    );
    useEffect(() => {
        if (!classroom) {
            setErrorInfo({
                displayModal: true,
                modal: {
                    type: "NOTIFICATION",
                    content: {
                        title: intl.formatMessage(dashboardMessages.classroomNotFound),
                    },
                },
            });
            history.push(getClassroomListUrl(config.pages));
        }
    }, [classroom, setErrorInfo, intl, config.pages, history]);

    const [student, setStudent] =
        useState<Pick<Student, "firstname" | "lastname">>();
    useEffect(() => {
        if (
            classroom &&
            classroom.modulesList.length > 0 &&
            classroom.modulesList[0].students[studentId]
        )
            setStudent(classroom.modulesList[0].students[studentId]);
        else
            (async () => {
                try {
                    const classroom = await athenaAPIClient.getClassroom(
                        classroomId
                    );
                    const student = classroom.students.find(
                        (student) => student.id === studentId
                    );
                    if (student)
                        setStudent({
                            firstname: student.first_name,
                            lastname: student.last_name,
                        });
                } catch {}
            })();
    }, [athenaAPIClient, classroomId, dashboard, studentId, classroom]);

    const studentModules = useMemo<ModuleDashboard[] | undefined>(
        () =>
            classroom?.modulesList.filter(
                (module) => module.students[studentId]?.id === studentId
            ),
        [studentId, classroom]
    );

    const isAlert = useMemo<boolean>(() => {
        if (!studentModules) return false;
        if (
            studentModules.some(
                (module) => module.students[studentId].studentInDifficulty
            )
        )
            return true;
        return false;
    }, [studentModules, studentId]);

    const breadcrumbsItems = useMemo<Breadcrumb[]>(() => {
        const breadcrumbs = [
            {
                title: intl.formatMessage({
                    id: "dashboard-common-classes",
                    defaultMessage: "Classes",
                }),
                url: getDashboardUrl(config.pages),
            },
            {
                title: dashboard!.classrooms.find(
                    (classroom) => classroom.id === classroomId
                )!.name,
                url: getSingleClassroomUrl(config.pages, classroomId, intl),
            },
            {
                title: intl.formatMessage({
                    id: "dashboard-common-progression",
                    defaultMessage: "Progression",
                }),
                url: getProgressionOverviewUrl(
                    config.pages,
                    classroomId,
                    data.modules[0].id,
                    intl
                ),
            },
        ];

        if (config.features.clustering)
            breadcrumbs.push({
                title: intl.formatMessage({
                    id: "dashboard-common-groups",
                    defaultMessage: "Groups",
                }),
                url: getClusteringUrl(
                    config.pages,
                    classroomId,
                    data.modules[0].id,
                    intl
                ),
            });

        return breadcrumbs;
    }, [
        classroomId,
        config.features.clustering,
        config.pages,
        dashboard,
        data.modules,
        intl,
    ]);

    if (
        !student ||
        !studentModules ||
        // This happens when navigating from one StudentDetailsPage to another:
        // studentMoules has not yet been updated for the new student
        studentModules.some(
            (module) => typeof module.students[studentId] === "undefined"
        )
    )
        return <Loader />;

    return (
        <>
            <div id="student-details-container">
                <div className="top-container">
                    <div className="back-btn">
                        <button onClick={() => history.goBack()} title={intl.formatMessage({
                            id: "a11y-dashboard-goBackToStudentList",
                            defaultMessage: "Go back to the list of students"
                        })}>
                            <Icon path="arrow_left" size="medium" />
                        </button>
                    </div>

                    <Breadcrumbs items={breadcrumbsItems} />
                </div>

                <div id="student-name-container">
                    {isAlert && <StudentIcon />}

                    <h1 id="student-name">
                        <span>{`${student.firstname} ${student.lastname}`}</span>
                    </h1>
                </div>
                <div id="card-container">
                    <div className="top-cards">
                        <TimeSpentChart
                            studentId={studentId}
                            studentModules={studentModules}
                            prLockStatus={prLockStatus}
                        />

                        {config.features.clustering && (
                            <GroupCard
                                classroomId={classroomId}
                                studentId={studentId}
                                prLockStatus={prLockStatus}
                            />
                        )}
                    </div>

                    {studentModules.length > 0 && (
                        <Progress
                            modules={studentModules}
                            setProgression={setProgression}
                            prLockStatus={prLockStatus}
                        />
                    )}
                </div>

                {progression && (
                    <StudentProgression
                        {...progression}
                        onDismiss={() => {
                            setProgression(undefined);
                        }}
                    />
                )}
            </div>
            { specimen &&
                <SpecimenHelper
                    dialogTitle={intl.formatMessage({id: "specimen-student-details-helper-title", defaultMessage: "The student record"})}
                    dialogDescription={intl.formatMessage({id: "specimen-student-details-helper-description", defaultMessage: "The student record presents all the information concerning the work of a particular student: the time spent on the resource/platform, the group to which the student belongs for each module, the details of his/her individual progress as well as his/her successes and difficulties."})}
                />
            }
        </>
    );
}
