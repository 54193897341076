import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { Item } from "react-stately";
import { useOverlayTriggerState } from "@react-stately/overlays";

import { ClassroomAnalytics } from "../../../../interfaces/Dashboard";
import { Config } from "../../../../interfaces/Config";
import { configStore } from "../../../../contexts/ConfigContext";
import ClassroomName from "./Components/ClassroomName/ClassroomName";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import StudentManager from "./StudentManager/StudentManager";
import { getUrl } from "../../../../utils/dataRetrieval";
import { Classroom, Student, UserType } from "../../../../interfaces/User";
import "./SingleClassroom.scss";
import useAthenaAPIClient from "../../../../hooks/useAthenaAPIClient";
import Icon from "../../../../design-system-components/Icon/Icon";
import { errorStore } from "../../../../contexts/ErrorContext";
import StudentCreator from "../StudentCreator/StudentCreator";
import { dashboardStore } from "../../../../contexts/DashboardContext";
import OverviewTab from "./Components/OverviewTab/OverviewTab";
import Loader from "../../../../components/Loader/Loader";
import Tabs from "../../../../design-system-components/Tabs/Tabs";

const SingleClassroom = () => {
    const intl = useIntl();
    const { config } = useContext(configStore);
    const {
        dashboard,
    } = useContext(dashboardStore);

    const { setErrorInfo } = useContext(errorStore);

    const athenaAPIClient = useAthenaAPIClient();

    const { classroomId } = useParams<{ classroomId: string }>();

    const [classroom, setClassroom] = useState<Classroom>();
    const [students, setStudents] = useState<Student[]>([]);
    const studentCreatorModal = useOverlayTriggerState({})


    const onStudentsAdded = () => {
        fetchStudentList()
        studentCreatorModal.close()
    };

    const renameStudent = (updatedStudent: Student) => {
        const newStudentList = [...students];
        const studentIndex = newStudentList.findIndex(
            (student) => student.id === updatedStudent.id
        )
        if (studentIndex !== -1)
            newStudentList[studentIndex] = updatedStudent;
        setStudents(newStudentList);
    };

    const fetchStudentList = useCallback(async () => {
        try {
            const cr = await athenaAPIClient.getClassroom(classroomId);
            setStudents(cr.students);
            setClassroom(cr);
        } catch (err) {
            setErrorInfo((errorInfo) => {
                return {
                    ...errorInfo,
                    displayModal: true,
                    modal: {
                        type: "POPUP",
                        content: {
                            title: (
                                <>
                                    <Icon
                                        style={{ marginRight: 8 }}
                                        path="warning_outline"
                                        size="medium"
                                    />
                                    {intl.formatMessage(
                                        messages.fetchStudentListErrorTitle
                                    )}
                                </>
                            ),
                            text: (
                                <>
                                    {intl.formatMessage(
                                        messages.fetchStudentListErrorMessage
                                    )}
                                </>
                            ),
                        },
                    },
                };
            });
            console.error("ERROR: fetchStudentList", err);
        }
    }, [athenaAPIClient, classroomId, setErrorInfo, intl]);

    useEffect(() => {
        fetchStudentList();
    }, [fetchStudentList]);

    if (!dashboard) return <Loader />

    return (
        <div className="single-classroom">
            <Breadcrumbs
                items={breadcrumbs(config, dashboard.classrooms, classroomId, intl)}
            />
            {classroom && (
                <>
                    <div className="single-classroom__header">
                        <ClassroomName
                            className="single-classroom__header__name"
                            id={classroomId}
                            name={classroom.name}
                        />
                        {
                            config.features.classCode && (
                                <div className="single-classroom__header__code">
                                    {intl.formatMessage(messages.classroomCode)}{" "}
                                    {classroom.code ?? classroom.external_id}
                                </div>
                            )
                        }
                    </div>
                    {
                        !config.features.studentManagement && (
                            <OverviewTab
                                classroomId={classroomId}
                                onAddUserClick={() => {
                                    studentCreatorModal.open()
                                }}
                            />
                        )
                    }
                    {
                        config.features.studentManagement && (
                            <>
                                <Tabs>
                                    <Item title={intl.formatMessage(messages.overview)} key="overview">
                                        <OverviewTab
                                            classroomId={classroomId}
                                            onAddUserClick={() => {
                                                studentCreatorModal.open()
                                            }}
                                        />
                                    </Item>
                                    <Item title={intl.formatMessage(messages.students)} key="students">
                                        <StudentManager
                                            students={students}
                                            onAddUserClick={() => {
                                                studentCreatorModal.open()
                                            }}
                                            classroom={classroom}
                                            onRenameStudent={(student) => {
                                                renameStudent(student);
                                            }}
                                        />
                                    </Item>
                                </Tabs>
                                {classroom && studentCreatorModal.isOpen && (
                                    <StudentCreator
                                        classroom={classroom}
                                        onEnd={onStudentsAdded}
                                        onCancel={() => studentCreatorModal.close()}
                                        state={studentCreatorModal}
                                    />
                                )}
                            </>
                        )
                    }
                </>
            )}
        </div>
    );
};

const breadcrumbs = (
    config: Config,
    classrooms: ClassroomAnalytics[],
    classroomId: string,
    intl: IntlShape
) => {
    return [
        {
            title: intl.formatMessage(messages.classrooms),
            url: `/${getUrl(
                config.pages.find((page) => page.type === "DASHBOARD")!,
                UserType.Teacher
            ) as string
                }`,
        },
        {
            title: classrooms.find((classroom) => classroom.id === classroomId)!
                .name,
            url: "",
            current: true,
        },
    ];
};

export default SingleClassroom;

export const messages = defineMessages({
    moduleTitle: {
        id: "dashboard-common-modules",
        defaultMessage: "Modules",
    },
    PRMLabel: {
        id: "classList-settingPRMLabel",
        defaultMessage: "Manage resources",
    },
    noStudentsData: {
        id: "dashboard-common-noStudentsData",
        defaultMessage:
            "There is no data at the moment.",
    },
    classrooms: {
        id: "dashboard-common-classes",
        defaultMessage: "Classes",
    },
    overview: {
        id: "singleClassroom-tabOverview",
        defaultMessage: "Overview",
    },
    students: {
        id: "singleClassroom-tabStudent",
        defaultMessage: "Student",
    },
    classroomCode: {
        id: "singleClassroom-classroomCode",
        defaultMessage: "Classroom code:",
    },
    fetchStudentListErrorTitle: {
        id: "singleClassroom-fetchStudentListErrorTitle",
        defaultMessage: "Error",
    },
    fetchStudentListErrorMessage: {
        id: "singleClassroom-fetchStudentListErrorMessage",
        defaultMessage:
            "An error has occured while retrieving the student list",
    },
});
